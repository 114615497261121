import './UserLanguage.scss';
import { RadioButton } from '@carbon/react';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { GlobeIcon } from 'src/modules/common/icons/GlobeIcon';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { getEnvironmentLanguages } from 'src/modules/config/selectors/getEnvironmentLanguages';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';

export const UserLanguage = memo(() => {
  const intl = useIntl();

  const language = useSelector(getLanguage);
  const languages = useSelector(getEnvironmentLanguages);

  const switchLanguage = useAction(CONFIG_LANGUAGE_SWITCH.request);

  return (
    <CustomMenu
      className="bp-language"
      flipped={true}
      iconDescription={intl.formatMessage({ id: 'common/header/language' })}
      tooltipPosition="bottom"
      renderIcon={() => (
        <div className="bp-language__icon">
          <GlobeIcon/>
        </div>
      )}
    >
      {languages.map((languageOption) => (
        <CustomMenuItem
          key={languageOption}
          onClick={() => switchLanguage(languageOption)}
          itemText={(
            <RadioButton
              labelText={intl.formatMessage({ id: `header/user/language/${languageOption}` })}
              value={languageOption}
              checked={language === languageOption}
              id={languageOption}
            />
          )}
          hasDivider={true}
        />
      ))}
    </CustomMenu>
  );
});
