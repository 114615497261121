import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { SHARE_PROJECT_REMOVE_SUBMIT } from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { removeUserFromSharedProject } from 'src/modules/sharing-projects/sagas/utils/removeUserFromSharedProject';
import { getShareProjectProject } from 'src/modules/sharing-projects/selectors/getShareProjectProject';
import { getShareProjectRemovingUser } from 'src/modules/sharing-projects/selectors/getShareProjectRemovingUser';
import { getShareProjectUsers } from 'src/modules/sharing-projects/selectors/getShareProjectUsers';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put, select } from 'typed-redux-saga';

export function* shareProjectRemoveSaga(): SagaIterator<void> {
  const project = yield* select(getShareProjectProject);
  const users = yield* select(getShareProjectUsers);
  const user = yield* select(getShareProjectRemovingUser);

  try {
    yield* call(logDebug, 'Removing user from project...', { project, user });
    yield* put(SHARE_PROJECT_REMOVE_SUBMIT.pending());

    yield* call(removeUserFromSharedProject, user, project);

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'shareProject/notification/removeUser/success',
      },
    );

    yield* call(logDebug, 'Removing user from project... done', { project, user });
    yield* put(SHARE_PROJECT_REMOVE_SUBMIT.success(users.filter((it) => it.id !== user.id)));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(logError, 'Removing user from project... error', { project, user, error });
    yield* put(SHARE_PROJECT_REMOVE_SUBMIT.failure(NativeError.wrap(error)));
  }
}
