import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CoolingIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <path
        fill="currentColor"
        d="m26.163 17.1-4.95 1.287-4.07-2.277 4.07-2.266 4.95 1.287.286-1.067-3.85-1.001
          3.718-2.079-.539-.968-3.762 2.112 1.034-3.74-1.056-.297-1.32 4.796-3.905
          2.178v-4.334l3.586-3.509-.77-.781-2.816 2.75V5h-1.1v4.202l-2.816-2.761-.77.781
          3.586 3.509v4.565l-3.894-2.189-1.32-4.785-1.067.297 1.034 3.74-3.762-2.112-.539.968 3.718
          2.079-3.839.99.275 1.067 4.961-1.287 3.652 2.046-3.652 2.046-4.961-1.276L6 17.947l3.839.99-3.718
          2.079.539.968 3.762-2.112-1.034 3.74 1.067.297 1.32-4.785 3.894-2.189v4.334l-3.586
          3.509.77.781 2.816-2.761V27h1.1v-4.191l2.816 2.75.77-.781-3.586-3.509v-4.103l3.905 2.178 1.32 4.785
          1.056-.286-1.034-3.74 3.762 2.112.539-.968-3.718-2.079 3.85-1.001-.286-1.067Z"
      />
    </svg>
  </IconWrapper>
));
