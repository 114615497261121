import { createSelector } from 'reselect';
import { getUsersState } from 'src/modules/users/selectors/getUsersState';
import { assertNotNull } from 'src/utils/assert';

export const getUserDetailsData = createSelector([
  getUsersState,
], (state) => assertNotNull(
  state.user,
  'User details should be fetch first',
  { state },
));
