import './TitleText.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const TitleText = ({ className, children }: Props): React.ReactElement => (
  <h1 className={clsx('bp-title-text', className)}>
    {children}
  </h1>
);
