import './AppLayout.scss';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { Footer } from 'src/modules/common/components/Footer';
import { Header } from 'src/modules/common/components/Header';
import { FeedbackButton } from 'src/modules/feedback/views/FeedbackButton';
import { NOTIFICATION_HIDE } from 'src/modules/notifications/actions/NotificationActions';
import { getNotification } from 'src/modules/notifications/selectors/getNotification';
import { Notification } from 'src/modules/notifications/views/Notification';

type Props = {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly classNameBody?: string;
};

export const AppLayout = ({ children, className, classNameBody }: Props): React.ReactElement => {
  const notifications = useSelector(getNotification);

  const hideNotification = useAction(NOTIFICATION_HIDE.trigger);

  return (
    <div className={clsx('bp-app-layout', className)}>

      <Header/>

      <main className={clsx('bp-app-layout__body', classNameBody)}>
        {children}
      </main>

      <Footer/>

      <FeedbackButton/>
      <Notification handleClose={hideNotification} notifications={notifications}/>

    </div>
  );
};
