import './TileCard.scss';
import React, { memo } from 'react';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { DesktopLargeText } from 'src/modules/common/typography/DesktopLargeText';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
};

export const TileCard = memo(({ title, subtitle, children }: Props) => (
  <div className="bp-tile-card">
    <div className="bp-tile-card__header">
      <LeadText as="div">
        {title}
      </LeadText>

      {subtitle ? (
        <DesktopLargeText as="div">
          {subtitle}
        </DesktopLargeText>
      ) : null}
    </div>

    <HorizontalDivider/>

    {children}
  </div>
));
