import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { ChangePasswordData } from 'src/modules/profile/types/ChangePasswordData';

export const CHANGE_PASSWORD_SCHEMA: FormRule<ChangePasswordData> = R.allOf([
  R.struct({
    confirmation: R.pass(),
    password: R.allOf([
      R.nonEmpty('profile/form/section/changePassword/error/empty'),
      R.minLength(8, 'profile/form/section/changePassword/error/short'),
      R.maxLength(30, 'profile/form/section/changePassword/error/long'),
      R.match(/\d/, 'profile/form/section/changePassword/error/noDigits'),
      R.match(/[a-z]/i, 'profile/form/section/changePassword/error/noLetters'),
      R.match(/^\S+$/, 'profile/form/section/changePassword/error/spaces'),
    ]),
  }),
  R.matchField(
    'confirmation',
    'password',
    'profile/form/section/changePassword/error/mismatch',
  ),
]);
