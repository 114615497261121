import { HtmlNode, parse as parseHtmlToPdf } from '@xiag/html2pdf/lib/parse';
import { SagaIterator } from 'redux-saga';
import { doOnPdfWorker } from 'src/sagas/utils/doOnPdfWorker';
import { HtmlToPdfWorkerRequest } from 'src/types/worker/HtmlToPdfWorker';
import { call } from 'typed-redux-saga';

export function* htmlToPdf(
  pages: ReadonlyArray<Element>,
): SagaIterator<Blob> {
  const nodes: HtmlNode[] = [];
  for (const child of pages) {
    const pageNode = yield* call(parseHtmlToPdf, child);
    nodes.push(pageNode);
  }

  const imageUrls = getImageUrls(nodes);
  const resources = new Map<string, ArrayBuffer>();
  for (const imageUrl of imageUrls) {
    const response = yield* call(fetch, imageUrl);
    const imageData = yield* call({ fn: response.arrayBuffer, context: response });
    resources.set(imageUrl, imageData);
  }

  const workerRequest: HtmlToPdfWorkerRequest = { nodes, resources };
  return yield* call(doOnPdfWorker, workerRequest);
}

function getImageUrls(nodes: ReadonlyArray<HtmlNode>): string[] {
  const imageUrls: string[] = [];
  for (const node of nodes) {
    if (node.type === 'block') {
      imageUrls.push(...getImageUrls(node.children));
    }
    if (node.type === 'image') {
      imageUrls.push(node.imageSource);
    }
  }

  return imageUrls;
}
