import { SagaIterator } from 'redux-saga';
import { USERS_LIST_ABORT } from 'src/modules/users/actions/UsersActions';
import { logDebug } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* abortUsersList(): SagaIterator<void> {
  yield* call(logDebug, 'Aborting users list operations...');
  yield* put(USERS_LIST_ABORT.trigger());
  yield* call(logDebug, 'Aborting users list operations... done');
}
