import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { USER_DETAILS_FETCH, USER_DETAILS_RESET } from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function userDetailsDataReducer(state: UsersState, action: Action): UsersState {
  if (USER_DETAILS_FETCH.is(action)) {
    return {
      ...state,
      user: action.data,
    };
  }
  if (USER_DETAILS_RESET.is(action)) {
    return pipe(
      O.id<UsersState>(),
      O.prop('user'),
      O.filter((user) => user?.id === action.meta),
      O.modify(() => null),
    )(state);
  }
  return state;
}
