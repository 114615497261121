import { SagaIterator } from 'redux-saga';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { Organization } from 'src/modules/common/types/Organization';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put, select } from 'typed-redux-saga';

export function* updateUserSaga(organization: Organization): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Updating the user...');

    const user = yield* select(getLoggedInUser);

    const updatedUser: AuthUser = {
      ...user,
      organization,
    };

    yield* put(WHOAMI_ACTION.trigger(updatedUser));
    yield* call(logDebug, 'User updated...', updatedUser);
  } catch (error) {
    yield* call(logError, 'User updating error', { error });
    throw error;
  }
}
