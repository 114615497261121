import { SagaIterator } from 'redux-saga';
import { ROUTING_PRE_HOOKS, ROUTING_POST_HOOKS } from 'src/modules/routing/constants/RoutingHooks';
import { Transition } from 'src/modules/routing/types/Transition';
import { getStatesToEnter, getStatesToLeave, getStatesToRetain } from 'src/modules/routing/utils/transitionUtils';
import { call } from 'typed-redux-saga';

export function* performPreTransition(transition: Transition): SagaIterator<void> {
  for (const preHook of ROUTING_PRE_HOOKS) {
    yield* call(preHook, transition);
  }

  const statesToLeave = yield* call(
    getStatesToLeave,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToLeave) {
    if (state.state.onLeaving) {
      yield* call(state.state.onLeaving, transition, state);
    }
  }

  const statesToRetain = yield* call(
    getStatesToRetain,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToRetain) {
    if (state.state.onRetaining) {
      yield* call(state.state.onRetaining, transition, state);
    }
  }

  const statesToEnter = yield* call(
    getStatesToEnter,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToEnter) {
    if (state.state.onEntering) {
      yield* call(state.state.onEntering, transition, state);
    }
  }
}

export function* performPostTransition(transition: Transition): SagaIterator<void> {
  const statesToLeave = yield* call(
    getStatesToLeave,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToLeave) {
    if (state.state.onLeft) {
      yield* call(state.state.onLeft, transition, state);
    }
  }

  const statesToRetain = yield* call(
    getStatesToRetain,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToRetain) {
    if (state.state.onRetained) {
      yield* call(state.state.onRetained, transition, state);
    }
  }

  const statesToEnter = yield* call(
    getStatesToEnter,
    transition.to.match,
    transition.from?.match ?? null,
  );
  for (const state of statesToEnter) {
    if (state.state.onEntered) {
      yield* call(state.state.onEntered, transition, state);
    }
  }

  for (const postHook of ROUTING_POST_HOOKS) {
    yield* call(postHook, transition);
  }
}
