import './ReportFormSectionConfig.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { Information } from '@carbon/icons-react';
import { FieldPath } from '@form-ts/core';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { Project } from 'src/modules/common/types/Project';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { CheckboxField } from 'src/modules/form/components/CheckboxField';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { FormError } from 'src/modules/form/types/FormError';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportFormFieldToggle } from 'src/modules/report/views/ReportFormFieldToggle';

type Props = {
  readonly area: SchemaArea;
  readonly field: FieldPath<ReportFormData, FormError, ReportFormData['schema']>;
  readonly project: Project;
};

export const ReportFormSectionConfigSchema = ({ area, field, project }: Props): React.ReactElement => {
  const schemaData = project.data.schema[area];
  const futureOptions = useMemo(() => schemaData?.future.map((it) => it?.id) ?? [], [schemaData]);

  const isSchemaMissing = schemaData === null;
  const isCommentMissing = !(schemaData?.comment.length);

  return (
    <div className="bp-report-form-section-config">
      <div className="bp-report-form-section-config__header">
        <LeadText className="bp-report-form-section-config__header-title">
          {AREA_NAMES[area]}
        </LeadText>

        <CheckboxField field={field.at(area).at('enabled')} disabled={isSchemaMissing} labelText=""/>
      </div>

      <HorizontalDivider/>

      <div className="bp-report-form-section-config__content">
        <ReportFormFieldToggle field={field.at(area).at('showComments')} disabled={isSchemaMissing || isCommentMissing}>
          <FormattedMessage id="report/form/schema/showComments"/>
        </ReportFormFieldToggle>

        <HorizontalDivider/>

        <ReportFormFieldToggle field={field.at(area).at('showZoomedIn')} disabled={isSchemaMissing}>
          <FormattedMessage id="report/form/schema/showZoomedIn"/>
        </ReportFormFieldToggle>

        <div className="bp-report-form-section-config__with-hint">
          <HorizontalDivider/>

          <div className="bp-report-form-section-config__hint">
            <Information/>

            <FormattedMessage id="report/form/schema/zoomedInNote"/>
          </div>
        </div>

        <DropdownField
          size="md"
          field={field.at(area).at('futureVersion')}
          options={futureOptions}
          titleText={<FormattedMessage id="report/form/schema/futureVersion"/>}
          itemToElement={renderFutureVariantOption}
          renderSelectedItem={renderFutureVariantOption}
          disabled={isSchemaMissing}
        />
      </div>

      <div className="bp-report-form-section-config__action">
        <CustomButton
          fullWidth={true}
          as={Link}
          to={`/project/${encodeURIComponent(project.id)}/schema`}
          kind="tertiary"
          size="md"
          renderIcon={() => (
            <div className="bp-report-form-section-config__button-icon">
              <ArrowRightIcon/>
            </div>
          )}
        >
          <FormattedMessage id="report/form/schema/editLinkText"/>
        </CustomButton>
      </div>
    </div>
  );
};

function renderFutureVariantOption(option: number): React.ReactElement {
  return <FormattedMessage id="schemaEdit/version/future" values={{ number: option }}/>;
}

const AREA_NAMES = {
  [SchemaArea.HEATING]: <FormattedMessage id="report/form/schema/header/heating"/>,
  [SchemaArea.COOLING]: <FormattedMessage id="report/form/schema/header/cooling"/>,
  [SchemaArea.VENTILATION]: <FormattedMessage id="report/form/schema/header/ventilation"/>,
};
