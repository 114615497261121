import { createSelector } from 'reselect';
import { getProjectViewState } from 'src/modules/project-view/selectors/getProjectViewState';
import { assertNotNull } from 'src/utils/assert';

export const getProjectViewData = createSelector([
  getProjectViewState,
], (state) => assertNotNull(
  state.data,
  'Project details should be fetched first',
  { state },
));
