import './PasswordSetupForm.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { PasswordField } from 'src/modules/form/components/PasswordField';
import { FormError } from 'src/modules/form/types/FormError';
import { PasswordResetFormData } from 'src/modules/password-reset/types/PasswordResetFormData';

type Props = {
  readonly form: FormController<PasswordResetFormData, FormError>;
  readonly onSubmit: () => void;
};

export const PasswordSetupForm = ({ form, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();
  const renderError = useCallback((e: FormError) => intl.formatMessage({ id: e.message }, e.context), [intl]);

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const isSubmitting = useFormWatch(form, form.submitting.get);

  return (
    <form
      className="bp-password-setup-form"
      noValidate={true}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <PasswordField
        wrapperClass="bp-password-setup-form__field"
        field={form.field.at('password')}
        labelText={intl.formatMessage({ id: 'passwordSetup/password/label' })}
        renderError={renderError}
        type="password"
        size="md"
        disabled={isSubmitting}
        autoComplete="current-password"
        spellCheck={false}
        autoCorrect="off"
        autoCapitalize="off"
      />

      <PasswordField
        wrapperClass="bp-password-setup-form__field"
        field={form.field.at('confirmation')}
        labelText={intl.formatMessage({ id: 'passwordSetup/confirmation/label' })}
        renderError={renderError}
        type="password"
        size="md"
        disabled={isSubmitting}
        autoComplete="current-password"
        spellCheck={false}
        autoCorrect="off"
        autoCapitalize="off"
      />

      <CustomButton
        kind="primary"
        type="submit"
        className="bp-password-setup-form__submit"
        loading={isSubmitting}
        isFullWidth={true}
        size="md"
        renderIcon={() => (
          <div className="bp-password-setup-form__submit-icon">
            <ArrowRightIcon/>
          </div>
        )}
      >
        <FormattedMessage id="passwordSetup/submit"/>
      </CustomButton>
    </form>
  );
};
