import { HvacArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ReportGeneratedSavingsAnnualValues } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsEnergyRelative = createSelector([
  getHvacResults,
], (results): ReportGeneratedSavingsAnnualValues => ({
  heating: results.perArea[HvacArea.HEATING].savings.thermal.relative,
  cooling: results.perArea[HvacArea.COOLING].savings.thermal.relative,

  total: results.overall.savings.overall.relative,
  electricity: results.overall.savings.electrical.relative,
}));
