import { useForm, useFormWatch } from '@form-ts/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { AdminLayout } from 'src/modules/common/layouts/AdminLayout';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { UserRole } from 'src/modules/common/types/UserRole';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { USER_SAVE } from 'src/modules/users/actions/UserActions';
import { UserActions } from 'src/modules/users/components/UserActions';
import { USER_FORM_SCHEMA } from 'src/modules/users/constants/schema';
import { AddUserForm } from 'src/modules/users/views/AddUserForm';

export const AddUserPage = (): React.ReactElement => {
  const intl = useIntl();

  const saveUser = useAction(USER_SAVE.request);

  const form = useForm<UserFormData, FormError>('admin.users.add', {
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: UserRole.USER,
    },
    reinitialize: false,
  });
  useFormValidator(form, USER_FORM_SCHEMA);

  const submitting = useFormWatch(form, form.submitting.get);

  const handleSave = (): void => saveUser(form, null);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'admin/user/add/title' })}</title>
      </Helmet>

      <AdminLayout
        title={intl.formatMessage({ id: 'admin/user/add/header/title' })}
        breadcrumbs={(
          <>
            <CustomBreadcrumbItem>
              <Link to="/projects">
                {intl.formatMessage({ id: 'admin/users/header/breadcrumbs/project' })}
              </Link>
            </CustomBreadcrumbItem>

            <CustomBreadcrumbItem>
              <Link to="/admin/users">
                {intl.formatMessage({ id: 'admin/user/header/breadcrumbs/users' })}
              </Link>
            </CustomBreadcrumbItem>
          </>
        )}
        extension={<UserActions onSave={handleSave} submitting={submitting}/>}
      >
        <AddUserForm form={form}/>
      </AdminLayout>
    </>
  );
};
