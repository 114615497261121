import './DecimalField.scss';
import './Field.scss';
import { TextInput } from '@carbon/react';
import { TextInputProps } from '@carbon/react/lib/components/TextInput/TextInput';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import CurrencyInput, { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DECIMAL_PLACES } from 'src/modules/common/constants/decimal';
import { getLocale } from 'src/modules/config/selectors/getLocale';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
TextInputProps,
'className' |
'id' |
'name' |
'invalid' |
'value' |
'onChange' |
'onBlur' |
'inputMode' |
'pattern' |
'allowEmpty'
>;

type FieldProps<TData> =
  & BaseProps
  & DataProps<TData>
  & StyleProps;

type InputProps =
  & BaseProps
  & ViewProps
  & StyleProps;

type DataProps<TData> = {
  readonly maxValue?: number;
  readonly decimalPlaces?: number;
  readonly decimalScale?: number;
  readonly field: FieldPath<TData, FormError, Decimal | null>;
};
type ViewProps = {
  readonly id: string;
  readonly name: string;
  readonly invalid: boolean;
  readonly error: FormError | undefined;
  readonly value: string;
};
type StyleProps = {
  readonly size: 'sm' | 'md' | 'lg';
  readonly theme?: 'gray' | 'white';
  readonly wrapperClass?: string;
  readonly endAdornment?: ReactNode;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const DecimalField: <TData>(props: FieldProps<TData>) => React.ReactElement = ({
  field,
  decimalPlaces = DECIMAL_PLACES,
  decimalScale,
  maxValue,
  ...restProps
}) => {
  const locale = useSelector(getLocale);
  const intlConfig = useMemo(() => ({ locale }), [locale]);

  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const [viewValue, setViewValue] = useState(() => value?.toString() ?? '');
  useEffect(() => {
    try {
      if (viewValue.trim()) {
        const parsed = new Decimal(viewValue);
        if (value === null) {
          setViewValue('');
        } else if (!value.equals(parsed)) {
          setViewValue(value.toString());
        }
      } else if (value !== null) {
        setViewValue(value.toString());
      }
    } catch {
      // noop
    }
  }, [value, viewValue]);

  const handleChange = useCallback((
    _1: unknown,
    _2: unknown,
    values: CurrencyInputOnChangeValues | undefined,
  ) => {
    if (!values) {
      return;
    }

    if (Number(values.float) > (maxValue || Number.MAX_SAFE_INTEGER)) {
      return;
    }

    const nextValue = values.float !== null
      ? new Decimal(values.float)
      : null;

    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));

    setViewValue(values.value);
  }, [field, maxValue]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <CurrencyInput
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      value={viewValue}
      data-value={value?.toNumber()}
      // CurrencyInput passes all props to "customInput" (see implementation)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      error={error}
      invalid={invalid}
      onBlur={handleBlur}
      onValueChange={handleChange}
      decimalScale={decimalScale}
      decimalsLimit={decimalPlaces}
      allowDecimals={decimalPlaces > 0}
      allowNegativeValue={false}
      disableGroupSeparators={false}
      disableAbbreviations={true}
      formatValueOnBlur={true}
      customInput={DecimalInput}
      intlConfig={intlConfig}
      {...restProps}
    />
  );
};

const DecimalInput = React.forwardRef((props: InputProps, ref: React.Ref<unknown>): React.ReactElement => {
  const {
    error,
    invalid,
    size,
    theme = 'gray',
    disabled,
    wrapperClass,
    endAdornment,
    labelText,
    renderError = renderErrorDefault,
    ...restProps
  } = props;

  return (
    <div className="bp-field-wrapper bp-decimal-field-wrapper">
      <TextInput
        {...restProps}
        ref={ref}
        disabled={disabled}
        labelText={labelText}
        className={clsx(
          'bp-field',
          'bp-decimal-field',
          `bp-decimal-field--${size}`,
          `bp-decimal-field--${theme}`,
          {
            'bp-decimal-field--invalid': invalid,
            'bp-decimal-field--disabled': disabled,
            'bp-decimal-field--end-adorned': endAdornment,
          },
          wrapperClass,
        )}
        invalid={invalid}
        invalidText={error && invalid ? renderError(error) : null}
      />

      <div className={clsx(
        'bp-decimal-field__end-adornment',
        {
          'bp-decimal-field__end-adornment-with-label': labelText,
        },
      )}
      >
        {endAdornment}
      </div>
    </div>
  );
});

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);
