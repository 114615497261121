import { NativeError, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { call, select } from 'typed-redux-saga';

export function* deleteUser(userId: Uuid): SagaIterator<void> {
  const authUser = yield* select(getLoggedInUser);

  try {
    yield* call(
      makeRestRequest,
      'DELETE',
      `/organizations/${encodeURIComponent(authUser.organization.id)}/users/${encodeURIComponent(userId)}`,
    );
  } catch (error) {
    throw new RuntimeError('Could not delete user', { userId }, NativeError.wrap(error));
  }
}
