export const CARBON_THEME = {
  belimoOrangeColor: '#ff6600',
  additionalSuccess20: '#B6D6A4',
  additionalSuccess100: '#517D34',
  additionalSuccess125: '#236119',
  additionalDanger20: '#F8BBB8',
  additionalDanger100: '#C33224',
  additionalDanger125: '#A02E23',
  surface04: '#c3c3c3',
  blue04: '#375f80',
  onSurfaceLightPrimary: '#000000',
  onSurfaceLightSecondary: '#2c2b2b',
  onInteractionDark: '#000000',
};
