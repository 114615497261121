import './OrganizationSettings.scss';
import { FormController } from '@form-ts/core';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { TileCard } from 'src/modules/common/components/TileCard';
import { AssetField } from 'src/modules/form/components/AssetField';
import { FormError } from 'src/modules/form/types/FormError';
import { OrganizationFormData } from 'src/modules/organization/types/OrganizationFormData';

type Props = {
  form: FormController<OrganizationFormData, FormError>;
};

export const OrganizationSettings = memo(({ form }: Props) => (
  <div className="bp-organization-settings">
    <div className="bp-organization-settings__contents">
      <TileCard title={<FormattedMessage id="organization/settings/logo/title"/>}>
        <FormattedMessage id="organization/settings/logo/label"/>

        <AssetField field={form.field.at('logo')}/>
      </TileCard>
    </div>
  </div>
));
