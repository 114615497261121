import './ErrorPage.scss';
import { BaseError } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { isHttpError } from 'src/modules/api/utils/isHttpError';
import { ErrorView } from 'src/modules/error/views/ErrorView';
import { RouteNotFoundError } from 'src/modules/routing/errors/RouteNotFoundError';

type Props = {
  readonly error: BaseError;
};

export const ErrorPage = ({ error }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'errorPage/title' })}
        </title>
      </Helmet>

      <div className="bp-error-page">
        {error instanceof RouteNotFoundError
          ? <ErrorView errorCode={404}/>
          : isHttpError(error, 404)
            ? <ErrorView errorCode={404}/>
            : isHttpError(error, 401)
              ? <ErrorView errorCode={401}/>
              : <ErrorView errorCode={500}/>}
      </div>
    </>
  );
};
