import { LOCATION_CHANGE } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { locationChangeSaga } from 'src/modules/routing/sagas/locationChangeSaga';
import { routeChangeSaga } from 'src/modules/routing/sagas/routeChangeSaga';
import { takeLatest } from 'typed-redux-saga';

export function* routingSaga(): SagaIterator<void> {
  yield* takeLatest(LOCATION_CHANGE, locationChangeSaga);
  yield* takeLatest(ROUTE_CHANGE.isRequest, routeChangeSaga);
}
