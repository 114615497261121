import './HvacResultSummaryChapter.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import { modify } from 'monocle-ts/Lens';
import React, { useCallback } from 'react';
import { CustomToggle } from 'src/modules/common/components/CustomToggle';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormChapterData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormChapterData>;
  readonly title: React.ReactNode;
  readonly icon: React.ReactNode;
};

export const HvacResultSummaryChapter = ({ path, title, icon }: Props): React.ReactElement => {
  const enabled = useFormWatch(path.form, path.at('enabled').value.get);

  const handleToggle = useCallback(() => {
    path.form.change(pipe(
      path.form.currentState,
      pipe(
        path.at('enabled').value,
        modify((value) => !value),
      ),
    ));
  }, [path]);

  return (
    <div className="bp-hvac-result-summary-chapter">
      <div className="bp-hvac-result-summary-chapter__control">
        <div className="bp-hvac-result-summary-chapter__icon">
          {icon}
        </div>

        <CustomToggle
          id={`${path.form.name}.${path.path}`}
          hideLabel={true}
          toggled={enabled}
          onToggle={handleToggle}
        />
      </div>

      <div className="bp-hvac-result-summary-chapter__name">
        <BodySmallText className="bp-hvac-result-summary-chapter__title">
          {title}
        </BodySmallText>
      </div>
    </div>
  );
};
