import { SagaIterator } from 'redux-saga';
import { ShadowHost } from 'src/modules/report/types/ShadowHost';
import { call, delay } from 'typed-redux-saga';

export function* waitReportDocument(host: ShadowHost): SagaIterator<void> {
  do {
    if (
      (yield* call(checkImageState, host.element)) &&
      (yield* call(checkReadyState, host.element))
    ) {
      return;
    }

    yield* call(delay, 50);
  } while (true);
}

function checkImageState(element: Element): boolean {
  return Array.from(element.querySelectorAll('img')).every((it) => it.complete);
}

function checkReadyState(element: Element): boolean {
  return Array.from(element.querySelectorAll('[data-ready]')).every((it) => {
    const value = it.getAttribute('data-ready');
    return value === null || value === 'true';
  });
}
