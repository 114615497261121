import './UsersListBody.scss';
import { Integer } from '@belimo-retrofit-portal/logic';
import { PaginationNav, Stack } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { UserRole } from 'src/modules/common/types/UserRole';
import { USERS_LIST_PAGE_SIZE } from 'src/modules/users/constants/pageSize';
import { UsersListData } from 'src/modules/users/types/UsersListData';
import { UsersListQuery } from 'src/modules/users/types/UsersListQuery';
import { buildUsersListSearchParams } from 'src/modules/users/utils/query';

type Props = {
  query: UsersListQuery;
  users: UsersListData;
};

export const UsersListBody = ({ query, users }: Props): React.ReactElement => {
  const intl = useIntl();

  const history = useHistory();
  const totalPages = Math.ceil(users.total / USERS_LIST_PAGE_SIZE);

  const handlePageChange = (page: number): void => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const searchParams = buildUsersListSearchParams({ ...query, page: page + 1 as Integer });
    history.push({ search: `?${searchParams.toString()}` });
  };

  return (
    <Stack as="main" className="bp-users-list-body" gap="0.5rem" orientation="vertical">
      <Stack className="bp-users-list-body__list-wrapper" gap="0.5rem" orientation="vertical">
        <Stack
          className="bp-users-list-body__list-row bp-users-list-body__list-row--header"
          gap="0"
          orientation="horizontal"
        >
          <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
            {intl.formatMessage({ id: 'admin/user/form/field/lastName' })}
          </span>

          <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
            {intl.formatMessage({ id: 'admin/user/form/field/firstName' })}
          </span>

          <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
            {intl.formatMessage({ id: 'admin/user/form/field/username' })}
          </span>

          <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
            {intl.formatMessage({ id: 'admin/user/form/field/role' })}
          </span>
        </Stack>

        {users.found.map(({ email, firstName, id, lastName, role }) => (
          <Link
            className="bp-users-list-body__list-row bp-users-list-body__list-row--link-button"
            to={`/admin/users/${encodeURIComponent(id)}/edit`}
            key={id}
          >
            <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
              {lastName}
            </span>

            <span className="bp-users-list-body__list-cell bp-users-list-body__list-cell--regular-weight">
              {firstName}
            </span>

            <span className="bp-users-list-body__list-cell">{email}</span>

            <span className="bp-users-list-body__list-cell">{ROLE_NAMES[role]}</span>

            <div className="bp-users-list-body__list-cell bp-users-list-body__list-cell--actions">
              <ArrowRightIcon/>
            </div>
          </Link>
        ))}
      </Stack>

      {users.total >= USERS_LIST_PAGE_SIZE && (
        <PaginationNav
          className="bp-users-list-body__pagination"
          itemsShown={USERS_LIST_PAGE_SIZE}
          onChange={handlePageChange}
          page={query.page - 1}
          totalItems={totalPages}
        />
      )}
    </Stack>
  );
};

const ROLE_NAMES = {
  [UserRole.USER]: <FormattedMessage id="common/role/user"/>,
  [UserRole.ADMIN]: <FormattedMessage id="common/role/admin"/>,
};
