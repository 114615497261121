import { replace } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { RouteHook } from 'src/modules/routing/types/RouteHook';
import { logDebug } from 'src/sagas/utils/logging';
import { call, cancel, put } from 'typed-redux-saga';

export function redirectTo(location: LocationDescriptorObject): RouteHook {
  return function* goto() {
    yield* call(logDebug, 'ROUTER. Redirecting to', location);
    yield* put(replace(location));
    yield* cancel();
  };
}
