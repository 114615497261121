import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import { MultipleSchemaFilterForm } from 'src/modules/schema/types/SchemaSelectionState';

export const DEFAULT_FILTER_FORM_DATA: SchemaFilterForm = {
  waterToAir: null,
  changeOverLevel: null,
  differentTemperatureLevel: null,
};

export const DEFAULT_MULTIPLE_FILTER_FORM_DATA: MultipleSchemaFilterForm = {
  [SchemaArea.HEATING]: DEFAULT_FILTER_FORM_DATA,
  [SchemaArea.COOLING]: DEFAULT_FILTER_FORM_DATA,
  [SchemaArea.VENTILATION]: DEFAULT_FILTER_FORM_DATA,
};
