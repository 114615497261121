import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/types/RootState';

export function useParametricSelector<TResult, TParameter>(
  selector: (state: RootState, param: TParameter) => TResult,
  param: TParameter,
): TResult {
  const plainSelector = useCallback((state: RootState) => selector(state, param), [selector, param]);
  return useSelector(plainSelector);
}
