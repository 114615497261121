import './ReportDocumentPageSchemaCanvas.scss';
import { Matrix } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { SvgFile } from 'src/modules/common/components/SvgFile';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import {
  SCHEMA_CONTAINER_SIZE,
} from 'src/modules/schema/constants/schema';
import { SchemaContainer } from 'src/modules/schema/types/SchemaContainer';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { SchemaDeviceView } from 'src/modules/schema/views/canvas/SchemaDeviceView';

type Props = {
  readonly schema: ConfigSchema;
  readonly version: SchemaVersion;
  readonly matrix: Matrix;
};

export const ReportDocumentPageSchemaCanvas = ({ schema, version, matrix }: Props): React.ReactElement => {
  const transformLine = [
    matrix.a.toFixed(5),
    matrix.b.toFixed(5),
    matrix.c.toFixed(5),
    matrix.d.toFixed(5),
    matrix.e.toFixed(5),
    matrix.f.toFixed(5),
  ].join(' ');

  return (
    <svg
      viewBox={`0 0 ${schema.width} ${schema.height}`}
      className="bp-report-document-page-schema-canvas"
    >
      <g transform={`matrix(${transformLine})`}>
        <SvgFile
          urls={[`/images/schemas/${encodeURIComponent(schema.id)}.svg`]}
          width={schema.width}
          height={schema.height}
        />
        {version.assignments.map((assignment) => assignment.selection && (
          <DeviceShape
            key={assignment.container.id}
            container={assignment.container}
            selection={assignment.selection}
          />
        ))}
      </g>
    </svg>
  );
};

type DeviceProps = {
  readonly container: SchemaContainer;
  readonly selection: SchemaDevice;
};

const DeviceShape = ({ container, selection }: DeviceProps): React.ReactElement => {
  const position = {
    x: container.position.x,
    y: container.position.y,
  };

  return (
    <g transform={`translate(${position.x} ${position.y})`}>
      <rect
        x={0}
        y={0}
        width={SCHEMA_CONTAINER_SIZE.x}
        height={SCHEMA_CONTAINER_SIZE.y}
        rx={4}
        ry={4}
        fill="white"
        stroke="none"
      />
      <SchemaDeviceView
        size={SCHEMA_CONTAINER_SIZE}
        device={selection}
      />
    </g>
  );
};
