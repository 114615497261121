import { CallHistoryMethodAction } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { put, take } from 'typed-redux-saga';

export function* navigate(
  action: CallHistoryMethodAction,
): SagaIterator<void> {
  yield* put(action);

  const result = yield* take([
    ROUTE_CHANGE.isSuccess,
    ROUTE_CHANGE.isFailure,
  ]);
  if (ROUTE_CHANGE.isFailure(result)) {
    throw result.data;
  }
}
