import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

type Props = {
  readonly className?: string;
};

export const LoadingIcon = memo(
  ({ className }: Props): React.ReactElement => (
    <div className={className}>
      <IconWrapper>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
          // eslint-disable-next-line max-len
            d="M12.1 18.6998C10.2296 18.6998 8.64534 18.0511 7.34722 16.7538C6.04909 15.4564 5.40002 13.8731 5.40002 12.0038C5.40002 10.1345 6.04909 8.5498 7.34722 7.2498C8.64534 5.9498 10.2296 5.2998 12.1 5.2998C13.3 5.2998 14.4167 5.59564 15.45 6.1873C16.4834 6.77897 17.3 7.5998 17.9 8.6498V5.2998H18.6V9.9998H13.9V9.2998H17.45C16.9334 8.28314 16.2 7.47897 15.25 6.8873C14.3 6.29564 13.25 5.9998 12.1 5.9998C10.4334 5.9998 9.01669 6.58314 7.85002 7.7498C6.68336 8.91647 6.10002 10.3331 6.10002 11.9998C6.10002 13.6665 6.68336 15.0831 7.85002 16.2498C9.01669 17.4165 10.4334 17.9998 12.1 17.9998C13.3834 17.9998 14.5417 17.6331 15.575 16.8998C16.6084 16.1665 17.3334 15.1998 17.75 13.9998H18.5C18.0667 15.4165 17.2625 16.554 16.0875 17.4123C14.9125 18.2706 13.5834 18.6998 12.1 18.6998Z"
            fill="currentColor"
          />
        </svg>
      </IconWrapper>
    </div>
  ),
);
