import { ROUTING_ADMIN_STATES } from 'src/modules/routing/states/AdminStates';
import { ROUTING_HOME_STATES } from 'src/modules/routing/states/HomeStates';
import { ROUTING_LAYOUT_STATES } from 'src/modules/routing/states/LayoutStates';
import { ROUTING_LOGIN_STATES } from 'src/modules/routing/states/LoginStates';
import { ROUTING_ORGANIZATION_STATES } from 'src/modules/routing/states/OrganizationStates';
import { ROUTING_PROFILE_STATE } from 'src/modules/routing/states/ProfileState';
import { ROUTING_PROJECT_STATES } from 'src/modules/routing/states/ProjectStates';
import { RouteState } from 'src/modules/routing/types/RouteState';

export const ROUTING_STATES: ReadonlyArray<RouteState> = [
  ...ROUTING_LAYOUT_STATES,
  ...ROUTING_LOGIN_STATES,
  ...ROUTING_HOME_STATES,
  ...ROUTING_PROJECT_STATES,
  ...ROUTING_ADMIN_STATES,
  ...ROUTING_PROFILE_STATE,
  ...ROUTING_ORGANIZATION_STATES,
];
