import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const decoder: D.Decoder<unknown, number> = pipe(
  D.string,
  D.parse((input) => {
    const value = +input;
    return Number.isNaN(value) || input.trim() === ''
      ? D.failure(input, 'NumberFromString')
      : D.success(value);
  }),
);

const encoder: E.Encoder<string, number> = {
  encode: String,
};

export const NUMBER_FROM_STRING = C.make(decoder, encoder);
