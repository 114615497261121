import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { PROJECT_LIST_SHARE } from 'src/modules/project-list/actions/ProjectListActions';
import { getProjectListQuery } from 'src/modules/project-list/selectors/getProjectListQuery';
import { getProjectListSlice } from 'src/modules/project-list/selectors/getProjectListSlice';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';
import { navigate } from 'src/modules/routing/utils/navigate';
import {
  SHARE_PROJECT_CLOSE,
  SHARE_PROJECT_OPEN,
  SHARE_PROJECT_REMOVE_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, put, select, take } from 'typed-redux-saga';

export function* projectListShareSaga(
  action: GetTriggerActionType<typeof PROJECT_LIST_SHARE>,
): SagaIterator<void> {
  const project = action.data;
  const loggedInUser = yield* select(getLoggedInUser);

  yield* put(SHARE_PROJECT_OPEN.trigger(project));
  const trigger = yield* take([SHARE_PROJECT_REMOVE_SUBMIT.isSuccess, SHARE_PROJECT_CLOSE.is]);
  if (SHARE_PROJECT_CLOSE.is(trigger)) {
    // the Share Project modal was closed
    // there is no need to do anything
    return;
  }

  const hasAccess = (
    project.owner?.id === loggedInUser.id ||
    trigger.data.some((it) => it.id === loggedInUser.id)
  );
  if (hasAccess) {
    // we still have access to the project
    // there is no need to do anything
    return;
  }

  // the project is not accessible for us anymore
  // we need to close the modal immediately
  yield* put(SHARE_PROJECT_CLOSE.trigger());

  // and reload the project list
  const projects = yield* select(getProjectListSlice);
  const isOnlyProject = projects.found.every((it) => it.project.id === project.id);
  if (isOnlyProject) {
    const query = yield* select(getProjectListQuery);
    const search = yield* call(buildProjectListQuery, { ...query, page: INTEGER_ONE });
    yield* call(navigate, replace({ pathname: '/projects', search: search.toString() }));
  } else {
    const query = yield* select(getProjectListQuery);
    const search = yield* call(buildProjectListQuery, query);
    yield* call(navigate, replace({ pathname: '/projects', search: search.toString() }));
  }
}
