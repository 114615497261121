import { LocationChangeAction } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { getIsRoutingBlocked } from 'src/modules/routing/selectors/getIsRoutingBlocked';
import { put, select } from 'typed-redux-saga';

export function* locationChangeSaga({ payload: { location } }: LocationChangeAction): SagaIterator<void> {
  const isBlocked = yield* select(getIsRoutingBlocked);
  if (isBlocked) {
    return;
  }

  yield* put(ROUTE_CHANGE.request({ location }));
}
