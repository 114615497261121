import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { EVALUATION_CHANGE } from 'src/modules/evaluation/actions/EvaluationActions';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* evaluationChangeSaga(
  action: GetTriggerActionType<typeof EVALUATION_CHANGE>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Saving evaluation data...', formData);

    const previous = yield* select(getProjectDetails);
    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        evaluation: formData,
      },
    };

    const reloaded = yield* call(syncProjectBackground, modified);
    yield* call(logDebug, 'Saving evaluation data... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save evaluation data', { formData }, NativeError.wrap(error));
    yield* call(logError, 'Saving evaluation data... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
