import { CurrencyCode } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedAmount } from 'src/modules/common/components/FormattedAmount';

type Props = {
  readonly value: Decimal | null;
  readonly currency: CurrencyCode;
};

export const SavingsAmount = ({ value, currency }: Props): React.ReactElement => {
  if (value === null) {
    return (
      <FormattedAmount
        value={null}
        currency={currency}
      />
    );
  }

  if (value.isNegative()) {
    return (
      <>
        &lt;
        <FormattedAmount
          value={0}
          currency={currency}
        />
      </>
    );
  }
  if (value.isPositive() && value.lessThan(1_000)) {
    return (
      <>
        &lt;
        <FormattedAmount
          value={1_000}
          currency={currency}
        />
      </>
    );
  }

  return (
    <FormattedAmount
      value={value.div(1_000).toDecimalPlaces(0).mul(1_000)}
      currency={currency}
    />
  );
};
