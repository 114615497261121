import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const LightningIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="m10.5 27.633 5.4-9.166L5.733 17.3l14.7-12.933h1.134L16 13.567 26.267 14.7l-14.7 12.933H10.5Zm2.7-2.733 10.833-9.5-9.566-1.033 4.4-7.334-10.9 9.534L17.5 17.7l-4.3 7.2Z"
      />
    </svg>
  </IconWrapper>
));
