import './HvacResultEnergyThermalView.scss';
import { EnergySource, getCarbonIntensityEditable, ProjectHvacConsumption } from '@belimo-retrofit-portal/logic';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CarbonIntensityUnit } from 'src/modules/common/components/CarbonIntensityUnit';
import { EnergySourceUnit } from 'src/modules/common/components/EnergySourceUnit';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, ProjectHvacConsumption>;
  readonly disabled: boolean;
  readonly energySources: ReadonlyArray<EnergySource>;
};

export const HvacResultEnergyThermalView = ({ path, disabled, energySources }: Props): React.ReactElement => {
  const intl = useIntl();

  const energySource = useFormWatch(path.form, path.at('energySource').value.get);

  return (
    <div className="bp-hvac-result-energy-thermal-view">
      <DropdownField
        size="md"
        titleText={intl.formatMessage({ id: 'hvac/energy/energySource' })}
        field={path.at('energySource').transform(ensureNonNull())}
        options={energySources}
        renderSelectedItem={renderEnergySourceOption}
        itemToElement={renderEnergySourceOption}
        disabled={disabled}
      />

      <DecimalField
        size="md"
        labelText={intl.formatMessage({ id: 'hvac/energy/annualEnergyUse' })}
        field={path.at('annualEnergyUse')}
        disabled={disabled}
        endAdornment={<EnergySourceUnit source={energySource}/>}
      />

      <DecimalField
        size="md"
        labelText={intl.formatMessage({ id: 'hvac/energy/carbonIntensity' })}
        field={path.at('carbonIntensity')}
        disabled={disabled || !getCarbonIntensityEditable(energySource)}
        endAdornment={<CarbonIntensityUnit source={energySource}/>}
      />
    </div>
  );
};

function renderEnergySourceOption(option: EnergySource): React.ReactElement {
  return <FormattedMessage id={`common/energySource/${option}`}/>;
}
