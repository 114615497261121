import { Language } from 'src/types/common/Language';

export function getPreferredLanguage(environmentLanguages: ReadonlyArray<Language>): Language {
  const navigatorLanguages = navigator.languages.map((language) => language.substring(0, 2).toLowerCase());
  const supportedLanguages = environmentLanguages.map((language) => language.substring(0, 2));

  const matchedLanguage = navigatorLanguages.find((language) => supportedLanguages.includes(language));

  const matchedSupportedLanguage = environmentLanguages.find(
    (language) => language.substring(0, 2) === matchedLanguage,
  );

  return matchedSupportedLanguage || environmentLanguages[0];
}
