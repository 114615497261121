import { NativeError, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { PROJECT } from 'src/modules/common/codecs/Project';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* loadProject(projectId: Uuid): SagaIterator<Project> {
  try {
    const { content } = yield* call(makeRestRequest, 'GET', `/projects/${projectId}`);
    return yield* call(decodeOrThrow, PROJECT, content);
  } catch (error) {
    throw new RuntimeError(`Could not load project "${projectId}"`, {}, NativeError.wrap(error));
  }
}
