import React from 'react';
import { TickProps } from 'src/modules/evaluation/types/graph';

export const EvaluationResultsChartsXTick =
  ({
    fontSize,
    orientation,
    width,
    height,
    stroke,
    x,
    y,
    className,
    fill,
    payload,
    textAnchor,
    tickFormatter,
  }: TickProps):React.ReactElement => (
    <g>
      <line
        fontSize={fontSize}
        orientation={orientation}
        width={width}
        height={height}
        stroke={stroke}
        x={x}
        y={y}
        className={className}
        fill={fill}
      />

      <text
        fontSize={fontSize}
        orientation={orientation}
        width={width}
        height={height}
        stroke={stroke}
        x={x}
        y={y + 10}
        className={className}
        textAnchor={textAnchor}
        fill={fill}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    </g>
  );
