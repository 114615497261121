import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { PROJECT_LIST_EXPORT } from 'src/modules/project-list/actions/ProjectListActions';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';

export function projectListExportReducer(state: ProjectListState, action: Action): ProjectListState {
  if (PROJECT_LIST_EXPORT.isPending(action)) {
    return {
      ...state,
      export: FUTURE.pending(),
    };
  }
  if (PROJECT_LIST_EXPORT.isSuccess(action)) {
    return {
      ...state,
      export: FUTURE.success(action.data),
    };
  }
  if (PROJECT_LIST_EXPORT.isFailure(action)) {
    return {
      ...state,
      export: FUTURE.failure(action.data),
    };
  }

  return state;
}
