import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { getProjectListQuery } from 'src/modules/project-list/selectors/getProjectListQuery';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';
import { navigate } from 'src/modules/routing/utils/navigate';
import { call, select } from 'typed-redux-saga';

export function* projectListImportCompleteSaga(): SagaIterator<void> {
  const query = yield* select(getProjectListQuery);
  const search = yield* call(buildProjectListQuery, query);

  yield* call(navigate, replace({ pathname: '/projects', search: search.toString() }));
}
