import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { PASSWORD_FORGOT_SUBMIT } from 'src/modules/password-forgot/actions/PasswordForgotActions';
import { PASSWORD_FORGOT_FORM_SCHEMA } from 'src/modules/password-forgot/constants/schema';
import { submitPasswordForgot } from 'src/modules/password-forgot/sagas/utils/submitPasswordForgot';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* passwordForgotSubmitSaga(
  action: GetRequestActionType<typeof PASSWORD_FORGOT_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  const valid = yield* call(formValidate, form, PASSWORD_FORGOT_FORM_SCHEMA);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Requesting password recovery...');
    yield* put(PASSWORD_FORGOT_SUBMIT.pending());
    yield* call(formSubmitStart, form);

    yield* delay(500);
    yield* call(submitPasswordForgot, values);

    yield* call(logDebug, 'Requesting password recovery... done');
    yield* put(PASSWORD_FORGOT_SUBMIT.success());

    yield* call(formSubmitSuccess, form);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Requesting password recovery... error', error);
    yield* put(PASSWORD_FORGOT_SUBMIT.failure(NativeError.wrap(error)));

    yield* call(formSubmitFailure, form, {
      username: {
        code: 'invalid',
        path: 'username',

        value: values.username,
        message: 'Invalid response',
        context: {},
      },
    });
  }
}
