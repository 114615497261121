import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { getIsAuthenticated } from 'src/modules/login/selectors/getIsAuthenticated';
import { cancel, put, select } from 'typed-redux-saga';

export function* checkIsAuthenticated(): SagaIterator<void> {
  const isAuthenticated = yield* select(getIsAuthenticated);
  if (!isAuthenticated) {
    yield* put(replace('/login'));
    yield* cancel();
  }
}
