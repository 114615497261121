import './ToggleField.scss';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { CustomToggle } from 'src/modules/common/components/CustomToggle';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly field: FieldPath<TData, FormError, boolean>;
  readonly label: React.ReactNode;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
};

export const ToggleField: <TData>(props: Props<TData>) => React.ReactElement = ({
  field,
  label,
  disabled,
  readOnly,
}) => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((toggled: boolean) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(toggled),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <CustomToggle
      className="bp-toggle-field"
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      toggled={value}
      disabled={disabled}
      readOnly={readOnly}
      onToggle={handleChange}
      onBlur={handleBlur}
      labelText={undefined}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error labels used as nodes, but typed as strings https://github.com/carbon-design-system/carbon/blob/main/packages/react/src/components/Toggle/Toggle.tsx
      labelA={label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error Why string only??
      labelB={label}
      data-field-invalid={invalid}
      data-field-touched={meta.touched}
    />
  );
};
