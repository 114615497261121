import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { getPreferredLocale } from 'src/modules/common/utils/locale';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Locale } from 'src/types/common/Locale';
import { call } from 'typed-redux-saga';

export function* loadLocale(): SagaIterator<Locale> {
  try {
    yield* call(logDebug, 'Loading user locale...');
    const locale = yield* call(getPreferredLocale);

    yield* call(logDebug, 'Loading user locale... done', locale);
    return locale;
  } catch (error) {
    const wrapped = new RuntimeError('Could not load user locale', {}, NativeError.wrap(error));

    yield* call(logError, 'Loading user locale... error', error);
    yield* call(sentryCatch, wrapped);

    throw wrapped;
  }
}
