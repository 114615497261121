import { Action } from 'redux';
import { USERS_LIST_FETCH, USERS_LIST_RESET } from 'src/modules/users/actions/UsersActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function usersListDataReducer(state: UsersState, action: Action): UsersState {
  if (USERS_LIST_FETCH.is(action)) {
    return {
      ...state,
      users: action.data,
    };
  }
  if (USERS_LIST_RESET.is(action)) {
    return {
      ...state,
      users: null,
    };
  }
  return state;
}
