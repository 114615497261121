import './BasicInfoFormModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useForm } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { FileField } from 'src/modules/form/components/FileField';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { BASIC_SCHEMA } from 'src/modules/project-new/constants/schema';
import { BasicFormData } from 'src/modules/project-new/types/BasicFormData';

type Props = {
  readonly formData: BasicFormData;
  readonly onNext: (form: FormController<BasicFormData, FormError>) => void;
  readonly onClose: () => void;
};

export const BasicInfoFormModal = ({ formData, onNext, onClose }: Props): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<BasicFormData, FormError>('project.create.new.basic', {
    reinitialize: false,
    initialValues: formData,
  });
  useFormValidator(form, BASIC_SCHEMA);

  const handleSubmit = useCallback((): void => {
    onNext(form);
  }, [form, onNext]);

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        className="bp-project-create-modal__header"
        labelClassName="bp-project-create-modal__label"
        label={intl.formatMessage({ id: 'project/create/basic/title' })}
        titleClassName="bp-project-create-modal__title"
        title={intl.formatMessage({ id: 'project/create/basic/subTitle' })}
        closeModal={onClose}
      />

      <ModalBody className="bp-project-create-modal__body">
        <TextField
          size="lg"
          field={form.field.at('title')}
          labelText={intl.formatMessage({ id: 'project/create/building/field/title' })}
          required={true}
          maxLength={40}
          autoFocus={true}
          width="100%"
        />

        <TextAreaField
          field={form.field.at('projectGoals')}
          labelText={intl.formatMessage({ id: 'basedata/projectGoals' })}
          width="100%"
          rows={2}
          theme="gray"
          maxLength={280}
          enableCounter={false}
          showHint={true}
        />

        <div className="bp-basic-info-form-modal__file-field">
          <label
            className="bp-basic-info-form-modal__file-label"
            htmlFor={`${form.field.at('cover').form.name}.${form.field.at('cover').path}`}
            aria-label={intl.formatMessage({ id: 'basedata/coverImage' })}
          >
            <FormattedMessage id="basedata/coverImage"/>
          </label>

          <FileField
            className="bp-basic-info-form-modal__file-image-container"
            field={form.field.at('cover')}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          className="bp-project-create-modal__button bp-project-create-modal__button--secondary"
          kind="secondary"
          onClick={onClose}
        >
          <FormattedMessage id="project/create/action/back"/>
        </CustomButton>

        <CustomButton
          className="bp-project-create-modal__button bp-project-create-modal__button--primary"
          kind="primary"
          onClick={handleSubmit}
        >
          <FormattedMessage id="project/create/action/next"/>

          <div className="bp-project-create-modal__button-icon">
            <ArrowRightIcon/>
          </div>
        </CustomButton>
      </ModalFooter>
    </>
  );
};
