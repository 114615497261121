import { getOrElse } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Decoder } from 'io-ts/Decoder';

export function decodeOrGet<I, A>(decoder: Decoder<I, A>, input: I, value: A): A {
  return pipe(
    decoder.decode(input),
    getOrElse(() => value),
  );
}
