import { SagaIterator } from 'redux-saga';
import { NOTIFICATION_SHOW } from 'src/modules/notifications/actions/NotificationActions';
import { NotificationData } from 'src/modules/notifications/types/NotificationData';
import { put } from 'typed-redux-saga';

let counter = 1_000_000;

export function* showNotification(data: Omit<NotificationData, 'id'>): SagaIterator<void> {
  const id = counter++;

  yield* put(NOTIFICATION_SHOW.trigger({ ...data, id }));
}
