import {
  init,
  BrowserTracing,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  HttpContext,
  Breadcrumbs,
  GlobalHandlers,
  LinkedErrors,
} from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import { BaseTransportOptions } from '@sentry/types/types/transport';
import escapeStringRegexp from 'escape-string-regexp';
import { Environment } from 'src/types/Environment';

export function sentryInit(environment: Environment): void {
  if (environment.sentryDsn === '') {
    return;
  }

  init({
    dsn: environment.sentryDsn,
    environment: environment.sentryEnv,
    release: process.env.SENTRY_RELEASE,
    transport: makeBrowserOfflineTransport((options: BaseTransportOptions) => makeFetchTransport({
      ...options,
      // masking sentry_key because adblockers use it for blocking sentry
      url: options.url.replace(/sentry_key/, 'xauth'),
    })),
    tracePropagationTargets: [
      new RegExp(`^${escapeStringRegexp(environment.apiBaseUrl)}`),
    ],

    debug: false,
    enabled: true,

    normalizeDepth: 0,
    maxValueLength: 100_000,

    defaultIntegrations: false,
    integrations: [
      new HttpContext(),
      new Breadcrumbs({
        dom: true,
        xhr: true,
        fetch: true,
        console: true,
        history: true,
        sentry: true,
      }),
      new GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new ExtraErrorData({
        depth: 1_000,
      }),
      new LinkedErrors({
        key: 'previous',
        limit: 100,
      }),
      new BrowserTracing({
        traceXHR: true,
        traceFetch: true,
        enableHTTPTimings: true,
      }),
    ],

    tracesSampleRate: 1.0,
  });
}
