import './CustomTabList.scss';
import { Tab, TabList } from '@carbon/react';
import { TabProps } from '@carbon/react/lib/components/Tabs/Tabs';
import clsx from 'clsx';
import React, { memo } from 'react';

type Props = {
  ariaLabel: string;
  contained: boolean;
  isFullWidth: boolean;
  theme?: 'gray' | 'white';
  children: React.ReactNode;
};

export const CustomTabList = (
  { ariaLabel, contained, isFullWidth, theme = 'gray', children }: Props,
):React.ReactElement => (
  <TabList
    className={clsx(
      'bp-custom-tabs',
      `bp-custom-tabs--${theme}`,
      {
        'bp-custom-tabs--full-width': isFullWidth,
      },
    )}
    aria-label={ariaLabel}
    contained={contained}
  >
    {children}
  </TabList>
);

export const CustomTab = memo((props: TabProps) => (
  <Tab
    {...props}
    className={clsx(
      'bp-custom-tabs__tab',
      props.className,
    )}
  >
    {props.children}
  </Tab>
));
