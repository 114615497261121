import './ProjectListHeader.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { ProjectListSortLink } from 'src/modules/project-list/views/ProjectListSortLink';

type Props = {
  readonly query: ProjectListQuery;
};

export const ProjectListHeader = ({ query }: Props): React.ReactElement => (
  <div className="bp-project-list-header">
    <div className="bp-project-list-header__column bp-project-list-header__column--icon"/>

    <div className="bp-project-list-header__column bp-project-list-header__column--project">
      <ProjectListSortLink query={query} field="title">
        <FormattedMessage id="projectList/projectListHeader/project"/>
      </ProjectListSortLink>
    </div>

    <div className="bp-project-list-header__column">
      <ProjectListSortLink query={query} field="ownership">
        <FormattedMessage id="projectList/projectListHeader/ownership"/>
      </ProjectListSortLink>
    </div>

    <div className="bp-project-list-header__column">
      <ProjectListSortLink query={query} field="updated">
        <FormattedMessage id="projectList/projectListHeader/lastUpdated"/>
      </ProjectListSortLink>
    </div>

    <div className="bp-project-list-header__column">
      <ProjectListSortLink query={query} field="rating">
        <FormattedMessage id="projectList/projectListHeader/energyEfficiency"/>
      </ProjectListSortLink>
    </div>

    <div className="bp-project-list-header__column"/>
  </div>
);
