import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { ProjectListSort } from 'src/modules/project-list/types/ProjectListSort';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';

export const PROJECT_LIST_DEFAULT_SORT: ReadonlyRecord<ProjectListViewMode, ProjectListSort> = {
  [ProjectListViewMode.LIST]: {
    field: 'updated',
    direction: 'desc',
  },
  [ProjectListViewMode.GRID]: {
    field: 'title',
    direction: 'asc',
  },
};
