import { BaseError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { ROUTING_ERROR_HOOKS } from 'src/modules/routing/constants/RoutingHooks';
import { Transition } from 'src/modules/routing/types/Transition';
import { call } from 'typed-redux-saga';

export function* handleTransitionError(transition: Transition, error: BaseError): SagaIterator<void> {
  for (const errorHook of ROUTING_ERROR_HOOKS) {
    yield* call(errorHook, transition, error);
  }
}
