import './AreaUnitView.scss';
import { AreaUnit } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';

type Props = {
  readonly unit: AreaUnit;
};

export const AreaUnitView = ({ unit }: Props): React.ReactElement => (
  <span className="bp-area-unit-view">
    {unit === AreaUnit.SQM
      ? <FormattedMessage id="unit/square_meter" values={{ sub, sup }}/>
      : <FormattedMessage id="unit/square_foot" values={{ sub, sup }}/>}
  </span>
);
