import './ProjectListFilterDropdown.scss';
import { BuildingType, CountryCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { identity, pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { FormError } from 'src/modules/form/types/FormError';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { getProjectListFilterValues } from 'src/modules/project-list/selectors/getProjectListFilterValues';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { ProjectListFilterGroup } from 'src/modules/project-list/views/ProjectListFilterGroup';

type Props = {
  readonly form: FormController<ProjectListFilter, FormError>;
  readonly onApply: () => void;
  readonly onClose: () => void;
};

export const ProjectListFilterDropdown = ({ form, onApply, onClose }: Props): React.ReactElement => {
  const intl = useIntl();

  const authUser = useSelector(getLoggedInUser);
  const values = useSelector(getProjectListFilterValues);

  const handleClear = useCallback(() => {
    const formValues = form.values.get(form.currentState);
    form.change(pipe(
      form.currentState,
      form.values.set({
        term: formValues.term,

        buildingType: new Set(),

        country: new Set(),
        city: new Set(),

        ratingActual: new Set(),
        ratingFuture: new Set(),

        ownership: new Set(),
      }),
    ));

    onApply();
  }, [form, onApply]);

  // the first group is always here since country and building type are required
  const hasEfficiencyFilter = (
    values.ratingActual.length > 0 ||
    values.ratingFuture.length > 0
  );

  return (
    <div className="bp-project-list-filter-dropdown">
      <div className="bp-project-list-filter-dropdown__content">
        <div className="bp-project-list-filter-dropdown__column">
          <p className="bp-project-list-filter-dropdown__title">
            <FormattedMessage id="projectList/filterDropdown/project"/>
          </p>

          {values.buildingType.length > 0 && (
            <>
              <div className="bp-project-list-filter-dropdown__subtitle">
                <FormattedMessage id="projectList/filterDropdown/buildingType"/>

                <HorizontalDivider/>
              </div>

              <ProjectListFilterGroup
                field={form.field.at('buildingType')}
                options={values.buildingType}
                renderOption={renderBuildingTypeOption}
                onApply={onApply}
              />
            </>
          )}

          {values.city.length > 0 && (
            <>
              <div className="bp-project-list-filter-dropdown__subtitle">
                <FormattedMessage id="projectList/filterDropdown/city"/>

                <HorizontalDivider/>
              </div>

              <ProjectListFilterGroup
                field={form.field.at('city')}
                options={values.city}
                renderOption={identity}
                onApply={onApply}
              />
            </>
          )}

          {values.country.length > 0 && (
            <>
              <div className="bp-project-list-filter-dropdown__subtitle">
                <FormattedMessage id="projectList/filterDropdown/country"/>

                <HorizontalDivider/>
              </div>

              <ProjectListFilterGroup
                field={form.field.at('country')}
                options={values.country}
                renderOption={renderCountryCodeOption}
                onApply={onApply}
              />
            </>
          )}

          {values.ownership.length > 0 && (
            <>
              <div className="bp-project-list-filter-dropdown__subtitle">
                <FormattedMessage id="projectList/filterDropdown/ownership"/>

                <HorizontalDivider/>
              </div>

              <ProjectListFilterGroup
                field={form.field.at('ownership')}
                options={values.ownership}
                renderOption={(option) => (
                  authUser.email === option ? intl.formatMessage({ id: 'projectList/item/owned' }) : option
                )}
                onApply={onApply}
              />
            </>
          )}
        </div>

        {hasEfficiencyFilter && (
          <div className="bp-project-list-filter-dropdown__column">
            <p className="bp-project-list-filter-dropdown__title">
              <FormattedMessage id="projectList/filterDropdown/energyEfficiency"/>
            </p>

            {values.ratingActual.length > 0 && (
              <>
                <div className="bp-project-list-filter-dropdown__subtitle">
                  <FormattedMessage id="projectList/filterDropdown/ratingActual"/>

                  <HorizontalDivider/>
                </div>

                <ProjectListFilterGroup
                  field={form.field.at('ratingActual')}
                  options={values.ratingActual}
                  renderOption={identity}
                  onApply={onApply}
                />
              </>
            )}

            {values.ratingFuture.length > 0 && (
              <>
                <div className="bp-project-list-filter-dropdown__subtitle">
                  <FormattedMessage id="projectList/filterDropdown/ratingFuture"/>

                  <HorizontalDivider/>
                </div>

                <ProjectListFilterGroup
                  field={form.field.at('ratingFuture')}
                  options={values.ratingFuture}
                  renderOption={identity}
                  onApply={onApply}
                />
              </>
            )}
          </div>
        )}
      </div>

      <div className="bp-project-list-filter-dropdown__footer">
        <CustomButton type="button" kind="secondary" onClick={handleClear}>
          <FormattedMessage id="projectList/filterDropdown/clear"/>
        </CustomButton>

        <CustomButton type="button" onClick={onClose}>
          <FormattedMessage id="projectList/filterDropdown/apply"/>
        </CustomButton>
      </div>
    </div>
  );
};

function renderBuildingTypeOption(option: BuildingType): React.ReactElement {
  return <FormattedMessage id={`common/buildingType/${option}`}/>;
}

function renderCountryCodeOption(option: CountryCode): React.ReactElement {
  return <FormattedMessage id={`common/country/name/${option}`}/>;
}
