import * as React from 'react';
import { CustomModal } from 'src/modules/common/components/CustomModal';

type Props = {
  readonly in?: boolean;
  readonly children: React.ReactNode;
};

/* eslint-disable react/destructuring-assignment */
export const ModalWrapper = (props: Props): React.ReactElement => (
  <CustomModal
    open={props.in ?? false}
    onClose={() => false}
    isShaded={false}
    shouldUnmount={true}
    size="md"
  >
    {props.children}
  </CustomModal>
);
