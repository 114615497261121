import { Matrix, SchemaArea } from '@belimo-retrofit-portal/logic';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import {
  MultipleSchemaFilterForm,
  MultipleSchemaPreselectedData,
  SchemaSelectedData,
  SchemaSelectionData,
} from 'src/modules/schema/types/SchemaSelectionState';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const SCHEMA_TEMPLATE_SELECT =
  createTrigger<ConfigSchema, void>('SCHEMA_TEMPLATE_SELECT');

export const SCHEMA_VERSION_CREATE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_CREATE');

export const SCHEMA_VERSION_CHANGE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_CHANGE');

export const SCHEMA_VERSION_REMOVE =
  createTrigger<SchemaVersion, SchemaArea>('SCHEMA_VERSION_REMOVE');

export const SCHEMA_COMMENT_CHANGE =
  createTrigger<string, SchemaArea>('SCHEMA_COMMENT_CHANGE');

export const SCHEMA_SELECTIONSTATE_FETCH = createTrigger<SchemaSelectionData>('SCHEMA_SELECTIONSTATE_FETCH');

export const SCHEMA_SELECTION_FILTER_CHANGE = createActions<
SchemaFilterForm,
MultipleSchemaFilterForm,
SchemaArea
>('SCHEMA_SELECTION_FILTER_CHANGE');

export const PRESELECTED_SCHEMA_CHANGE = createActions<
SchemaSelectedData | null,
MultipleSchemaPreselectedData,
SchemaArea
>('PRESELECTED_CHEMA_CHANGE');

export const SCHEMA_MATRIX_CHANGE = createTrigger<Matrix, SchemaArea>('SCHEMA_MATRIX_CHANGE');
