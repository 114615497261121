import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ArrowRightIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m13.55 17.25-.5-.515 4.35-4.375H5.25v-.72H17.4l-4.35-4.375.5-.515 5.2 5.25-5.2 5.25Z"
      />
    </svg>
  </IconWrapper>
));
