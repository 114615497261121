import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { PROJECT_SYNC_PERFORM } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { getProjectSyncData } from 'src/modules/project-sync/selectors/getProjectSyncData';
import { call, select, take } from 'typed-redux-saga';

export function* waitProjectSynchronization(project: Project): SagaIterator<Project> {
  const saving = yield* select(getProjectSyncData);
  if (saving === null) {
    return project;
  }
  if (saving.status.state === 'success') {
    return saving.project;
  }
  if (saving.status.state === 'failure') {
    throw saving.status.error;
  }

  yield* take([PROJECT_SYNC_PERFORM.isSuccess, PROJECT_SYNC_PERFORM.isFailure]);
  return yield* call(waitProjectSynchronization, project);
}
