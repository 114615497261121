import * as React from 'react';
import { Lifecycle } from 'src/modules/common/components/Lifecycle';
import { ShadowHost } from 'src/modules/report/types/ShadowHost';

export async function renderComponent(host: ShadowHost, element: React.ReactElement): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    host.render((
      <Lifecycle onReady={resolve} onError={reject}>
        {element}
      </Lifecycle>
    ));
  });
}
