import './HvacFormAssessmentView.scss';
import { HvacArea } from '@belimo-retrofit-portal/logic';
import { Tabs, Tooltip } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTab, CustomTabList } from 'src/modules/common/components/CustomTabList';
import { TileCard } from 'src/modules/common/components/TileCard';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormCounters } from 'src/modules/hvac/types/HvacFormCounter';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacFormChapter } from 'src/modules/hvac/views/HvacFormChapter';

type Props = {
  readonly form: FormController<HvacFormData, FormError>;
  readonly counters: HvacFormCounters;
};

export const HvacFormAssessmentView = ({ form, counters }: Props): React.ReactElement => {
  const intl = useIntl();

  const isHeatingEnabled = useFormWatch(
    form,
    form.field.at('assessment').at('heating').at('enabled').value.get,
  );
  const isCoolingEnabled = useFormWatch(
    form,
    form.field.at('assessment').at('cooling').at('enabled').value.get,
  );
  const isVentilationEnabled = useFormWatch(
    form,
    form.field.at('assessment').at('ventilation').at('enabled').value.get,
  );

  const defaultSelectedTab = useMemo(() => {
    if (isHeatingEnabled) {
      return 0;
    }

    if (isCoolingEnabled) {
      return 1;
    }

    if (isVentilationEnabled) {
      return 2;
    }

    return 0;
  }, [
    isHeatingEnabled,
    isCoolingEnabled,
    isVentilationEnabled,
  ]);

  const [activeTab, setActiveTab] = useState(defaultSelectedTab);

  const handleTabChange = useCallback((state: { selectedIndex: number }) => {
    setActiveTab(state.selectedIndex);
  }, []);

  const currentTab: HvacArea = useMemo(
    () => {
      const tab = [
        HvacArea.HEATING,
        HvacArea.COOLING,
        HvacArea.VENTILATION,
      ][activeTab];

      return tab || HvacArea.HEATING;
    },
    [activeTab],
  );

  useEffect(() => {
    const isCurrentTabActive = [
      isHeatingEnabled,
      isCoolingEnabled,
      isVentilationEnabled,
    ][activeTab];

    if (!isCurrentTabActive) {
      setActiveTab(defaultSelectedTab);
    }
  }, [isHeatingEnabled, isCoolingEnabled, isVentilationEnabled, activeTab, defaultSelectedTab]);

  return (
    <TileCard title={<FormattedMessage id="hvac/form/assign/title"/>}>
      <div className="bp-hvac-form-assessment-view">
        <Tabs selectedIndex={activeTab} onChange={handleTabChange}>
          <CustomTabList
            ariaLabel={intl.formatMessage({ id: 'hvac/form/assign/tabs' })}
            contained={true}
            isFullWidth={true}
          >
            {isHeatingEnabled ? (
              <CustomTab className="bp-hvac-form-assessment-view__tab">
                <div className="bp-hvac-form-assessment-view__tab-content">
                  <FormattedMessage id="hvac/tab/heating"/>

                  {counters[HvacArea.HEATING].answered}/{counters[HvacArea.HEATING].enabled}
                </div>
              </CustomTab>
            ) : (
              <Tooltip
                align="top"
                description={intl.formatMessage({ id: 'tab/heating/disabled' })}
              >
                <CustomTab as="div" className="bp-hvac-form-assessment-view__tab" disabled={!isHeatingEnabled}>
                  <div className="bp-hvac-form-assessment-view__tab-content">
                    <FormattedMessage id="hvac/tab/heating"/>

                    {counters[HvacArea.HEATING].answered}/{counters[HvacArea.HEATING].enabled}
                  </div>
                </CustomTab>
              </Tooltip>
            )}

            {isCoolingEnabled ? (
              <CustomTab className="bp-hvac-form-assessment-view__tab">
                <div className="bp-hvac-form-assessment-view__tab-content">
                  <FormattedMessage id="hvac/tab/cooling"/>

                  {counters[HvacArea.COOLING].answered}/{counters[HvacArea.COOLING].enabled}
                </div>
              </CustomTab>
            ) : (
              <Tooltip
                align="top"
                description={intl.formatMessage({ id: 'tab/cooling/disabled' })}
              >
                <CustomTab as="div" className="bp-hvac-form-assessment-view__tab" disabled={!isCoolingEnabled}>
                  <div className="bp-hvac-form-assessment-view__tab-content">
                    <FormattedMessage id="hvac/tab/cooling"/>

                    {counters[HvacArea.COOLING].answered}/{counters[HvacArea.COOLING].enabled}
                  </div>
                </CustomTab>
              </Tooltip>
            )}

            {isVentilationEnabled ? (
              <CustomTab className="bp-hvac-form-assessment-view__tab">
                <div className="bp-hvac-form-assessment-view__tab-content">
                  <FormattedMessage id="hvac/tab/ventilation"/>

                  {counters[HvacArea.VENTILATION].answered}/{counters[HvacArea.VENTILATION].enabled}
                </div>
              </CustomTab>
            ) : (
              <Tooltip
                align="top"
                description={intl.formatMessage({ id: 'tab/ventilation/disabled' })}
              >
                <CustomTab as="div" className="bp-hvac-form-assessment-view__tab" disabled={!isVentilationEnabled}>
                  <div className="bp-hvac-form-assessment-view__tab-content">
                    <FormattedMessage id="hvac/tab/ventilation"/>

                    {counters[HvacArea.VENTILATION].answered}/{counters[HvacArea.VENTILATION].enabled}
                  </div>
                </CustomTab>
              </Tooltip>
            )}
          </CustomTabList>
        </Tabs>

        <HvacFormChapter path={form.field.at('assessment').at(currentTab)}/>
      </div>
    </TileCard>
  );
};
