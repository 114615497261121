import './ReportFormView.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { useForm } from '@form-ts/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { FileIcon } from 'src/modules/common/icons/FileIcon';
import { Project } from 'src/modules/common/types/Project';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { REPORT_CHANGE, REPORT_GENERATE } from 'src/modules/report/actions/ReportActions';
import { REPORT_FORM_SCHEMA } from 'src/modules/report/constants/schema';
import { ReportContentPage } from 'src/modules/report/types/ReportContent';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportFormDocument } from 'src/modules/report/views/ReportFormDocument';
import { ReportFormSectionConfigCover } from 'src/modules/report/views/ReportFormSectionConfigCover';
import { ReportFormSectionConfigEnergy } from 'src/modules/report/views/ReportFormSectionConfigEnergy';
import { ReportFormSectionConfigEvaluation } from 'src/modules/report/views/ReportFormSectionConfigEvaluation';
import {
  ReportFormSectionConfigEvaluationCharts,
} from 'src/modules/report/views/ReportFormSectionConfigEvaluationCharts';
import { ReportFormSectionConfigHvac } from 'src/modules/report/views/ReportFormSectionConfigHvac';
import { ReportFormSectionConfigSavings } from 'src/modules/report/views/ReportFormSectionConfigSavings';
import { ReportFormSectionConfigSchema } from 'src/modules/report/views/ReportFormSectionConfigSchema';
import { ReportFormSectionNoSchema } from 'src/modules/report/views/ReportFormSectionNoSchema';
import { ReportFormSectionPreview } from 'src/modules/report/views/ReportFormSectionPreview';

type Props = {
  readonly project: Project;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
  readonly loading: boolean;
};

export const ReportFormView = ({ project, document, formData, loading }: Props): React.ReactElement => {
  const projectEvaluationUrl = `/project/${encodeURIComponent(project.id)}/evaluation`;

  const form = useForm<ReportFormData, FormError>('project.report', {
    reinitialize: false,
    initialValues: formData,
  });
  useFormValidator(form, REPORT_FORM_SCHEMA);

  const saveForm = useAction(REPORT_CHANGE.trigger);
  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;

    if (prevState.values !== nextState.values) {
      saveForm(nextState.values);
    }
  }), [form, saveForm]);

  const generateReport = useAction(REPORT_GENERATE.request);
  const handleReportClick = useCallback(() => {
    generateReport(form.currentState.values);
  }, [form, generateReport]);

  const [reportPages, setReportPages] = useState<ReadonlyArray<ReportContentPage>>([]);

  return (
    <div className="bp-report-form-view">
      <div className="bp-report-form-view__wrapper">
        <div className="bp-report-form-view__actions">
          <div/>

          <CustomButton
            disabled={loading}
            className="bp-report-form-view__actions-button"
            kind="primary"
            onClick={handleReportClick}
            loading={loading}
            size="md"
            renderIcon={() => (
              <div className="bp-report-form-view__action-icon">
                <FileIcon/>
              </div>
            )}
          >
            <FormattedMessage id="report/form/generateReport"/>
          </CustomButton>
        </div>

        <ReportFormDocument
          document={document}
          formData={formData}
          onChange={setReportPages}
          project={project}
        />

        <div className="bp-report-form-view__content">
          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'cover')}
                enabled={true}
              />
            </div>
            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigCover
                field={form.field.at('cover')}
                project={project}
                document={document}
              />
            </div>
          </div>

          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'savings')}
                enabled={formData.savings.enabled}
              />
            </div>
            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigSavings
                field={form.field.at('savings')}
                project={project}
              />
            </div>
          </div>

          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'evaluation')}
                enabled={formData.evaluation.enabled}
              />
            </div>
            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigEvaluation
                field={form.field.at('evaluation')}
                project={project}
              />
            </div>
          </div>

          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'evaluationCharts')}
                enabled={formData.evaluationCharts.enabled}
              />
            </div>
            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigEvaluationCharts
                field={form.field.at('evaluationCharts')}
                project={project}
              />
            </div>
          </div>

          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'energy')}
                enabled={formData.energy.enabled}
              />
            </div>

            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigEnergy
                field={form.field.at('energy')}
                project={project}
              />
            </div>
          </div>

          <div className="bp-report-form-view__section">
            <div className="bp-report-form-view__section-preview">
              <ReportFormSectionPreview
                pages={reportPages.filter((it) => it.section === 'hvac')}
                enabled={formData.hvac.enabled}
              />
            </div>
            <div className="bp-report-form-view__section-config">
              <ReportFormSectionConfigHvac
                field={form.field.at('hvac')}
                project={project}
              />
            </div>
          </div>

          {[
            SchemaArea.HEATING,
            SchemaArea.COOLING,
          ].map((area) => (
            <div key={area} className="bp-report-form-view__section">
              <div className="bp-report-form-view__section-preview">
                {document.schema[area] !== null && (
                  <ReportFormSectionPreview
                    pages={reportPages.filter((it) => it.section === `schema-${area}`)}
                    enabled={formData.schema[area].enabled}
                  />
                )}
                {document.schema[area] === null && (
                  <ReportFormSectionNoSchema
                    area={area}
                    project={project}
                  />
                )}
              </div>
              <div className="bp-report-form-view__section-config">
                <ReportFormSectionConfigSchema
                  area={area}
                  field={form.field.at('schema')}
                  project={project}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="bp-report-form-view__actions">
          <CustomButton
            as={Link}
            to={projectEvaluationUrl}
            size="md"
            kind="ghost"
          >
            <div className="bp-report-form-view__action-icon">
              <ArrowLeftIcon/>
            </div>

            <FormattedMessage id="report/navigation/back"/>
          </CustomButton>

          <CustomButton
            disabled={loading}
            className="bp-report-form-view__actions-button"
            kind="primary"
            onClick={handleReportClick}
            loading={loading}
            size="md"
            renderIcon={() => (
              <div className="bp-report-form-view__action-icon">
                <FileIcon/>
              </div>
            )}
          >
            <FormattedMessage id="report/form/generateReport"/>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
