import './PasswordSetupScreen.scss';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { PASSWORD_RESET_FORM_SCHEMA } from 'src/modules/password-reset/constants/schema';
import { PasswordResetFormData } from 'src/modules/password-reset/types/PasswordResetFormData';
import { PasswordSetupForm } from 'src/modules/password-reset/views/PasswordSetupForm';

type Props = {
  readonly onSubmit: (form: FormController<PasswordResetFormData, FormError>) => void;
};

export const PasswordSetupScreen = ({ onSubmit }: Props): React.ReactElement => {
  const form = useForm<PasswordResetFormData, FormError>('password.setup', {
    reinitialize: false,
    initialValues: {
      password: '',
      confirmation: '',
    },
  });
  useFormValidator(form, PASSWORD_RESET_FORM_SCHEMA);

  const isSubmitted = useFormWatch(form, form.submitSucceded.get);
  const handleSubmit = useCallback(() => {
    onSubmit(form);
  }, [form, onSubmit]);

  return (
    <div className="bp-password-setup-screen">
      <h4 className="bp-password-setup-screen__title">
        <FormattedMessage id="passwordSetup/title"/>
      </h4>

      <p className="bp-password-setup-screen__subtitle">
        {isSubmitted
          ? <FormattedMessage id="passwordSetup/success"/>
          : <FormattedMessage id="passwordSetup/subtitle"/>}
      </p>

      {!isSubmitted && (
        <div className="bp-password-setup-screen__form">
          <PasswordSetupForm
            form={form}
            onSubmit={handleSubmit}
          />
        </div>
      )}

      <div className="bp-password-setup-screen__back">
        <CustomButton kind="ghost" as={Link} to="/login" size="md">
          <ArrowLeftIcon/>

          <FormattedMessage id="passwordSetup/goBack"/>
        </CustomButton>
      </div>
    </div>
  );
};
