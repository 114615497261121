import { TolgeeProvider } from '@tolgee/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { getConfigState } from 'src/modules/config/selectors/getConfigState';
import { getTolgeeInstance } from 'src/modules/config/selectors/getTolgeeInstance';
import { RouterLoader } from 'src/modules/routing/views/RouterLoader';

type Props = {
  readonly children: React.ReactElement;
};

export const ConfigProvider = ({ children }: Props): React.ReactElement | null => {
  const config = useSelector(getConfigState);
  if (config.data.state === 'pending') {
    return <RouterLoader/>;
  }
  if (config.data.state === 'failure') {
    // NOTE: not a good idea but the expected behaviour is not specified
    throw config.data.error;
  }

  return (
    <IntlProvider>
      {children}
    </IntlProvider>
  );
};

const IntlProvider = ({ children }: Props): React.ReactElement => {
  const tolgee = useSelector(getTolgeeInstance);

  return (
    <TolgeeProvider tolgee={tolgee}>
      {children}
    </TolgeeProvider>
  );
};
