import './CustomButton.scss';
import { Button, ButtonProps } from '@carbon/react';
import clsx from 'clsx';
import React from 'react';
import { LoadingIcon } from 'src/modules/common/icons/LoadingIcon';

type Props = {
  fullWidth?: boolean;
  loading?: boolean;
};

type CustomButtonProps<TButtonProps> = TButtonProps extends ButtonProps<infer TType>
  ? TButtonProps & Omit<React.ComponentProps<TType>, 'as'> & Props
  : never;

export const CustomButton = <TButtonProps extends ButtonProps<React.ElementType>>({
  children,
  className,
  disabled,
  fullWidth,
  loading,
  renderIcon: RenderIcon,
  size = 'lg',
  ...props
}: CustomButtonProps<TButtonProps>): React.ReactElement => {
  const computedClassName = clsx(
    'bp-custom-button',
    `bp-custom-button--${size}`,
    {
      'bp-custom-button--disabled': disabled,
      'bp-custom-button--full-width': fullWidth,
      'bp-custom-button--loading': loading,
    },
    className,
  );

  return (
    <Button
      {...props}
      className={computedClassName}
      disabled={disabled || loading}
      renderIcon={
        loading ? (): React.ReactElement => <LoadingIcon className="bp-custom-button__loading-icon"/> : RenderIcon
      }
      size={size}
    >
      {children}
    </Button>
  );
};
