import './ErrorView.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';

type ErrorCode = 404 | 500 | 401;

type Props = {
  errorCode: ErrorCode;
};

export const ErrorView = ({ errorCode }: Props): React.ReactElement => (
  <div className="bp-error-view">
    <h1 className="bp-error-view__title">
      {errorCode}
    </h1>

    <h6 className="bp-error-view__subtitle">
      <FormattedMessage id={`errorPage/subTitle/${errorCode}`}/>
    </h6>

    <p className="bp-error-view__description">
      <FormattedMessage id={`errorPage/description/${errorCode}`}/>
    </p>

    <CustomButton
      as={Link}
      to="/projects"
      className="bp-error-view__button"
      kind="primary"
    >
      <FormattedMessage id="errorPage/action/buttonText"/>

      <div className="bp-error-view__button-icon">
        <ArrowRightIcon/>
      </div>
    </CustomButton>
  </div>
);
