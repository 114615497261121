import { trim } from 'fp-ts/string';
import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { BasicFormData } from 'src/modules/project-new/types/BasicFormData';
import { BuildingFormData } from 'src/modules/project-new/types/BuildingFormData';

export const BASIC_SCHEMA: FormRule<BasicFormData> = R.struct({
  cover: R.pass(),
  title: R.allOf([
    R.map(trim, R.nonEmpty()),
    R.maxLength(40),
  ]),
  projectGoals: R.maxLength(280),
});

export const BUILDING_SCHEMA: FormRule<BuildingFormData> = R.struct({
  unit: R.required(),
  type: R.required(),
  size: R.nullable(R.gt(0)),
  address: R.struct({
    street: R.maxLength(30),
    city: R.maxLength(20),
    country: R.required(),
    zip: R.maxLength(10),
  }),
});
