import { SUPPORTED_LOCALES } from 'src/modules/common/constants/locale';
import { Locale } from 'src/types/common/Locale';

export function getPreferredLocale(): Locale {
  for (const value of navigator.languages) {
    const locale = SUPPORTED_LOCALES.get(value.toLowerCase());
    if (locale) {
      return locale;
    }
  }

  return 'en-us';
}
