import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const StarIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
          d="m8.125 7.092 2.608-3.47c.159-.215.347-.372.566-.472.219-.1.452-.15.701-.15.249 0 .482.05.701.15.219.1.407.257.566.471l2.608 3.471 4.02 1.368c.356.12.63.322.822.606.193.283.289.596.289.939 0 .158-.023.315-.07.471a1.44 1.44 0 0 1-.228.449l-2.635 3.573.1 3.83a1.52 1.52 0 0 1-.466 1.189 1.58 1.58 0 0 1-1.143.483l-.454-.056L12 18.733l-4.11 1.211a.693.693 0 0 1-.24.053 8.738 8.738 0 0 1-.213.003c-.444 0-.827-.161-1.15-.483a1.53 1.53 0 0 1-.46-1.188l.1-3.856-2.629-3.548a1.46 1.46 0 0 1-.228-.453 1.64 1.64 0 0 1 .227-1.414c.197-.29.473-.496.828-.618l4-1.348Zm.629.86L4.462 9.398a.605.605 0 0 0-.395.394.548.548 0 0 0 .087.548l2.792 3.84-.12 4.16a.574.574 0 0 0 .232.52c.166.128.352.16.557.096L12 17.696l4.385 1.285a.581.581 0 0 0 .557-.096.574.574 0 0 0 .231-.52l-.12-4.184 2.793-3.79a.548.548 0 0 0 .087-.549.605.605 0 0 0-.395-.394l-4.292-1.496-2.765-3.683A.553.553 0 0 0 12 4.02a.553.553 0 0 0-.48.25L8.753 7.952Z"
      />
    </svg>
  </IconWrapper>
));
