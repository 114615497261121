import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const UserIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M11.647 10.379c-.792 0-1.47-.288-2.034-.863a2.86 2.86 0 0 1-.846-2.076c0-.809.282-1.5.846-2.077.564-.575 1.242-.863 2.034-.863.792 0 1.47.288 2.034.863a2.86 2.86 0 0 1 .846 2.077c0 .808-.282 1.5-.846 2.076-.564.575-1.242.863-2.034.863ZM4.5 18v-1.415c0-.4.12-.776.36-1.13.24-.354.564-.63.973-.83a14.75 14.75 0 0 1 2.92-1.062 12.195 12.195 0 0 1 2.894-.353c.96 0 1.925.118 2.894.353.968.236 1.942.59 2.92 1.062.409.2.733.476.973.83s.36.73.36 1.13V18H4.5Zm.747-.762h12.8v-.653c0-.254-.084-.495-.253-.722a2.115 2.115 0 0 0-.707-.585 13.28 13.28 0 0 0-2.658-.966 11.568 11.568 0 0 0-5.564 0c-.919.227-1.805.549-2.658.966a2.115 2.115 0 0 0-.707.585 1.188 1.188 0 0 0-.253.722v.653Zm6.4-7.621c.587 0 1.089-.213 1.507-.64.418-.426.627-.939.627-1.537 0-.6-.21-1.112-.627-1.538a2.034 2.034 0 0 0-1.507-.64c-.587 0-1.089.213-1.507.64a2.119 2.119 0 0 0-.626 1.538c0 .598.209 1.11.626 1.537.418.427.92.64 1.507.64Z"
      />
    </svg>
  </IconWrapper>
));
