/* eslint-disable quote-props */
import {
  AreaUnit,
  BuildingType,
  COUNTRY_CODES,
  CountryCode,
  CurrencyCode,
  EnergySource,
} from '@belimo-retrofit-portal/logic';
import { CURRENCY_CODE_OPTIONS } from 'src/modules/common/constants/options';

export const EXPORT_HEADER_COLUMNS = [
  'project id',
  'project title',
  'project goal',

  'building type',
  'building size unit',
  'building size',
  'building street address',
  'building city',
  'building country',
  'building zip code',

  'participants author',
  'participants property manager',
  'participants consulting engineer',
  'participants product engineer',

  'currency',
  'electricity price',
  'gas price',
  'heating oil price',
  'district heating price',
  'district cooling price',
  'heating other price',
  'cooling other price',

  'heating energy source',
  'heating energy annual consumption',
  'heating energy carbon intensity',
  'heating electrical aux annual consumption',

  'cooling energy source',
  'cooling energy annual consumption',
  'cooling energy carbon intensity',
  'cooling electrical aux annual consumption',

  'ventilation electrical aux annual consumption',

  'overall efficiency actual',
  'overall efficiency future',

  'heating area efficiency actual',
  'heating area efficiency future',

  'cooling area efficiency actual',
  'cooling area efficiency future',

  'ventilation area efficiency actual',
  'ventilation area efficiency future',

  'heating energy annual savings in MWh or m3',
  'heating energy annual savings in currency',
  'heating energy annual savings in co2 tonne',

  'heating electrical aux annual savings in MWh',
  'heating electrical aux annual savings in currency',
  'heating electrical aux annual savings in co2 tonne',

  'cooling energy annual savings in MWh',
  'cooling energy annual savings in currency',
  'cooling energy annual savings in co2 tonne',

  'cooling electrical aux annual savings in MWh',
  'cooling electrical aux annual savings in currency',
  'cooling electrical aux annual savings in co2 tonne',

  'ventilation electrical aux annual savings in MWh',
  'ventilation electrical aux annual savings in currency',
  'ventilation electrical aux annual savings in co2 tonne',

  'overall thermal energy annual savings in currency',
  'overall thermal energy annual savings in co2 tonne',

  'overall electrical aux annual savings in MWh',
  'overall electrical aux annual savings in currency',
  'overall electrical aux annual savings in co2 tonne',

  'total potential annual savings in currency',

  'project data and configuration',
] as const;

export const EXPORT_BUILDING_TYPE_MAP: Readonly<Record<BuildingType, string>> = {
  [BuildingType.OFFICE]: 'Office',
  [BuildingType.DATA_CENTER]: 'Data center',
  [BuildingType.EDUCATION_ELEMENTARY]: 'Education, elementary',
  [BuildingType.EDUCATION_HIGHER]: 'Education, higher',
  [BuildingType.EDUCATION_OTHER]: 'Education, other',
  [BuildingType.ENTERTAINMENT_LEISURE]: 'Entertainment and Leisure',
  [BuildingType.GOVERNMENT]: 'Government',
  [BuildingType.HEALTH]: 'Health',
  [BuildingType.HOSPITAL]: 'Hospital',
  [BuildingType.HOTEL]: 'Hotel',
  [BuildingType.LIFE_SCIENCE]: 'Life science',
  [BuildingType.RESTAURANT]: 'Restaurant',
  [BuildingType.RETAIL]: 'Retail',
  [BuildingType.WHOLESALE_RETAIL_TRADE]: 'Wholesale/retail trade',
};

export const EXPORT_AREA_UNIT_MAP: Readonly<Record<AreaUnit, string>> = {
  [AreaUnit.SQM]: 'm2',
  [AreaUnit.SQFT]: 'ft2',
};

export const EXPORT_COUNTRY_CODE_MAP: Readonly<Record<string, CountryCode>> =
  Object.fromEntries(COUNTRY_CODES.map((code) => [code, code]));

export const EXPORT_CURRENCY_CODE_MAP: Readonly<Record<string, CurrencyCode>> =
  Object.fromEntries(CURRENCY_CODE_OPTIONS.map((code) => [code, code]));

export const EXPORT_ENERGY_SOURCE_HEATING: Readonly<Record<EnergySource, string>> = {
  [EnergySource.ELECTRICITY]: 'Electricity',
  [EnergySource.GAS]: 'Gas',

  [EnergySource.HEATING_OIL]: 'Heating Oil',
  [EnergySource.HEATING_DISTRICT]: 'District',
  [EnergySource.HEATING_OTHER]: 'Other',

  [EnergySource.COOLING_DISTRICT]: '',
  [EnergySource.COOLING_OTHER]: '',
};

export const EXPORT_ENERGY_SOURCE_COOLING: Readonly<Record<EnergySource, string>> = {
  [EnergySource.ELECTRICITY]: 'Electricity',
  [EnergySource.GAS]: 'Gas',

  [EnergySource.HEATING_OIL]: '',
  [EnergySource.HEATING_DISTRICT]: '',
  [EnergySource.HEATING_OTHER]: '',

  [EnergySource.COOLING_DISTRICT]: 'District',
  [EnergySource.COOLING_OTHER]: 'Other',
};
