import './CurrencySymbol.scss';
import { CurrencyCode } from '@belimo-retrofit-portal/logic';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly currency: CurrencyCode;
};

export const CurrencySymbol = ({ currency }: Props): React.ReactElement => (
  <span className="bp-currency-symbol">
    <FormattedMessage id={`common/currency/${currency}`}/>
  </span>
);
