import * as DE from 'io-ts/DecodeError';
import * as D from 'io-ts/Decoder';
import { ProjectListImportError } from 'src/modules/project-list/types/ProjectListImport';

export function formatImportError(decodeError: D.DecodeError): ProjectListImportError[] {
  return getColumnErrors(decodeError).map(([column, errors]): ProjectListImportError => {
    const value = getActualValue(errors);
    const reason = value === '' || value == null ? 'required' : 'invalid';
    return { column, reason, value };
  });
}

function getActualValue(decodeError: D.DecodeError): string {
  // eslint-disable-next-line no-underscore-dangle
  if (decodeError._tag === 'Concat') {
    return (
      getActualValue(decodeError.left) ??
      getActualValue(decodeError.right)
    );
  }

  return DE.fold<string, string>({
    Index: (index: number, kind: DE.Kind, errors: D.DecodeError) => getActualValue(errors),
    Key: (key: string, kind: DE.Kind, errors: D.DecodeError) => getActualValue(errors),
    Lazy: (id: string, errors: D.DecodeError) => getActualValue(errors),
    Leaf: (input: unknown, error: string) => String(input),
    Member: (index: number, errors: D.DecodeError) => getActualValue(errors),
    Wrap: (error: string, errors: D.DecodeError) => getActualValue(errors),
  })(decodeError.value);
}

function getColumnErrors(decodeError: D.DecodeError): [string, D.DecodeError][] {
  // eslint-disable-next-line no-underscore-dangle
  if (decodeError._tag === 'Concat') {
    return [
      ...getColumnErrors(decodeError.left),
      ...getColumnErrors(decodeError.right),
    ];
  }

  return DE.fold<string, [string, D.DecodeError][]>({
    Index: (index: number, kind: DE.Kind, errors: D.DecodeError) => getColumnErrors(errors),
    Key: (key: string, kind: DE.Kind, errors: D.DecodeError) => [[key, errors]],
    Lazy: (id: string, errors: D.DecodeError) => getColumnErrors(errors),
    Leaf: (input: unknown, error: string) => [],
    Member: (index: number, errors: D.DecodeError) => getColumnErrors(errors),
    Wrap: (error: string, errors: D.DecodeError) => getColumnErrors(errors),
  })(decodeError.value);
}
