import { Action } from 'redux';
import { ASSET_CANCEL } from 'src/modules/asset/actions/AssetActions';
import { AssetState } from 'src/modules/asset/types/AssetState';

export function assetCancelReducer(state: AssetState, action: Action): AssetState {
  if (ASSET_CANCEL.is(action)) {
    const copy = new Map(state);
    copy.delete(action.meta);
    return copy;
  }

  return state;
}
