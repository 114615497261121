import { createContext } from 'react';

type OutputPageContextData = {
  readonly pageNumber: number;
  readonly totalPages: number;
};

export const OutputPageContext = createContext<OutputPageContextData>({
  pageNumber: 0,
  totalPages: 0,
});
