import './CheckboxField.scss';
import { Checkbox, CheckboxProps } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
CheckboxProps,
'id' |
'name' |
'checked' |
'onChange' |
'onBlur' |
'data-field-invalid' |
'data-field-touched'
>;

type Props<TData> = BaseProps & {
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, boolean>;
};

export const CheckboxField: <TData>(props: Props<TData>) => React.ReactElement = ({
  wrapperClass, field, ...props
}) => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(event.target.checked),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <Checkbox
      {...props}
      className={clsx('bp-checkbox-field', wrapperClass)}
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      checked={value}
      onChange={handleChange}
      onBlur={handleBlur}
      data-field-invalid={invalid}
      data-field-touched={meta.touched}
    />
  );
};
