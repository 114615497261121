import { identity } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const decoder: D.Decoder<unknown, null> = {
  decode: (input) => (
    input === '' || input === undefined || input === null
      ? D.success(null)
      : D.failure(input, 'Empty')
  ),
};

const encoder: E.Encoder<null, null> = {
  encode: identity,
};
export const EMPTY: C.Codec<unknown, null, null> = C.make(decoder, encoder);
