import { createSelector } from 'reselect';
import { getShareProjectData } from 'src/modules/sharing-projects/selectors/getShareProjectData';
import { assertNever } from 'src/utils/assert';

export const getShareProjectRemovingUser = createSelector([
  getShareProjectData,
], (data) => {
  if (data?.removing?.user) {
    return data.removing.user;
  }

  return assertNever('Project to remove is not initialed yet', { data });
});
