import './HvacFormSectionContent.scss';
import './HvacFormSetupField.scss';
import { FieldPath } from '@form-ts/core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacConfigOption } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly field: FieldPath<HvacFormData, FormError, HvacConfigOption | null>;
  readonly disabled: boolean;
  readonly options: ReadonlyArray<HvacConfigOption>;
  readonly title: string;
  readonly renderOption: (option: HvacConfigOption) => React.ReactElement;
  readonly renderSelectedOption: (option: HvacConfigOption) => React.ReactElement;
};

export const HvacFormSetupField = ({
  field,
  disabled,
  options,
  title,
  renderOption,
  renderSelectedOption,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const getOptionLabel = useCallback((option: HvacConfigOption | null) => (
    option
      ? intl.formatMessage({ id: option.title })
      : ''
  ), [intl]);

  return (
    <DropdownField
      wrapperClass="bp-hvac-form-setup-field"
      size="md"
      titleText={title}
      disabled={disabled}
      field={field}
      itemToString={getOptionLabel}
      options={options}
      renderSelectedItem={renderSelectedOption}
      itemToElement={renderOption}
    />
  );
};
