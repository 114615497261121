import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux-saga';
import { FUTURE, Future } from 'src/modules/common/types/Future';
import {
  PROJECT_LIST_UNFOLLOW_CANCEL,
  PROJECT_LIST_UNFOLLOW_CONFIRM,
  PROJECT_LIST_UNFOLLOW_SUBMIT,
} from 'src/modules/project-list/actions/ProjectListActions';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';

export function projectListUnfollowingReducer(state: ProjectListState, action: Action): ProjectListState {
  if (PROJECT_LIST_UNFOLLOW_CONFIRM.is(action)) {
    return {
      ...state,
      unfollowing: {
        project: action.data,
        user: action.meta,
        status: null,
      },
    };
  }

  if (PROJECT_LIST_UNFOLLOW_CANCEL.is(action)) {
    return {
      ...state,
      unfollowing: null,
    };
  }

  if (PROJECT_LIST_UNFOLLOW_SUBMIT.isPending(action)) {
    return modifyStatus(state, FUTURE.pending());
  }

  if (PROJECT_LIST_UNFOLLOW_SUBMIT.isSuccess(action)) {
    return {
      ...state,
      unfollowing: null,
    };
  }

  if (PROJECT_LIST_UNFOLLOW_SUBMIT.isFailure(action)) {
    return modifyStatus(state, FUTURE.success(undefined));
  }

  return state;
}

function modifyStatus(state: ProjectListState, status: Future<void>): ProjectListState {
  return pipe(
    O.id<ProjectListState>(),
    O.prop('unfollowing'),
    O.fromNullable,
    O.prop('status'),
    O.modify(() => status),
  )(state);
}
