import { UUID } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { PROJECT_VIEW_RESET } from 'src/modules/project-view/actions/ProjectViewActions';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { Transition } from 'src/modules/routing/types/Transition';
import { logDebug } from 'src/sagas/utils/logging';
import { assertDecode, assertNotEmpty } from 'src/utils/assert';
import { call, put } from 'typed-redux-saga';

export function* resetProjectDetails(transition: Transition, match: RouteMatch): SagaIterator<void> {
  const id = assertDecode(
    match.params.id,
    UUID,
    'Missing "id" route parameter',
    { transition, match },
  );

  yield* call(logDebug, `Resetting project "${id}" details...`);
  yield* put(PROJECT_VIEW_RESET.trigger(undefined, id));
  yield* call(logDebug, `Resetting project "${id}" details... done`);
}
