import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { loadProjectListFull } from 'src/modules/project-list/sagas/utils/loadProjectListFull';
import { getProjectListItem } from 'src/modules/project-list/selectors/getProjectListItem';
import { ProjectListItem } from 'src/modules/project-list/types/ProjectListItem';
import { call, select } from 'typed-redux-saga';

export function* loadProjectList(): SagaIterator<ProjectListItem[]> {
  try {
    const projects = yield* call(loadProjectListFull);
    const listItems: ProjectListItem[] = [];

    for (const project of projects) {
      const listItem = yield* select(getProjectListItem, project);
      listItems.push(listItem);
    }

    return listItems;
  } catch (error) {
    throw new RuntimeError('Could not load project list', {}, NativeError.wrap(error));
  }
}
