/* eslint-disable max-len,quote-props,@typescript-eslint/dot-notation */
import {
  EnergySource,
  getEnergyEfficiencyLabel,
  HvacArea,
  PROJECT_DATA,
  ProjectData,
  UUID,
} from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import * as E from 'io-ts/Encoder';
import { XlsxValue } from 'src/modules/common/types/XlsxValue';
import {
  EXPORT_AREA_UNIT_MAP,
  EXPORT_BUILDING_TYPE_MAP,
  EXPORT_COUNTRY_CODE_MAP,
  EXPORT_CURRENCY_CODE_MAP,
  EXPORT_ENERGY_SOURCE_COOLING,
  EXPORT_ENERGY_SOURCE_HEATING,
  EXPORT_HEADER_COLUMNS,
} from 'src/modules/project-list/constants/export';
import { ProjectListExportEntry } from 'src/modules/project-list/types/ProjectListExport';

const CELL_STRING = E.id<string>();
const CELL_DECIMAL_2: E.Encoder<number, Decimal> = {
  encode: (value) => value.toDecimalPlaces(2).toNumber(),
};
const CELL_DECIMAL_4: E.Encoder<number, Decimal> = {
  encode: (value) => value.toDecimalPlaces(4).toNumber(),
};
const CELL_ENUM: <T extends string>(mapping: Readonly<Record<T, string>>) => E.Encoder<string, T> = (mapping) => ({
  encode: (value) => mapping[value],
});
const CELL_RATING: E.Encoder<string, Decimal> = {
  encode: (value) => {
    const maxLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_UP));
    const minLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_DOWN));

    return minLabel === maxLabel
      ? minLabel
      : `${minLabel}–${maxLabel}`;
  },
};

const CELL_PROJECT_DATA: E.Encoder<string, ProjectData> = {
  encode: (value) => JSON.stringify(PROJECT_DATA.encode(value)),
};

const CELL_CARBON_SAVINGS: E.Encoder<number | string, Decimal> = {
  encode: (value) => {
    if (value.isNegative()) {
      return '<0';
    }
    if (value.isZero()) {
      return 0;
    }
    if (value.lessThan(1_000_000)) {
      return '<1';
    }

    return value.div(1_000_000).toDecimalPlaces(0).toNumber();
  },
};

const CELL_ENERGY_SAVINGS: E.Encoder<number | string, Decimal> = {
  encode: (value) => {
    if (value.isNegative()) {
      return '<0';
    }
    if (value.isZero()) {
      return 0;
    }
    if (value.lessThan(1_000)) {
      return '<1';
    }

    return value.div(1_000).toDecimalPlaces(0).toNumber();
  },
};

const CELL_AMOUNT_SAVINGS: E.Encoder<number | string, Decimal> = {
  encode: (value) => {
    if (value.isNegative()) {
      return '<0';
    }
    if (value.isZero()) {
      return 0;
    }
    if (value.lessThan(1_000)) {
      return '<1000';
    }

    return value.div(1_000).toDecimalPlaces(0).mul(1_000).toNumber();
  },
};

const CONTENT_DATA = E.struct({
  'project id': E.nullable(UUID),
  'project title': CELL_STRING,
  'project goal': CELL_STRING,

  'building type': CELL_ENUM(EXPORT_BUILDING_TYPE_MAP),
  'building size unit': CELL_ENUM(EXPORT_AREA_UNIT_MAP),
  'building size': E.nullable(CELL_DECIMAL_2),
  'building street address': CELL_STRING,
  'building city': CELL_STRING,
  'building country': CELL_ENUM(EXPORT_COUNTRY_CODE_MAP),
  'building zip code': CELL_STRING,

  'participants author': CELL_STRING,
  'participants property manager': CELL_STRING,
  'participants consulting engineer': CELL_STRING,
  'participants product engineer': CELL_STRING,

  'currency': CELL_ENUM(EXPORT_CURRENCY_CODE_MAP),
  'electricity price': E.nullable(CELL_DECIMAL_4),
  'gas price': E.nullable(CELL_DECIMAL_4),
  'heating oil price': E.nullable(CELL_DECIMAL_4),
  'district heating price': E.nullable(CELL_DECIMAL_4),
  'district cooling price': E.nullable(CELL_DECIMAL_4),
  'heating other price': E.nullable(CELL_DECIMAL_4),
  'cooling other price': E.nullable(CELL_DECIMAL_4),

  'heating energy source': CELL_ENUM(EXPORT_ENERGY_SOURCE_HEATING),
  'heating energy annual consumption': E.nullable(CELL_DECIMAL_2),
  'heating energy carbon intensity': E.nullable(CELL_DECIMAL_2),
  'heating electrical aux annual consumption': E.nullable(CELL_DECIMAL_2),

  'cooling energy source': CELL_ENUM(EXPORT_ENERGY_SOURCE_COOLING),
  'cooling energy annual consumption': E.nullable(CELL_DECIMAL_2),
  'cooling energy carbon intensity': E.nullable(CELL_DECIMAL_2),
  'cooling electrical aux annual consumption': E.nullable(CELL_DECIMAL_2),

  'ventilation electrical aux annual consumption': E.nullable(CELL_DECIMAL_2),

  'overall efficiency actual': E.nullable(CELL_RATING),
  'overall efficiency future': E.nullable(CELL_RATING),

  'heating area efficiency actual': E.nullable(CELL_RATING),
  'heating area efficiency future': E.nullable(CELL_RATING),

  'cooling area efficiency actual': E.nullable(CELL_RATING),
  'cooling area efficiency future': E.nullable(CELL_RATING),

  'ventilation area efficiency actual': E.nullable(CELL_RATING),
  'ventilation area efficiency future': E.nullable(CELL_RATING),

  'heating energy annual savings in MWh or m3': E.nullable(CELL_ENERGY_SAVINGS),
  'heating energy annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'heating energy annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'heating electrical aux annual savings in MWh': E.nullable(CELL_ENERGY_SAVINGS),
  'heating electrical aux annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'heating electrical aux annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'cooling energy annual savings in MWh': E.nullable(CELL_ENERGY_SAVINGS),
  'cooling energy annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'cooling energy annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'cooling electrical aux annual savings in MWh': E.nullable(CELL_ENERGY_SAVINGS),
  'cooling electrical aux annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'cooling electrical aux annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'ventilation electrical aux annual savings in MWh': E.nullable(CELL_ENERGY_SAVINGS),
  'ventilation electrical aux annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'ventilation electrical aux annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'overall thermal energy annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'overall thermal energy annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'overall electrical aux annual savings in MWh': E.nullable(CELL_ENERGY_SAVINGS),
  'overall electrical aux annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),
  'overall electrical aux annual savings in co2 tonne': E.nullable(CELL_CARBON_SAVINGS),

  'total potential annual savings in currency': E.nullable(CELL_AMOUNT_SAVINGS),

  'project data and configuration': CELL_PROJECT_DATA,
});

export const EXPORT_CONTENT_LINE: E.Encoder<XlsxValue[], ProjectListExportEntry> = {
  encode: (entry) => {
    const cells = CONTENT_DATA.encode({
      'project id': entry.project.id,
      'project title': entry.project.data.title,
      'project goal': entry.project.data.goals,

      'building type': entry.project.data.building.type,
      'building size unit': entry.project.data.building.unit,
      'building size': entry.project.data.building.size,
      'building street address': entry.project.data.building.address.street,
      'building city': entry.project.data.building.address.city,
      'building country': entry.project.data.building.address.country,
      'building zip code': entry.project.data.building.address.zip,

      'participants author': entry.project.data.participants.author,
      'participants property manager': entry.project.data.participants.propertyManager,
      'participants consulting engineer': entry.project.data.participants.consutlingEngineer,
      'participants product engineer': entry.project.data.participants.productManager,

      'currency': entry.project.data.currency,
      'electricity price': entry.project.data.hvac.prices[EnergySource.ELECTRICITY],
      'gas price': entry.project.data.hvac.prices[EnergySource.GAS],
      'heating oil price': entry.project.data.hvac.prices[EnergySource.HEATING_OIL],
      'district heating price': entry.project.data.hvac.prices[EnergySource.HEATING_DISTRICT],
      'district cooling price': entry.project.data.hvac.prices[EnergySource.COOLING_DISTRICT],
      'heating other price': entry.project.data.hvac.prices[EnergySource.HEATING_OTHER],
      'cooling other price': entry.project.data.hvac.prices[EnergySource.COOLING_OTHER],

      'heating energy source': entry.project.data.hvac.areas[HvacArea.HEATING].thermal.energySource,
      'heating energy annual consumption': entry.project.data.hvac.areas[HvacArea.HEATING].thermal.annualEnergyUse,
      'heating energy carbon intensity': entry.project.data.hvac.areas[HvacArea.HEATING].thermal.carbonIntensity,
      'heating electrical aux annual consumption': entry.project.data.hvac.areas[HvacArea.HEATING].electrical.annualEnergyUse,

      'cooling energy source': entry.project.data.hvac.areas[HvacArea.COOLING].thermal.energySource,
      'cooling energy annual consumption': entry.project.data.hvac.areas[HvacArea.COOLING].thermal.annualEnergyUse,
      'cooling energy carbon intensity': entry.project.data.hvac.areas[HvacArea.COOLING].thermal.carbonIntensity,
      'cooling electrical aux annual consumption': entry.project.data.hvac.areas[HvacArea.COOLING].electrical.annualEnergyUse,

      'ventilation electrical aux annual consumption': entry.project.data.hvac.areas[HvacArea.VENTILATION].electrical.annualEnergyUse,

      'overall efficiency actual': entry.results.overall.rating.actual,
      'overall efficiency future': entry.results.overall.rating.future,

      'heating area efficiency actual': entry.results.perArea.heating.rating.actual,
      'heating area efficiency future': entry.results.perArea.heating.rating.future,

      'cooling area efficiency actual': entry.results.perArea.cooling.rating.actual,
      'cooling area efficiency future': entry.results.perArea.cooling.rating.future,

      'ventilation area efficiency actual': entry.results.perArea.ventilation.rating.actual,
      'ventilation area efficiency future': entry.results.perArea.ventilation.rating.future,

      'heating energy annual savings in MWh or m3': entry.results.perArea.heating.savings.thermal.absolute,
      'heating energy annual savings in currency': entry.results.perArea.heating.savings.thermal.annual,
      'heating energy annual savings in co2 tonne': entry.results.perArea.heating.savings.carbon.thermal,

      'heating electrical aux annual savings in MWh': entry.results.perArea.heating.savings.electrical.absolute,
      'heating electrical aux annual savings in currency': entry.results.perArea.heating.savings.electrical.annual,
      'heating electrical aux annual savings in co2 tonne': entry.results.perArea.heating.savings.carbon.electrical,

      'cooling energy annual savings in MWh': entry.results.perArea.cooling.savings.thermal.absolute,
      'cooling energy annual savings in currency': entry.results.perArea.cooling.savings.thermal.annual,
      'cooling energy annual savings in co2 tonne': entry.results.perArea.cooling.savings.carbon.thermal,

      'cooling electrical aux annual savings in MWh': entry.results.perArea.cooling.savings.electrical.absolute,
      'cooling electrical aux annual savings in currency': entry.results.perArea.cooling.savings.electrical.annual,
      'cooling electrical aux annual savings in co2 tonne': entry.results.perArea.cooling.savings.carbon.electrical,

      'ventilation electrical aux annual savings in MWh': entry.results.perArea.ventilation.savings.electrical.absolute,
      'ventilation electrical aux annual savings in currency': entry.results.perArea.ventilation.savings.electrical.annual,
      'ventilation electrical aux annual savings in co2 tonne': entry.results.perArea.ventilation.savings.carbon.electrical,

      'overall thermal energy annual savings in currency': entry.results.overall.savings.thermal.annual,
      'overall thermal energy annual savings in co2 tonne': entry.results.overall.savings.carbon.thermal,

      'overall electrical aux annual savings in MWh': entry.results.overall.savings.electrical.absolute,
      'overall electrical aux annual savings in currency': entry.results.overall.savings.electrical.annual,
      'overall electrical aux annual savings in co2 tonne': entry.results.overall.savings.carbon.electrical,

      'total potential annual savings in currency': entry.results.overall.savings.overall.annual,

      'project data and configuration': entry.project.data,
    });
    return EXPORT_HEADER_COLUMNS.map((column) => cells[column]);
  },
};
