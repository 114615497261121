import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { useForm } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { TextField } from 'src/modules/form/components/TextField';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly open: boolean;
  readonly title: string;
  readonly onCancel: () => void;
  readonly onSubmit: (title: string) => void;
};

export const HvacFormSectionCopyTitleModal = ({ open, title, onSubmit, onCancel }: Props): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<string, FormError>('hvac.section.copy.title', {
    reinitialize: true,
    initialValues: title,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);
  const handleCancel = useCallback(() => {
    form.reset(form.initialState);
    onCancel();
  }, [form, onCancel]);

  return (
    <CustomModal
      open={open}
      onClose={handleCancel}
      aria-labelledby="hvac-title-dialog-title"
      size="md"
      isShaded={false}
      shouldUnmount={true}
    >
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={intl.formatMessage({ id: 'hvac/form/section/customTitle/modal/title' })}
        title={intl.formatMessage({ id: 'hvac/form/section/customTitle/modal/description' })}
      />

      <ModalBody>
        <TextField
          size="lg"
          field={form.field}
          labelText={
            <FormattedMessage id="hvac/form/section/customTitle/modal/fieldLabel"/>
          }
          maxLength={80}
        />
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          onClick={handleCancel}
        >
          <FormattedMessage id="hvac/form/section/customTitle/modal/cancel"/>
        </CustomButton>

        <CustomButton
          kind="primary"
          onClick={handleSubmit}
          autoFocus={true}
        >
          <FormattedMessage id="hvac/form/section/customTitle/modal/submit"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
