import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ArrowLeftIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m10.45 6.75.5.515L6.6 11.64h12.15v.72H6.6l4.35 4.375-.5.515L5.25 12l5.2-5.25Z"
      />
    </svg>
  </IconWrapper>
));
