import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { REPORT_GENERATE } from 'src/modules/report/actions/ReportActions';
import { ReportState } from 'src/modules/report/types/ReportState';

export function reportGenerateReducer(state: ReportState, action: Action): ReportState {
  if (REPORT_GENERATE.isPending(action)) {
    return { status: FUTURE.pending() };
  }
  if (REPORT_GENERATE.isSuccess(action)) {
    return { status: FUTURE.success(undefined) };
  }
  if (REPORT_GENERATE.isFailure(action)) {
    return { status: FUTURE.failure(action.data) };
  }

  return state;
}
