import { NativeError, RuntimeError, resolveInitialProjectData } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { createProject } from 'src/modules/common/sagas/utils/createProject';
import { updateProject } from 'src/modules/common/sagas/utils/updateProject';
import { Project } from 'src/modules/common/types/Project';
import { loadProjectListFull } from 'src/modules/project-list/sagas/utils/loadProjectListFull';
import { ProjectListImportEntry } from 'src/modules/project-list/types/ProjectListImport';
import { mergeImportData } from 'src/modules/project-list/utils/mergeImportData';
import { call } from 'typed-redux-saga';

export function* importProjects(entries: ReadonlyArray<ProjectListImportEntry>): SagaIterator<Project[]> {
  try {
    const result: Project[] = [];
    const projects = yield* call(loadProjectListFull);

    for (const entry of entries) {
      const project = projects.find((it) => it.id === entry.id);
      if (project) {
        const merged = yield* call(mergeImportData, project, entry.project);
        const resolved = yield* call(resolveInitialProjectData, merged);

        const updated = yield* call(updateProject, project.id, resolved);
        result.push(updated);
      } else {
        const resolved = yield* call(resolveInitialProjectData, entry.project);

        const created = yield* call(createProject, resolved);
        result.push(created);
      }
    }

    return result;
  } catch (error) {
    throw new RuntimeError('Could not import projects', { entries }, NativeError.wrap(error));
  }
}
