import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const GlobeIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M19.875 12a7.883 7.883 0 0 0-7.755-7.867H12a7.875 7.875 0 1 0 0 15.75h.12A7.882 7.882 0 0 0 19.875 12Zm-7.77 7.118h-.06c-.863 0-1.77-1.215-2.303-3.203.748-.11 1.502-.166 2.258-.165.806-.007 1.61.051 2.408.173-.556 1.98-1.44 3.157-2.303 3.195ZM12 15a16.44 16.44 0 0 0-2.4.158 16.373 16.373 0 0 1-.293-2.813h5.58c-.017.957-.12 1.911-.307 2.85A15.33 15.33 0 0 0 12 15Zm-5.647 1.328a7.08 7.08 0 0 1-1.455-3.953h3.63c.014.99.12 1.977.314 2.948a7.58 7.58 0 0 0-2.46.99l-.03.015Zm.082-8.768a6.953 6.953 0 0 0 2.438.96 16.448 16.448 0 0 0-.345 3.105h-3.63A7.073 7.073 0 0 1 6.434 7.56Zm5.61-2.685h.06c.84 0 1.702 1.155 2.25 3-.775.106-1.557.156-2.34.15a16.5 16.5 0 0 1-2.213-.135c.526-1.89 1.403-3 2.243-3.015ZM12 8.798c.843.005 1.685-.05 2.52-.165a15.3 15.3 0 0 1 .338 3h-5.58a15.84 15.84 0 0 1 .33-3c.792.109 1.592.164 2.392.165Zm5.55-1.253a7.043 7.043 0 0 1 1.552 4.08h-3.495a15.986 15.986 0 0 0-.36-3.127 7.268 7.268 0 0 0 2.25-.938l.053-.015Zm-1.943 4.83h3.495a7.013 7.013 0 0 1-1.5 3.968 7.387 7.387 0 0 0-2.354-.975c.213-.984.334-1.986.36-2.993Zm1.433-5.407a6.293 6.293 0 0 1-1.972.795 7.501 7.501 0 0 0-1.253-2.648 7.17 7.17 0 0 1 3.225 1.853Zm-6.69-1.89a7.447 7.447 0 0 0-1.297 2.715 6.3 6.3 0 0 1-2.116-.803 7.087 7.087 0 0 1 3.413-1.912ZM6.848 16.905A6.868 6.868 0 0 1 9 16.05a7.65 7.65 0 0 0 1.335 2.873 7.118 7.118 0 0 1-3.488-2.018Zm6.967 1.98a7.733 7.733 0 0 0 1.29-2.79 6.53 6.53 0 0 1 2.04.825 7.208 7.208 0 0 1-3.33 1.965Z"
      />
    </svg>
  </IconWrapper>
));
