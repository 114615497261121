import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { CONFIG_FETCH } from 'src/modules/config/actions/ConfigActions';
import { ConfigState } from 'src/modules/config/types/ConfigState';

export function configFetchReducer(state: ConfigState, action: Action): ConfigState {
  if (CONFIG_FETCH.isPending(action)) {
    return {
      data: FUTURE.pending(),
    };
  }
  if (CONFIG_FETCH.isSuccess(action)) {
    return {
      data: FUTURE.success(action.data),
    };
  }
  if (CONFIG_FETCH.isFailure(action)) {
    return {
      data: FUTURE.failure(action.data),
    };
  }

  return state;
}
