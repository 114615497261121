import { SagaIterator, Task } from 'redux-saga';
import { ASSET_CANCEL, ASSET_UPLOAD } from 'src/modules/asset/actions/AssetActions';
import { assetUploadSaga } from 'src/modules/asset/sagas/assetUploadSaga';
import { cancel, fork, take } from 'typed-redux-saga';

export function* assetSaga(): SagaIterator<void> {
  const TASK_MAP = new Map<string, Task>();

  while (true) {
    const action = yield* take([
      ASSET_UPLOAD.isRequest,
      ASSET_CANCEL.is,
    ]);

    const fieldId = action.meta;
    const oldTask = TASK_MAP.get(fieldId);
    if (oldTask) {
      yield* cancel(oldTask);
      TASK_MAP.delete(fieldId);
    }

    if (ASSET_UPLOAD.isRequest(action)) {
      const newTask = yield* fork(assetUploadSaga, action);
      TASK_MAP.set(fieldId, newTask);
    }
  }
}
