import { ChangeOverLevel } from 'src/modules/common/types/ChangeOverLevel';
import { DifferentTemperatureLevel } from 'src/modules/common/types/DifferentTemperatureLevel';
import { WaterToAir } from 'src/modules/common/types/WaterToAir';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';

const filterByWaterToAir = (
  value: WaterToAir | null,
  schema: ConfigSchema,
): boolean => (
  value === null || schema.water_to_air === value
);

const filterByChangeOverLevel = (
  value: ChangeOverLevel | null,
  schema: ConfigSchema,
): boolean => (
  value === null || schema.change_over_level === value
);

const filterByDifferentTemperatureLevel = (
  value: DifferentTemperatureLevel | null,
  schema: ConfigSchema,
): boolean => (
  value === null || schema.different_temperature_level === value
);

export const filterSchemas = (
  schemas: ReadonlyArray<ConfigSchema>,
  filterFormValues: SchemaFilterForm,
): ReadonlyArray<ConfigSchema> => schemas.filter(
  (schema) => filterByWaterToAir(filterFormValues.waterToAir, schema) &&
    filterByChangeOverLevel(filterFormValues.changeOverLevel, schema) &&
    filterByDifferentTemperatureLevel(filterFormValues.differentTemperatureLevel, schema),

);
