import { Action } from 'redux';
import { NOTIFICATION_HIDE, NOTIFICATION_SHOW } from 'src/modules/notifications/actions/NotificationActions';
import { NotificationState } from 'src/modules/notifications/types/NotificationData';

const defaultState: NotificationState = [];

// eslint-disable-next-line @typescript-eslint/default-param-last
export const notificationReducer = (state: NotificationState = defaultState, action: Action): NotificationState => {
  if (NOTIFICATION_SHOW.is(action)) {
    return [
      ...state,
      {
        id: action.data.id,
        type: action.data.type,
        variant: action.data.variant,
        messageId: action.data.messageId,
        messageValue: action.data.messageValue,
      }];
  }
  if (NOTIFICATION_HIDE.is(action)) {
    return state.filter((notification) => notification.id !== action.data);
  }

  return state;
};
