import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { syncProjectForeground } from 'src/modules/project-sync/sagas/utils/syncProjectForeground';
import { waitProjectSynchronization } from 'src/modules/project-sync/sagas/utils/waitProjectSynchronization';
import { getProjectSyncData } from 'src/modules/project-sync/selectors/getProjectSyncData';
import { call, select } from 'typed-redux-saga';

export function* forceProjectSynchronization(project: Project): SagaIterator<Project> {
  // if synchronization is scheduled, new foreground task is triggered
  // to cancel all existing processes and to skip the planned delay
  const syncData = yield* select(getProjectSyncData);
  return syncData?.status.state === 'pending'
    ? yield* call(syncProjectForeground, project)
    : yield* call(waitProjectSynchronization, project);
}
