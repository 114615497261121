import { Matrix } from '@belimo-retrofit-portal/logic';

export const MATRIX_IDENTITY: Matrix = {
  a: 1,
  b: 0,
  c: 0,
  d: 1,
  e: 0,
  f: 0,
};
