import './SavingTileCard.scss';
import clsx from 'clsx';
import React, { memo } from 'react';
import { SavingTileAmount } from 'src/modules/common/components/SavingTileAmount';

type Props = {
  savingTitle: React.ReactNode;
  icon: React.ReactNode;
  amountTitle: React.ReactNode;
  amount: React.ReactNode;
  unit: React.ReactNode;
  children: React.ReactNode;
  small: boolean;
};

export const SavingTileCard = memo(({
  savingTitle,
  icon,
  amountTitle,
  amount,
  unit,
  children,
  small,
}:Props) => (
  <div className={clsx(
    'bp-saving-tile-card',
    { 'bp-saving-tile-card--sm': small },
  )}
  >
    <div className="bp-saving-tile-card__title">
      {savingTitle}
    </div>

    <div className="bp-saving-tile-card__details">
      <div className="bp-saving-tile-card__icon">
        {icon}
      </div>

      <SavingTileAmount
        title={amountTitle}
        amount={amount}
        unit={unit}
      />
    </div>

    <div>
      {children}
    </div>
  </div>
));
