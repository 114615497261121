import './ReportDocumentPageHvac.scss';
import { HvacArea } from '@belimo-retrofit-portal/logic';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EnergyEfficiencyTransition } from 'src/modules/common/components/EnergyEfficiencyTransition';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { OutputBlock, splitBlocksIntoPages } from 'src/modules/output/utils/splitBlocksIntoPages';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { OutputTitle } from 'src/modules/output/views/OutputTitle';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated, ReportGeneratedHvacSection } from 'src/modules/report/types/ReportGenerated';
import { assertNotEmpty } from 'src/utils/assert';

type Props = {
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageHvac = React.memo(({ document, formData }: Props): React.ReactElement => (
  <>
    {formData.hvac.showHeating && (
      <AreaPage
        area={HvacArea.HEATING}
        order={5_000}
        document={document}
        formData={formData}
      />
    )}

    {formData.hvac.showCooling && (
      <AreaPage
        area={HvacArea.COOLING}
        order={5_100}
        document={document}
        formData={formData}
      />
    )}

    {formData.hvac.showVentilation && (
      <AreaPage
        area={HvacArea.VENTILATION}
        order={5_200}
        document={document}
        formData={formData}
      />
    )}
  </>
));

type AreaProps = {
  readonly area: HvacArea;
  readonly order: number;

  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

const AreaPage = React.memo(({ area, order, document, formData }: AreaProps): React.ReactElement => {
  const contentElement = useRef<HTMLDivElement>(null);
  const [outputPages, setOutputPages] = useState<BlockElementSection[][]>([]);

  useLayoutEffect(() => {
    const pageElement = contentElement.current;
    if (!pageElement) {
      return;
    }

    const pages = collectHvacPages(area, document, formData, pageElement);
    setOutputPages(pages);
  }, [area, document, formData]);

  return (
    <>
      <OutputPage ref={contentElement} showPageNumber={true}>
        {document.hvac[area]
          .map((section) => linearizeHvacSection(section, formData))
          .flat(1)
          .map((section) => React.cloneElement(section.content, { key: section.id }))}
      </OutputPage>

      {outputPages.map((pageData, pageIndex) => (
        <OutputDocumentPage
          // eslint-disable-next-line react/no-array-index-key
          key={pageIndex}
          id={`report-hvac-${area}-${pageIndex}`}
          order={order + pageIndex}
          section="hvac"
          visible={formData.hvac.enabled}
        >
          <OutputPage showPageNumber={true}>
            <div className="bp-report-document-page-hvac__header">
              <OutputTitle>
                <FormattedMessage id="report/document/hvac/header"/>
              </OutputTitle>
            </div>

            {pageIndex === 0 && (
              <div className="bp-report-document-page-hvac__title">
                {AREA_TITLES[area]}
              </div>
            )}

            {pageData.map((section) => React.cloneElement(section.content, { key: section.id }))}
          </OutputPage>
        </OutputDocumentPage>
      ))}
    </>
  );
});

type BlockElementSection = {
  readonly id: string;
  readonly content: React.ReactElement;
};

function collectHvacPages(
  area: HvacArea,
  document: ReportGenerated,
  formData: ReportFormData,
  pageElement: HTMLDivElement,
): BlockElementSection[][] {
  const sectionMargin = pt2px(10);
  const sectionHeight = (sectionId: string): number => assertNotEmpty(
    pageElement.querySelector(`[data-hvac-section="${sectionId}"]`)?.clientHeight,
    `Could not calculate HVAC section "${sectionId}" height`,
    {},
  );

  const PAGE_HEIGHT = 986;
  const PAGE_HEADER_HEIGHT = pt2px(12) + pt2px(5);
  const AREA_NAME_HEIGHT = pt2px(12) + pt2px(10);

  const blocks: OutputBlock<'page', BlockElementSection>[] = [{
    block: 'page',
    widows: 1,
    orphans: 1,
    rows: document.hvac[area]
      .map((section) => linearizeHvacSection(section, formData))
      .flat(1)
      .map((section) => ({
        element: section,
        height: () => sectionHeight(section.id) + sectionMargin,
      })),
  }];

  return splitBlocksIntoPages(
    blocks,
    (pageIndex) => (
      pageIndex === 0
        ? PAGE_HEIGHT - PAGE_HEADER_HEIGHT - AREA_NAME_HEIGHT
        : PAGE_HEIGHT - PAGE_HEADER_HEIGHT
    ),
  );
}

function linearizeHvacSection(
  section: ReportGeneratedHvacSection,
  formData: ReportFormData,
): BlockElementSection[] {
  const result: BlockElementSection[] = [];

  const isCommentShown = Boolean(section.actual.comment || section.future.comment);

  result.push({
    id: section.config.id,
    content: (
      <div data-hvac-section={section.config.id} className="bp-report-document-page-hvac__section">
        <div className="bp-report-document-page-hvac__section-efficiency">
          <div className="bp-report-document-page-hvac__section-title">
            {section.config.number}&nbsp;<FormattedMessage id={section.config.name}/>
            {Boolean(section.title) && `\u00A0\u2013 ${section.title}`}
          </div>

          <div className="bp-report-document-page-hvac__section-rating">
            <EnergyEfficiencyTransition
              actual={section.actual.rating}
              future={section.future.rating}
            />
          </div>
        </div>

        {formData.hvac.showComments && (
          <div className="bp-report-document-page-hvac__details">
            <div className="bp-report-document-page-hvac__detail">
              <div className="bp-report-document-page-hvac__detail-state">
                <FormattedMessage id="report/document/hvac/comment/title/actual"/>
              </div>

              {section.actual.title && (
                <div className="bp-report-document-page-hvac__detail-title">
                  <FormattedMessage id={section.actual.title}/>
                </div>
              )}

              {section.actual.explanation && (
                <div className="bp-report-document-page-hvac__detail-description">
                  <FormattedMessage id={section.actual.explanation}/>
                </div>
              )}

              {isCommentShown && (
                <div className="bp-report-document-page-hvac__detail-comment">
                  {section.actual.comment}
                </div>
              )}
            </div>

            <div className="bp-report-document-page-hvac__details-icon">
              <ArrowRightIcon/>
            </div>

            <div className="bp-report-document-page-hvac__detail">
              <div className="bp-report-document-page-hvac__detail-state">
                <FormattedMessage id="report/document/hvac/comment/title/future"/>
              </div>

              {section.future.title && (
                <div className="bp-report-document-page-hvac__detail-title">
                  <FormattedMessage id={section.future.title}/>
                </div>
              )}

              {section.future.explanation && (
                <div className="bp-report-document-page-hvac__detail-description">
                  <FormattedMessage id={section.future.explanation}/>
                </div>
              )}

              {isCommentShown && (
                <div className="bp-report-document-page-hvac__detail-comment">
                  {section.future.comment}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ),
  });

  for (const copy of section.copies) {
    result.push({
      id: copy.id,
      content: (
        <div data-hvac-section={copy.id} className="bp-report-document-page-hvac__section">
          <div className="bp-report-document-page-hvac__section-efficiency">
            <div className="bp-report-document-page-hvac__section-title">
              {section.config.number}&nbsp;<FormattedMessage id={section.config.name}/> &ndash; {copy.name}
            </div>

            <div className="bp-report-document-page-hvac__section-rating">
              <EnergyEfficiencyTransition
                actual={copy.actual.rating}
                future={copy.future.rating}
              />
            </div>
          </div>

          {formData.hvac.showComments && (
            <div className="bp-report-document-page-hvac__details">
              <div className="bp-report-document-page-hvac__detail">
                <div className="bp-report-document-page-hvac__detail-state">
                  <FormattedMessage id="report/document/hvac/comment/title/actual"/>
                </div>

                {copy.actual.title && (
                  <div className="bp-report-document-page-hvac__detail-title">
                    <FormattedMessage id={copy.actual.title}/>
                  </div>
                )}

                {copy.actual.explanation && (
                  <div className="bp-report-document-page-hvac__detail-description">
                    <FormattedMessage id={copy.actual.explanation}/>
                  </div>
                )}

                {isCommentShown && (
                  <div className="bp-report-document-page-hvac__detail-comment">
                    {copy.actual.comment}
                  </div>
                )}
              </div>

              <div className="bp-report-document-page-hvac__details-icon">
                <ArrowRightIcon/>
              </div>

              <div className="bp-report-document-page-hvac__detail">
                <div className="bp-report-document-page-hvac__detail-state">
                  <FormattedMessage id="report/document/hvac/comment/title/future"/>
                </div>

                {copy.future.title && (
                  <div className="bp-report-document-page-hvac__detail-title">
                    <FormattedMessage id={copy.future.title}/>
                  </div>
                )}

                {copy.future.explanation && (
                  <div className="bp-report-document-page-hvac__detail-description">
                    <FormattedMessage id={copy.future.explanation}/>
                  </div>
                )}

                {isCommentShown && (
                  <div className="bp-report-document-page-hvac__detail-comment">
                    {copy.future.comment}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ),
    });
  }

  return result;
}

function pt2px(value: number): number {
  return value / 0.75;
}

const AREA_TITLES = {
  heating: <FormattedMessage id="report/form/schema/area/heating"/>,
  cooling: <FormattedMessage id="report/form/schema/area/cooling"/>,
  ventilation: <FormattedMessage id="report/form/schema/area/ventilation"/>,
};
