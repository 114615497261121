import './HvacFormSectionFormBlock.scss';
import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { EnergyEfficiencyLabel } from 'src/modules/common/components/EnergyEfficiencyLabel';
import { LABELS } from 'src/modules/common/constants/energyEfficiencyLabels';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  readonly title: React.ReactNode;
  readonly level: EnergyEfficiencyClass | null;
  readonly children: React.ReactNode;
};

export const HvacFormSectionFormBlock = ({ title, level, children }: Props): React.ReactElement => (
  <div className="bp-hvac-form-section-form-block">
    <div className="bp-hvac-form-section-form-block__label-wrapper">
      <LeadText className="bp-hvac-form-section-form-block__label-title">
        {title}
      </LeadText>

      <div className="bp-hvac-form-section-form-block__label">
        <EnergyEfficiencyLabel value={level} sized={false}>
          {level === null ? '\u2013' : LABELS[level]}
        </EnergyEfficiencyLabel>
      </div>
    </div>

    <div className="bp-hvac-form-section-form-block__content">
      {children}
    </div>
  </div>
);
