import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { DEVICE_TYPE } from 'src/modules/common/codecs/DeviceType';
import { EMPTY } from 'src/modules/common/codecs/Empty';
import { ConfigDevice } from 'src/modules/config/types/ConfigDevice';

const CONFIG_DEVICE_DECODER: D.Decoder<unknown, ConfigDevice> = D.struct({
  id: D.string,
  type: DEVICE_TYPE,

  name: D.string,
  explanation: D.union(EMPTY, D.string),
});

export const CONFIG_DEVICE = C.make(CONFIG_DEVICE_DECODER, E.id());
