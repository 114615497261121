import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CopyIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M10.2 22.267V4.4h13.867v17.867H10.2Zm.933-.934h12v-16h-12v16ZM6.6 25.867v-16.8h.933v15.866H19.4v.934H6.6Z"
      />
    </svg>
  </IconWrapper>
));
