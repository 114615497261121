import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { EVALUATION_CHANGE } from 'src/modules/evaluation/actions/EvaluationActions';
import { getEvaluationFormData } from 'src/modules/evaluation/selectors/getEvaluationFormData';
import { getEvaluationResults } from 'src/modules/evaluation/selectors/getEvaluationResults';
import { EvaluationScreen } from 'src/modules/evaluation/views/EvaluationScreen';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { getReportCurrency } from 'src/modules/report/selectors/getReportCurrency';
import { SHARE_PROJECT_OPEN } from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const EvaluationPage = (): React.ReactElement => {
  const intl = useIntl();
  const project = useSelector(getProjectDetails);
  const currency = useParametricSelector(getReportCurrency, project);
  const results = useParametricSelector(getEvaluationResults, project);
  const formData = useParametricSelector(getEvaluationFormData, project);
  const saveForm = useAction(EVALUATION_CHANGE.trigger);
  const shareProject = useBoundAction(SHARE_PROJECT_OPEN.trigger, project);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'evaluation/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />
        <EvaluationScreen
          projectId={project.id}
          currency={currency}
          results={results}
          formData={formData}
          onChange={saveForm}
        />
      </AppLayout>

      <ShareProject/>
    </>
  );
};
