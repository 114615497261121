import './HvacFormSectionCopyDeleteConfirmationModal.scss';
import { WarningFilled } from '@carbon/icons-react';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { BodyText } from 'src/modules/common/typography/BodyText';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  sectionName: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const HvacFormSectionCopyDeleteConfirmationModal = (
  { sectionName, open, onClose, onSubmit }: Props,
): React.ReactElement => {
  const intl = useIntl();

  return (
    <CustomModal
      danger={true}
      className="bp-hvac-form-section-copy-delete-confirmation-modal"
      open={open}
      isShaded={false}
      onClose={onClose}
      size="sm"
      shouldUnmount={true}
    >
      <ModalHeader
        label={<FormattedMessage id="hvac/form/section/copy/modal/header/label"/>}
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        labelClassName="bp-hvac-form-section-copy-delete-confirmation-modal__label"
      />

      <ModalBody className="bp-hvac-form-section-copy-delete-confirmation-modal__body">
        <WarningFilled color={CARBON_THEME.belimoOrangeColor} size={64}/>

        <LeadText>
          <FormattedMessage id="hvac/form/section/copy/modal/body/title"/>
        </LeadText>

        <BodyText>
          <FormattedMessage id="hvac/form/section/copy/modal/body/description" values={{ section: sectionName }}/>
        </BodyText>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          autoFocus={true}
          onClick={onClose}
        >
          <FormattedMessage id="hvac/form/section/copy/modal/footer/cancel"/>
        </CustomButton>

        <CustomButton
          kind="primary"
          onClick={onSubmit}
        >
          <FormattedMessage id="hvac/form/section/copy/modal/footer/submit"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
