import { UUID } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ORGANIZATION } from 'src/modules/common/codecs/Organization';
import { USER_ROLE } from 'src/modules/common/codecs/UserRole';
import { AuthUser } from 'src/modules/common/types/AuthUser';

export const AUTH_USER: C.Codec<unknown, Json, AuthUser> = C.struct({
  id: UUID,
  email: C.string,
  firstName: C.string,
  lastName: C.string,
  organization: ORGANIZATION,
  role: USER_ROLE,
});
