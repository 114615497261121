import {
  DeviceIdentifier,
  ProjectSchema,
  ProjectSchemaArea,
  ProjectSchemaAssignments,
  SchemaArea,
} from '@belimo-retrofit-portal/logic';
import { ConfigData } from 'src/modules/config/types/ConfigData';
import { ConfigSchema, ConfigSchemaContainer } from 'src/modules/config/types/ConfigSchema';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaContainer } from 'src/modules/schema/types/SchemaContainer';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { MultipleSchemaFormData, SchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import { assertDefined } from 'src/utils/assert';

export function mapSchemaFormDataToProject(schema: SchemaFormData): Omit<ProjectSchemaArea, 'matrix'> {
  return {
    schema: schema.schema.id,
    comment: schema.comment,
    actual: {
      assignments: mapAssignments(schema.actual.assignments),
    },
    future: schema.future.map((future) => ({
      id: future.number,
      assignments: mapAssignments(future.assignments),
    })),
  };

  function mapAssignments(assignments: ReadonlyArray<SchemaAssignment>): ProjectSchemaAssignments {
    const result: Record<string, DeviceIdentifier> = {};
    for (const assignment of assignments) {
      if (assignment.selection) {
        result[assignment.container.id] = {
          deviceId: assignment.selection.device.id,
          valvePosition: assignment.selection.valvePosition,
        };
      }
    }

    return result;
  }
}

export function mapSchemaProjectToFormData(schema: ProjectSchema, config: ConfigData): MultipleSchemaFormData {
  return {
    [SchemaArea.HEATING]: mapSchemaByArea(SchemaArea.HEATING),
    [SchemaArea.COOLING]: mapSchemaByArea(SchemaArea.COOLING),
    [SchemaArea.VENTILATION]: mapSchemaByArea(SchemaArea.VENTILATION),
  };

  function mapSchemaByArea(area: SchemaArea): SchemaFormData | null {
    const currentSchema = schema[area];
    if (!currentSchema) {
      return null;
    }

    const currentSchemaArea = assertDefined(
      config.schemas.find((configSchema) => configSchema.id === currentSchema.schema),
      `Schema template "${currentSchema.schema}" could not be found`,
      { schema, config },
    );

    return {
      schema: currentSchemaArea,
      comment: currentSchema.comment,
      actual: {
        type: 'actual',
        assignments: mapAssignments(currentSchema.actual.assignments, currentSchemaArea),
      },
      future: currentSchema.future.map((future) => ({
        type: 'future',
        number: future.id,
        assignments: mapAssignments(future.assignments, currentSchemaArea),
      })),
    };
  }

  function mapAssignments(assignments: ProjectSchemaAssignments, currentSchema: ConfigSchema): SchemaAssignment[] {
    return Object.entries(currentSchema.children).map(([id, child]) => {
      const container = mapContainer(id, child);
      const assignment = assignments[id];

      return {
        container: container,
        selection: assignment ? mapAssignment(container, assignment) : null,
      };
    });
  }

  function mapContainer(id: string, container: ConfigSchemaContainer): SchemaContainer {
    const configContainer = assertDefined(
      config.containers.find((it) => it.type === container.type),
      'Could not find container configuration',
      { container, config },
    );

    return {
      id: id,
      type: container.type,

      name: configContainer.name,
      description: configContainer.description,

      position: {
        x: container.left,
        y: container.top,
      },
      devices: configContainer.children.map((device) => mapDevice(container, device)),
    };
  }

  function mapDevice(container: ConfigSchemaContainer, device: DeviceIdentifier): SchemaDevice {
    const configDevice = assertDefined(
      config.devices.find((it) => it.id === device.deviceId),
      'Could not find device configuration',
      { container, device },
    );

    return {
      device: configDevice,
      orientation: container.orientation,
      valvePosition: device.valvePosition,
      flowDirection: container.flow_direction,
      thermalEnergy: container.termal_energy,
    };
  }

  function mapAssignment(container: SchemaContainer, assignment: DeviceIdentifier): SchemaDevice {
    const device = container.devices.find((it) => (
      it.device.id === assignment.deviceId &&
      it.valvePosition === assignment.valvePosition
    ));

    return assertDefined(
      device,
      'Could not find assigned device',
      { assignment },
    );
  }
}
