import { NativeError } from '@belimo-retrofit-portal/logic';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { not } from 'fp-ts/Predicate';
import * as S from 'fp-ts/string';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { SHARE_PROJECT_SUBMIT } from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { SHARE_PROJECT_FORM_SCHEMA } from 'src/modules/sharing-projects/constants/schema';
import { loadSharedProjectUsers } from 'src/modules/sharing-projects/sagas/utils/loadSharedProjectUsers';
import { shareProjectWith } from 'src/modules/sharing-projects/sagas/utils/shareProjectWith';
import { getShareProjectProject } from 'src/modules/sharing-projects/selectors/getShareProjectProject';
import { getShareProjectUsers } from 'src/modules/sharing-projects/selectors/getShareProjectUsers';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* shareProjectSubmitSaga(
  action: GetRequestActionType<typeof SHARE_PROJECT_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;

  const project = yield* select(getShareProjectProject);
  const currentUsers = yield* select(getShareProjectUsers);
  const schema = SHARE_PROJECT_FORM_SCHEMA(
    currentUsers
      .map((it) => it.email)
      .concat(project.owner ? [project.owner.email] : []),
  );

  const formValid = yield* call(formValidate, form, schema);
  if (!formValid) {
    return;
  }

  const values = form.values.get(form.currentState);
  const emails = pipe(
    values.users.split(','),
    A.map(S.trim),
    A.map(S.toLowerCase),
    A.filter(not(S.isEmpty)),
    A.uniq(S.Eq),
  );

  try {
    yield* call(logDebug, 'Post emails to share project... started');
    yield* put(SHARE_PROJECT_SUBMIT.pending());
    yield* call(formSubmitStart, form);

    yield* call(shareProjectWith, project, emails);
    const updatedUsers = yield* call(loadSharedProjectUsers, project);

    yield* call({
      fn: form.reset,
      context: form,
    }, form.initialState);
    yield* call(formSubmitSuccess, form);

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'shareProject/notification/share/success',
        messageValue: {
          userList: values.users,
        },
      },
    );

    yield* call(logDebug, 'Post emails to share project... done');
    yield* put(SHARE_PROJECT_SUBMIT.success(updatedUsers));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(formSubmitFailure, form, {
      users: {
        path: '',
        code: 'emailListFailure',
        value: values,

        message: String(error),
        context: {},
      },
    });

    yield* call(logError, 'Post emails to share project... error', { error });
    yield* put(SHARE_PROJECT_SUBMIT.failure(NativeError.wrap(error)));
  }
}
