import { assetCancelReducer } from 'src/modules/asset/reducers/assetCancelReducer';
import { assetUploadReducer } from 'src/modules/asset/reducers/assetUploadReducer';
import { AssetState } from 'src/modules/asset/types/AssetState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: AssetState = new Map();

export const assetReducer = chainReducers(defaultState, [
  assetUploadReducer,
  assetCancelReducer,
]);
