import React from 'react';
import { useSelector } from 'react-redux';
import { Asset } from 'src/modules/common/types/Asset';
import { getApiBaseUrl } from 'src/modules/config/selectors/getApiBaseUrl';

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  readonly asset: Asset;
  readonly size: 'small' | 'medium' | 'large';
};

export const AssetImage = ({ asset, size, alt, ...props }: Props): JSX.Element => {
  const apiBaseUrl = useSelector(getApiBaseUrl);
  const imageUrl = size === 'medium'
    ? `${apiBaseUrl}/preview/${encodeURIComponent(asset.id)}/500/500`
    : size === 'small'
      ? `${apiBaseUrl}/preview/${encodeURIComponent(asset.id)}/100/100`
      : `${apiBaseUrl}/source/${encodeURIComponent(asset.id)}`;

  return <img alt={alt} src={imageUrl} {...props}/>;
};
