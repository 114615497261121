import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { assertNotNull } from 'src/utils/assert';

export const getProjectSchema = createSelector([
  getProjectLazy,
], (project) => assertNotNull(
  project.data.schema,
  'Project schema is not selected',
  { project },
));
