import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { RouteParams } from 'src/modules/routing/types/RouteParams';

export function isMatchEqual(match1: RouteMatch, match2: RouteMatch): boolean {
  return (
    match1.state.name === match2.state.name &&
        isParamsEqual(match1.params, match2.params)
  );
}

export function isMatchContain(possibleAncestor: RouteMatch, match: RouteMatch): boolean {
  if (isMatchEqual(possibleAncestor, match)) {
    return true;
  }

  return possibleAncestor.parent
    ? isMatchContain(possibleAncestor.parent, match)
    : false;
}

function isParamsEqual(
  params1: RouteParams,
  params2: RouteParams,
): boolean {
  const aKeys = Object.keys(params1);
  const bKeys = Object.keys(params2);

  return (
    aKeys.length === bKeys.length &&
        aKeys.every((key) => params1[key] === params2[key])
  );
}
