import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { optionalFuture } from 'src/modules/common/utils/optional';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { ConfigState } from 'src/modules/config/types/ConfigState';

export function configLanguageSwitchReducer(state: ConfigState, action: Action): ConfigState {
  if (CONFIG_LANGUAGE_SWITCH.isSuccess(action)) {
    return pipe(
      O.id<ConfigState>(),
      O.prop('data'),
      O.compose(optionalFuture()),
      O.prop('i18n'),
      O.modify(() => action.data),
    )(state);
  }

  return state;
}
