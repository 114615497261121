import { SagaIterator } from 'redux-saga';
import { SCROLL_RESTORE } from 'src/modules/scroll/actions/ScrollRestoration';
import { getScrollRestoration } from 'src/modules/scroll/selectors/getScrollRestoration';
import { call, put, select } from 'typed-redux-saga';

export function* scrollRestore(): SagaIterator<void> {
  const scrollRestoration = yield* select(getScrollRestoration);

  if (scrollRestoration.isRestoring) {
    window.requestAnimationFrame(() => window.scrollTo(0, scrollRestoration.lastScrollPosition));

    yield* put(SCROLL_RESTORE.trigger({
      isRestoring: false,
      lastScrollPosition: 0,
    }));
  } else {
    yield* call(window.scrollTo, 0, 0);
  }
}
