import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { PROJECT_LIST_DOWNLOAD } from 'src/modules/project-list/actions/ProjectListActions';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';

export function projectListDownloadReducer(state: ProjectListState, action: Action): ProjectListState {
  if (PROJECT_LIST_DOWNLOAD.isPending(action)) {
    return {
      ...state,
      downloading: {
        project: action.meta,
        status: FUTURE.pending(),
      },
    };
  }
  if (PROJECT_LIST_DOWNLOAD.isSuccess(action)) {
    return {
      ...state,
      downloading: {
        project: action.meta,
        status: FUTURE.success(undefined),
      },
    };
  }
  if (PROJECT_LIST_DOWNLOAD.isFailure(action)) {
    return {
      ...state,
      downloading: {
        project: action.meta,
        status: FUTURE.failure(action.data),
      },
    };
  }

  return state;
}
