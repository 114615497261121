import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const DownloadIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M21 21H3m15-10-6 6m0 0-6-6m6 6V3"/>
    </svg>
  </IconWrapper>
));
