import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import { filter } from 'fp-ts/Record';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { getApiBaseUrl } from 'src/modules/config/selectors/getApiBaseUrl';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { isDefined } from 'src/utils/guard';
import { call, select } from 'typed-redux-saga';

export function* makeRestRequest(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  relativeUrl: string,
  requestData: Json | FormData | URLSearchParams | undefined = undefined,
): SagaIterator<{ content: unknown }> {
  const language = yield* select(getLanguage);
  const apiBaseUrl = yield* select(getApiBaseUrl);

  const url = apiBaseUrl.concat(relativeUrl);
  const config: RequestInit = {
    method: method,
    mode: 'cors',
    credentials: 'include',
    headers: filter(isDefined)({
      'Content-Type': getRequestType(requestData),
      'Accept-Language': language,
    }),
    body: getRequestBody(requestData),
  };

  try {
    const response = yield* call(fetch, url, config);
    if (!response.ok) {
      throw new HttpError(response);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType?.startsWith('application/json')) {
      const content: unknown = yield* call({
        fn: response.json,
        context: response,
      });
      return { content };
    }
    if (contentType?.startsWith('text/plain')) {
      const content: unknown = yield* call({
        fn: response.text,
        context: response,
      });
      return { content };
    }

    const content: unknown = yield* call({
      fn: response.blob,
      context: response,
    });
    return { content };
  } catch (error) {
    throw new RuntimeError(
      `Could not perform "${method} ${relativeUrl}" request`,
      { url, config },
      NativeError.wrap(error),
    );
  }
}

function getRequestBody(requestData: Json | FormData | URLSearchParams | undefined): BodyInit | undefined {
  if (
    requestData === undefined ||
    requestData instanceof FormData ||
    requestData instanceof URLSearchParams
  ) {
    return requestData;
  }

  return JSON.stringify(requestData);
}

function getRequestType(requestData: Json | FormData | URLSearchParams | undefined): string | undefined {
  if (
    requestData === undefined ||
    requestData instanceof FormData ||
    requestData instanceof URLSearchParams
  ) {
    // will be set by fetch
    return undefined;
  }

  return 'application/json';
}
