import { useEffect, useRef } from 'react';

export const usePrevious = <T>(prop: T): T => {
  const propRef = useRef(prop);

  useEffect(() => {
    propRef.current = prop;
  }, [prop]);

  return propRef.current;
};
