import './EvaluationResultsCharts.scss';
import { CurrencyCode, EvaluationResults } from '@belimo-retrofit-portal/logic';
import { Information } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';
import { Decimal } from 'decimal.js-light';
import { identity } from 'fp-ts/lib/function';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { FormattedBEP } from 'src/modules/common/components/FormattedBEP';
import { FormattedMathMessage } from 'src/modules/common/components/FormattedMathMessage';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';
import { SavingTileAmount } from 'src/modules/common/components/SavingTileAmount';
import { TileCard } from 'src/modules/common/components/TileCard';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { EvaluationResultsChartsGraphBEP } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphBEP';
import { EvaluationResultsChartsGraphCO2 } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphCO2';
import { EvaluationResultsChartsGraphIRR } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphIRR';
import { EvaluationResultsChartsGraphROI } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphROI';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';

type Props = {
  readonly results: EvaluationResults;
  readonly currency: CurrencyCode;
};

export const EvaluationResultsCharts = ({ results, currency }: Props): React.ReactElement => {
  const intl = useIntl();

  const project = useSelector(getProjectDetails);
  const hvacAssessmentFormData = useParametricSelector(getHvacAssesmentFormData, project);

  const isOnlyOneAreaEnabled = useMemo(() => {
    const { heating, cooling, ventilation } = hvacAssessmentFormData;

    return [heating.enabled, cooling.enabled, ventilation.enabled].filter(Boolean).length === 1;
  }, [hvacAssessmentFormData]);

  const areCostExclSavingsNegative = (results.savings.costExclCO2?.overall &&
    !results.savings.costExclCO2?.overall?.isPositive()) ||
    false;
  const isInvestmentEmpty = Boolean(
    results.savings.costExclCO2?.overall?.isPositive() &&
    (project.data.evaluation.investmentAmount === null ||
    project.data.evaluation.investmentAmount?.isZero()),
  );

  return (
    <div className="bp-evaluation-results-charts">
      <div className="bp-evaluation-results-charts__grid">
        <TileCard
          title={(
            <FormattedMessage id="evaluation/charts/BEP/title"/>
          )}
          subtitle={<FormattedMessage id="evaluation/charts/BEP/subtitle"/>}
        >
          <EvaluationResultsChartsGraphBEP
            data={results.charts.BEP}
            xAxisLabel={intl.formatMessage({ id: 'unit/years' })}
            yAxisLabel={
              intl.formatMessage(
                { id: 'evaluation/charts/BEP/yAxisLabel' },
                { currency: intl.formatMessage({ id: `common/currency/${currency}` }) },
              )
            }
            interactive={true}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-evaluation-results-charts__table">
            <div className="bp-evaluation-results-charts__grid">
              <div className="bp-evaluation-results-charts__amount">
                <SavingTileAmount
                  title={(
                    <FormattedMessage
                      id="evaluation/charts/BEP/summary/exclCO2"
                      values={{ sub }}
                    />
                  )}
                  amount={<FormattedBEP value={results.charts.BEP?.years.exclCO2}/>}
                  unit={(
                    <FormattedMessage
                      id="evaluation/charts/BEP/summary/unit"
                      values={{ sub, sup }}
                    />
                  )}
                />
              </div>

              <div className="bp-evaluation-results-charts__amount">
                <SavingTileAmount
                  title={(
                    <FormattedMessage
                      id="evaluation/charts/BEP/summary/inclCO2"
                      values={{ sub, sup }}
                    />
                  )}
                  amount={<FormattedBEP value={results.charts.BEP?.years.inclCO2}/>}
                  unit={(
                    <FormattedMessage
                      id="evaluation/charts/BEP/summary/unit"
                      values={{ sub, sup }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </TileCard>

        <TileCard
          title={(
            <FormattedMessage
              id="evaluation/charts/CO2/title"
              values={{ sub, sup }}
            />
          )}
          subtitle={(
            <FormattedMessage
              id="evaluation/charts/CO2/subtitle"
              values={{ sub, sup }}
            />
          )}
        >
          <EvaluationResultsChartsGraphCO2
            data={results.charts.CO2}
            interactive={true}
            shouldDisplayOverallData={!isOnlyOneAreaEnabled}
            yAxisLabel={(
              <FormattedMessage
                id="evaluation/charts/CO2/yAxisLabel"
                values={{ sub, sup }}
              />
            )}
          />
        </TileCard>
      </div>

      <div className="bp-evaluation-results-charts__grid">
        <TileCard
          title={(
            <div className="bp-evaluation-results-charts__grid-title">
              <FormattedMessage
                id="evaluation/charts/ROI/title"
                values={{ sub, sup }}
              />

              <Tooltip
                align="top"
                enterDelayMs={0}
                className="bp-evaluation-results-charts__tooltip"
                description={<FormattedMathMessage id="evaluation/charts/ROI/explanation"/>}
              >
                <Information/>
              </Tooltip>
            </div>
          )}
          subtitle={(
            <FormattedMessage
              id="evaluation/charts/ROI/subtitle"
              values={{ sub, sup }}
            />
          )}
        >
          <EvaluationResultsChartsGraphROI
            seria={results.charts.ROI}
            interactive={true}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-evaluation-results-charts__table">
            <table>
              <thead>
                <tr>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/ROI/values/year"
                      values={{ sub, sup }}
                    />
                  </th>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/ROI/values/exclCO2/table"
                      values={{ sub, sup }}
                    />
                  </th>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/ROI/values/inclCO2/table"
                      values={{ sub, sup }}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {!areCostExclSavingsNegative && results.charts.ROI !== null && results.charts.ROI.map((it) => (
                  <tr key={it.year.toNumber()}>
                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >{it.year.toString().padStart(2, '0')}
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={it.value.exclCO2.mul(100)}
                        precision={1}
                      />
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={it.value.inclCO2.mul(100)}
                        precision={1}
                      />
                    </td>
                  </tr>
                ))}
                {(areCostExclSavingsNegative || results.charts.ROI === null) && NO_AREA_DATA.map((it) => (
                  <tr key={it.year.toNumber()}>
                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >{it.year.toString().padStart(2, '0')}
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={null}
                        precision={1}
                      />
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={null}
                        precision={1}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </TileCard>

        <TileCard
          title={(
            <div className="bp-evaluation-results-charts__grid-title">
              <FormattedMessage
                id="evaluation/charts/IRR/title"
                values={{ sub, sup }}
              />

              <Tooltip
                align="top"
                enterDelayMs={0}
                className="bp-evaluation-results-charts__tooltip"
                description={<FormattedMathMessage id="evaluation/charts/IRR/explanation"/>}
              >
                <Information/>
              </Tooltip>
            </div>
          )}
          subtitle={(
            <FormattedMessage
              id="evaluation/charts/IRR/subtitle"
              values={{ sub, sup }}
            />
          )}
        >
          <EvaluationResultsChartsGraphIRR
            seria={results.charts.IRR}
            interactive={true}
            areCostSavingsExcludedNegative={areCostExclSavingsNegative}
            isInvestmentEmpty={isInvestmentEmpty}
          />

          <div className="bp-evaluation-results-charts__table">
            <table>
              <thead>
                <tr>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/IRR/values/year"
                      values={{ sub, sup }}
                    />
                  </th>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/IRR/values/exclCO2/table"
                      values={{ sub, sup }}
                    />
                  </th>
                  <th aria-label="-">
                    <FormattedMessage
                      id="evaluation/charts/IRR/values/inclCO2/table"
                      values={{ sub, sup }}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {!areCostExclSavingsNegative && results.charts.IRR !== null && results.charts.IRR.map((it) => (
                  <tr key={it.year.toNumber()}>
                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >{it.year.toString().padStart(2, '0')}
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={it.value.exclCO2.mul(100)}
                        precision={1}
                      />
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={it.value.inclCO2.mul(100)}
                        precision={1}
                      />
                    </td>
                  </tr>
                ))}
                {(areCostExclSavingsNegative || results.charts.IRR === null) && NO_AREA_DATA.map((it) => (
                  <tr key={it.year.toNumber()}>
                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >{it.year.toString().padStart(2, '0')}
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={null}
                        precision={1}
                      />
                    </td>

                    <td
                      className="bp-evaluation-results-charts__table-cell"
                      aria-label="-"
                    >
                      <FormattedValue
                        value={null}
                        precision={1}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </TileCard>
      </div>
    </div>
  );
};

const NO_AREA_DATA = [
  new Decimal(1),
  new Decimal(2),
  new Decimal(3),
  new Decimal(4),
  new Decimal(5),
  new Decimal(10),
  new Decimal(20),
].map((year) => ({
  year: year,
  exclCO2: null,
  inclCO2: null,
}));
