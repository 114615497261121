import './UsersListPage.scss';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { AdminLayout } from 'src/modules/common/layouts/AdminLayout';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { getUsersListQuery } from 'src/modules/users/selectors/getUsersListQuery';
import { UsersListBody } from 'src/modules/users/views/UsersListBody';
import { UsersListLayoutExtension } from 'src/modules/users/views/UsersListLayoutExtension';

export const UsersListPage = (): React.ReactElement => {
  const intl = useIntl();

  const user = useSelector(getLoggedInUser);
  const users = useSelector(getUsersListData);
  const query = useSelector(getUsersListQuery);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'admin/users/list/title' })}</title>
      </Helmet>

      <AdminLayout
        breadcrumbs={(
          <CustomBreadcrumbItem>
            <Link to="/projects">
              {intl.formatMessage({ id: 'admin/users/header/breadcrumbs/project' })}
            </Link>
          </CustomBreadcrumbItem>
        )}
        extension={<UsersListLayoutExtension className="bp-users-list-page__extension"/>}
        title={intl.formatMessage({ id: 'admin/users/list/header/title' }, { organization: user.organization.name })}
      >
        <UsersListBody query={query} users={users}/>
      </AdminLayout>
    </>
  );
};
