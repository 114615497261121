import './EnergyPriceUnit.scss';
import { CurrencyCode, EnergySource } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { CurrencySymbol } from 'src/modules/common/components/CurrencySymbol';
import { EnergySourceUnit } from 'src/modules/common/components/EnergySourceUnit';

type Props = {
  readonly currency: CurrencyCode;
  readonly source: EnergySource;
};

export const EnergyPriceUnit = ({ currency, source }: Props): React.ReactElement => (
  <span className="bp-energy-price-unit">
    <CurrencySymbol currency={currency}/>
    <span className="bp-energy-price-unit__div">/</span>
    <EnergySourceUnit source={source}/>
  </span>
);
