import './EnergySavingsUnit.scss';
import { EnergySource } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';

type Props = {
  readonly source: EnergySource;
};

export const EnergySavingsUnit = ({ source }: Props): React.ReactElement => {
  if (source === EnergySource.HEATING_OIL) {
    return (
      <span className="bp-energy-savings-unit">
        <FormattedMessage id="unit/cubic_meter" values={{ sub, sup }}/>
      </span>
    );
  }

  return (
    <span className="bp-energy-savings-unit">
      <FormattedMessage id="unit/megawatt_hour" values={{ sub, sup }}/>
    </span>
  );
};
