import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { ASSET } from 'src/modules/common/codecs/Asset';
import { Asset } from 'src/modules/common/types/Asset';
import { call } from 'typed-redux-saga';

export function* uploadAsset(file: File): SagaIterator<Asset> {
  try {
    const body = new FormData();
    body.append('file', file);

    const { content } = yield* call(makeRestRequest, 'POST', '/assets', body);
    return yield* call(decodeOrThrow, ASSET, content);
  } catch (error) {
    throw new RuntimeError('Could not upload asset', { file }, NativeError.wrap(error));
  }
}
