import { SagaIterator } from 'redux-saga';
import { RouteHook } from 'src/modules/routing/types/RouteHook';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { Transition } from 'src/modules/routing/types/Transition';
import { logDebug } from 'src/sagas/utils/logging';
import { call } from 'typed-redux-saga';

export function createStateTransitionLoggingSaga(stage: string): RouteHook {
  return function* saga(transition: Transition, match: RouteMatch): SagaIterator<void> {
    yield* call(logDebug, `ROUTER. ${stage}: ${match.state.name}`, { match, transition });
  };
}
