import './HvacResultEnergySystemVentilationView.scss';
import { FieldPath } from '@form-ts/core';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LightningIcon } from 'src/modules/common/icons/LightningIcon';
import { BodyLargeRegularText } from 'src/modules/common/typography/BodyLargeRegularText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormChapterData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacResultEnergyElectricalView } from 'src/modules/hvac/views/HvacResultEnergyElectricalView';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormChapterData>;
  readonly disabled: boolean;
};

export const HvacResultEnergySystemVentilationView = ({ path, disabled }: Props): React.ReactElement => (
  <div className="bp-hvac-result-energy-system-ventilation-view">
    <BodyLargeRegularText className={clsx('bp-hvac-result-energy-system-ventilation-view__title', {
      'bp-hvac-result-energy-system-ventilation-view__title--disabled': disabled,
    })}
    >
      <span className="bp-hvac-result-energy-system-ventilation-view__title-icon">
        <LightningIcon/>
      </span>

      <FormattedMessage id="hvac/energy/electricalEnergy/ventilation"/>
    </BodyLargeRegularText>

    <HvacResultEnergyElectricalView
      path={path.at('electrical')}
      disabled={disabled}
    />
  </div>
);
