import { Action } from 'redux';
import { PASSWORD_RESET_TOKEN } from 'src/modules/password-reset/actions/PasswordResetActions';
import { PasswordResetState } from 'src/modules/password-reset/types/PasswordResetState';

export function passwordResetTokenReducer(state: PasswordResetState, action: Action): PasswordResetState {
  if (PASSWORD_RESET_TOKEN.is(action)) {
    return {
      ...state,
      token: action.data,
    };
  }

  return state;
}
