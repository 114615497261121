import { Action, Reducer } from 'redux';

type StrictReducer<TState> = (state: TState, action: Action) => TState;

export function chainReducers<TState>(
  initialState: TState,
  reducers: ReadonlyArray<StrictReducer<TState>>,
): Reducer<TState> {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state = initialState, action) => reducers.reduce((result, reducer) => reducer(result, action), state);
}
