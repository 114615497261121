import './ProjectListRemoveModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { PreserveContent } from 'src/modules/common/components/PreserveContent';
import { ProjectTitle } from 'src/modules/common/components/ProjectTitle';
import { BodyText } from 'src/modules/common/typography/BodyText';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { ProjectListRemoving } from 'src/modules/project-list/types/ProjectListRemoving';

type Props = {
  readonly data: ProjectListRemoving | null;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
};

export const ProjectListRemoveModal = ({ data, onCancel, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();

  const handleClose = useCallback(() => {
    if (data?.status?.state !== 'pending') {
      onCancel();
    }
  }, [data?.status, onCancel]);

  return (
    <CustomModal
      className="bp-project-list-remove"
      open={data !== null}
      isShaded={false}
      onClose={handleClose}
      size="sm"
      danger={true}
      shouldUnmount={true}
    >
      <PreserveContent>
        {data && (
          <>
            <ModalHeader
              iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
              closeModal={handleClose}
              labelClassName="bp-project-list-remove__label"
              label={intl.formatMessage({ id: 'projectList/removeModal/title' })}
            />

            <ModalBody>
              <div className="bp-project-list-remove__body">
                <LeadText>
                  <FormattedMessage
                    id="projectList/removeModal/subtitle"
                  />
                </LeadText>

                <BodyText>
                  <FormattedMessage
                    id="projectList/removeModal/description"
                    values={{ projectName: <b><ProjectTitle title={data.project.data.title}/></b> }}
                  />
                </BodyText>
              </div>
            </ModalBody>

            <ModalFooter>
              <CustomButton
                kind="secondary"
                onClick={onCancel}
                autoFocus={true}
                disabled={data.status?.state === 'pending'}
              >
                <FormattedMessage id="projectList/removeModal/cancel"/>
              </CustomButton>

              <CustomButton
                disabled={data.status?.state === 'pending'}
                kind="primary"
                onClick={onSubmit}
                loading={data.status?.state === 'pending'}
              >
                <FormattedMessage id="projectList/removeModal/submit"/>
              </CustomButton>
            </ModalFooter>
          </>
        )}
      </PreserveContent>
    </CustomModal>
  );
};
