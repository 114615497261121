import { Language } from 'src/types/common/Language';

export const LANGUAGE_LIST = [
  'en-US',
  'de-CH',
  'fr-FR',
  'hu-HU',
  'es-ES',
  'sv-SE',
  'zh-CN',
  'it-IT',
] as const;

export const INTL_LOCALE_MAP: Record<Language, string> = {
  'en-US': 'en-US',
  'de-CH': 'de-CH',
  'fr-FR': 'fr-FR',
  'hu-HU': 'hu-HU',
  'es-ES': 'es-ES',
  'sv-SE': 'sv-SE',
  'zh-CN': 'zh-CN',
  'it-IT': 'it-IT',
};
