import { RuntimeError } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { XLSX_VALUE } from 'src/modules/common/codecs/XlsxValue';
import { XlsxValue } from 'src/modules/common/types/XlsxValue';
import { doOnWorker } from 'src/sagas/utils/doOnWorker';
import { ImportWorkerResponse } from 'src/types/worker/ImportWorker';
import { call } from 'typed-redux-saga';

export function* doOnImportWorker(
  request: File,
): SagaIterator<XlsxValue[][]> {
  const response = yield* call(doOnWorker, '/import-worker.js', request);
  const data = decodeOrThrow(
    WORKER_RESULT,
    response,
  );
  if (data.type === 'failure') {
    throw new RuntimeError(
      `Error while executing import worker (${data.error.name}: ${data.error.message})`,
      { error: data.error },
    );
  }

  return data.content;
}

const WORKER_ERROR = D.struct({
  name: D.string,
  message: D.string,
});

const WORKER_RESULT_SUCCESS = D.struct({
  type: D.literal('success'),
  content: D.array(D.array(XLSX_VALUE)),
});

const WORKER_RESULT_FAILURE = D.struct({
  type: D.literal('failure'),
  error: WORKER_ERROR,
});

const WORKER_RESULT: D.Decoder<unknown, ImportWorkerResponse> = D.sum('type')({
  success: WORKER_RESULT_SUCCESS,
  failure: WORKER_RESULT_FAILURE,
});
