import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { call } from 'typed-redux-saga';

export function* validateRecoveryToken(token: string): SagaIterator<void> {
  try {
    const query = new URLSearchParams({ token });
    yield* call(makeRestRequest, 'GET', `/auth/check-token?${query.toString()}`);
  } catch (error) {
    throw new RuntimeError('Password recovery token is not valid', { token }, NativeError.wrap(error));
  }
}
