import { pipe } from 'fp-ts/function';
import { concatAll } from 'fp-ts/Monoid';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';
import * as S from 'fp-ts/string';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { ordNullable, ordPrecedence } from 'src/modules/common/utils/ord';
import { ProjectListItem } from 'src/modules/project-list/types/ProjectListItem';
import { ProjectListSort } from 'src/modules/project-list/types/ProjectListSort';

export function getProjectOrd(sort: ProjectListSort, authUser: AuthUser): O.Ord<ProjectListItem> {
  const ord = concatAll(O.getMonoid<ProjectListItem>())([
    SORT_MAP(authUser)[sort.field],
    SORT_BY_ID,
  ]);

  return sort.direction === 'asc' ? ord : O.reverse(ord);
}

const SORT_BY_ID = pipe(
  S.Ord,
  O.contramap((item: ProjectListItem) => item.project.id),
);

const SORT_MAP = (authUser: AuthUser): Readonly<Record<ProjectListSort['field'], O.Ord<ProjectListItem>>> => ({
  title: pipe(
    S.Ord,
    O.contramap((item) => item.project.data.title),
  ),
  rating: pipe(
    N.Ord,
    O.contramap((item) => item.rating.future?.toNumber() ?? -1),
  ),
  updated: pipe(
    N.Ord,
    O.contramap((item) => item.project.updatedAt.getTime()),
  ),
  ownership: pipe(
    S.Ord,
    ordPrecedence(authUser.email),
    ordNullable,
    O.contramap((item) => item.project.owner?.email ?? null),
  ),
});
