import { trim } from 'fp-ts/string';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';

export const USER_FORM_SCHEMA: FormRule<UserFormData> = R.struct({
  email: R.map(trim, R.allOf([R.nonEmpty(), R.email()])),
  firstName: R.map(trim, R.allOf([R.nonEmpty(), R.maxLength(50)])),
  lastName: R.map(trim, R.allOf([R.nonEmpty(), R.maxLength(50)])),
  role: R.nonEmpty(),
});
