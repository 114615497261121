import './ProjectToolbar.scss';
import { ChevronDown, ChevronUp, Folder, Share } from '@carbon/icons-react';
import { Breadcrumb, Button, Tabs } from '@carbon/react';
import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AssetImage } from 'src/modules/common/components/AssetImage';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { CustomTab, CustomTabList } from 'src/modules/common/components/CustomTabList';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { Project } from 'src/modules/common/types/Project';
import { BodyDesktopLargeRegularText } from 'src/modules/common/typography/BodyDesktopLargeRegularText';
import { OverlineDesktopSmallText } from 'src/modules/common/typography/OverlineDesktopSmallText';
import { formatProjectDetailsSubTitle } from 'src/modules/common/utils/formatProjectDetailsSubTitle';
import { getCurrentMatch } from 'src/modules/routing/selectors/getCurrentMatch';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

type Props = {
  readonly project: Project;
  readonly onShare: () => void;
};

export const ProjectToolbar = ({ project, onShare }: Props): React.ReactElement => {
  const intl = useIntl();

  const routeState = useSelector(getCurrentMatch);

  const history = useHistory();

  const projectBaseUrl = `/project/${encodeURIComponent(project.id)}`;

  const [areDetailsShown, setDetailsShown] = useState(false);

  const projectTitleRef = useRef<HTMLButtonElement | null>(null);
  const projectDetailsRef = useRef<HTMLDivElement | null>(null);

  const heightOfOpenedDetails = (projectTitleRef.current?.offsetHeight || 0) +
    (projectDetailsRef.current?.offsetHeight || 0) +
    24;
  const heightOfClosedDetails = (projectTitleRef.current?.offsetHeight || 56);

  const address = useMemo(
    () => formatProjectDetailsSubTitle(
      intl.formatMessage({ id: `common/buildingType/${project.data.building.type}` }),
      project.data.building.address,
    ),
    [intl, project.data.building],
  );

  const defaultCheckedTab = useMemo(() => {
    const stateNames = [
      'bp:project:basedata',
      'bp:project:hvac',
      'bp:project:schema',
      'bp:project:evaluation',
      'bp:project:report',
    ];
    return stateNames.findIndex((it) => getMatchedRoute(routeState, it) !== null);
  }, [routeState]);

  return (
    <div className="bp-project-toolbar">
      <div className="bp-project-toolbar__content">
        <Breadcrumb>
          <CustomBreadcrumbItem>
            <Link to="/projects">
              <FormattedMessage id="projectList/toolbar/tab"/>
            </Link>
          </CustomBreadcrumbItem>
        </Breadcrumb>

        <div
          className="bp-project-toolbar__project"
          style={{
            height: areDetailsShown
              ? heightOfOpenedDetails
              : heightOfClosedDetails,
          }}
        >
          <div className="bp-project-toolbar__header-container">
            <button
              type="button"
              className="bp-project-toolbar__project-title"
              ref={projectTitleRef}
              onClick={() => setDetailsShown((prev) => !prev)}
            >
              <div className="bp-project-toolbar__project-title-name">
                {project.data.title || intl.formatMessage({ id: 'project/toolbar/project/unnamed' })}
              </div>

              {areDetailsShown
                ? (
                  <ChevronUp
                    className="bp-project-toolbar__project-chevron"
                    color={CARBON_THEME.onSurfaceLightPrimary}
                  />
                )
                : (
                  <ChevronDown
                    className="bp-project-toolbar__project-chevron"
                    color={CARBON_THEME.onSurfaceLightPrimary}
                  />
                )}
            </button>

            <Button
              type="button"
              kind="ghost"
              className="bp-project-toolbar__share-button"
              onClick={onShare}
            >
              <FormattedMessage id="projectList/button/shareProject"/>

              <Share size="1.5rem" color={CARBON_THEME.belimoOrangeColor}/>
            </Button>
          </div>

          <div className="bp-project-toolbar__project-details" ref={projectDetailsRef}>
            {project.data.cover
              ? <AssetImage asset={project.data.cover} size="small" className="bp-project-toolbar__project-image"/>
              : <Folder size={32}/>}

            <div className="bp-project-toolbar__project-info">
              <BodyDesktopLargeRegularText className="bp-project-toolbar__project-address">
                {address}
              </BodyDesktopLargeRegularText>

              <OverlineDesktopSmallText className="bp-project-toolbar__project-goals">
                {project.data.goals || intl.formatMessage({ id: 'basedata/projectGoals/empty' })}
              </OverlineDesktopSmallText>
            </div>
          </div>
        </div>

        <div className="bp-project-toolbar__controls">
          <Tabs selectedIndex={defaultCheckedTab}>
            <CustomTabList
              ariaLabel={intl.formatMessage({ id: 'projectList/toolbar/tabs' })}
              contained={false}
              isFullWidth={true}
            >
              <Link to={projectBaseUrl}>
                <CustomTab>
                  <FormattedMessage id="project/toolbar/navigation/basedata"/>
                </CustomTab>
              </Link>

              <Link to={`${projectBaseUrl}/hvac`}>
                <CustomTab>
                  <FormattedMessage id="project/toolbar/navigation/hvac"/>
                </CustomTab>
              </Link>

              <Link to={`${projectBaseUrl}/schema`}>
                <CustomTab>
                  <div className="bp-project-toolbar__beta">
                    <FormattedMessage id="project/toolbar/navigation/schema/beta"/>
                  </div>

                  <FormattedMessage id="project/toolbar/navigation/schema"/>
                </CustomTab>
              </Link>

              <Link to={`${projectBaseUrl}/evaluation`}>
                <CustomTab>
                  <FormattedMessage id="project/toolbar/navigation/evaluation"/>
                </CustomTab>
              </Link>

              <Link to={`${projectBaseUrl}/report`}>
                <CustomTab>
                  <FormattedMessage id="project/toolbar/navigation/report"/>
                </CustomTab>
              </Link>
            </CustomTabList>
          </Tabs>
        </div>
      </div>

      <ShareProject/>
    </div>
  );
};

function getMatchedRoute(match: RouteMatch | null, state: string): RouteMatch | null {
  if (match?.state.name === state) {
    return match;
  }

  return match?.parent
    ? getMatchedRoute(match.parent, state)
    : null;
}
