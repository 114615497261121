import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const DECODER = pipe(
  D.string,
  D.parse((input) => (
    input.length > 0
      ? D.success(input)
      : D.failure(input, 'NonEmptyString')
  )),
);

export const NON_EMPTY_STRING = C.make(DECODER, E.id());
