import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { PASSWORD_RESET_SUBMIT } from 'src/modules/password-reset/actions/PasswordResetActions';
import { PASSWORD_RESET_FORM_SCHEMA } from 'src/modules/password-reset/constants/schema';
import { submitPasswordReset } from 'src/modules/password-reset/sagas/utils/submitPasswordReset';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* passwordResetSubmitSaga(
  action: GetRequestActionType<typeof PASSWORD_RESET_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  const valid = yield* call(formValidate, form, PASSWORD_RESET_FORM_SCHEMA);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Resetting password...');
    yield* put(PASSWORD_RESET_SUBMIT.pending());
    yield* call(formSubmitStart, form);

    yield* delay(500);
    yield* call(submitPasswordReset, values);

    yield* call(logDebug, 'Resetting password... done');
    yield* put(PASSWORD_RESET_SUBMIT.success());

    yield* call(formSubmitSuccess, form);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Resetting password... error', error);
    yield* put(PASSWORD_RESET_SUBMIT.failure(NativeError.wrap(error)));

    yield* call(formSubmitFailure, form, {
      password: {
        code: 'invalid',
        path: 'username',

        value: values.password,
        message: 'Invalid response',
        context: {},
      },
    });
  }
}
