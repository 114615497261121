import {
  CurrencyCode,
  EnergySource,
  HvacArea,
  ProjectEvaluation,
  ProjectHvac,
  ProjectParticipants,
  ProjectReport,
  ProjectSchema,
  SchemaArea,
} from '@belimo-retrofit-portal/logic';
import { DECIMAL_ZERO } from 'src/modules/common/constants/decimal';

export const PROJECT_VERSION = '2024.10.01.0';

export const PROJECT_CURRENCY_DEFAULT_DATA: CurrencyCode = 'EUR';

export const PROJECT_SCHEMA_DEFAULT_DATA: ProjectSchema = {
  [SchemaArea.HEATING]: null,
  [SchemaArea.COOLING]: null,
  [SchemaArea.VENTILATION]: null,
};

export const PROJECT_REPORT_DEFAULT_DATA: ProjectReport = {
  cover: {
    creationDate: null,
    showBuildingInfo: true,
    showOrganizationLogo: true,
  },
  energy: {
    enabled: true,
    showHeating: false,
    showCooling: false,
    showVentilation: false,
    showComments: false,
  },
  hvac: {
    enabled: true,
    showHeating: false,
    showCooling: false,
    showVentilation: false,
    showComments: true,
  },
  savings: {
    enabled: true,
    showOverallEfficiency: true,
    showPerAreaEfficiency: true,
    showPotentialSavings: true,
  },
  schema: {
    [SchemaArea.HEATING]: {
      enabled: true,
      showZoomedIn: false,
      showComments: false,
      futureVersion: null,
    },
    [SchemaArea.COOLING]: {
      enabled: true,
      showZoomedIn: false,
      showComments: false,
      futureVersion: null,
    },
    [SchemaArea.VENTILATION]: {
      enabled: true,
      showZoomedIn: false,
      showComments: false,
      futureVersion: null,
    },
  },
  evaluation: {
    enabled: true,
    showInitialInvestment: true,
    showAnnualSavings: true,
    showInvestmentPerCarbonSavings: true,
  },
  evaluationCharts: {
    enabled: true,
    showReturnOnInvestment: true,
    showInternalRateOfReturn: true,
    showAnnualCarbonEmmission: true,
    showBreakEvenPoint: true,
  },
};

export const PROJECT_PARTICIPANTS_DEFAULT_DATA: ProjectParticipants = {
  author: '',
  consutlingEngineer: '',
  productManager: '',
  propertyManager: '',
};

export const PROJECT_HVAC_DEFAULT_DATA: ProjectHvac = {
  comment: '',
  prices: {
    [EnergySource.ELECTRICITY]: null,
    [EnergySource.GAS]: null,

    [EnergySource.HEATING_DISTRICT]: null,
    [EnergySource.HEATING_OIL]: null,
    [EnergySource.HEATING_OTHER]: null,

    [EnergySource.COOLING_DISTRICT]: null,
    [EnergySource.COOLING_OTHER]: null,
  },
  areas: {
    [HvacArea.HEATING]: {
      enabled: false,
      sections: [],
      thermal: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: null,
        carbonIntensity: null,
      },
      electrical: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: null,
        carbonIntensity: null,
      },
    },
    [HvacArea.COOLING]: {
      enabled: false,
      sections: [],
      thermal: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: null,
        carbonIntensity: null,
      },
      electrical: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: null,
        carbonIntensity: null,
      },
    },
    [HvacArea.VENTILATION]: {
      enabled: false,
      sections: [],
      thermal: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: DECIMAL_ZERO,
        carbonIntensity: null,
      },
      electrical: {
        energySource: EnergySource.ELECTRICITY,
        annualEnergyUse: null,
        carbonIntensity: null,
      },
    },
  },
};

export const PROJECT_EVALUATION_DEFAULT_DATA: ProjectEvaluation = {
  investmentAmount: null,
  carbonEmissionPrice: null,
  proposalDescription: '',
};
