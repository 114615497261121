import './ProjectListFilterGroup.scss';
import { Checkbox, FormGroup, FormLabel } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { FormError } from 'src/modules/form/types/FormError';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';

type Props<T> = {
  readonly field: FieldPath<ProjectListFilter, FormError, ReadonlySet<T>>;
  readonly options: ReadonlyArray<T>;
  readonly renderOption: (option: T) => React.ReactElement | string;
  readonly onApply: () => void;
};

// eslint-disable-next-line react/function-component-definition
export function ProjectListFilterGroup<T>({
  field,
  options,
  renderOption,
  onApply,
}: Props<T>): React.ReactElement {
  const value = useFormWatch(field.form, field.value.get);

  const [isGroupOpened, setGroupOpened] = useState(false);

  const groupListRef = useRef<HTMLDivElement | null>(null);

  const someSelected = useMemo(() => options.some((it) => value.has(it)), [options, value]);

  const formGroupTopOffset = 8;
  const formGroupGap = 4;
  const formGroupRowHeight = 23;
  const formGroupMinRows = Math.min(options.length, 3);
  const formGroupLength = isGroupOpened
    ? options.length
    : formGroupMinRows;
  const formGroupHeight = useMemo(
    () => (formGroupRowHeight * formGroupLength) + (formGroupGap * (formGroupLength - 1)) + formGroupTopOffset,
    [formGroupRowHeight, formGroupLength],
  );

  const resetAll = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    const newValue = new Set<T>();

    field.form.change(pipe(
      field.form.currentState,
      field.value.set(newValue),
    ));

    onApply();
  }, [field, onApply]);

  const toggleOption = useCallback((option: T) => {
    const oldValue = field.value.get(field.form.currentState);
    const newValue = toggleSetValue(oldValue, option);

    field.form.change(pipe(
      field.form.currentState,
      field.value.set(newValue),
    ));

    onApply();
  }, [field, onApply]);

  return (
    <>
      <FormGroup
        legendText=""
        className="bp-project-list-filter-group"
        style={{
          height: formGroupHeight,
        }}
      >
        <div ref={groupListRef} className="bp-project-list-filter-group__list">
          {options.map((option) => (
            <FormLabel
              className="bp-project-list-filter-group__label"
              key={String(option)}
            >
              <Checkbox
                id={`${field.form.name}.${field.path}.${String(option)}`}
                labelText={renderOption(option)}
                checked={value.has(option)}
                onChange={() => toggleOption(option)}
              />
            </FormLabel>
          ))}
        </div>
      </FormGroup>

      <div className="bp-project-list-filter-group__buttons">
        {options.length > 3 ? (
          <CustomButton kind="tertiary" size="md" onClick={() => setGroupOpened((prev) => !prev)}>
            {isGroupOpened ? (
              <FormattedMessage id="projectList/filterDropdown/viewLess"/>
            ) : (
              <FormattedMessage id="projectList/filterDropdown/viewMore"/>
            )}
          </CustomButton>
        ) : null}

        {someSelected ? (
          <CustomButton kind="tertiary" size="md" onClick={resetAll}>
            <FormattedMessage id="projectList/filterDropdown/clear"/>
          </CustomButton>
        ) : null}
      </div>
    </>
  );
}

function toggleSetValue<T>(value: ReadonlySet<T>, option: T): Set<T> {
  const newValue = new Set(value);
  if (newValue.has(option)) {
    newValue.delete(option);
  } else {
    newValue.add(option);
  }

  return newValue;
}
