import './HvacFormSectionContent.scss';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import * as L from 'monocle-ts/Lens';
import React from 'react';
import { useIntl } from 'react-intl';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormAdditionalData, HvacFormAdditionalValue, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { assertNever } from 'src/utils/assert';

type Props = {
  readonly labelText: string;
  readonly field: FieldPath<HvacFormData, FormError, HvacFormAdditionalData>;
  readonly disabled: boolean;
};

export const HvacFormSectionAdditionalInput = ({ labelText, field, disabled }: Props): React.ReactElement => {
  const intl = useIntl();
  const { value: { config } } = useFormField(field);

  if (config.element === 'textarea') {
    const placeholder = config.placeholder ? intl.formatMessage({ id: config.placeholder }) : undefined;
    return (
      <TextAreaField
        labelText={labelText}
        field={field.at('value').transform(TO_TEXT_VALUE)}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={280}
        showHint={true}
        rows={2}
      />
    );
  }

  return assertNever('Unexpected additional field', { field });
};

const TO_TEXT_VALUE = L.lens<HvacFormAdditionalValue, string>(
  (value) => (typeof value === 'string' ? value : ''),
  (value) => (): string => value,
);
