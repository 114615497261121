import { ModalBody, ModalFooter, ModalHeader, Button } from '@carbon/react';
import { useForm } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly open: boolean;
  readonly comment: string;
  readonly onCancel: () => void;
  readonly onSubmit: (title: string) => void;
};

export const HvacFormCommentModal = ({ open, comment, onSubmit, onCancel }: Props): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<string, FormError>('hvac.comment', {
    reinitialize: true,
    initialValues: comment,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);
  const handleCancel = useCallback(() => {
    form.reset(form.initialState);
    onCancel();
  }, [form, onCancel]);

  return (
    <CustomModal
      className="bp-hvac-form-comment-modal"
      onClose={handleCancel}
      open={open}
      isShaded={false}
      size="md"
      shouldUnmount={true}
    >
      <ModalHeader
        labelClassName="bp-hvac-form-comment-modal__label"
        label={intl.formatMessage({ id: 'hvac/form/comment/modal/title' })}
        titleClassName="bp-hvac-form-comment-modal__title"
        title={intl.formatMessage({ id: 'hvac/form/comment/modal/subtitle' })}
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
      />

      <ModalBody>
        <TextAreaField
          field={form.field}
          labelText={intl.formatMessage({ id: 'hvac/form/comment/modal/fieldLabel' })}
          rows={4}
          maxLength={280}
          showHint={true}
        />
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          className="bp-hvac-form-comment-modal__button"
          onClick={handleCancel}
        >
          <FormattedMessage id="hvac/form/comment/modal/cancel"/>
        </CustomButton>

        <CustomButton
          kind="primary"
          className="bp-hvac-form-comment-modal__button"
          autoFocus={true}
          onClick={handleSubmit}
        >
          <FormattedMessage id="hvac/form/comment/modal/submit"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
