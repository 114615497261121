import { UUID } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { Transition } from 'src/modules/routing/types/Transition';
import { USER_DETAILS_RESET } from 'src/modules/users/actions/UserActions';
import { logDebug } from 'src/sagas/utils/logging';
import { assertDecode } from 'src/utils/assert';
import { call, put } from 'typed-redux-saga';

export function* resetUserDetails(transition: Transition, match: RouteMatch): SagaIterator<void> {
  const id = assertDecode(
    match.params.userId,
    UUID,
    'Missing "userId" route parameter',
    { match, transition },
  );

  yield* call(logDebug, `Resetting user "${id}" details...`);
  yield* put(USER_DETAILS_RESET.trigger(undefined, id));
  yield* call(logDebug, `Resetting user "${id}" details... done`);
}
