import './HvacResultSavingsView.scss';
import { HvacArea, HvacResults } from '@belimo-retrofit-portal/logic';
import { TabPanel, TabPanels, Tabs, Tooltip } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTab, CustomTabList } from 'src/modules/common/components/CustomTabList';
import { TileCard } from 'src/modules/common/components/TileCard';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacResultSavingsExplanation } from 'src/modules/hvac/views/HvacResultSavingsExplanation';
import { HvacResultSavingsOverall } from 'src/modules/hvac/views/HvacResultSavingsOverall';
import { HvacResultSavingsSystem } from 'src/modules/hvac/views/HvacResultSavingsSystem';

type Props = {
  readonly projectId: string;
  readonly form: FormController<HvacFormData, FormError>;
  readonly results: HvacResults;
  readonly inaccurate: boolean;
};

const overallTabIndex = 0;
const heatingTabIndex = 1;
const coolingTabIndex = 2;
const ventilationTabIndex = 3;

export const HvacResultSavingsView = ({ projectId, form, results, inaccurate }: Props): React.ReactElement => {
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback((state: { selectedIndex: number }) => {
    setActiveTab(state.selectedIndex);
  }, []);

  const heatingEnabled = useFormWatch(form, form.field.at('assessment').at('heating').at('enabled').value.get);
  const coolingEnabled = useFormWatch(form, form.field.at('assessment').at('cooling').at('enabled').value.get);
  const ventilationEnabled = useFormWatch(form, form.field.at('assessment').at('ventilation').at('enabled').value.get);

  useEffect(() => {
    if (activeTab === heatingTabIndex && !heatingEnabled) {
      setActiveTab(overallTabIndex);
    }
    if (activeTab === coolingTabIndex && !coolingEnabled) {
      setActiveTab(overallTabIndex);
    }
    if (activeTab === ventilationTabIndex && !ventilationEnabled) {
      setActiveTab(overallTabIndex);
    }
  }, [activeTab, heatingEnabled, coolingEnabled, ventilationEnabled]);

  return (
    <>
      <TileCard title={<FormattedMessage id="hvac/result/savings/title"/>}>
        <div className="bp-hvac-result-savings-view">
          <Tabs selectedIndex={activeTab} onChange={handleTabChange}>
            <CustomTabList
              ariaLabel={intl.formatMessage({ id: 'hvac/result/savings/tabs' })}
              contained={true}
              isFullWidth={true}
            >
              <CustomTab>
                <FormattedMessage id="hvac/tab/overall"/>
              </CustomTab>

              {heatingEnabled ? (
                <CustomTab>
                  <FormattedMessage id="hvac/tab/heating"/>
                </CustomTab>
              ) : (
                <Tooltip align="top" description={intl.formatMessage({ id: 'tab/heating/disabled' })}>
                  <CustomTab as="div" disabled={!heatingEnabled}>
                    <FormattedMessage id="hvac/tab/heating"/>
                  </CustomTab>
                </Tooltip>
              )}

              {coolingEnabled ? (
                <CustomTab>
                  <FormattedMessage id="hvac/tab/cooling"/>
                </CustomTab>
              ) : (
                <Tooltip align="top" description={intl.formatMessage({ id: 'tab/cooling/disabled' })}>
                  <CustomTab as="div" disabled={!coolingEnabled}>
                    <FormattedMessage id="hvac/tab/cooling"/>
                  </CustomTab>
                </Tooltip>
              )}

              {ventilationEnabled ? (
                <CustomTab>
                  <FormattedMessage id="hvac/tab/ventilation"/>
                </CustomTab>
              ) : (
                <Tooltip align="top" description={intl.formatMessage({ id: 'tab/ventilation/disabled' })}>
                  <CustomTab as="div" disabled={!ventilationEnabled}>
                    <FormattedMessage id="hvac/tab/ventilation"/>
                  </CustomTab>
                </Tooltip>
              )}
            </CustomTabList>

            <TabPanels>
              <TabPanel className="bp-hvac-result-savings-view__tab-panel">
                <HvacResultSavingsOverall
                  form={form}
                  results={results}
                />
              </TabPanel>

              <TabPanel className="bp-hvac-result-savings-view__tab-panel">
                {results.perArea[HvacArea.HEATING].enabled && (
                  <HvacResultSavingsSystem
                    form={form}
                    results={results.perArea[HvacArea.HEATING]}
                  />
                )}
              </TabPanel>

              <TabPanel className="bp-hvac-result-savings-view__tab-panel">
                {results.perArea[HvacArea.COOLING].enabled && (
                  <HvacResultSavingsSystem
                    form={form}
                    results={results.perArea[HvacArea.COOLING]}
                  />
                )}
              </TabPanel>

              <TabPanel className="bp-hvac-result-savings-view__tab-panel">
                {results.perArea[HvacArea.VENTILATION].enabled && (
                  <HvacResultSavingsSystem
                    form={form}
                    results={results.perArea[HvacArea.VENTILATION]}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </TileCard>

      <HvacResultSavingsExplanation
        projectId={projectId}
        form={form}
        results={results.overall}
        inaccurate={inaccurate}
      />
    </>
  );
};
