import { FormController } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import { modify } from 'monocle-ts/Lens';
import { SagaIterator } from 'redux-saga';
import { FormError } from 'src/modules/form/types/FormError';
import { call } from 'typed-redux-saga';

export function* formSubmitStart<T>(
  form: FormController<T, FormError>,
): SagaIterator<void> {
  yield* call({
    fn: form.change,
    context: form,
  }, pipe(
    form.currentState,
    form.submitting.set(true),
    pipe(form.submitCount, modify((count) => count + 1)),
  ));
}
