import './HvacFormSection.scss';
import { AccordionItem } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData, HvacFormSectionData } from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormAnswer } from 'src/modules/hvac/utils/getDefaultFormAnswer';
import { HvacFormSectionContent } from 'src/modules/hvac/views/HvacFormSectionContent';
import { HvacFormSectionCopyTitleModal } from 'src/modules/hvac/views/HvacFormSectionCopyTitleModal';
import { HvacFormSectionHeader } from 'src/modules/hvac/views/HvacFormSectionHeader';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormSectionData>;
  readonly readOnly: boolean;
};

export const HvacFormSection = ({ path, readOnly }: Props): React.ReactElement => {
  const [titleModalOpen, setTitleModalOpen] = useState(false);
  const [isItemOpened, setItemOpened] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);

  const { value: section } = useFormField(path);

  const handleClick = useCallback((
    {
      isOpen,
      event,
    }: {
      isOpen: boolean;
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    },
  ) => {
    setItemOpened(isOpen);

    if (isOpen) {
      event.currentTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleClear = useCallback(() => {
    const current = path.value.get(path.form.currentState);
    const value = getDefaultFormAnswer(current.config);

    path.form.change(pipe(
      path.form.currentState,
      path.at('answer').value.set(value),
    ));
  }, [path]);

  const handleToggle = useCallback(() => {
    const current = path.value.get(path.form.currentState);
    const value = !current.enabled;

    if (value && headerRef.current) {
      setItemOpened(value);
      headerRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    path.form.change(pipe(
      path.form.currentState,
      path.at('enabled').value.set(value),
    ));
  }, [path]);

  const handleClone = useCallback(() => {
    const current = path.value.get(path.form.currentState);
    const value = current.copies.concat({
      title: 'copy',
      answer: getDefaultFormAnswer(current.config),
    });

    path.form.change(pipe(
      path.form.currentState,
      path.at('copies').value.set(value),
    ));
  }, [path]);

  const handleTitleModalOpen = useCallback(() => {
    setTitleModalOpen(true);
  }, []);

  const handleTitleModalCancel = useCallback(() => {
    setTitleModalOpen(false);
  }, []);

  const handleTitleModalSubmit = useCallback((title: string) => {
    setTitleModalOpen(false);

    path.form.change(pipe(
      path.form.currentState,
      path.at('title').value.set(title),
    ));
  }, [path]);

  const renderToggle: ({ onClick }: {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }) => React.JSX.Element = useCallback(({ onClick }) => (
    <HvacFormSectionHeader
      actual={section.answer.actual.option?.class ?? null}
      future={section.answer.future.option?.class ?? null}
      isItemOpened={isItemOpened}
      disabled={!section.enabled}
      readOnly={readOnly}
      wrapperRef={headerRef}
      isCopy={false}
      onClick={onClick}
      onToggle={section.config.deactivation ? handleToggle : undefined}
      onClone={section.config.multiplication ? handleClone : undefined}
      onRename={handleTitleModalOpen}
    >
      {section.config.number}
      &nbsp;
      <FormattedMessage id={section.config.name}/>
      {section.title && ` \u2013 ${section.title}`}
    </HvacFormSectionHeader>
  ), [
    isItemOpened,
    readOnly,
    handleToggle,
    handleClone,
    handleTitleModalOpen,
    section,
  ]);

  return (
    <>
      <AccordionItem
        open={isItemOpened}
        className="bp-hvac-form-section"
        disabled={readOnly || !section.enabled}
        onHeadingClick={handleClick}
        renderToggle={renderToggle}
      >
        <HvacFormSectionContent
          path={path.at('answer')}
          disabled={readOnly || !section.enabled}
          config={section.config}
          onClear={handleClear}
        />
      </AccordionItem>

      <HvacFormSectionCopyTitleModal
        open={!readOnly && section.enabled && titleModalOpen}
        title={section.title}
        onCancel={handleTitleModalCancel}
        onSubmit={handleTitleModalSubmit}
      />
    </>
  );
};
