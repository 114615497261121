import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { PROJECT_SYNC_PERFORM } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { ProjectSyncState } from 'src/modules/project-sync/types/ProjectSyncState';

export function projectSyncPerformReducer(state: ProjectSyncState, action: Action): ProjectSyncState {
  if (PROJECT_SYNC_PERFORM.isRequest(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        status: FUTURE.pending(),
      },
    };
  }
  if (PROJECT_SYNC_PERFORM.isPending(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        status: FUTURE.pending(),
      },
    };
  }
  if (PROJECT_SYNC_PERFORM.isSuccess(action)) {
    return {
      ...state,
      data: {
        project: action.data,
        status: FUTURE.success(undefined),
      },
    };
  }
  if (PROJECT_SYNC_PERFORM.isFailure(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        status: FUTURE.failure(action.data),
      },
    };
  }

  return state;
}
