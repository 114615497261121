import {
  ENERGY_OUTPUT_BY_SOURCE_UNIT,
  HvacArea,
  HvacResultsSavingsEnergy,
  mulDecimals,
} from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { createSelector } from 'reselect';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ReportGeneratedSavingsAnnualValues } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsEnergyAbsolute = createSelector([
  getHvacResults,
], (results): ReportGeneratedSavingsAnnualValues => ({
  heating: getEnergySavingsInKWh(results.perArea[HvacArea.HEATING].savings.thermal),
  cooling: getEnergySavingsInKWh(results.perArea[HvacArea.COOLING].savings.thermal),

  total: results.overall.savings.overall.absolute,
  electricity: results.overall.savings.electrical.absolute,
}));

function getEnergySavingsInKWh(savings: HvacResultsSavingsEnergy): Decimal | null {
  return mulDecimals(
    savings.absolute,
    ENERGY_OUTPUT_BY_SOURCE_UNIT[savings.source],
  );
}
