import { passwordResetTokenReducer } from 'src/modules/password-reset/reducers/passwordResetTokenReducer';
import { PasswordResetState } from 'src/modules/password-reset/types/PasswordResetState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: PasswordResetState = {
  token: null,
};

export const passwordResetReducer = chainReducers(defaultState, [
  passwordResetTokenReducer,
]);
