import './ShareProjectUserList.scss';
import {
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from '@carbon/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { Project } from 'src/modules/common/types/Project';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';

type Props = {
  readonly project: Project;
  readonly users: ReadonlyArray<SharedUser>;
  readonly onRemove: (user: SharedUser) => void;
};

export const ShareProjectUserList = ({ project, users, onRemove }: Props): React.ReactElement => (
  <StructuredListWrapper className="bp-share-project-user-list">
    <StructuredListHead>
      <StructuredListRow head={true}>
        <StructuredListCell className="bp-share-project-user-list__header-cell" head={true}>
          <FormattedMessage id="shareProject/modal/shareField/column/surname"/>
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__header-cell" head={true}>
          <FormattedMessage id="shareProject/modal/shareField/column/name"/>
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__header-cell" head={true}>
          <FormattedMessage id="shareProject/modal/shareField/column/email"/>
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__header-cell" head={true}>
          <FormattedMessage id="shareProject/modal/shareField/column/access"/>
        </StructuredListCell>

        <StructuredListCell head={true}/>
      </StructuredListRow>
    </StructuredListHead>

    <StructuredListBody className="bp-share-project-user-list__body">
      <StructuredListRow className="bp-share-project-user-list__body-row">
        <StructuredListCell className="bp-share-project-user-list__body-cell">
          {project.owner
            ? project.owner.lastName
            : '—'}
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__body-cell">
          {project.owner
            ? project.owner.firstName
            : '—'}
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__body-cell">
          {project.owner
            ? project.owner.email
            : '—'}
        </StructuredListCell>

        <StructuredListCell className="bp-share-project-user-list__body-cell">
          <FormattedMessage id="shareProject/modal/cell/owner"/>
        </StructuredListCell>

        <StructuredListCell/>
      </StructuredListRow>

      {users.map((user) => (
        <StructuredListRow className="bp-share-project-user-list__body-row" key={user.id}>
          <StructuredListCell className="bp-share-project-user-list__body-cell">
            {user.lastName}
          </StructuredListCell>

          <StructuredListCell className="bp-share-project-user-list__body-cell">
            {user.firstName}
          </StructuredListCell>

          <StructuredListCell className="bp-share-project-user-list__body-cell">
            {user.email}
          </StructuredListCell>

          <StructuredListCell className="bp-share-project-user-list__body-cell">
            <FormattedMessage id="shareProject/modal/cell/canEdit"/>
          </StructuredListCell>

          <StructuredListCell className="bp-share-project-user-list__body-button-cell">
            <CustomButton
              className="bp-share-project-user-list__body-cell-button"
              kind="ghost"
              size="md"
              onClick={() => onRemove(user)}
            >
              <FormattedMessage id="shareProject/modal/action/removeUser"/>
            </CustomButton>
          </StructuredListCell>
        </StructuredListRow>
      ))}
    </StructuredListBody>
  </StructuredListWrapper>
);
