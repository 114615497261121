import { BasedataFormData } from 'src/modules/basedata/types/BasedataFormData';
import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';

export const BASEDATA_FORM_SCHEMA: FormRule<BasedataFormData> = R.struct({
  cover: R.pass(),
  title: R.maxLength(40),

  projectGoals: R.maxLength(280),

  building: R.struct({
    type: R.required(),
    size: R.nullable(R.gt(0)),
    address: R.struct({
      street: R.maxLength(30),
      city: R.maxLength(20),
      country: R.required(),
      zip: R.maxLength(10),
    }),
  }),
  participants: R.struct({
    propertyManager: R.maxLength(50),
    consutlingEngineer: R.maxLength(50),
    productManager: R.maxLength(50),
  }),
});
