import { Uuid } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormError } from 'src/modules/form/types/FormError';
import { User } from 'src/modules/users/types/User';
import { UserData } from 'src/modules/users/types/UserData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const USER_DETAILS_FETCH = createTrigger<UserData, Uuid>('USER_DETAILS_FETCH');
export const USER_DETAILS_RESET = createTrigger<void, Uuid>('USER_DETAILS_RESET');

export const USER_SAVE = createActions<FormController<UserFormData, FormError>, User, Uuid | null>('USER_SAVE');

export const USER_REMOVE_PROCESS = createTrigger<Uuid>('USER_REMOVE_PROCESS');
export const USER_REMOVE_CANCEL = createTrigger<void>('USER_REMOVE_CANCEL');
export const USER_REMOVE_CONFIRM = createActions<void, void, Uuid>('USER_REMOVE_CONFIRM');
