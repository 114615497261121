import './FormSection.scss';
import { Stack } from '@carbon/react';
import React from 'react';
import { Divider } from 'src/modules/common/components/Divider';

type Props = {
  readonly children: React.ReactNode;
  readonly title: React.ReactNode;
};

export const FormSection = ({ children, title }: Props): React.ReactElement => (
  <Stack as="section" className="bp-form-section" gap="1.5rem" orientation="vertical">
    <h2 className="bp-form-section__title">{title}</h2>
    <Divider orientation="horizontal"/>
    {children}
  </Stack>
);
