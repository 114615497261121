import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { getPasswordResetToken } from 'src/modules/password-reset/selectors/getPasswordResetToken';
import { PasswordResetFormData } from 'src/modules/password-reset/types/PasswordResetFormData';
import { call, select } from 'typed-redux-saga';

export function* submitPasswordReset(form: PasswordResetFormData): SagaIterator<void> {
  try {
    const token = yield* select(getPasswordResetToken);
    const query = new URLSearchParams({ token });

    const body = { password: form.password };

    yield* call(makeRestRequest, 'POST', `/auth/set-password?${query.toString()}`, body);
  } catch (error) {
    throw new RuntimeError('Could not reset user password', { form }, NativeError.wrap(error));
  }
}
