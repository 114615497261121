import { DEVICE_IDENTIFIER } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ReadOnlyArray } from 'src/io/combinators/ReadOnlyArray';
import { CONTAINER_TYPE } from 'src/modules/common/codecs/ContainerType';
import { ConfigContainer } from 'src/modules/config/types/ConfigContainer';

export const CONFIG_CONTAINER: C.Codec<unknown, Json, ConfigContainer> = C.struct({
  type: CONTAINER_TYPE,
  name: C.string,
  description: C.string,
  children: ReadOnlyArray(DEVICE_IDENTIFIER),
});
