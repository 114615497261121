import { FormController } from '@form-ts/core';
import { FormError } from 'src/modules/form/types/FormError';
import { PasswordResetFormData } from 'src/modules/password-reset/types/PasswordResetFormData';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const PASSWORD_RESET_TOKEN =
  createTrigger<string | null>('PASSWORD_RESET_TOKEN');

export const PASSWORD_RESET_SUBMIT =
  createActions<FormController<PasswordResetFormData, FormError>, void>('PASSWORD_RESET_SUBMIT');
