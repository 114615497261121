import { projectSyncPerformReducer } from 'src/modules/project-sync/reducers/projectSyncPerformReducer';
import { projectSyncResetReducer } from 'src/modules/project-sync/reducers/projectSyncResetReducer';
import { ProjectSyncState } from 'src/modules/project-sync/types/ProjectSyncState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ProjectSyncState = {
  data: null,
};

export const projectSyncReducer = chainReducers(defaultState, [
  projectSyncResetReducer,
  projectSyncPerformReducer,
]);
