import './UserProfile.scss';
import { Checkmark } from '@carbon/icons-react';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { UserIcon } from 'src/modules/common/icons/UserIcon';
import { UserRole } from 'src/modules/common/types/UserRole';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { getCurrentLocation } from 'src/modules/routing/selectors/getCurrentLocation';

export const UserProfile = memo(() => {
  const intl = useIntl();
  const location = useSelector(getCurrentLocation);

  const loggedInUser = useSelector(getLoggedInUser);

  return (
    <CustomMenu
      className="bp-user-profile"
      data-testid="header-menu"
      flipped={true}
      iconDescription={intl.formatMessage({ id: 'common/header/user' })}
      tooltipPosition="bottom"
      renderIcon={() => (
        <div className="bp-user-profile__icon">
          <UserIcon/>
        </div>
      )}
    >
      <p className="bp-user-profile__menu-email">{loggedInUser.email}</p>

      <Link
        data-testid="header-menu-projects"
        className="bp-user-profile__menu-item-link"
        to="/projects"
      >
        <CustomMenuItem
          itemText={(
            <>
              <p className="bp-user-profile__menu-item-text">
                <FormattedMessage id="header/user/projects"/>
              </p>

              <div className="bp-user-profile__menu-item-icon">
                {location?.pathname === '/projects' ? <Checkmark size={24}/> : <ArrowRightIcon/>}
              </div>
            </>
          )}
          hasDivider={false}
        />
      </Link>

      <Link className="bp-user-profile__menu-item-link" to="/profile">
        <CustomMenuItem
          itemText={(
            <>
              <p className="bp-user-profile__menu-item-text">
                <FormattedMessage id="header/user/edit"/>
              </p>

              <div className="bp-user-profile__menu-item-icon">
                <ArrowRightIcon/>
              </div>
            </>
          )}
          hasDivider={true}
        />
      </Link>

      {loggedInUser.role === UserRole.ADMIN && (
        <p className="bp-user-profile__menu-group-title">
          <FormattedMessage id="header/user/administration"/>
        </p>
      )}

      {loggedInUser.role === UserRole.ADMIN && (
        <Link className="bp-user-profile__menu-item-link" to="/admin/users">
          <CustomMenuItem
            itemText={(
              <>
                <p className="bp-user-profile__menu-item-text">
                  <FormattedMessage id="header/user/management"/>
                </p>

                <div className="bp-user-profile__menu-item-icon">
                  {location?.pathname === '/admin/users' ? <Checkmark size={24}/> : <ArrowRightIcon/>}
                </div>
              </>
            )}
            hasDivider={false}
          />
        </Link>
      )}

      {loggedInUser.role === UserRole.ADMIN && (
        <Link
          data-testid="header-menu-organization"
          className="bp-user-profile__menu-item-link"
          to="/admin/organization"
        >
          <CustomMenuItem
            itemText={(
              <>
                <p className="bp-user-profile__menu-item-text">
                  <FormattedMessage id="header/admin/organization"/>
                </p>

                <div className="bp-user-profile__menu-item-icon">
                  {location?.pathname === '/admin/organization' ? <Checkmark size={24}/> : <ArrowRightIcon/>}
                </div>
              </>
            )}
            hasDivider={true}
          />
        </Link>
      )}

      <Link className="bp-user-profile__menu-item-link" to="/login">
        <CustomMenuItem
          itemText={(
            <p
              className="bp-user-profile__menu-item-text"
              aria-label={intl.formatMessage({ id: 'header/user/logout' })}
            >
              <FormattedMessage id="header/user/logout"/>
            </p>
          )}
          hasDivider={true}
        />
      </Link>
    </CustomMenu>
  );
});
