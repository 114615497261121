import './OutputPage.scss';
import React from 'react';
import { OutputPagination } from 'src/modules/output/views/OutputPagination';

type Props = {
  readonly children: React.ReactNode;
  readonly showPageNumber: boolean;
};

export const OutputPage = React.forwardRef((
  { children, showPageNumber }: Props,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement => (
  <div ref={forwardedRef} className="bp-output-page">
    <div className="bp-output-page__body">
      {children}
    </div>

    {showPageNumber && (
      <div className="bp-output-page__pager">
        <OutputPagination/>
      </div>
    )}
  </div>
));
