/* eslint-disable quote-props */
import {
  AreaUnit,
  BuildingType,
  COUNTRY_CODES,
  CountryCode,
  CurrencyCode,
  EnergySource,
} from '@belimo-retrofit-portal/logic';
import { CURRENCY_CODE_OPTIONS } from 'src/modules/common/constants/options';

export const IMPORT_HEADER_COLUMNS = [
  'project id',
  'project title',
  'project goal',

  'building type',
  'building size unit',
  'building size',
  'building street address',
  'building city',
  'building country',
  'building zip code',

  'participants author',
  'participants property manager',
  'participants consulting engineer',
  'participants product engineer',

  'currency',
  'electricity price',
  'gas price',
  'heating oil price',
  'district heating price',
  'district cooling price',
  'heating other price',
  'cooling other price',

  'heating energy source',
  'heating energy annual consumption',
  'heating energy carbon intensity',
  'heating electrical aux annual consumption',

  'cooling energy source',
  'cooling energy annual consumption',
  'cooling energy carbon intensity',
  'cooling electrical aux annual consumption',

  'ventilation electrical aux annual consumption',

  'project data and configuration',
] as const;

export const IMPORT_BUILDING_TYPE_MAP: Readonly<Record<string, BuildingType>> = {
  'Office': BuildingType.OFFICE,
  'Data center': BuildingType.DATA_CENTER,
  'Education, elementary': BuildingType.EDUCATION_ELEMENTARY,
  'Education, higher': BuildingType.EDUCATION_HIGHER,
  'Education, other': BuildingType.EDUCATION_OTHER,
  'Entertainment and Leisure': BuildingType.ENTERTAINMENT_LEISURE,
  'Government': BuildingType.GOVERNMENT,
  'Health': BuildingType.HEALTH,
  'Hospital': BuildingType.HOSPITAL,
  'Hotel': BuildingType.HOTEL,
  'Life science': BuildingType.LIFE_SCIENCE,
  'Restaurant': BuildingType.RESTAURANT,
  'Retail': BuildingType.RETAIL,
  'Wholesale/retail trade': BuildingType.WHOLESALE_RETAIL_TRADE,
};

export const IMPORT_AREA_UNIT_MAP: Readonly<Record<string, AreaUnit>> = {
  'm2': AreaUnit.SQM,
  'ft2': AreaUnit.SQFT,
};

export const IMPORT_COUNTRY_CODE_MAP: Readonly<Record<string, CountryCode>> =
  Object.fromEntries(COUNTRY_CODES.map((code) => [code, code]));

export const IMPORT_CURRENCY_CODE_MAP: Readonly<Record<string, CurrencyCode>> =
  Object.fromEntries(CURRENCY_CODE_OPTIONS.map((code) => [code, code]));

export const IMPORT_ENERGY_SOURCE_HEATING: Readonly<Record<string, EnergySource>> = {
  'Electricity': EnergySource.ELECTRICITY,
  'District': EnergySource.HEATING_DISTRICT,
  'Heating Oil': EnergySource.HEATING_OIL,
  'Gas': EnergySource.GAS,
  'Other': EnergySource.HEATING_OTHER,
};

export const IMPORT_ENERGY_SOURCE_COOLING: Readonly<Record<string, EnergySource>> = {
  'Electricity': EnergySource.ELECTRICITY,
  'District': EnergySource.COOLING_DISTRICT,
  'Gas': EnergySource.GAS,
  'Other': EnergySource.COOLING_OTHER,
};
