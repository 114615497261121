import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { loadContainers } from 'src/modules/config/sagas/utils/loadContainers';
import { loadDevices } from 'src/modules/config/sagas/utils/loadDevices';
import { loadEnvironment } from 'src/modules/config/sagas/utils/loadEnvironment';
import { loadHvacConfig } from 'src/modules/config/sagas/utils/loadHvacConfig';
import { loadLanguage } from 'src/modules/config/sagas/utils/loadLanguage';
import { loadLocale } from 'src/modules/config/sagas/utils/loadLocale';
import { loadSchemas } from 'src/modules/config/sagas/utils/loadSchemas';
import { loadTranslations } from 'src/modules/config/sagas/utils/loadTranslations';
import { ConfigData } from 'src/modules/config/types/ConfigData';
import { sentryInit } from 'src/modules/config/utils/sentryInit';
import { all, call } from 'typed-redux-saga';

export function* loadConfig(): SagaIterator<ConfigData> {
  const currentDate = new Date();
  const environment = yield* call(loadEnvironment);

  // init Sentry as soon as possible
  yield* call(sentryInit, environment);

  const locale = yield* call(loadLocale);
  const language = yield* call(loadLanguage, environment.languages);
  const translations = yield* call(loadTranslations, language);
  const { hvac, schemas, devices, containers } = yield* all({
    hvac: callEffect(loadHvacConfig),
    schemas: callEffect(loadSchemas),
    devices: callEffect(loadDevices),
    containers: callEffect(loadContainers),
  });

  return {
    environment: environment,
    currentDate: currentDate,
    hvac: hvac,
    schemas: schemas,
    devices: devices,
    containers: containers,
    i18n: {
      locale,
      language,
      translations,
    },
  };
}
