import './AssetUpload.scss';
import React, { useCallback, useEffect } from 'react';
import { useAction } from 'src/hooks/useAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ASSET_CANCEL, ASSET_UPLOAD } from 'src/modules/asset/actions/AssetActions';
import { getAssetState } from 'src/modules/asset/selectors/getAssetState';
import { FileUpload } from 'src/modules/asset/views/FileUpload';
import { CustomLoading } from 'src/modules/common/components/CustomLoading';
import { Asset } from 'src/modules/common/types/Asset';

type Props = {
  readonly id: string;
  readonly name?: string;
  readonly disabled: boolean;
  readonly children: React.ReactNode;

  readonly onTouch: () => void;
  readonly onChange: (asset: Asset) => void;
};

export const AssetUpload = ({
  id,
  name,
  onTouch,
  onChange,
  disabled,
  children,
}: Props): React.ReactElement => {
  const assetUpload = useAction(ASSET_UPLOAD.request);
  const assetCancel = useAction(ASSET_CANCEL.trigger);

  const state = useParametricSelector(getAssetState, id);
  const loading = state?.state === 'pending';

  const handleChange = useCallback((file: File) => {
    assetUpload(file, id);
  }, [assetUpload, id]);

  useEffect(() => (): void => {
    assetCancel(undefined, id);
  }, [assetCancel, id]);
  useEffect(() => {
    if (state?.state === 'success') {
      onChange(state.value);
    }
  }, [state, onChange]);

  return (
    <div className="bp-asset-upload">
      <div className="bp-asset-upload__button">
        <FileUpload
          id={id}
          name={name}
          disabled={loading || disabled}
          onTouch={onTouch}
          onChange={handleChange}
        >
          {children}
        </FileUpload>
      </div>

      {state?.state === 'pending' && (
        <div className="bp-asset-upload__loader">
          <CustomLoading small={true} withOverlay={false}/>
        </div>
      )}
    </div>
  );
};
