import './Header.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { OnboardingModal } from 'src/modules/common/components/OnboardingModal';
import { UserLanguage } from 'src/modules/common/components/UserLanguage';
import { UserProfile } from 'src/modules/common/components/UserProfile';
import { IS_ONBOARDING_MODAL_VIEWED } from 'src/modules/common/constants/localStorageKeys';
import { QuestionIcon } from 'src/modules/common/icons/QuestionIcon';

export const Header = (): React.ReactElement => {
  const intl = useIntl();

  const [isOnboardingModalOpened, setOnboardingModalOpened] = useState(false);

  const handleOnboardingModalClose = useCallback(() => {
    setOnboardingModalOpened(false);
  }, []);

  const handleOnboardingModalOpen = useCallback(() => {
    setOnboardingModalOpened(true);
  }, []);

  useEffect(() => {
    const isModalViewed = localStorage.getItem(IS_ONBOARDING_MODAL_VIEWED);

    if (!isModalViewed) {
      setOnboardingModalOpened(true);
    }
  }, []);

  return (
    <>
      <div
        className="bp-header"
      >
        <Link to="/" className="bp-header__branding">
          <img
            alt="Logo"
            src="/images/logo.svg"
            className="bp-header__logo"
          />
        </Link>

        <div className="bp-header__controls">
          <CustomButton
            kind="ghost"
            hasIconOnly={true}
            size="xl"
            iconDescription={intl.formatMessage({ id: 'common/header/onboarding' })}
            tooltipPosition="bottom"
            onClick={handleOnboardingModalOpen}
          >
            <div className="bp-header__onboarding-icon">
              <QuestionIcon/>
            </div>
          </CustomButton>

          <UserLanguage/>

          <UserProfile/>
        </div>
      </div>

      <CustomModal
        open={isOnboardingModalOpened}
        onClose={handleOnboardingModalClose}
        className="bp-onboarding-modal"
        size="md"
        isShaded={false}
        shouldUnmount={true}
      >
        <OnboardingModal handleClose={handleOnboardingModalClose}/>
      </CustomModal>
    </>
  );
};
