import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import Decimal from 'decimal.js-light';

export const FACTOR_WEIGHTS_LABELS: Readonly<Record<EnergyEfficiencyClass, Decimal>> = {
  'A+': new Decimal(4),
  A: new Decimal(3),
  B: new Decimal(2),
  C: new Decimal(1),
  D: new Decimal(0),
};
