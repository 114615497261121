import { BaseError, NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import React from 'react';

type Props = {
  readonly onReady: () => void;
  readonly onError: (error: BaseError) => void;
  readonly children: React.ReactElement | null;
};
type State = {
  readonly error: Error | null;
};

export class Lifecycle extends React.Component<Props, State> {
  public readonly state: State = {
    error: null,
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });

    const { onError } = this.props;
    onError(new RuntimeError('Rendering error', errorInfo, NativeError.wrap(error)));
  }

  public componentDidMount(): void {
    const { onReady } = this.props;
    onReady();
  }

  public render(): React.ReactNode {
    const { error } = this.state;
    if (error) {
      return null;
    }

    const { children } = this.props;
    return children;
  }
}
