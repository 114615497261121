import Decimal from 'decimal.js-light';
import React from 'react';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';

type Props = {
  value?: Decimal;
};

export const FormattedBEP = ({ value }: Props): React.ReactNode => {
  if (!value || !value.isPositive()) {
    return '\u2013';
  }

  if (value.lessThan(1)) {
    return '<1';
  }

  if (value.lessThan(10)) {
    const roundedToNearestHalfValue = value.mul(2).toDecimalPlaces(0).div(2);
    const roundedValuePrecision = roundedToNearestHalfValue.decimalPlaces();

    return (
      <FormattedValue
        value={roundedToNearestHalfValue}
        precision={roundedValuePrecision}
      />
    );
  }

  if (value.greaterThan(100)) {
    return '>100';
  }

  return <FormattedValue value={value} precision={0}/>;
};
