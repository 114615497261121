import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ListViewIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M4.3 18.9v-5.4h15.4v5.4H4.3Zm.7-.7h14v-4H5v4Zm-.7-7.7V5.1h15.4v5.4H4.3Zm.7-.7h14v-4H5v4Z"
      />
    </svg>
  </IconWrapper>
));
