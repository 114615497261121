import './Field.scss';
import './TextAreaField.scss';
import { TextArea as CarbonTextArea } from '@carbon/react';
import { TextAreaProps } from '@carbon/react/lib/components/TextArea/TextArea';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
TextAreaProps,
'id' | 'invalid' | 'invalidText' | 'helperText' | 'name' | 'value' | 'onChange'
| 'onBlur' | 'maxCount' | 'maxLength'
>;

type Props<TData> = BaseProps & {
  readonly theme?: 'gray' | 'white';
  readonly outlined?: boolean;
  readonly maxLength?: number;
  readonly showHint?: boolean;
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, string>;
  readonly onBlur?: () => void;
  readonly renderError?: (error: FormError) => React.ReactNode;
  readonly renderHint?: () => React.ReactNode;
};

export const TextAreaField: <TData>(props: Props<TData>) => React.ReactElement = (
  {
    theme = 'gray',
    outlined = false,
    maxLength,
    showHint,
    field,
    wrapperClass,
    onBlur,
    renderError = renderErrorDefault,
    renderHint,
    ...props
  },
) => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const hint = renderHint || (():React.ReactNode => renderHintDefault(value.length, maxLength));

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(event.target.value),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));

    onBlur?.();
  }, [field, onBlur]);

  return (
    <div className={clsx(
      'bp-field',
      'bp-text-area-field',
      {
        'bp-text-area-field--invalid': invalid,
        'bp-text-area-field--outlined': outlined,
      },
      `bp-text-area-field--${theme}`,
      wrapperClass,
    )}
    >
      <CarbonTextArea
        {...props}
        id={`${field.form.name}.${field.path}`}
        invalid={invalid}
        invalidText={error && invalid ? renderError(error) : null}
        helperText={showHint
          ? hint()
          : null}
        name={field.path}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        maxCount={maxLength}
        maxLength={maxLength}
      />
    </div>
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);

const renderHintDefault = (length: number, maxLength?: number): React.ReactNode => (
  <FormattedMessage
    id="form/helper/maxLength"
    values={{ actualLength: length, maxLength: maxLength }}
  />
);
