import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { updateProject } from 'src/modules/common/sagas/utils/updateProject';
import { PROJECT_SYNC_PERFORM } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { PROJECT_VIEW_REPLACE } from 'src/modules/project-view/actions/ProjectViewActions';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* projectSyncPerformSaga(
  action: GetRequestActionType<typeof PROJECT_SYNC_PERFORM>,
): SagaIterator<void> {
  const mode = action.data;
  const project = action.meta;

  try {
    // optimistically update with the new version
    yield* put(PROJECT_VIEW_REPLACE.trigger(project));

    yield* call(logDebug, `Synchronizing project in ${mode}...`, project);
    yield* put(PROJECT_SYNC_PERFORM.pending(project));
    if (mode === 'background') {
      yield* delay(1_000);
    }

    const reloaded = yield* call(updateProject, project.id, project.data);

    // update with the reloaded version
    yield* put(PROJECT_VIEW_REPLACE.trigger(reloaded));

    yield* call(logDebug, `Synchronizing project in ${mode}... done`, reloaded);
    yield* put(PROJECT_SYNC_PERFORM.success(reloaded, project));
  } catch (error) {
    yield* call(logError, `Synchronizing project in ${mode}... error`, error);
    yield* put(PROJECT_SYNC_PERFORM.failure(NativeError.wrap(error), project));
  }
}
