import { HvacArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ReportGeneratedSavingsAnnualValues } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsCarbonEmission = createSelector([
  getHvacResults,
], (hvacResults): ReportGeneratedSavingsAnnualValues => ({
  heating: hvacResults.perArea[HvacArea.HEATING].savings.carbon.thermal,
  cooling: hvacResults.perArea[HvacArea.COOLING].savings.carbon.thermal,

  total: hvacResults.overall.savings.carbon.overall,
  electricity: hvacResults.overall.savings.carbon.electrical,
}));
