import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const QuestionIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M16.069 22.667a.832.832 0 0 0 .614-.252.842.842 0 0 0 .25-.617.833.833 0 0 0-.252-.615.842.842 0 0 0-.616-.25.833.833 0 0 0-.615.252.842.842 0 0 0-.25.617c0 .243.084.448.252.615.168.166.373.25.617.25Zm-.536-4.2h.934c.044-.534.16-.973.35-1.317.189-.344.539-.772 1.05-1.283.6-.6 1.039-1.128 1.316-1.584.278-.455.417-.976.417-1.56 0-.993-.343-1.795-1.03-2.406-.687-.611-1.499-.917-2.437-.917-.866 0-1.616.239-2.25.717-.633.477-1.105 1.016-1.416 1.616l.933.4c.244-.51.567-.939.967-1.283s.966-.517 1.7-.517c.91 0 1.572.25 1.983.75.411.5.617 1.05.617 1.65 0 .467-.123.878-.367 1.234a6.99 6.99 0 0 1-.967 1.1c-.71.666-1.189 1.255-1.433 1.766a3.739 3.739 0 0 0-.367 1.634Zm.473 9.133c-1.604 0-3.112-.304-4.525-.913a11.724 11.724 0 0 1-3.685-2.478 11.708 11.708 0 0 1-2.482-3.681 11.277 11.277 0 0 1-.914-4.522c0-1.604.304-3.112.913-4.525a11.724 11.724 0 0 1 2.478-3.685 11.707 11.707 0 0 1 3.681-2.482c1.411-.61 2.918-.914 4.522-.914s3.112.304 4.525.913a11.725 11.725 0 0 1 3.685 2.478 11.708 11.708 0 0 1 2.482 3.681c.61 1.411.914 2.918.914 4.522s-.304 3.112-.913 4.525a11.724 11.724 0 0 1-2.478 3.685 11.708 11.708 0 0 1-3.681 2.482c-1.411.61-2.918.914-4.522.914ZM16 26.667c2.978 0 5.5-1.034 7.567-3.1 2.066-2.067 3.1-4.59 3.1-7.567 0-2.978-1.034-5.5-3.1-7.567-2.067-2.066-4.59-3.1-7.567-3.1-2.978 0-5.5 1.034-7.567 3.1-2.066 2.067-3.1 4.59-3.1 7.567 0 2.978 1.034 5.5 3.1 7.567 2.067 2.066 4.59 3.1 7.567 3.1Z"
      />
    </svg>
  </IconWrapper>
));
