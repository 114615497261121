import React from 'react';

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  readonly image: Blob;
};

export const BlobImage = ({ image, alt, ...props }: Props): JSX.Element => {
  const [imageUrl, setImageUrl] = React.useState<string>('');
  React.useLayoutEffect(() => {
    const url = URL.createObjectURL(image);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [image]);

  return <img alt={alt} src={imageUrl} {...props}/>;
};
