import { userDetailsDataReducer } from 'src/modules/users/reducers/userDetailsDataReducer';
import { userRemovingReducer } from 'src/modules/users/reducers/userRemovingReducer';
import { usersListDataReducer as usersDataReducer } from 'src/modules/users/reducers/usersListDataReducer';
import { UsersState } from 'src/modules/users/types/UsersState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: UsersState = {
  removing: null,
  user: null,
  users: null,
};

export const usersReducer = chainReducers(defaultState, [
  userDetailsDataReducer,
  userRemovingReducer,
  usersDataReducer,
]);
