import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const GridViewIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M4.3 11V4.3H11V11H4.3Zm0 8.7V13H11v6.7H4.3ZM13 11V4.3h6.7V11H13Zm0 8.7V13h6.7v6.7H13Zm-8-9.4h5.3V5H5v5.3Zm8.7 0H19V5h-5.3v5.3Zm0 8.7H19v-5.3h-5.3V19ZM5 19h5.3v-5.3H5V19Z"
      />
    </svg>
  </IconWrapper>
));
