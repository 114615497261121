import { RuntimeError } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { NATIVE_BLOB } from 'src/io/codecs/NativeBlob';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { XlsxValue } from 'src/modules/common/types/XlsxValue';
import { doOnWorker } from 'src/sagas/utils/doOnWorker';
import { ExportWorkerResponse } from 'src/types/worker/ExportWorker';
import { call } from 'typed-redux-saga';

export function* doOnExportWorker(
  request: ReadonlyArray<ReadonlyArray<XlsxValue>>,
): SagaIterator<Blob> {
  const response = yield* call(doOnWorker, '/export-worker.js', request);
  const data = decodeOrThrow(
    WORKER_RESULT,
    response,
  );
  if (data.type === 'failure') {
    throw new RuntimeError(
      `Error while executing export worker (${data.error.name}: ${data.error.message})`,
      { error: data.error },
    );
  }

  return data.content;
}

const WORKER_ERROR = D.struct({
  name: D.string,
  message: D.string,
});

const WORKER_RESULT_SUCCESS = D.struct({
  type: D.literal('success'),
  content: NATIVE_BLOB,
});

const WORKER_RESULT_FAILURE = D.struct({
  type: D.literal('failure'),
  error: WORKER_ERROR,
});

const WORKER_RESULT: D.Decoder<unknown, ExportWorkerResponse> = D.sum('type')({
  success: WORKER_RESULT_SUCCESS,
  failure: WORKER_RESULT_FAILURE,
});
