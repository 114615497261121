import { HvacArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { getHvacFormCounters } from 'src/modules/hvac/selectors/getHvacFormCounters';

export const getHvacResultsInaccurate = createSelector([
  getHvacFormCounters,
  getHvacAssesmentFormData,
], (counters, formData): boolean => HVAC_AREAS
  .filter((area) => formData[area].enabled)
  .some((area) => (
    // enabled at least one section
    counters[area].enabled > 0 &&
    // enabled less than 5 sections
    counters[area].enabled < 5 &&
    // all enabled sections answered
    counters[area].enabled === counters[area].answered
  )));

const HVAC_AREAS = [
  HvacArea.HEATING,
  HvacArea.COOLING,
  HvacArea.VENTILATION,
];
