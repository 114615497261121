import './Rating.scss';
import clsx from 'clsx';
import React, { memo, useMemo, useState } from 'react';
import { StarFilledIcon } from 'src/modules/common/icons/StarFilledIcon';
import { StarIcon } from 'src/modules/common/icons/StarIcon';

type Props = {
  readonly disabled: boolean;
  readonly onSelect: (value: number) => void;
};

export const Rating = memo(({ disabled, onSelect }: Props) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const stars = useMemo(() => Array.from({ length: 5 }, (_, i) => (i + 1)), []);

  const onClick = (star: number): void => {
    setRating(star);
    onSelect(star);
  };

  return (
    <div className={clsx('bp-rating', { 'bp-rating--disabled': disabled })}>
      {
        stars.map((star) => (
          <button
            aria-label="rating"
            type="button"
            key={star}
            className={clsx(
              'bp-rating-star',
              {
                'bp-rating-star--selected': star <= (hover || rating),
              },
            )}
            onClick={() => onClick(star)}
            onMouseEnter={() => setHover(star)}
            onMouseLeave={() => setHover(rating)}
          >
            {star <= (hover || rating) ? (
              <StarFilledIcon/>
            ) : (
              <StarIcon/>
            )}
          </button>
        ))
      }
    </div>
  );
});
