import './HvacFormChapter.scss';
import { Accordion } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { BodyDesktopLargeRegularText } from 'src/modules/common/typography/BodyDesktopLargeRegularText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacAssessmentCategories } from 'src/modules/hvac/types/HvacAssessmentCategories';
import { HvacFormChapterData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { filterHvacSectionByCategory } from 'src/modules/hvac/utils/filterHvacSectonByCategory';
import { HvacFormSection } from 'src/modules/hvac/views/HvacFormSection';
import { HvacFormSectionCopy } from 'src/modules/hvac/views/HvacFormSectionCopy';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormChapterData>;
};

export const HvacFormChapter = memo(({ path }: Props) => {
  const { value } = useFormField(path);

  const emission = useMemo(
    () => filterHvacSectionByCategory(value.sections, HvacAssessmentCategories.EMISSION),
    [value.sections],
  );

  const distribution = useMemo(
    () => filterHvacSectionByCategory(value.sections, HvacAssessmentCategories.DISTRIBUTION),
    [value.sections],
  );

  const generation = useMemo(
    () => filterHvacSectionByCategory(value.sections, HvacAssessmentCategories.GENERATION),
    [value.sections],
  );

  return (
    <Accordion className="bp-hvac-form-chapter">
      {Boolean(emission.length) && (
        <>
          <BodyDesktopLargeRegularText>
            <FormattedMessage id="hvac/form/common/section/emission"/>
          </BodyDesktopLargeRegularText>

          {emission.map(({ section, index }) => (
            <React.Fragment key={section.config.id}>
              <HvacFormSection
                path={path.at('sections').at(index)}
                readOnly={!value.enabled}
              />

              {section.copies.map((copy, copyIndex) => (
                <HvacFormSectionCopy
                  // eslint-disable-next-line react/no-array-index-key
                  key={copyIndex}
                  path={path}
                  config={section.config}
                  disabled={!section.enabled}
                  readOnly={!value.enabled}
                  copyIndex={copyIndex}
                  sectionIndex={index}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      )}

      {Boolean(distribution.length) && (
        <>
          <BodyDesktopLargeRegularText>
            <FormattedMessage id="hvac/form/common/section/distribution"/>
          </BodyDesktopLargeRegularText>

          {distribution.map(({ section, index }) => (
            <React.Fragment key={section.config.id}>
              <HvacFormSection
                path={path.at('sections').at(index)}
                readOnly={!value.enabled}
              />

              {section.copies.map((copy, copyIndex) => (
                <HvacFormSectionCopy
                  // eslint-disable-next-line react/no-array-index-key
                  key={copyIndex}
                  path={path}
                  config={section.config}
                  disabled={!section.enabled}
                  readOnly={!value.enabled}
                  copyIndex={copyIndex}
                  sectionIndex={index}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      )}

      {Boolean(generation.length) && (
        <>
          <BodyDesktopLargeRegularText>
            <FormattedMessage id="hvac/form/common/section/generation"/>
          </BodyDesktopLargeRegularText>

          {generation.map(({ section, index }) => (
            <React.Fragment key={section.config.id}>
              <HvacFormSection
                path={path.at('sections').at(index)}
                readOnly={!value.enabled}
              />

              {section.copies.map((copy, copyIndex) => (
                <HvacFormSectionCopy
                  // eslint-disable-next-line react/no-array-index-key
                  key={copyIndex}
                  path={path}
                  config={section.config}
                  disabled={!section.enabled}
                  readOnly={!value.enabled}
                  copyIndex={copyIndex}
                  sectionIndex={index}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      )}
    </Accordion>
  );
});
