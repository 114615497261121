import { createSelector } from 'reselect';
import { SchemaSelectionState } from 'src/modules/schema/types/SchemaSelectionState';
import { RootState } from 'src/types/RootState';

function getSchemaSelectionState(state: RootState): SchemaSelectionState {
  return state.schemaSelection;
}

export const getSchemaSelectionFilter = createSelector([
  getSchemaSelectionState,
], (state) => state.data.filter);

export const getSchemaPreselected = createSelector([
  getSchemaSelectionState,
], (state) => state.data.schemaSelected);
