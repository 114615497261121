import './UsersListLayoutExtension.scss';
import { Add } from '@carbon/icons-react';
import { Stack } from '@carbon/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';

type Props = {
  className?: string;
};

export const UsersListLayoutExtension = ({ className }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <Stack className={className} orientation="horizontal">
      <CustomButton
        as={Link}
        to="/admin/users/add"
        className="bp-users-list-layout-extension__button"
        renderIcon={() => <Add size="2rem"/>}
        size="md"
      >
        {intl.formatMessage({ id: 'admin/users/list/header/addButton' })}
      </CustomButton>
    </Stack>
  );
};
