import './SavingTileAmount.scss';
import React, { memo } from 'react';

type Props = {
  title: React.ReactNode;
  amount: React.ReactNode;
  unit: React.ReactNode;
};

export const SavingTileAmount = memo(({ title, amount, unit }: Props) => (
  <div className="bp-saving-tile-amount">
    <div className="bp-saving-tile-amount__title">
      {title}
    </div>

    <div className="bp-saving-tile-amount__amount">
      {amount}

      {unit}
    </div>
  </div>
));
