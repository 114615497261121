/* eslint-disable react/no-unused-class-component-methods */
import './SchemaGesture.scss';
import { Matrix } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { merge, Subscription } from 'rxjs';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { BBox } from 'src/modules/common/types/BBox';
import { Point } from 'src/modules/common/types/Point';
import { bind } from 'src/modules/common/utils/decorator';
import { matrixInverse, matrixMultiply, matrixScale, matrixTranslate } from 'src/modules/common/utils/matrix';
import { pointTransform } from 'src/modules/common/utils/point';
import { SchemaEventCanvas, SchemaEventCanvasMove, SchemaEventCanvasZoom } from 'src/modules/schema/types/SchemaEvent';
import { canvasGesture } from 'src/modules/schema/utils/event/canvasGesture';
import { canvasPointer } from 'src/modules/schema/utils/event/canvasPointer';
import { canvasWheel } from 'src/modules/schema/utils/event/canvasWheel';

type Props = {
  readonly width: number;
  readonly height: number;

  readonly onEvent: (event: SchemaEventCanvas) => void;
  readonly children: React.ReactNode;
};
type State = {
  readonly bbox: BBox;
  readonly scale: Matrix;
};

export class SchemaGesture extends React.PureComponent<Props, State> {
  private events = Subscription.EMPTY;

  private readonly container: React.RefObject<HTMLDivElement> = React.createRef();

  public readonly state: State = {
    bbox: { x: 0, y: 0, w: 0, h: 0 },
    scale: MATRIX_IDENTITY,
  };

  public componentDidMount(): void {
    this.handleResize();

    window.addEventListener('resize', this.handleResize, { passive: true });
    window.addEventListener('orientationchange', this.handleResize, { passive: true });

    if (this.container.current) {
      this.events.unsubscribe();
      this.events = merge(
        canvasGesture(this.container.current),
        canvasPointer(this.container.current),
        canvasWheel(this.container.current),
      ).subscribe(this.handleCanvasEvent);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);

    this.events.unsubscribe();
  }

  public render(): React.ReactElement {
    const { children, width, height } = this.props;
    const { scale } = this.state;

    const transformLine = [
      scale.a.toFixed(5),
      scale.b.toFixed(5),
      scale.c.toFixed(5),
      scale.d.toFixed(5),
      scale.e.toFixed(5),
      scale.f.toFixed(5),
    ].join(',');

    return (
      <div
        ref={this.container}
        className="bp-schema-gesture"
      >
        <div
          className="bp-schema-gesture__scale"
          style={{ width: width, height: height, transform: `matrix(${transformLine})` }}
        >
          {children}
        </div>
      </div>
    );
  }

  @bind()
  private handleResize(): void {
    const rect = this.container.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const bbox: BBox = {
      x: rect.left,
      y: rect.top,
      w: rect.width,
      h: rect.height,
    };

    this.setState({
      bbox: bbox,
      scale: this.getScaleMatrix(bbox),
    });
  }

  @bind()
  private handleCanvasEvent(event: SchemaEventCanvas): void {
    if (event.type === 'zoom') {
      this.handleZoomEvent(event);
    } else if (event.type === 'move') {
      this.handleMoveEvent(event);
    }
  }

  @bind()
  private handleZoomEvent(event: SchemaEventCanvasZoom): void {
    const { bbox } = this.state;

    const origin = this.getViewboxPoint({
      x: Math.max(0, Math.min(bbox.w, event.point.x - bbox.x)),
      y: Math.max(0, Math.min(bbox.h, event.point.y - bbox.y)),
    });

    const { onEvent } = this.props;
    onEvent({
      type: 'zoom',
      mode: event.mode,
      delta: event.delta,
      point: origin,
    });
  }

  @bind()
  private handleMoveEvent(event: SchemaEventCanvasMove): void {
    const { scale } = this.state;

    const delta = {
      x: event.delta.x / scale.a,
      y: event.delta.y / scale.a,
    };

    const { onEvent } = this.props;
    onEvent({
      type: 'move',
      delta: delta,
    });
  }

  private getViewboxPoint(bboxPoint: Point): Point {
    const { scale } = this.state;

    return pointTransform(
      bboxPoint,
      matrixInverse(scale),
    );
  }

  private getScaleMatrix(bbox: BBox): Matrix {
    const { width, height } = this.props;

    const viewBoxRatio = bbox.w / bbox.h;
    const contentRatio = width / height;

    const scale = contentRatio > viewBoxRatio
      ? bbox.w / width
      : bbox.h / height;
    const delta = {
      x: (bbox.w - width) / 2,
      y: (bbox.h - height) / 2,
    };
    const center = {
      x: width / 2,
      y: height / 2,
    };

    return matrixMultiply(
      matrixTranslate(delta),
      matrixScale(scale, center),
    );
  }
}
