import './OnboardingModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { IS_ONBOARDING_MODAL_VIEWED } from 'src/modules/common/constants/localStorageKeys';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { BodyText } from 'src/modules/common/typography/BodyText';
import { TitleText } from 'src/modules/common/typography/TitleText';

type Props = {
  handleClose: () => void;
};

export const OnboardingModal = memo(({ handleClose }: Props): React.ReactElement => {
  const intl = useIntl();

  const [step, setStep] = useState(1);

  const maxStep = 5;

  useEffect(() => () => {
    // set item on unmount
    localStorage.setItem(IS_ONBOARDING_MODAL_VIEWED, JSON.stringify(true));
  }, []);

  return (
    <>
      <ModalHeader
        labelClassName="bp-onboarding-modal__label"
        titleClassName="bp-onboarding-modal__title"
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        buttonOnClick={handleClose}
        label={intl.formatMessage({ id: 'projectList/onboarding/label' })}
        title={intl.formatMessage({ id: 'projectList/onboarding/step' }, { current: step, overall: maxStep })}
      />

      <ModalBody className="bp-onboarding-modal__body">
        <img
          className="bp-onboarding-modal__image"
          alt={intl.formatMessage({ id: 'projectList/onboarding/image/alt' })}
          src={`/images/onboarding/step${step}.svg`}
        />

        <TitleText className="bp-onboarding-modal__title-body-text">
          <FormattedMessage id={`projectList/onboarding/title/step${step}`}/>
        </TitleText>

        <BodyText>
          <FormattedMessage id={`projectList/onboarding/description/step${step}`}/>
        </BodyText>
      </ModalBody>

      <ModalFooter>
        {step === 1 ? (
          <CustomButton
            kind="primary"
            autoFocus={true}
            onClick={() => setStep(2)}
          >
            <FormattedMessage id="projectList/onboarding/next"/>

            <div className="bp-onboarding-modal__button-icon">
              <ArrowRightIcon/>
            </div>
          </CustomButton>
        ) : step !== 5 ? (
          <>
            <CustomButton
              kind="secondary"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <div className="bp-onboarding-modal__button-icon">
                <ArrowLeftIcon/>
              </div>

              <FormattedMessage id="projectList/onboarding/back"/>
            </CustomButton>

            <CustomButton
              kind="primary"
              autoFocus={true}
              onClick={() => setStep((prev) => prev + 1)}
            >
              <FormattedMessage id="projectList/onboarding/next"/>

              <div className="bp-onboarding-modal__button-icon">
                <ArrowRightIcon/>
              </div>
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              kind="secondary"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <div className="bp-onboarding-modal__button-icon">
                <ArrowLeftIcon/>
              </div>

              <FormattedMessage id="projectList/onboarding/back"/>
            </CustomButton>

            <CustomButton
              kind="primary"
              autoFocus={true}
              onClick={handleClose}
            >
              <FormattedMessage id={`projectList/onboarding/${step !== 5 ? 'next' : 'finish'}`}/>

              <div className="bp-onboarding-modal__button-icon">
                <ArrowRightIcon/>
              </div>
            </CustomButton>
          </>
        )}
      </ModalFooter>
    </>
  );
});
