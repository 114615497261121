import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const EditIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M22.39 11.695 20.304 9.61l1.174-1.179a1.477 1.477 0 0 1 2.088 2.09l-1.178 1.173Zm-3.498-2.083 3.496 3.495 1.886-1.878a2.477 2.477 0 1 0-3.503-3.503l-1.879 1.886ZM7 25l2.24-5.744 8.658-8.658 3.504 3.504-8.81 8.75L7 25Zm1.754-1.745 3.283-1.26 7.949-7.896-2.088-2.087-7.802 7.803-1.342 3.44Z"
      />
    </svg>
  </IconWrapper>
));
