import './OutputTitle.scss';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

export const OutputTitle = ({ children }: Props): React.ReactElement => (
  <div className="bp-output-title">
    {children}
  </div>
);
