import { BaseError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { CancelledError } from 'src/errors/CancelledError';
import { restoreCurrentUrl } from 'src/modules/routing/sagas/restoreCurrentUrl';
import { call, cancel } from 'typed-redux-saga';

export function* cancelledErrorHandler(error: BaseError): SagaIterator<void> {
  if (!(error instanceof CancelledError)) {
    return;
  }

  yield* call(restoreCurrentUrl);
  yield* cancel();
}
