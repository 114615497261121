import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { FUTURE, Future } from 'src/modules/common/types/Future';
import {
  PROJECT_LIST_ABORT,
  PROJECT_LIST_IMPORT_CANCEL,
  PROJECT_LIST_IMPORT_COMPLETE,
  PROJECT_LIST_IMPORT_OPEN,
  PROJECT_LIST_IMPORT_SUBMIT,
  PROJECT_LIST_IMPORT_UPLOAD,
} from 'src/modules/project-list/actions/ProjectListActions';
import { ProjectListImportData } from 'src/modules/project-list/types/ProjectListImport';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';

export function projectListImportReducer(state: ProjectListState, action: Action): ProjectListState {
  if (PROJECT_LIST_IMPORT_OPEN.is(action)) {
    return {
      ...state,
      import: {
        upload: null,
        submit: null,
      },
    };
  }
  if (PROJECT_LIST_IMPORT_CANCEL.is(action)) {
    return {
      ...state,
      import: null,
    };
  }

  if (PROJECT_LIST_IMPORT_UPLOAD.isPending(action)) {
    return modifyUploadStatus(state, FUTURE.pending());
  }
  if (PROJECT_LIST_IMPORT_UPLOAD.isSuccess(action)) {
    return modifyUploadStatus(state, FUTURE.success(action.data));
  }
  if (PROJECT_LIST_IMPORT_UPLOAD.isFailure(action)) {
    return modifyUploadStatus(state, FUTURE.failure(action.data));
  }

  if (PROJECT_LIST_IMPORT_SUBMIT.isPending(action)) {
    return modifySubmitStatus(state, FUTURE.pending());
  }
  if (PROJECT_LIST_IMPORT_SUBMIT.isSuccess(action)) {
    return modifySubmitStatus(state, FUTURE.success(undefined));
  }
  if (PROJECT_LIST_IMPORT_SUBMIT.isFailure(action)) {
    return modifySubmitStatus(state, FUTURE.failure(action.data));
  }

  if (PROJECT_LIST_IMPORT_COMPLETE.is(action)) {
    return {
      ...state,
      import: null,
    };
  }

  return state;
}

function modifyUploadStatus(state: ProjectListState, status: Future<ProjectListImportData>): ProjectListState {
  return pipe(
    O.id<ProjectListState>(),
    O.prop('import'),
    O.fromNullable,
    O.prop('upload'),
    O.modify(() => status),
  )(state);
}

function modifySubmitStatus(state: ProjectListState, status: Future<void>): ProjectListState {
  return pipe(
    O.id<ProjectListState>(),
    O.prop('import'),
    O.fromNullable,
    O.prop('submit'),
    O.modify(() => status),
  )(state);
}
