import './DocumentLayout.scss';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
};

export const DocumentLayout = ({ children }: Props): React.ReactElement => (
  <div className="bp-document-layout">
    {children}
  </div>
);
