import './LoginLayout.scss';
import { Column, Grid } from '@carbon/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  readonly children: React.ReactNode;
};

export const LoginLayout = ({ children }: Props): React.ReactElement => (
  <div className="bp-login-layout">
    <img
      alt="Logo"
      src="/images/logo.svg"
      height={29}
      className="bp-login-layout__logo"
    />

    <Grid fullWidth={true} condensed={true} className="bp-login-layout__grid">
      <Column sm={4} md={4} lg={10} className="bp-login-layout__column bp-login-layout__column--gray">
        <div className="bp-login-layout__company">
          <h2 className="bp-login-layout__brand">
            <FormattedMessage id="login/mainLoginText"/>
          </h2>

          <LeadText className="bp-login-layout__description">
            <FormattedMessage id="login/subLoginText"/>
          </LeadText>
        </div>

        <img
          src="/images/LoginPagePicture.svg"
          className="bp-login-layout__picture"
          alt=""
        />
      </Column>

      <Column sm={4} md={4} lg={6} className="bp-login-layout__column bp-login-layout__column--white">
        <div className="bp-login-layout__content">
          {children}
        </div>
      </Column>
    </Grid>
  </div>
);
