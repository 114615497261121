import { Action } from 'redux';
import { PROJECT_SYNC_RESET } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { ProjectSyncState } from 'src/modules/project-sync/types/ProjectSyncState';

export function projectSyncResetReducer(state: ProjectSyncState, action: Action): ProjectSyncState {
  if (PROJECT_SYNC_RESET.is(action)) {
    return {
      ...state,
      data: null,
    };
  }

  return state;
}
