import './ScreenSizeGuard.scss';
import { ModalBody } from '@carbon/react';
import { constUndefined } from 'fp-ts/lib/function';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomModal } from 'src/modules/common/components/CustomModal';

type Props = {
  children: React.ReactNode;
};

export const ScreenSizeGuard = memo(({ children }: Props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const screenWidthChangeListener = useCallback(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', screenWidthChangeListener);

    window.screen.orientation.addEventListener('change', screenWidthChangeListener);

    return (): void => {
      window.removeEventListener('resize', screenWidthChangeListener);

      window.screen.orientation.removeEventListener('change', screenWidthChangeListener);
    };
  }, [screenWidthChangeListener]);

  return screenWidth >= supportedScreenWidth
    ? children
    : (
      <div className="bp-screen-size-guard">
        <CustomModal
          className="bp-screen-size-guard__modal"
          open={true}
          onClose={constUndefined}
          preventCloseOnClickOutside={true}
          isShaded={false}
          shouldUnmount={false}
          isFullWidth={true}
        >
          <ModalBody>
            <h2 className="bp-screen-size-guard__modal-label">
              <FormattedMessage id="screenSizeGuard/modal/label"/>
            </h2>

            <h1 className="bp-screen-size-guard__modal-title">
              <FormattedMessage id="screenSizeGuard/modal/title"/>
            </h1>

            <p className="bp-screen-size-guard__modal-description">
              <FormattedMessage id="screenSizeGuard/modal/description"/>
            </p>

            <p className="bp-screen-size-guard__modal-description">
              <FormattedMessage id="screenSizeGuard/modal/hint"/>
            </p>
          </ModalBody>
        </CustomModal>
      </div>
    );
});

const supportedScreenWidth = 1024;
