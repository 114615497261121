import {
  HvacArea,
  ProjectHvac,
  ProjectHvacAnswer,
  ProjectHvacArea,
  ProjectHvacSection,
  ProjectHvacSectionCopy,
  ProjectHvacVariant,
} from '@belimo-retrofit-portal/logic';
import { HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import {
  HvacFormAdditionalValue,
  HvacFormAssessmentData,
  HvacFormChapterData,
  HvacFormSectionAnswerData,
  HvacFormSectionCopyData,
  HvacFormSectionData,
  HvacFormVariantData,
} from 'src/modules/hvac/types/HvacFormData';

export function mapHvacFormToStore(form: HvacFormAssessmentData): ProjectHvac {
  return {
    comment: form.comment,
    prices: form.prices,
    areas: {
      [HvacArea.HEATING]: mapHvacFormChapter(form.heating),
      [HvacArea.COOLING]: mapHvacFormChapter(form.cooling),
      [HvacArea.VENTILATION]: mapHvacFormChapter(form.ventilation),
    },
  };
}

function mapHvacFormChapter(form: HvacFormChapterData): ProjectHvacArea {
  return {
    enabled: form.enabled,
    thermal: form.thermal,
    electrical: form.electrical,
    sections: form.sections.map(mapHvacFormSection),
  };
}

function mapHvacFormSection(form: HvacFormSectionData): ProjectHvacSection {
  return {
    id: form.config.id,
    title: form.title,
    enabled: form.enabled,
    answer: mapHvacFormSectionAnswer(form.answer, form.config),
    copies: form.copies.map((copy) => mapHvacFormSectionCopy(copy, form.config)),
  };
}

function mapHvacFormSectionCopy(
  form: HvacFormSectionCopyData,
  config: HvacConfigSection,
): ProjectHvacSectionCopy {
  return {
    title: form.title,
    answer: mapHvacFormSectionAnswer(form.answer, config),
  };
}

function mapHvacFormSectionAnswer(
  form: HvacFormSectionAnswerData,
  config: HvacConfigSection,
): ProjectHvacAnswer {
  return {
    actual: mapHvacFormVariant(form.actual),
    future: mapHvacFormVariant(form.future),
  };
}

function mapHvacFormVariant(form: HvacFormVariantData): ProjectHvacVariant {
  return {
    option: form.option?.id ?? null,
    additionals: form.additionals.reduce<Record<string, HvacFormAdditionalValue>>((map, it) => {
      // eslint-disable-next-line no-param-reassign
      map[it.config.id] = it.value;
      return map;
    }, {}),
  };
}
