import './DropdownField.scss';
import './Field.scss';
import { Dropdown } from '@carbon/react';
import { DropdownProps, OnChangeData } from '@carbon/react/lib/components/Dropdown/Dropdown';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';

type Option<T> = {
  value: T | null;
  label: T | string | null;
  selected: boolean;
};

type BaseProps<TValue> = Omit<
DropdownProps<TValue>,
'className' |
'id' |
'onChange' |
'onBlur' |
'label' |
'invalid' |
'invalidText' |
'items' |
'itemToString' |
'itemToElement' |
'renderSelectedItem' |
'selectedItem'
>;

type Props<TData, TValue> = BaseProps<TValue> & {
  readonly size: 'sm' | 'md' | 'lg';
  readonly theme?: 'gray' | 'white';
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, TValue | null>;
  readonly options: ReadonlyArray<TValue>;
  readonly label?: string;
  readonly outlined?: boolean;
  readonly itemToString?: (item: TValue | null) => string;
  readonly itemToElement: (item: TValue) => ReactElement;
  readonly renderSelectedItem: (item: TValue) => ReactElement;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const DropdownField = <TData, TValue>({
  size,
  theme = 'gray',
  wrapperClass,
  field,
  options,
  label,
  outlined,
  disabled,
  itemToString,
  itemToElement,
  renderSelectedItem,
  renderError = renderErrorDefault,
  ...rest
}: Props<TData, TValue>): React.ReactElement => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const emptyObject = {
    label: '',
    value: null,
    selected: true,
  };

  const composedOptions: Array<Option<TValue>> = useMemo(() => options.map((option) => ({
    label: itemToString ? itemToString(option) : option,
    value: option,
    selected: option === value,
  })), [options, itemToString, value]);

  const selectedItem: Option<TValue> | undefined = useMemo(
    () => composedOptions.find((option) => option.selected),
    [composedOptions],
  );

  const handleChange = useCallback((event: OnChangeData<Option<TValue>>) => {
    const selectedOption = event.selectedItem?.value
      ? event.selectedItem.value
      : null;

    field.form.change(pipe(
      field.form.currentState,
      field.value.set(selectedOption),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  const itemToElementHandler = useCallback(
    (option: Option<TValue>) => (
      option.value ? itemToElement(option.value) : null),
    [itemToElement],
  );

  const selectedItemHandler = useCallback(
    (option: Option<TValue>) => (option.value ? renderSelectedItem(option.value) : null),
    [renderSelectedItem],
  );

  return (
    <Dropdown
      {...rest}
      className={clsx(
        'bp-field',
        'bp-dropdown-field',
        `bp-dropdown-field--${size}`,
        `bp-dropdown-field--${theme}`,
        {
          'bp-dropdown-field--outlined': outlined,
          'bp-dropdown-field--invalid': invalid,
          'bp-dropdown-field--disabled': disabled,
        },
        wrapperClass,
      )}
      id={`${field.form.name}.${field.path}`}
      label={label || ''}
      disabled={disabled}
      invalid={invalid}
      invalidText={error && invalid ? renderError(error) : null}
      items={composedOptions}
      selectedItem={selectedItem || emptyObject}
      initialSelectedItem={selectedItem}
      downshiftProps={{}}
      onChange={handleChange}
      onBlur={handleBlur}
      itemToElement={itemToElementHandler}
      renderSelectedItem={selectedItemHandler}
    />
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error?.code}`} values={error?.context}/>
);
