import { matchPath } from 'react-router';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { assertNotNull } from 'src/utils/assert';

type StateTreeItem = {
  readonly url: string;
  readonly state: RouteState;
  readonly parent: StateTreeItem | null;
};

export function matchState(
  states: ReadonlyArray<RouteState>,
  pathname: string,
): RouteMatch | null {
  const stateTree = createStateTree(states, null);
  for (const statePath of stateTree) {
    const stateMatch = matchPath(pathname, { path: statePath.url, strict: true, sensitive: true, exact: true });
    if (!stateMatch) {
      continue;
    }

    return statePathToMatch(statePath, pathname);
  }

  return null;
}

function statePathToMatch(
  statePath: StateTreeItem,
  pathname: string,
): RouteMatch {
  const stateMatch = assertNotNull(
    matchPath(pathname, { path: statePath.url, strict: true, sensitive: true, exact: false }),
    `Invalid route state "${statePath.state.name}" configuration`,
    { statePath, pathname },
  );

  return {
    state: statePath.state,
    params: stateMatch.params,
    parent: statePath.parent
      ? statePathToMatch(statePath.parent, pathname)
      : null,
  };
}

function createStateTree(
  states: ReadonlyArray<RouteState>,
  parent: StateTreeItem | null,
): StateTreeItem[] {
  const parentName = parent
    ? parent.state.name
    : null;

  // FIXME: seems to be a conflict between `indent` and `@typescript-eslint/indent` rules
  // https://github.com/typescript-eslint/typescript-eslint/issues/121#issuecomment-512177263
  return states
    .filter((state) => state.parent === parentName)
    .reduce((result: StateTreeItem[], state: RouteState) => {
      const treeItem: StateTreeItem = {
        state: state,
        parent: parent,
        url: parent
          ? parent.url + state.url
          : state.url,
      };

      const children = createStateTree(states, treeItem);
      return children.length
        ? [...children, ...result]
        : [treeItem, ...result];
    }, []);
}
