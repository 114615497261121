import './EnergySourceUnit.scss';
import { EnergySource } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';

type Props = {
  readonly source: EnergySource;
};

export const EnergySourceUnit = ({ source }: Props): React.ReactElement => {
  if (source === EnergySource.HEATING_OIL) {
    return (
      <span className="bp-energy-source-unit">
        <FormattedMessage id="unit/liter" values={{ sub, sup }}/>
      </span>
    );
  }

  return (
    <span className="bp-energy-source-unit">
      <FormattedMessage id="unit/kilowatt_hour" values={{ sub, sup }}/>
    </span>
  );
};
