import { ParametricSelector, Selector } from 'reselect';
import { RootState } from 'src/types/RootState';

export function createParameterSelector<TParam>(): ParametricSelector<RootState, TParam, TParam> {
  return (state, param) => param;
}

export function createConditionalSelector<TThen, TElse>(
  condition: Selector<RootState, boolean>,
  thenSelector: Selector<RootState, TThen>,
  elseSelector: Selector<RootState, TElse>,
): Selector<RootState, TThen | TElse>;

export function createConditionalSelector<TThen, TElse, TParam>(
  condition: ParametricSelector<RootState, TParam, boolean>,
  thenSelector: ParametricSelector<RootState, TParam, TThen>,
  elseSelector: ParametricSelector<RootState, TParam, TElse>,
): ParametricSelector<RootState, TParam, TThen | TElse>;

export function createConditionalSelector<TThen, TElse, TParam>(
  condition: ParametricSelector<RootState, TParam, boolean>,
  thenSelector: ParametricSelector<RootState, TParam, TThen>,
  elseSelector: ParametricSelector<RootState, TParam, TElse>,
): ParametricSelector<RootState, TParam, TThen | TElse> {
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  return (state, param, ...args) => (condition(state, param, ...args)
    ? thenSelector(state, param, ...args)
    : elseSelector(state, param, ...args));
}

export function createChainedSelector<TResult1, TResult2>(
  selector1: Selector<RootState, TResult1>,
  selector2: ParametricSelector<RootState, TResult1, TResult2>,
): Selector<RootState, TResult2>;

export function createChainedSelector<TResult1, TResult2, TParam>(
  selector1: ParametricSelector<RootState, TParam, TResult1>,
  selector2: ParametricSelector<RootState, TResult1, TResult2>,
): ParametricSelector<RootState, TParam, TResult2>;

export function createChainedSelector<TResult1, TResult2, TParam>(
  selector1: ParametricSelector<RootState, TParam, TResult1>,
  selector2: ParametricSelector<RootState, TResult1, TResult2>,
): ParametricSelector<RootState, TParam, TResult2> {
  return (state, param) => selector2(state, selector1(state, param));
}
