import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { PASSWORD_RESET_TOKEN } from 'src/modules/password-reset/actions/PasswordResetActions';
import { validateRecoveryToken } from 'src/modules/password-reset/sagas/utils/validateRecoveryToken';
import { Transition } from 'src/modules/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchPasswordResetToken(transition: Transition): SagaIterator<void> {
  const params = new URLSearchParams(transition.to.location.search);
  const token = params.get('token') ?? '';

  try {
    yield* call(logDebug, 'Checking password reset token...');

    yield* call(validateRecoveryToken, token);

    yield* call(logDebug, 'Checking password reset token... done');
    yield* put(PASSWORD_RESET_TOKEN.trigger(token));
  } catch (error) {
    yield* call(sentryCatch, error);
    yield* call(logError, 'Checking password reset token... error');
    yield* put(PASSWORD_RESET_TOKEN.trigger(null));
  }
}
