import { LogicError, NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import React, { cloneElement, useEffect, useState } from 'react';
import { CancelledError } from 'src/errors/CancelledError';
import { HttpError } from 'src/errors/HttpError';
import { parseSvgFile } from 'src/modules/common/utils/svg';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';

type Props = {
  readonly urls: ReadonlyArray<string>;
  readonly width: number;
  readonly height: number;
};

export const SvgFile = React.memo(
  ({ urls, width, height }: Props): React.ReactElement | null => {
    const [element, setElement] = useState<React.ReactElement | null>(null);

    useEffect(() => {
      const abort = new AbortController();
      const fallback = <svg visibility="hidden" pointerEvents="none"/>;

      fetchAnySvgFile(urls, abort.signal)
        .then((result) => setElement(result ?? fallback))
        .catch((error) => {
          if (error instanceof CancelledError) {
            return;
          }

          setElement(fallback);
          sentryCatch(error);
        });

      return () => {
        abort.abort();
      };
    }, [urls]);

    if (element) {
      return cloneElement(element, {
        x: 0,
        y: 0,
        width: width,
        height: height,
        'data-ready': 'true',
      });
    }

    return (
      <svg
        width={width}
        height={height}
        data-ready="false"
        visibility="hidden"
        pointerEvents="none"
      />
    );
  },
  (prevProps: Props, nextProps: Props) => (
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.urls.every((url, index) => url === nextProps.urls[index])
  ),
);

async function fetchSvgFile(url: string, signal: AbortSignal): Promise<React.ReactElement | null> {
  try {
    const response = await fetch(url, {
      signal: signal,
      mode: 'no-cors',
      redirect: 'error',
      credentials: 'omit',
    });

    if (response.ok) {
      const content = await response.text();
      return parseSvgFile(content);
    }
    if (response.status === 404) {
      return null;
    }

    throw new HttpError(response);
  } catch (error) {
    const wrapped = NativeError.wrap(error);
    if (wrapped.name === 'AbortError') {
      throw new CancelledError('SVG file loading cancelled', { url }, wrapped);
    } else {
      throw new RuntimeError('Could not fetch SVG file', { url }, wrapped);
    }
  }
}

async function fetchAnySvgFile(urls: ReadonlyArray<string>, signal: AbortSignal): Promise<React.ReactElement | null> {
  for (const url of urls) {
    const file = await fetchSvgFile(url, signal);
    if (file) {
      return file;
    }
  }

  throw new LogicError('Could not fetch any of SVG files', { urls });
}
