import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { PROJECT_LIST_IMPORT_SUBMIT } from 'src/modules/project-list/actions/ProjectListActions';
import { importProjects } from 'src/modules/project-list/sagas/utils/importProjects';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* projectListImportSubmitSaga(
  action: GetRequestActionType<typeof PROJECT_LIST_IMPORT_SUBMIT>,
): SagaIterator<void> {
  const entries = action.data;

  try {
    yield* put(PROJECT_LIST_IMPORT_SUBMIT.pending());
    yield* call(logDebug, 'Importing projects...');

    const projects = yield* call(importProjects, entries);

    yield* call(logDebug, 'Importing projects... done', projects);
    yield* put(PROJECT_LIST_IMPORT_SUBMIT.success());
  } catch (error) {
    const wrapped = new RuntimeError('Could not import projects', { entries }, NativeError.wrap(error));
    yield* call(sentryCatch, wrapped);

    yield* call(logError, 'Importing projects... error', error);
    yield* put(PROJECT_LIST_IMPORT_SUBMIT.failure(NativeError.wrap(error)));
  }
}
