import { NativeError } from '@belimo-retrofit-portal/logic';
import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { LOGIN_PERFORM } from 'src/modules/login/actions/LoginActions';
import { LOGIN_FORM_SCHEMA } from 'src/modules/login/constants/schema';
import { performLogin } from 'src/modules/login/sagas/utils/performLogin';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* loginPerformSaga(action: GetRequestActionType<typeof LOGIN_PERFORM>): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  const valid = yield* call(formValidate, form, LOGIN_FORM_SCHEMA);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Logging in...');
    yield* put(LOGIN_PERFORM.pending());
    yield* call(formSubmitStart, form);
    yield* delay(500);

    const user = yield* call(performLogin, values);
    yield* put(replace('/'));

    yield* call(logDebug, 'Logging in... done', user);
    yield* put(LOGIN_PERFORM.success(user));

    // no need to unlock the form until router transition is completed
    // so there is no `formSubmitSuccess` call
  } catch (error) {
    yield* call(logError, 'Logging in... error', error);
    yield* put(LOGIN_PERFORM.failure(NativeError.wrap(error)));

    yield* call(formSubmitFailure, form, {
      username: {
        code: 'invalid',
        path: 'username',

        value: values.username,
        message: 'Invalid credentials',
        context: {},
      },
      password: {
        code: 'invalid',
        path: 'password',

        value: values.password,
        message: 'Invalid credentials',
        context: {},
      },
    });
  }
}
