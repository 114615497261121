import './CustomBreadcrumbItem.scss';
import { BreadcrumbItem } from '@carbon/react';
import React, { memo } from 'react';

type Props = {
  children: React.ReactNode;
};

export const CustomBreadcrumbItem = memo(({ children }: Props) => (
  <BreadcrumbItem className="bp-custom-breadcrumb-item">
    {children}
  </BreadcrumbItem>
));
