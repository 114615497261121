import './CarbonIntensityUnit.scss';
import { EnergySource } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EnergySourceUnit } from 'src/modules/common/components/EnergySourceUnit';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';

type Props = {
  readonly source: EnergySource;
};

export const CarbonIntensityUnit = ({ source }: Props): React.ReactElement => (
  <span className="bp-carbon-intensity-unit">
    <FormattedMessage id="unit/gram_CO2" values={{ sub, sup }}/>
    <span className="bp-carbon-intensity-unit__div">/</span>
    <EnergySourceUnit source={source}/>
  </span>
);
