import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CommentIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M6.65 13.35h6.7v-.7h-6.7v.7Zm0-3h10.7v-.7H6.65v.7Zm0-3h10.7v-.7H6.65v.7ZM3.3 19.5V4.8c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425h14.4c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075v10.4c0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425H6.1l-2.8 2.8ZM5.8 16h13.4c.2 0 .383-.083.55-.25.167-.167.25-.35.25-.55V4.8c0-.2-.083-.383-.25-.55-.167-.167-.35-.25-.55-.25H4.8c-.2 0-.383.083-.55.25-.167.167-.25.35-.25.55v13L5.8 16Z"
      />
    </svg>
  </IconWrapper>
));
