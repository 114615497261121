import { shareProjectDataReducer } from 'src/modules/sharing-projects/reducers/shareProjectDataReducer';
import { shareProjectRemoveReducer } from 'src/modules/sharing-projects/reducers/shareProjectRemoveReducer';
import { shareProjectUsersReducer } from 'src/modules/sharing-projects/reducers/shareProjectUsersReducer';
import { ShareProjectState } from 'src/modules/sharing-projects/types/ShareProjectState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ShareProjectState = {
  data: null,
};

export const shareProjectReducer = chainReducers(defaultState, [
  shareProjectDataReducer,
  shareProjectUsersReducer,
  shareProjectRemoveReducer,
]);
