import { ProjectData, SchemaArea } from '@belimo-retrofit-portal/logic';

export function resolveReportSchemaSelection(
  prevProjectData: ProjectData,
  nextProjectData: ProjectData,
  area: SchemaArea,
): ProjectData {
  const isSchemaCommentArea = Boolean(nextProjectData.schema[area]?.comment.length);

  return {
    ...nextProjectData,
    report: {
      ...nextProjectData.report,
      schema: {
        ...nextProjectData.report.schema,
        [area]: {
          ...nextProjectData.report.schema[area],
          showComments: isSchemaCommentArea ? nextProjectData.report.schema[area].showComments : false,
        },
      },
    },
  };
}
