import './HvacResultSavingsExplanation.scss';
import { HvacResultsOverall } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BottomNavigationHelper } from 'src/modules/common/components/BottomNavigationHelper';
import { FormattedAmount } from 'src/modules/common/components/FormattedAmount';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { SavingsAmount } from 'src/modules/common/components/SavingsAmount';
import { WarningIcon } from 'src/modules/common/icons/WarningIcon';
import { BodyDesktopSmallLightText } from 'src/modules/common/typography/BodyDesktopSmallLightText';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { TitleText } from 'src/modules/common/typography/TitleText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly projectId: string;
  readonly form: FormController<HvacFormData, FormError>;
  readonly results: HvacResultsOverall;
  readonly inaccurate: boolean;
};

export const HvacResultSavingsExplanation = ({ projectId, form, results, inaccurate }: Props): React.ReactElement => {
  const intl = useIntl();

  const projectBaseUrl = `/project/${encodeURIComponent(projectId)}`;
  const projectSchemaUrl = `/project/${encodeURIComponent(projectId)}/schema`;

  const currency = useFormWatch(form, form.field.at('currency').value.get);

  return (
    <div className="bp-hvac-result-savings-explanation">
      <div className="bp-hvac-result-savings-explanation__tile">
        <div className="bp-hvac-result-savings-explanation__savings">
          <LeadText className="bp-hvac-result-savings-explanation__savings-text">
            <FormattedMessage id="hvac/result/savings/potential/total"/>
          </LeadText>

          <TitleText>
            <SavingsAmount
              value={results.savings.overall.annual}
              currency={currency}
            />
          </TitleText>
        </div>

        {inaccurate && (
          <div className="bp-hvac-result-savings-explanation__warning">
            <div className="bp-hvac-result-savings-explanation__warning-icon">
              <WarningIcon/>
            </div>
            <div className="bp-hvac-result-savings-explanation__warning-text">
              <FormattedMessage id="hvac/result/savings/potential/warning"/>
            </div>
          </div>
        )}

        <HorizontalDivider/>

        <div className="bp-hvac-result-savings-explanation__costs">
          <BodyDesktopSmallLightText className="bp-hvac-result-savings-explanation__savings-text">
            <FormattedMessage id="hvac/result/savings/potential/costs"/>
          </BodyDesktopSmallLightText>

          <BodyDesktopSmallLightText>
            <FormattedAmount
              value={results.energyCosts.overall}
              currency={currency}
            />
          </BodyDesktopSmallLightText>
        </div>
      </div>

      <BottomNavigationHelper
        routeBackText={intl.formatMessage({ id: 'common/bottomNavigation/basedata' })}
        routeBackPath={projectBaseUrl}
        routeForthText={intl.formatMessage({ id: 'common/bottomNavigation/schema' })}
        routeForthPath={projectSchemaUrl}
      />

      <BodySmallText className="bp-hvac-result-savings-explanation__disclaimer-text">
        <FormattedMessage id="hvac/result/savings/potential/disclaimer"/>
      </BodySmallText>
    </div>
  );
};
