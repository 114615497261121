import { Integer } from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import { INTEGER_FROM_STRING } from 'src/io/codecs/IntegerFromString';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { UsersListQuery } from 'src/modules/users/types/UsersListQuery';

const PAGE_NUMBER: D.Decoder<unknown, Integer> = pipe(
  INTEGER_FROM_STRING,
  D.parse((input) => (input >= 1 ? D.success(input) : D.failure(input, 'PageNumber'))),
);

export function parseUsersListSearchParams(searchParams: URLSearchParams): UsersListQuery {
  const page = decodeOrGet(PAGE_NUMBER, searchParams.get('page'), INTEGER_ONE);
  return { page };
}

export function buildUsersListSearchParams({ page }: UsersListQuery): URLSearchParams {
  const searchParams = new URLSearchParams();

  if (page > 0) {
    searchParams.set('page', `${page}`);
  }

  searchParams.sort();
  return searchParams;
}
