import { UUID } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ASSET } from 'src/modules/common/codecs/Asset';
import { Organization } from 'src/modules/common/types/Organization';

export const ORGANIZATION: C.Codec<unknown, Json, Organization> = C.struct({
  id: UUID,
  logo: C.nullable(ASSET),
  name: C.string,
});
