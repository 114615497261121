import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { PROJECT_SYNC_PERFORM } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { waitProjectSynchronization } from 'src/modules/project-sync/sagas/utils/waitProjectSynchronization';
import { call, put } from 'typed-redux-saga';

export function* syncProjectForeground(project: Project): SagaIterator<Project> {
  yield* put(PROJECT_SYNC_PERFORM.request('foreground', project));
  return yield* call(waitProjectSynchronization, project);
}
