import './ErrorBoundary.scss';
import { BaseError, NativeError } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { ErrorMessage } from 'src/modules/error/views/ErrorMessage';

type Props = {
  readonly children: React.ReactNode;
};
type State = {
  readonly error: BaseError | undefined;
  readonly errorInfo: React.ErrorInfo | undefined;
};

export class ErrorBoundary extends React.Component<Props, State> {
  public readonly state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    sentryCatch(error);

    this.setState(() => ({
      error: NativeError.wrap(error),
      errorInfo: errorInfo,
    }));
  }

  public render(): React.ReactNode {
    const { error, errorInfo } = this.state;
    if (!error || !errorInfo) {
      const { children } = this.props;
      return children;
    }

    return (
      <div className="bp-error-boundary">
        <ErrorMessage
          error={error}
          errorInfo={errorInfo}
        />
      </div>
    );
  }
}
