import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ResetIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M12.1 18.7c-1.87 0-3.455-.649-4.753-1.946S5.4 13.874 5.4 12.004 6.049 8.55 7.347 7.25C8.645 5.95 10.23 5.3 12.1 5.3c1.2 0 2.317.296 3.35.888A6.498 6.498 0 0 1 17.9 8.65V5.3h.7V10h-4.7v-.7h3.55a5.972 5.972 0 0 0-2.2-2.412A5.84 5.84 0 0 0 12.1 6c-1.667 0-3.083.583-4.25 1.75C6.683 8.917 6.1 10.333 6.1 12c0 1.667.583 3.083 1.75 4.25C9.017 17.417 10.433 18 12.1 18a5.862 5.862 0 0 0 3.475-1.1A5.81 5.81 0 0 0 17.75 14h.75c-.433 1.417-1.238 2.554-2.413 3.413-1.175.858-2.504 1.287-3.987 1.287Z"
      />
    </svg>
  </IconWrapper>
));
