import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { saveAs } from 'file-saver';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectForeground } from 'src/modules/project-sync/sagas/utils/syncProjectForeground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { REPORT_GENERATE } from 'src/modules/report/actions/ReportActions';
import { buildReportDocument } from 'src/modules/report/sagas/utils/buildReportDocument';
import { getReportGenerated } from 'src/modules/report/selectors/getReportGenerated';
import { getReportDocumentFilename } from 'src/modules/report/utils/getReportDocumentFilename';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* reportGenerateSaga(
  action: GetRequestActionType<typeof REPORT_GENERATE>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* put(REPORT_GENERATE.pending());
    yield* call(logDebug, 'Generating report document...', formData);

    const previous = yield* select(getProjectDetails);
    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        report: formData,
      },
    };

    const reloaded = yield* call(syncProjectForeground, modified);
    const document = yield* select(getReportGenerated, reloaded);

    const fileData = yield* call(
      buildReportDocument,
      document,
      formData,
      modified,
    );
    const fileName = yield* call(
      getReportDocumentFilename,
      document,
    );

    yield* call(
      saveAs,
      fileData,
      `${fileName}.pdf`,
    );

    yield* call(logDebug, 'Generating report document... done', document);
    yield* put(REPORT_GENERATE.success());
  } catch (error) {
    const wrapped = new RuntimeError('Could not generate report document', { formData }, NativeError.wrap(error));
    yield* call(logError, 'Generating report document... error', error);
    yield* put(REPORT_GENERATE.failure(wrapped));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
