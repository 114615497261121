import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { SCHEMA_SELECTION_FILTER_CHANGE } from 'src/modules/schema/actions/SchemaActions';
import { saveSchemaSelection } from 'src/modules/schema/sagas/saveSchemaSelection';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* schemaSelectionChangeSaga(
  action: GetRequestActionType<typeof SCHEMA_SELECTION_FILTER_CHANGE>,
): SagaIterator<void> {
  const { data, meta: area } = action;
  try {
    yield* call(logDebug, 'Save preselected schema...');
    yield* put(SCHEMA_SELECTION_FILTER_CHANGE.pending(area));

    const saved = yield* call(saveSchemaSelection, data, area);

    yield* call(logDebug, 'Save preselected schema... done');

    yield* put(SCHEMA_SELECTION_FILTER_CHANGE.success(saved, area));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Save preselected schema... error', { error });
    yield* put(SCHEMA_SELECTION_FILTER_CHANGE.failure(NativeError.wrap(error), area));
  }
}
