import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { FUTURE, Future } from 'src/modules/common/types/Future';
import {
  USER_DETAILS_RESET,
  USER_REMOVE_CANCEL,
  USER_REMOVE_CONFIRM,
  USER_REMOVE_PROCESS,
} from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

const modifyStatus = (state: UsersState, status: Future<void>): UsersState => pipe(
  O.id<UsersState>(),
  O.prop('removing'),
  O.fromNullable,
  O.prop('status'),
  O.modify(() => status),
)(state);

export function userRemovingReducer(state: UsersState, action: Action): UsersState {
  if (USER_REMOVE_PROCESS.is(action)) {
    return {
      ...state,
      removing: {
        user: action.data,
        status: null,
      },
    };
  }
  if (USER_REMOVE_CANCEL.is(action)) {
    return {
      ...state,
      removing: null,
    };
  }
  if (USER_DETAILS_RESET.is(action)) {
    return {
      ...state,
      removing: null,
    };
  }
  if (USER_REMOVE_CONFIRM.isPending(action)) {
    return modifyStatus(state, FUTURE.pending());
  }
  if (USER_REMOVE_CONFIRM.isSuccess(action)) {
    return {
      ...state,
      removing: null,
    };
  }
  if (USER_REMOVE_CONFIRM.isFailure(action)) {
    return {
      ...state,
      removing: null,
    };
  }
  return state;
}
