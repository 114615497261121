import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { SCHEMA_MATRIX_CHANGE } from 'src/modules/schema/actions/SchemaActions';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* schemaMatrixChangeSaga(
  action: GetTriggerActionType<typeof SCHEMA_MATRIX_CHANGE>,
): SagaIterator<void> {
  const matrix = action.data;
  const area = action.meta;

  try {
    yield* call(logDebug, 'Saving schema matrix...', matrix);

    const previous = yield* select(getProjectDetails);

    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        schema: {
          ...previous.data.schema,
          [area]: {
            ...previous.data.schema[area],
            matrix,
          },
        },
      },
    };

    const reloaded = yield* call(syncProjectBackground, modified);

    yield* call(logDebug, 'Saving schema matrix... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save schema version', { matrix }, NativeError.wrap(error));
    yield* call(logError, 'Saving schema version... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
