export enum DeviceType {
  THROTTLING_CIRCUIT = 'throttling-circuit',
  DIVERTING_CIRCUIT = 'diverting-circuit',
  INJECTION_CIRCUIT = 'injection-circuit',
  MIXING_CIRCUIT = 'mixing-circuit',
  BYPASS = 'bypass',
  PUMP = 'pump',
  ACTIVE_OR_PASSIVE_CIRCUIT = 'active-or-passive-circuit',
  SPECIFIC_CIRCUIT = 'specific-circuit',
  CHANGEOVER = 'changeover',
}
