import { createSelector } from 'reselect';
import { getReportCurrency } from 'src/modules/report/selectors/getReportCurrency';
import {
  getReportGeneratedSavingsCarbonEmission,
} from 'src/modules/report/selectors/getReportGeneratedSavingsCarbonEmission';
import {
  getReportGeneratedSavingsEnergyAbsolute,
} from 'src/modules/report/selectors/getReportGeneratedSavingsEnergyAbsolute';
import {
  getReportGeneratedSavingsEnergyRelative,
} from 'src/modules/report/selectors/getReportGeneratedSavingsEnergyRelative';
import { getReportGeneratedSavingsTotalCost } from 'src/modules/report/selectors/getReportGeneratedSavingsTotalCost';
import { ReportGeneratedSavingsAnnual } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsAnnualSavings = createSelector([
  getReportCurrency,
  getReportGeneratedSavingsTotalCost,
  getReportGeneratedSavingsEnergyRelative,
  getReportGeneratedSavingsEnergyAbsolute,
  getReportGeneratedSavingsCarbonEmission,
], (currency, totalCost, energyRelative, energyAbsolute, carbonEmission): ReportGeneratedSavingsAnnual => ({
  currency,
  totalCost,
  energyRelative,
  energyAbsolute,
  carbonEmission,
}));
