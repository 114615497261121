import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getTranslator } from 'src/modules/config/selectors/getTranslator';
import { isNotNull } from 'src/utils/guard';

export const getProjectListItemTextBlocks = createSelector([
  getProjectLazy,
  getTranslator,
], (project, translator): ReadonlyArray<string> => [
  project.data.title,
  project.data.goals,

  ...project.owner === null ? [] : [
    project.owner.email,
    project.owner.firstName,
    project.owner.lastName,
  ],

  project.data.participants.consutlingEngineer,
  project.data.participants.propertyManager,
  project.data.participants.productManager,

  translator(`common/buildingType/${project.data.building.type}`),
  translator(`common/country/name/${project.data.building.address.country}`),
  project.data.building.address.city,
  project.data.building.address.zip,
  project.data.building.address.street,

  project.data.hvac.comment,

  ...SCHEMA_AREAS
    .map((area) => project.data.schema[area])
    .filter(isNotNull)
    .map((schema) => schema.comment),

  project.data.evaluation.proposalDescription,
]);

const SCHEMA_AREAS = [
  SchemaArea.HEATING,
  SchemaArea.COOLING,
  SchemaArea.VENTILATION,
];
