import { BaseError } from '@belimo-retrofit-portal/logic';

export type Future<TValue> =
    | { readonly state: 'pending' }
    | { readonly state: 'success'; readonly value: TValue }
    | { readonly state: 'failure'; readonly error: BaseError };

export const FUTURE = {
  pending(): Future<never> {
    return { state: 'pending' };
  },
  failure(error: BaseError): Future<never> {
    return { state: 'failure', error: error };
  },
  success<TValue>(value: TValue): Future<TValue> {
    return { state: 'success', value: value };
  },
};
