import './HvacFormSectionContent.scss';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ResetIcon } from 'src/modules/common/icons/ResetIcon';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacConfigOption, HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormData, HvacFormSectionAnswerData } from 'src/modules/hvac/types/HvacFormData';
import { HvacFormSectionAdditionals } from 'src/modules/hvac/views/HvacFormSectionAdditionals';
import { HvacFormSectionFormBlock } from 'src/modules/hvac/views/HvacFormSectionFormBlock';
import { HvacFormSetupField } from 'src/modules/hvac/views/HvacFormSetupField';
import { HvacFormSetupOption } from 'src/modules/hvac/views/HvacFormSetupOption';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormSectionAnswerData>;
  readonly disabled: boolean;
  readonly config: HvacConfigSection;
  readonly onClear: () => void;
};

export const HvacFormSectionContent = ({
  path,
  disabled,
  config,
  onClear,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const { value: answer } = useFormField(path);

  return (
    <div className="bp-hvac-form-section-content">
      <div className="bp-hvac-form-section-content__title-wrapper">
        <BodySmallText className="bp-hvac-form-section-content__title">
          {config.explanation !== null && (
            <FormattedMessage id={config.explanation}/>
          )}
        </BodySmallText>

        <CustomButton
          kind="tertiary"
          type="button"
          onClick={onClear}
          disabled={disabled}
          size="md"
        >
          <FormattedMessage id="hvac/form/section/clear"/>

          <div className="bp-hvac-form-section-content__button-icon">
            <ResetIcon/>
          </div>
        </CustomButton>
      </div>

      <HvacFormSectionFormBlock
        title={<FormattedMessage id="hvac/form/common/actual"/>}
        level={answer.actual.option?.class ?? null}
      >
        <HvacFormSetupField
          options={config.option}
          field={path.at('actual').at('option')}
          disabled={disabled}
          title={intl.formatMessage({ id: 'hvac/form/setup/actual' })}
          renderOption={renderActualSetupOption}
          renderSelectedOption={renderActualSetupOptionSelected}
        />

        <HvacFormSectionAdditionals
          path={path.at('actual').at('additionals')}
          disabled={disabled}
        />
      </HvacFormSectionFormBlock>

      <HvacFormSectionFormBlock
        title={<FormattedMessage id="hvac/form/common/future"/>}
        level={answer.future.option?.class ?? null}
      >
        <HvacFormSetupField
          options={config.option}
          field={path.at('future').at('option')}
          disabled={disabled}
          title={intl.formatMessage({ id: 'hvac/form/setup/future' })}
          renderOption={renderFutureSetupOption}
          renderSelectedOption={renderFutureSetupOptionSelected}
        />

        <HvacFormSectionAdditionals
          path={path.at('future').at('additionals')}
          disabled={disabled}
        />
      </HvacFormSectionFormBlock>
    </div>
  );
};

function renderActualSetupOption(
  option: HvacConfigOption,
): React.ReactElement {
  return (
    <HvacFormSetupOption
      label={<FormattedMessage id={option.title}/>}
      comment={option.explanation && <FormattedMessage id={option.explanation}/>}
      renderComment={true}
      renderEfficiencyLabel={true}
      energyEfficiencyClass={option.class}
    />
  );
}

function renderActualSetupOptionSelected(
  option: HvacConfigOption,
): React.ReactElement {
  return (
    <HvacFormSetupOption
      label={<FormattedMessage id={option.title}/>}
      comment={option.explanation && <FormattedMessage id={option.explanation}/>}
      renderComment={false}
      renderEfficiencyLabel={false}
    />
  );
}

function renderFutureSetupOption(
  option: HvacConfigOption,
): React.ReactElement {
  return (
    <HvacFormSetupOption
      label={<FormattedMessage id={option.title}/>}
      comment={option.explanation && <FormattedMessage id={option.explanation}/>}
      renderComment={true}
      renderEfficiencyLabel={true}
      energyEfficiencyClass={option.class}
    />
  );
}

function renderFutureSetupOptionSelected(
  option: HvacConfigOption,
): React.ReactElement {
  return (
    <HvacFormSetupOption
      label={<FormattedMessage id={option.title}/>}
      comment={option.explanation && <FormattedMessage id={option.explanation}/>}
      renderComment={false}
      renderEfficiencyLabel={false}
    />
  );
}
