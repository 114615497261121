import './Divider.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  orientation: 'horizontal' | 'vertical';
};

export const Divider = ({ orientation }: Props): React.ReactElement => (
  <div
    className={clsx('bp-divider', {
      'bp-divider--horizontal': orientation === 'horizontal',
      'bp-divider--vertical': orientation === 'vertical',
    })}
  />
);
