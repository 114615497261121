import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorPage } from 'src/modules/error/pages/ErrorPage';
import { getRoutingState } from 'src/modules/routing/selectors/getRoutingState';
import { RouterLoader } from 'src/modules/routing/views/RouterLoader';

type Props = {
  readonly children: React.ReactElement;
};

export const Initialization = ({ children }: Props): React.ReactElement => {
  const routing = useSelector(getRoutingState);

  if (routing.transitionError) {
    return <ErrorPage error={routing.transitionError}/>;
  }
  if (routing.currentState) {
    return children;
  }

  return <RouterLoader/>;
};
