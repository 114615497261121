import { SagaIterator } from 'redux-saga';
import { Transition } from 'src/modules/routing/types/Transition';
import { TransitionHook } from 'src/modules/routing/types/TransitionHook';
import { logDebug } from 'src/sagas/utils/logging';
import { call } from 'typed-redux-saga';

export function createGlobalTransitionLoggingSaga(stage: string): TransitionHook {
  return function* saga(transition: Transition): SagaIterator<void> {
    yield* call(logDebug, `ROUTER. ${stage}`, { transition });
  };
}
