import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { constFalse, constTrue } from 'fp-ts/function';
import { and, or, Predicate } from 'fp-ts/Predicate';
import { FACTOR_WEIGHTS_LABELS } from 'src/modules/common/constants/factorWeightedLabel';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { ProjectListItem } from 'src/modules/project-list/types/ProjectListItem';

export function getProjectFilter(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  return buildAndCondition([
    getPredicateTerm(filter),
    getPredicateBuildingType(filter),
    getPredicateCountry(filter),
    getPredicateCity(filter),
    getPredicateRatingActual(filter),
    getPredicateRatingFuture(filter),
    getPredicateOwnership(filter),
  ]);
}

function getPredicateTerm(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = getPredicateStringMatch(filter.term);
  return (item) => predicate(item.textBlocks);
}

function getPredicateBuildingType(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = getPredicateAnyOf(filter.buildingType);
  return (item) => predicate(item.project.data.building.type);
}

function getPredicateCountry(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = getPredicateAnyOf(filter.country);
  return (item) => predicate(item.project.data.building.address.country);
}

function getPredicateCity(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = getPredicateAnyOf(filter.city);
  return (item) => predicate(item.project.data.building.address.city);
}

function getPredicateRatingActual(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = buildOrCondition(Array.from(filter.ratingActual).map(getPredicateEfficiencyClass));
  return (item) => predicate(item.rating.actual);
}

function getPredicateRatingFuture(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = buildOrCondition(Array.from(filter.ratingFuture).map(getPredicateEfficiencyClass));
  return (item) => predicate(item.rating.future);
}

function getPredicateOwnership(
  filter: ProjectListFilter,
): Predicate<ProjectListItem> {
  const predicate = getPredicateAnyOf(filter.ownership);
  return (item) => predicate(item.project.owner?.email ?? '');
}

function getPredicateEfficiencyClass(
  rating: EnergyEfficiencyClass,
): Predicate<Decimal | null> {
  const { minValue, maxValue } = ENERGY_EFFICIENCY_RANGE[rating];
  return (value) => (
    value !== null &&
    value.gte(minValue) &&
    value.lt(maxValue)
  );
}

function getPredicateStringMatch(
  term: string,
): Predicate<ReadonlyArray<string>> {
  const norm = term.trim().toLowerCase();
  if (norm.length === 0) {
    return constTrue;
  }

  return (value) => value.some((chunk) => chunk.toLowerCase().includes(norm));
}

function getPredicateAnyOf<T>(
  options: ReadonlySet<T>,
): Predicate<T> {
  return (value) => (options.size === 0 || options.has(value));
}

function buildOrCondition<T>(
  predicates: ReadonlyArray<Predicate<T>>,
): Predicate<T> {
  if (predicates.length === 0) {
    return constTrue;
  }

  const initial: Predicate<T> = constFalse;
  return predicates.reduce((result, predicate) => or(predicate)(result), initial);
}

function buildAndCondition<T>(
  predicates: ReadonlyArray<Predicate<T>>,
): Predicate<T> {
  const initial: Predicate<T> = constTrue;
  return predicates.reduce((result, predicate) => and(predicate)(result), initial);
}

const ENERGY_EFFICIENCY_RANGE = {
  'A+': {
    minValue: FACTOR_WEIGHTS_LABELS['A+'],
    maxValue: new Decimal(1_000_000),
  },
  A: {
    minValue: FACTOR_WEIGHTS_LABELS.A,
    maxValue: FACTOR_WEIGHTS_LABELS['A+'],
  },
  B: {
    minValue: FACTOR_WEIGHTS_LABELS.B,
    maxValue: FACTOR_WEIGHTS_LABELS.A,
  },
  C: {
    minValue: FACTOR_WEIGHTS_LABELS.C,
    maxValue: FACTOR_WEIGHTS_LABELS.B,
  },
  D: {
    minValue: FACTOR_WEIGHTS_LABELS.D,
    maxValue: FACTOR_WEIGHTS_LABELS.C,
  },
};
