import './CustomModal.scss';
import { ComposedModal, ComposedModalProps } from '@carbon/react';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { usePrevious } from 'src/modules/common/hooks/usePrevious';

type Props = ComposedModalProps & {
  isFullScreen?: boolean;
  className?: string;
  open: boolean;
  shouldUnmount: boolean;
  isShaded: boolean;
  isNarrowFooter?: boolean;
  onClose: <T>(event: T) => void;
  onKeyDown?: <T>(event: T) => void;
  children: React.ReactNode;
};

export const CustomModal = memo(
  ({
    isFullScreen,
    className,
    open,
    shouldUnmount,
    isShaded,
    isNarrowFooter,
    children,
    onClose,
    onKeyDown,
    ...props
  }: Props): React.ReactNode => {
    const [isShown, setShown] = useState(false);

    const prevOpen = usePrevious(open);

    const isMounted = useMemo(() => {
      if (shouldUnmount) {
        return isShown;
      }

      return true;
    }, [shouldUnmount, isShown]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose(event);
      }

      onKeyDown?.(event);
    }, [onClose, onKeyDown]);

    // show the modal immediately when it is open
    useEffect(() => {
      if (open) {
        setShown(true);
      }
    }, [open]);

    // unmount the modal by timeout when it is closing to preserve animation
    useEffect(() => {
      const timeout = setTimeout(() => {
        if (prevOpen && !open) {
          setShown(false);
        }
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }, [open, prevOpen]);

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    useLayoutEffect(() => {
      const scrollbarWidth = open ? window.innerWidth - document.documentElement.clientWidth : 0;

      document.documentElement.style.setProperty('--scroll-bar-width', `${scrollbarWidth}px`);
    }, [open]);

    return (
      <ComposedModal
        className={clsx(
          'bp-custom-modal',
          {
            'bp-custom-modal--full-screen': isFullScreen,
            'bp-custom-modal--shaded': isShaded,
            'bp-custom-modal--narrow-footer': isNarrowFooter,
          },
          className,
        )}
        onClose={onClose}
        onKeyDown={onKeyDown}
        open={open}
        {...props}
      >
        {isMounted ? children : null}
      </ComposedModal>
    );
  },
);
