import { BaseError } from '@belimo-retrofit-portal/logic';
import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { isHttpError } from 'src/modules/api/utils/isHttpError';
import { cancel, put } from 'typed-redux-saga';

export function* unauthorizedErrorHandler(error: BaseError): SagaIterator<void> {
  if (!isHttpError(error, 401)) {
    return;
  }

  yield* put(replace('/login'));
  yield* cancel();
}
