import './Footer.scss';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IMPRINT_LINK_MAP, TERMS_AND_PRIVACY_LINK_MAP } from 'src/modules/common/constants/links';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';

export const Footer = ():React.ReactElement => {
  const intl = useIntl();
  const language = useSelector(getLanguage);
  const imprintLink = IMPRINT_LINK_MAP[language];
  const termsAndPrivacyLink = TERMS_AND_PRIVACY_LINK_MAP[language];

  return (
    <div className="bp-footer">
      <div className="bp-footer__links">
        <a
          href="mailto:retrofit.feedback@belimo.de"
          className="bp-footer__link"
          aria-label={intl.formatMessage({ id: 'common/footer/contact' })}
        >
          <FormattedMessage id="common/footer/contact"/>
        </a>

        <a
          href={imprintLink}
          className="bp-footer__link"
          target="_blank"
          aria-label={intl.formatMessage({ id: 'common/footer/imprint' })}
          rel="noreferrer"
        >
          <FormattedMessage id="common/footer/imprint"/>
        </a>

        <a
          href={termsAndPrivacyLink}
          className="bp-footer__link"
          target="_blank"
          aria-label={intl.formatMessage({ id: 'common/footer/conditions' })}
          rel="noreferrer"
        >
          <FormattedMessage id="common/footer/conditions"/>
        </a>
      </div>

      <div className="bp-footer__address" aria-label={intl.formatMessage({ id: 'common/footer/address' })}>
        <FormattedMessage id="common/footer/address"/>
      </div>
    </div>
  );
};
