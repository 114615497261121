import { NativeError, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { call } from 'typed-redux-saga';

export function* unfollowProject(projectId: Uuid): SagaIterator<void> {
  try {
    yield* call(makeRestRequest, 'DELETE', `/projects/${encodeURIComponent(projectId)}/leave`);
  } catch (error) {
    throw new RuntimeError('Could not unfollow project', { projectId }, NativeError.wrap(error));
  }
}
