import { UUID } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';

export const SHARED_USER: D.Decoder<unknown, SharedUser> = D.struct({
  id: UUID,
  email: D.string,
  firstName: D.string,
  lastName: D.string,
});
