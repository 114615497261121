import './Notification.scss';
import { ToastNotification } from '@carbon/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { NotificationData } from 'src/modules/notifications/types/NotificationData';

type Props = {
  notifications: ReadonlyArray<NotificationData>;
  handleClose: (id: number) => void;
};

export const Notification: React.FC<Props> = ({ notifications, handleClose }) => {
  const intl = useIntl();

  return (
    <div className="bp-notification-wrapper">
      {notifications.map(({ id, type, variant, messageId, messageValue }, index) => (
        <ToastNotification
          className="bp-notification"
          key={id}
          kind={variant}
          onClose={() => handleClose(id)}
          role="status"
          title={intl.formatMessage({ id: messageId || `notificationmessage/${type}` }, messageValue)}
          timeout={3000}
        />
      ))}
    </div>
  );
};
