import './DesktopLargeText.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  as?: React.ElementType;
  children: React.ReactNode;
};

export const DesktopLargeText = (props: Props): React.ReactElement => {
  const { className, as: Cmp = 'p', children } = props;

  return (
    <Cmp className={clsx('bp-desktop-large-text', className)}>
      {children}
    </Cmp>
  );
};
