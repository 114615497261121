import { AreaUnit } from '@belimo-retrofit-portal/logic';
import { BasicFormData } from 'src/modules/project-new/types/BasicFormData';
import { BuildingFormData } from 'src/modules/project-new/types/BuildingFormData';

export const DEFAULT_BASIC_FORM_DATA: BasicFormData = {
  cover: null,
  projectGoals: '',
  title: '',
};

export const DEFAULT_BUILDING_FORM_DATA: BuildingFormData = {
  unit: AreaUnit.SQM,
  type: null,
  size: null,
  address: {
    zip: '',
    city: '',
    street: '',
    country: null,
  },
};
