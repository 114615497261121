import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const HeatingIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M8.056 9.58a5.77 5.77 0 0 1 2.59-3.71l-.5-.87a6.66 6.66 0 0 0-3.07 4.41c-.37 2.27.62 4.74 2.92 7.33
      1.92 2.15 2.72 4.16 2.35 6-.52 2.74-3.58 4.26-3.61 4.26l.43.91c.14-.07 3.53-1.71 4.19-5
      .43-2.16-.44-4.46-2.59-6.86-2.15-2.4-3.02-4.51-2.71-6.47ZM14.246
      9.58a5.77 5.77 0 0 1 2.59-3.71l-.5-.87a6.69 6.69 0 0 0-3.08 4.41c-.36 2.27.63 4.74 3 7.33
      1.92 2.15 2.72 4.16 2.35 6-.61 2.74-3.61 4.26-3.68 4.26l.43.91c.14-.07 3.53-1.71 4.19-5
      .43-2.16-.44-4.46-2.59-6.86-2.15-2.4-3.02-4.51-2.71-6.47ZM23.146
      16.07c-2.11-2.35-3-4.53-2.71-6.49a5.768 5.768 0 0 1 2.56-3.71l-.47-.87a6.69
      6.69 0 0 0-3.08 4.41c-.36 2.27.63 4.74 3 7.33 1.92 2.15 2.72 4.16 2.35 6-.59 2.74-3.65 4.26-3.68
      4.26l.43.91c.14-.07 3.53-1.71 4.19-5 .43-2.14-.44-4.44-2.59-6.84Z"
      />
    </svg>
  </IconWrapper>
));
