import { SagaIterator } from 'redux-saga';
import {
  PROJECT_LIST_DOWNLOAD,
  PROJECT_LIST_EXPORT,
  PROJECT_LIST_FILTER,
  PROJECT_LIST_IMPORT_CANCEL,
  PROJECT_LIST_IMPORT_COMPLETE,
  PROJECT_LIST_IMPORT_SUBMIT,
  PROJECT_LIST_IMPORT_UPLOAD,
  PROJECT_LIST_REMOVE_SUBMIT,
  PROJECT_LIST_RESET,
  PROJECT_LIST_ABORT,
  PROJECT_LIST_UNFOLLOW_SUBMIT,
  PROJECT_LIST_SHARE,
} from 'src/modules/project-list/actions/ProjectListActions';
import { projectListDownloadSaga } from 'src/modules/project-list/sagas/projectListDownloadSaga';
import { projectListExportSaga } from 'src/modules/project-list/sagas/projectListExportSaga';
import { projectListFilterSaga } from 'src/modules/project-list/sagas/projectListFilterSaga';
import { projectListImportCompleteSaga } from 'src/modules/project-list/sagas/projectListImportCompleteSaga';
import { projectListImportSubmitSaga } from 'src/modules/project-list/sagas/projectListImportSubmitSaga';
import { projectListImportUploadSaga } from 'src/modules/project-list/sagas/projectListImportUploadSaga';
import { projectListRemoveSaga } from 'src/modules/project-list/sagas/projectListRemoveSaga';
import { projectListShareSaga } from 'src/modules/project-list/sagas/projectListShareSaga';
import { projectListUnfollowSaga } from 'src/modules/project-list/sagas/projectListUnfollowSaga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { takeLatest } from 'typed-redux-saga';

export function* projectListSaga(): SagaIterator<void> {
  yield* takeLatest(PROJECT_LIST_REMOVE_SUBMIT.isRequest, projectListRemoveSaga);

  yield* takeLatest(PROJECT_LIST_FILTER.is, cancelSagaOn(projectListFilterSaga, [
    ROUTE_CHANGE.isRequest,
  ]));

  yield* takeLatest(PROJECT_LIST_EXPORT.isRequest, cancelSagaOn(projectListExportSaga, [
    PROJECT_LIST_RESET.is,
    PROJECT_LIST_ABORT.is,
  ]));

  yield* takeLatest(PROJECT_LIST_IMPORT_COMPLETE.is, projectListImportCompleteSaga);
  yield* takeLatest(PROJECT_LIST_IMPORT_UPLOAD.isRequest, cancelSagaOn(projectListImportUploadSaga, [
    PROJECT_LIST_RESET.is,
    PROJECT_LIST_ABORT.is,
    PROJECT_LIST_IMPORT_CANCEL.is,
  ]));
  yield* takeLatest(PROJECT_LIST_IMPORT_SUBMIT.isRequest, cancelSagaOn(projectListImportSubmitSaga, [
    PROJECT_LIST_RESET.is,
    PROJECT_LIST_ABORT.is,
    PROJECT_LIST_IMPORT_CANCEL.is,
  ]));

  yield* takeLatest(PROJECT_LIST_DOWNLOAD.isRequest, cancelSagaOn(projectListDownloadSaga, [
    PROJECT_LIST_RESET.is,
    PROJECT_LIST_ABORT.is,
    PROJECT_LIST_REMOVE_SUBMIT.isRequest,
    PROJECT_LIST_UNFOLLOW_SUBMIT.isRequest,
  ]));

  yield* takeLatest(PROJECT_LIST_UNFOLLOW_SUBMIT.isRequest, projectListUnfollowSaga);

  yield* takeLatest(PROJECT_LIST_SHARE.is, cancelSagaOn(projectListShareSaga, [
    PROJECT_LIST_RESET.is,
    PROJECT_LIST_ABORT.is,
  ]));
}
