import { createRoot } from 'react-dom/client';
import { ShadowHost } from 'src/modules/report/types/ShadowHost';

export function createShadowHost(): ShadowHost {
  const host = document.createElement('div');
  host.style.position = 'fixed';
  host.style.zIndex = '1';
  host.style.top = '0';
  host.style.left = '10000px';
  host.style.width = '0';
  host.style.height = '0';
  host.style.overflow = 'hidden';

  document.body.appendChild(host);
  const root = createRoot(host);

  return {
    element: host,
    render: (element): void => {
      root.render(element);
    },
    destroy: (): void => {
      root.unmount();
      document.body.removeChild(host);
    },
  };
}
