import './FeedbackButton.scss';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { MailIcon } from 'src/modules/common/icons/MailIcon';
import { FeedbackModal } from 'src/modules/feedback/views/FeedbackModal';

export const FeedbackButton = (): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <div className="bp-feedback-button">
        <CustomButton
          className="bp-feedback-button__button"
          kind="secondary"
          size="sm"
          onClick={handleOpen}
        >
          <div className="bp-feedback-button__button-icon">
            <MailIcon/>
          </div>

          <FormattedMessage id="feedback/button"/>
        </CustomButton>
      </div>

      <FeedbackModal open={open} onClose={handleClose}/>
    </>
  );
};
