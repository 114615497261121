import { SagaIterator } from 'redux-saga';
import { call } from 'typed-redux-saga';

type SagaFactory<TResult, TArgs extends unknown[]> = (...args: TArgs) => SagaIterator<TResult>;

export function composeSagas<TArgs extends unknown[]>(
  ...sagas: ReadonlyArray<SagaFactory<unknown, TArgs>>
): SagaFactory<void, TArgs> {
  return function* chain(...args: TArgs): SagaIterator<void> {
    for (const saga of sagas) {
      yield* call(saga, ...args);
    }
  };
}
