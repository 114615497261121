import { Action } from 'redux';
import {
  PRESELECTED_SCHEMA_CHANGE,
  SCHEMA_SELECTIONSTATE_FETCH,
  SCHEMA_SELECTION_FILTER_CHANGE,
} from 'src/modules/schema/actions/SchemaActions';
import { SchemaSelectionState } from 'src/modules/schema/types/SchemaSelectionState';

export function schemaSelectionReducer(state: SchemaSelectionState, action: Action): SchemaSelectionState {
  if (SCHEMA_SELECTIONSTATE_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
    };
  }

  if (SCHEMA_SELECTION_FILTER_CHANGE.isSuccess(action)) {
    return {
      ...state,
      data: {
        ...state.data,
        filter: action.data,
      },
    };
  }

  if (PRESELECTED_SCHEMA_CHANGE.isSuccess(action)) {
    return {
      ...state,
      data: {
        ...state.data,
        schemaSelected: action.data,
      },
    };
  }

  return state;
}
