import { scrollRestorationReducer } from 'src/modules/scroll/reducers/scrollRestorationReducer';
import { ScrollState } from 'src/modules/scroll/types/ScrollState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ScrollState = {
  scrollRestoration: {
    isRestoring: false,
    lastScrollPosition: 0,
  },
};

export const scrollReducer = chainReducers(defaultState, [
  scrollRestorationReducer,
]);
