import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { array } from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { CONFIG_SCHEMA } from 'src/modules/config/codecs/ConfigSchema';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { call } from 'typed-redux-saga';

export function* loadSchemas(): SagaIterator<ConfigSchema[]> {
  try {
    const response = yield* call(fetch, '/configs/schemas.json', {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      array(CONFIG_SCHEMA),
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch schema templates', {}, NativeError.wrap(error));
  }
}
