import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const DeleteIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M8.343 26V7.854H7v-.927h4.97V6h8.06v.927H25v.927h-1.343V26H8.343Zm.94-.927h13.433V7.854H9.284v17.219Zm4.098-2.65h.94v-11.92h-.94v11.92Zm4.298 0h.94v-11.92h-.94v11.92Z"
      />
    </svg>
  </IconWrapper>
));
