import './HvacResultEnergySystemView.scss';
import { EnergySource } from '@belimo-retrofit-portal/logic';
import { Information } from '@carbon/icons-react';
import { Tooltip } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { LightningIcon } from 'src/modules/common/icons/LightningIcon';
import { BodyLargeRegularText } from 'src/modules/common/typography/BodyLargeRegularText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormChapterData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacResultEnergyElectricalView } from 'src/modules/hvac/views/HvacResultEnergyElectricalView';
import { HvacResultEnergyThermalView } from 'src/modules/hvac/views/HvacResultEnergyThermalView';

type Props = {
  readonly title: React.ReactNode;
  readonly path: FieldPath<HvacFormData, FormError, HvacFormChapterData>;
  readonly disabled: boolean;
  readonly energySources: ReadonlyArray<EnergySource>;
  readonly tooltipTextId: string;
};

export const HvacResultEnergySystemView = ({
  title, path, disabled, energySources, tooltipTextId,
}: Props): React.ReactElement => (
  <div className="bp-hvac-result-energy-system-view">
    <HvacResultEnergyThermalView
      path={path.at('thermal')}
      disabled={disabled}
      energySources={energySources}
    />

    <BodyLargeRegularText className={clsx('bp-hvac-result-energy-system-view__title', {
      'bp-hvac-result-energy-system-view__title--disabled': disabled,
    })}
    >
      <span className="bp-hvac-result-energy-system-view__title-icon">
        <LightningIcon/>
      </span>

      {title}
    </BodyLargeRegularText>

    <HvacResultEnergyElectricalView
      path={path.at('electrical')}
      disabled={disabled}
      tooltip={(
        <Tooltip
          disabled={disabled}
          align="top"
          enterDelayMs={0}
          label={<FormattedMessage id={tooltipTextId}/>}
          className={clsx(
            'bp-hvac-result-energy-system-view__tooltip',
            {
              'bp-hvac-result-energy-system-view__tooltip--disabled': disabled,
            },
          )}
        >
          <Information color={CARBON_THEME.onSurfaceLightPrimary} size={16}/>
        </Tooltip>
      )}
    />
  </div>
);
