import './ProjectListEmpty.scss';
import { Add, Upload } from '@carbon/icons-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { UploadIcon } from 'src/modules/common/icons/UploadIcon';

type Props = {
  readonly onCreate: () => void;
  readonly onImport: () => void;
};

export const ProjectListEmpty = ({ onCreate, onImport }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <div className="bp-project-list-empty">
      <div className="bp-project-list-empty__container">
        <div className="bp-project-list-empty__content">
          <img
            src="/images/projects/emptyList.svg"
            width={420}
            alt={intl.formatMessage({ id: 'projectList/empty/image' })}
          />

          <h3 className="bp-project-list-empty__title">
            <FormattedMessage id="projectList/empty/title"/>
          </h3>
        </div>

        <div className="bp-project-list-empty__actions">
          <CustomButton
            className="bp-project-list-empty__button"
            onClick={onCreate}
          >
            <FormattedMessage id="projectList/empty/newProject"/>

            <Add size={24}/>
          </CustomButton>

          <CustomButton
            kind="ghost"
            className="bp-project-list-empty__button"
            onClick={onImport}
          >
            <FormattedMessage id="projectList/empty/importProjects"/>

            <div className="bp-project-list-empty__button-icon">
              <UploadIcon/>
            </div>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
