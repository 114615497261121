import { Action } from 'redux';
import {
  PROJECT_LIST_ABORT,
  PROJECT_LIST_FETCH,
  PROJECT_LIST_RESET,
} from 'src/modules/project-list/actions/ProjectListActions';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';

export function projectListDataReducer(state: ProjectListState, action: Action): ProjectListState {
  if (PROJECT_LIST_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
      import: null,
      export: null,
      removing: null,
      downloading: null,
      unfollowing: null,
    };
  }
  if (PROJECT_LIST_RESET.is(action)) {
    return {
      ...state,
      data: null,
      import: null,
      export: null,
      removing: null,
      downloading: null,
      unfollowing: null,
    };
  }
  if (PROJECT_LIST_ABORT.is(action)) {
    return {
      ...state,
      import: null,
      export: null,
      removing: null,
      downloading: null,
      unfollowing: null,
    };
  }

  return state;
}
