import { Action } from 'redux';
import { LOGIN_PERFORM } from 'src/modules/login/actions/LoginActions';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { LoginState } from 'src/modules/login/types/LoginState';

export function loginUserReducer(state: LoginState, action: Action): LoginState {
  if (WHOAMI_ACTION.is(action)) {
    return {
      ...state,
      user: action.data,
    };
  }
  if (LOGIN_PERFORM.isSuccess(action)) {
    return {
      ...state,
      user: action.data,
    };
  }

  return state;
}
