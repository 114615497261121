import { SagaIterator } from 'redux-saga';
import { REPORT_CHANGE, REPORT_GENERATE } from 'src/modules/report/actions/ReportActions';
import { reportChangeSaga } from 'src/modules/report/sagas/reportChangeSaga';
import { reportGenerateSaga } from 'src/modules/report/sagas/reportGenerateSaga';
import { takeLatest } from 'typed-redux-saga';

export function* reportSaga(): SagaIterator<void> {
  yield* takeLatest(REPORT_CHANGE.is, reportChangeSaga);
  yield* takeLatest(REPORT_GENERATE.isRequest, reportGenerateSaga);
}
