import { projectListDataReducer } from 'src/modules/project-list/reducers/projectListDataReducer';
import { projectListDownloadReducer } from 'src/modules/project-list/reducers/projectListDownloadReducer';
import { projectListExportReducer } from 'src/modules/project-list/reducers/projectListExportReducer';
import { projectListImportReducer } from 'src/modules/project-list/reducers/projectListImportReducer';
import { projectListRemovingReducer } from 'src/modules/project-list/reducers/projectListRemovingReducer';
import { projectListUnfollowingReducer } from 'src/modules/project-list/reducers/projectListUnfollowingReducer';
import { ProjectListState } from 'src/modules/project-list/types/ProjectListState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ProjectListState = {
  data: null,
  import: null,
  export: null,
  removing: null,
  downloading: null,
  unfollowing: null,
};

export const projectListReducer = chainReducers(defaultState, [
  projectListDataReducer,
  projectListImportReducer,
  projectListExportReducer,
  projectListRemovingReducer,
  projectListDownloadReducer,
  projectListUnfollowingReducer,
]);
