import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { PROJECT_LIST_VIEW_MODE } from 'src/modules/project-list/codecs/ProjectListViewMode';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';
import { storageGet } from 'src/sagas/utils/storage';
import { call } from 'typed-redux-saga';

export function* loadProjectListViewMode(): SagaIterator<ProjectListViewMode> {
  try {
    const raw = yield* call(storageGet, 'project-list-view-mode');
    return yield* call(decodeOrGet, PROJECT_LIST_VIEW_MODE, raw, ProjectListViewMode.LIST);
  } catch (error) {
    throw new RuntimeError('Could not load project list view mode', {}, NativeError.wrap(error));
  }
}
