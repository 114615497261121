import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux-saga';
import { Future, FUTURE } from 'src/modules/common/types/Future';
import {
  SHARE_PROJECT_FETCH,
  SHARE_PROJECT_REMOVE_SUBMIT,
  SHARE_PROJECT_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { ShareProjectState } from 'src/modules/sharing-projects/types/ShareProjectState';

export function shareProjectUsersReducer(state: ShareProjectState, action: Action): ShareProjectState {
  if (SHARE_PROJECT_FETCH.isPending(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        users: FUTURE.pending(),
        removing: null,
      },
    };
  }
  if (SHARE_PROJECT_FETCH.isSuccess(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        users: FUTURE.success(action.data),
        removing: null,
      },
    };
  }
  if (SHARE_PROJECT_FETCH.isFailure(action)) {
    return {
      ...state,
      data: {
        project: action.meta,
        users: FUTURE.failure(action.data),
        removing: null,
      },
    };
  }

  if (SHARE_PROJECT_SUBMIT.isSuccess(action)) {
    return pipe(state, modifyUsers(FUTURE.success(action.data)));
  }
  if (SHARE_PROJECT_REMOVE_SUBMIT.isSuccess(action)) {
    return pipe(state, modifyUsers(FUTURE.success(action.data)));
  }

  return state;
}

function modifyUsers(
  users: Future<ReadonlyArray<SharedUser>>,
): (state: ShareProjectState) => ShareProjectState {
  return pipe(
    O.id<ShareProjectState>(),
    O.prop('data'),
    O.fromNullable,
    O.prop('users'),
    O.modify(() => users),
  );
}
