import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CircleCheckFilledIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <rect width="24" height="24" fill="#000" rx="12"/>
      <path fill="currentColor" d="M10 15.475 6.925 12.4l.475-.475 2.6 2.6 6.6-6.6.475.475L10 15.475Z"/>
    </svg>
  </IconWrapper>
));
