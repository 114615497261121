import './EnergyEfficiencyLabel.scss';
import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import clsx from 'clsx';
import React from 'react';

type Props = {
  readonly value: EnergyEfficiencyClass | null;
  readonly sized: boolean;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
};

export const EnergyEfficiencyLabel = ({ value, sized, disabled, children }: Props): React.ReactElement => (
  <div
    className={
      clsx(
        'bp-energy-efficiency-label',
        `bp-energy-efficiency-label--${value || 'unknown'}`,
        sized ? `bp-energy-efficiency-label--${value || 'unknown'}-sized` : null,
        disabled ? 'bp-energy-efficiency-label--disabled' : null,
      )
    }
  >
    {children}
  </div>
);
