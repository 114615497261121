import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { ENVIRONMENT } from 'src/modules/config/codecs/Environment';
import { Environment } from 'src/types/Environment';
import { call } from 'typed-redux-saga';

export function* loadEnvironment(): SagaIterator<Environment> {
  try {
    const response = yield* call(fetch, '/environment.json', {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      ENVIRONMENT,
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch environment file', {}, NativeError.wrap(error));
  }
}
