import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux-saga';
import { Future, FUTURE } from 'src/modules/common/types/Future';
import {
  SHARE_PROJECT_REMOVE_OPEN,
  SHARE_PROJECT_REMOVE_CLOSE,
  SHARE_PROJECT_REMOVE_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { ShareProjectRemoving, ShareProjectState } from 'src/modules/sharing-projects/types/ShareProjectState';

export function shareProjectRemoveReducer(state: ShareProjectState, action: Action): ShareProjectState {
  if (SHARE_PROJECT_REMOVE_OPEN.is(action)) {
    return pipe(state, modifyRemoving({ user: action.data, status: null }));
  }
  if (SHARE_PROJECT_REMOVE_CLOSE.is(action)) {
    return pipe(state, modifyRemoving(null));
  }

  if (SHARE_PROJECT_REMOVE_SUBMIT.isPending(action)) {
    return pipe(state, modifyStatus(FUTURE.pending()));
  }
  if (SHARE_PROJECT_REMOVE_SUBMIT.isSuccess(action)) {
    return pipe(state, modifyRemoving(null));
  }
  if (SHARE_PROJECT_REMOVE_SUBMIT.isFailure(action)) {
    return pipe(state, modifyStatus(FUTURE.failure(action.data)));
  }

  return state;
}

const dataLens = pipe(
  O.id<ShareProjectState>(),
  O.prop('data'),
  O.fromNullable,
);

function modifyRemoving(
  value: ShareProjectRemoving | null,
): (state: ShareProjectState) => ShareProjectState {
  return pipe(
    dataLens,
    O.prop('removing'),
    O.modify(() => value),
  );
}

function modifyStatus(
  value: Future<void>,
): (state: ShareProjectState) => ShareProjectState {
  return pipe(
    dataLens,
    O.prop('removing'),
    O.fromNullable,
    O.prop('status'),
    O.modify(() => value),
  );
}
