import { SagaIterator } from 'redux-saga';
import { LOGIN_PERFORM } from 'src/modules/login/actions/LoginActions';
import { REQUEST_ACCESS_SUBMIT } from 'src/modules/login/actions/RequestAccessActions';
import { loginPerformSaga } from 'src/modules/login/sagas/loginPerformSaga';
import { requestAccessSubmitSaga } from 'src/modules/login/sagas/requestAccessSubmitSaga';
import { takeLatest } from 'typed-redux-saga';

export function* loginSaga(): SagaIterator<void> {
  yield* takeLatest(LOGIN_PERFORM.isRequest, loginPerformSaga);
  yield* takeLatest(REQUEST_ACCESS_SUBMIT.is, requestAccessSubmitSaga);
}
