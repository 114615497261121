import './IconWrapper.scss';
import React, { memo } from 'react';

type Props = {
  children: React.ReactNode;
};

export const IconWrapper = memo(({ children }: Props) => (
  <span className="bp-icon-wrapper">
    {children}
  </span>
));
