import { SagaIterator } from 'redux-saga';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { loadUser } from 'src/modules/login/sagas/utils/loadUser';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* authenticate(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Performing authentication...');

    const user = yield* call(loadUser);

    yield* call(logDebug, 'Performing authentication... done', { user });
    yield* put(WHOAMI_ACTION.trigger(user));
  } catch (error) {
    yield* call(logError, 'Performing authentication... error', { error });
    throw error;
  }
}
