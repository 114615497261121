import './DeleteVersionDialog.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';

type Props = {
  readonly isOpen: boolean;
  readonly version: SchemaVersion;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
};

export const DeleteVersionDialog = ({ isOpen, onClose, onConfirm, version }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <CustomModal
      className="bp-delete-version-dialog"
      open={isOpen}
      isShaded={false}
      onClose={onClose}
      danger={true}
      size="sm"
      shouldUnmount={true}
    >
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        labelClassName="bp-delete-version-dialog__label"
        label={intl.formatMessage({ id: 'schemaEdit/deleteDialog/title' })}
      />

      <ModalBody>
        <div className="bp-delete-version-dialog__body">
          <LeadText>
            {
              version.type === 'actual' ? (
                <FormattedMessage
                  id="schemaEdit/deleteDialog/mainQuestion"
                />
              ) : (
                <FormattedMessage
                  id="schemaEdit/deleteDialog/mainQuestionFuture"
                  values={{
                    version: version.number,
                  }}
                />
              )
            }
          </LeadText>

          {version.type === 'actual' && (
            <BodySmallText>
              <FormattedMessage id="schemaEdit/deleteDialog/description"/>
            </BodySmallText>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          autoFocus={true}
          kind="secondary"
          onClick={onClose}
        >
          <FormattedMessage id="schemaEdit/deleteDialog/cancel"/>
        </CustomButton>

        <CustomButton kind="primary" onClick={onConfirm}>
          <FormattedMessage id="schemaEdit/deleteDialog/confirm"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
