import './SchemaCard.scss';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';

type Props = {
  readonly schema: ConfigSchema;
  readonly selected: boolean;
  readonly onSelect: (schema: ConfigSchema) => void;
};

export const SchemaCard = React.forwardRef<HTMLButtonElement, Props>(
  ({ schema, selected, onSelect }: Props, ref): React.ReactElement => {
    const handleSelect = useCallback(() => {
      onSelect(schema);
    }, [schema, onSelect]);

    return (
      <button
        type="button"
        ref={ref}
        className={clsx(
          'bp-schema-card',
          selected ? 'bp-schema-card--selected' : null,
        )}
        onClick={handleSelect}
      >
        <h6 className="bp-schema-card__title">
          {schema.id}
        </h6>

        <p className="bp-schema-card__description">
          <FormattedMessage id={schema.name}/>
        </p>

        <div className="bp-schema-card__image-wrapper">
          <img
            src={`/images/schemas/${encodeURIComponent(schema.id)}.svg`}
            alt="schema"
            className="bp-schema-card__image"
          />
        </div>
      </button>
    );
  },
);
