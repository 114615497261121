import {
  BuildingType,
  CountryCode,
  EnergyEfficiencyClass,
  getEnergyEfficiencyLabel,
  COUNTRY_CODES,
} from '@belimo-retrofit-portal/logic';
import { eqStrict } from 'fp-ts/Eq';
import { pipe } from 'fp-ts/function';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';
import * as A from 'fp-ts/ReadonlyArray';
import * as S from 'fp-ts/string';
import { createSelector } from 'reselect';
import { FACTOR_WEIGHTS_LABELS } from 'src/modules/common/constants/factorWeightedLabel';
import { BUILDING_TYPE_OPTIONS } from 'src/modules/common/constants/options';
import { ordPrecedence } from 'src/modules/common/utils/ord';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { getProjectListProjects } from 'src/modules/project-list/selectors/getProjectListProjects';
import { ProjectListFilterValues } from 'src/modules/project-list/types/ProjectListFilterValues';
import { isDefined, isNotNull } from 'src/utils/guard';

export const getProjectListFilterValues = createSelector([
  getProjectListProjects,
  getLoggedInUser,
], (projects, authUser): ProjectListFilterValues => ({
  buildingType: pipe(
    projects,
    (data) => data.map((it) => it.project.data.building.type),
    A.uniq<BuildingType>(eqStrict),
    A.sort(BUILDING_TYPE_ORD),
  ),

  country: pipe(
    projects,
    (data) => data.map((it) => it.project.data.building.address.country),
    A.uniq<CountryCode>(eqStrict),
    A.sort(COUNTRY_CODE_ORD),
  ),
  city: pipe(
    projects,
    (data) => data.map((it) => it.project.data.building.address.city),
    (data) => data.filter((it) => it !== ''),
    A.uniq(S.Eq),
    A.sort(S.Ord),
  ),

  ratingActual: pipe(
    projects,
    (data) => data.map((it) => it.rating.actual),
    (data) => data.filter(isNotNull),
    (data) => data.map(getEnergyEfficiencyLabel),
    A.uniq<EnergyEfficiencyClass>(eqStrict),
    A.sort(EFFICIENCY_LABEL_ORD),
  ),
  ratingFuture: pipe(
    projects,
    (data) => data.map((it) => it.rating.future),
    (data) => data.filter(isNotNull),
    (data) => data.map(getEnergyEfficiencyLabel),
    A.uniq<EnergyEfficiencyClass>(eqStrict),
    A.sort(EFFICIENCY_LABEL_ORD),
  ),

  ownership: pipe(
    projects,
    A.map((it) => it.project.owner?.email),
    A.filter(isDefined),
    A.uniq<string>(eqStrict),
    A.sort<string>(pipe(S.Ord, ordPrecedence(authUser.email))),
  ),
}));

const BUILDING_TYPE_ORD = pipe(
  N.Ord,
  O.contramap((value: BuildingType) => BUILDING_TYPE_OPTIONS.indexOf(value)),
);

const COUNTRY_CODE_ORD = pipe(
  N.Ord,
  O.contramap((value: CountryCode) => COUNTRY_CODES.indexOf(value)),
);

const EFFICIENCY_LABEL_ORD = pipe(
  N.Ord,
  O.contramap((value: EnergyEfficiencyClass) => FACTOR_WEIGHTS_LABELS[value].toNumber()),
  O.reverse,
);
