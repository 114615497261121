import { Interpolation } from 'src/modules/common/types/Interpolation';

export type SpringParams<T> = {
  readonly mass: number;
  readonly tension: number;
  readonly friction: number;

  readonly initialPosition: T;
  readonly initialVelocity: T;

  readonly onChange: (value: T, completed: boolean) => void;

  readonly precision: number;
  readonly interpolation: Interpolation<T>;
};

export type SpringController<T> = {
  readonly value: T;
  readonly target: T;

  animate(to: T): void;
  abort(): void;
};

export function spring<T>({
  mass,
  tension,
  friction,
  initialPosition,
  initialVelocity,
  onChange,
  precision,
  interpolation,
}: SpringParams<T>): SpringController<T> {
  let target = initialPosition;
  let position = initialPosition;
  let velocity = initialVelocity;

  let currFrameId = 0;
  let prevFrameTs = NaN;

  function loop(currFrameTs: number): void {
    // limit time delta to 46ms
    const elasped = Math.min(46, Math.floor(currFrameTs - prevFrameTs));

    // incrementaly iterpolate for each 1ms
    for (let i = 0; i < elasped; ++i) {
      const delta = interpolation.sub(position, target);
      const restoringForce = interpolation.mul(delta, -tension);
      const dampingForce = interpolation.mul(velocity, -friction);
      const totalForce = interpolation.add(restoringForce, dampingForce);
      const acceleration = interpolation.mul(totalForce, 1 / mass);

      velocity = interpolation.add(velocity, interpolation.mul(acceleration, ONE_MILLISECOND));
      position = interpolation.add(position, interpolation.mul(velocity, ONE_MILLISECOND));
    }

    const distance = interpolation.sub(position, target);
    const completed = (
      interpolation.len(velocity) < precision &&
      interpolation.len(distance) < precision
    );

    if (completed) {
      position = target;
      velocity = initialVelocity;
      prevFrameTs = NaN;
    } else {
      prevFrameTs = Math.floor(currFrameTs);
    }

    // we request animation before invoking the callback
    // to make possible to abort or start new animation inside the callback
    currFrameId = completed ? 0 : window.requestAnimationFrame(loop);
    onChange(position, completed);
  }

  return {
    get value(): T {
      return position;
    },
    get target(): T {
      return target;
    },

    animate: (to): void => {
      target = to;

      if (!currFrameId) {
        prevFrameTs = performance.now();
        currFrameId = window.requestAnimationFrame(loop);
      }
    },
    abort: (): void => {
      if (currFrameId) {
        window.cancelAnimationFrame(currFrameId);
        currFrameId = 0;
      }

      velocity = initialVelocity;
      prevFrameTs = NaN;
    },
  };
}

const ONE_MILLISECOND = 0.001;
