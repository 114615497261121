import { SagaIterator } from 'redux-saga';
import { USER_REMOVE_CANCEL, USER_REMOVE_CONFIRM, USER_SAVE } from 'src/modules/users/actions/UserActions';
import { userRemoveSaga } from 'src/modules/users/sagas/userRemoveSaga';
import { userSaveSaga } from 'src/modules/users/sagas/userSaveSaga';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { takeLatest } from 'typed-redux-saga';

export function* usersSaga(): SagaIterator<void> {
  yield* takeLatest(USER_SAVE.isRequest, userSaveSaga);
  yield* takeLatest(USER_REMOVE_CONFIRM.isRequest, cancelSagaOn(userRemoveSaga, [
    USER_REMOVE_CANCEL.is,
  ]));
}
