import { Action } from 'redux';
import { SCROLL_RESTORE } from 'src/modules/scroll/actions/ScrollRestoration';
import { ScrollState } from 'src/modules/scroll/types/ScrollState';

export function scrollRestorationReducer(
  state: ScrollState,
  action: Action,
): ScrollState {
  if (SCROLL_RESTORE.is(action)) {
    return {
      ...state,
      scrollRestoration: {
        isRestoring: action.data.isRestoring,
        lastScrollPosition: action.data.lastScrollPosition,
      },
    };
  }

  return state;
}
