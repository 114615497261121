import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { loadUser } from 'src/modules/login/sagas/utils/loadUser';
import { LoginFormData } from 'src/modules/login/types/LoginFormData';
import { call } from 'typed-redux-saga';

export function* performLogin(form: LoginFormData): SagaIterator<AuthUser> {
  try {
    const body = {
      email: form.username,
      password: form.password,
      remember: form.remember,
    };

    yield* call(makeRestRequest, 'POST', '/auth/login', body);
    return yield* call(loadUser);
  } catch (error) {
    throw new RuntimeError('Could not perform login', { form }, NativeError.wrap(error));
  }
}
