import { NativeError } from '@belimo-retrofit-portal/logic';
import { goBack } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { USER_REMOVE_CONFIRM } from 'src/modules/users/actions/UserActions';
import { deleteUser } from 'src/modules/users/sagas/utils/deleteUser';
import { getUserRemoving } from 'src/modules/users/selectors/getUserRemoving';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotNull } from 'src/utils/assert';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* userRemoveSaga(action: GetRequestActionType<typeof USER_REMOVE_CONFIRM>): SagaIterator<void> {
  try {
    yield* put(USER_REMOVE_CONFIRM.pending(action.meta));
    yield* call(logDebug, 'Removing user...');

    const { user } = assertNotNull(
      yield* select(getUserRemoving),
      'Could not find user to remove',
    );

    yield* call(deleteUser, action.meta);

    yield* put(goBack());

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'admin/user/notification/remove/success',
      },
    );

    yield* call(logDebug, 'Removing user... done', user);
    yield* put(USER_REMOVE_CONFIRM.success(undefined, action.meta));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(logError, 'Removing user... error', error);
    yield* put(USER_REMOVE_CONFIRM.failure(NativeError.wrap(error), action.meta));
  }
}
