import './CarbonSavingsUnit.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';

export const CarbonSavingsUnit = (): React.ReactElement => (
  <span className="bp-carbon-savings-unit">
    <FormattedMessage id="unit/tonne_CO2" values={{ sub, sup }}/>
  </span>
);
