import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { FEEDBACK_SEND } from 'src/modules/feedback/actions/FeedbackActions';
import { FEEDBACK_FORM_SCHEMA } from 'src/modules/feedback/constants/schema';
import { sendFeedback } from 'src/modules/feedback/sagas/utils/sendFeedback';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* feedbackSendSaga(
  action: GetRequestActionType<typeof FEEDBACK_SEND>,
): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  const valid = yield* call(formValidate, form, FEEDBACK_FORM_SCHEMA);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Sending feedback...');
    yield* put(FEEDBACK_SEND.pending());
    yield* call(formSubmitStart, form);

    yield* call(sendFeedback, values);
    yield* call(formSubmitSuccess, form);

    yield* call(logDebug, 'Sending feedback... done', values);
    yield* put(FEEDBACK_SEND.success());
  } catch (error) {
    const wrapped = new RuntimeError('Could not send feedback', { values }, NativeError.wrap(error));

    yield* call(logError, 'Sending feedback... error', error);
    yield* put(FEEDBACK_SEND.failure(wrapped));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(formSubmitFailure, form, {});
  }
}
