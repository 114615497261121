import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useAction } from 'src/hooks/useAction';
import { LoginLayout } from 'src/modules/common/layouts/LoginLayout';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { REQUEST_ACCESS_SUBMIT } from 'src/modules/login/actions/RequestAccessActions';
import { LoginScreen } from 'src/modules/login/views/LoginScreen';

export const LoginPage = (): React.ReactElement => {
  const intl = useIntl();
  const switchLanguage = useAction(CONFIG_LANGUAGE_SWITCH.request);
  const submitRequestAccess = useAction(REQUEST_ACCESS_SUBMIT.trigger);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'login/pageTitle' })}</title>
      </Helmet>

      <LoginLayout>
        <LoginScreen
          switchLanguage={switchLanguage}
          submitRequestAccess={submitRequestAccess}
        />
      </LoginLayout>
    </>
  );
};
