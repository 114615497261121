import './UnfollowProjectModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { PreserveContent } from 'src/modules/common/components/PreserveContent';
import { ProjectListUnfollowing } from 'src/modules/project-list/types/ProjectUnfollowing';

type Props = {
  data: ProjectListUnfollowing | null;
  onConfirmUnfollow: () => void;
  onClose: () => void;
};

export const UnfollowProjectModal = ({ data, onConfirmUnfollow, onClose }: Props): React.ReactElement => {
  const intl = useIntl();
  return (
    <CustomModal
      className="bp-project-unfollow"
      open={data !== null}
      onClose={onClose}
      size="sm"
      danger={true}
      shouldUnmount={true}
      isShaded={false}
    >
      <PreserveContent>
        {
          data && (
            <>
              <ModalHeader
                iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
                buttonOnClick={onClose}
                titleClassName="bp-project-title"
                label={(
                  <FormattedMessage
                    id="projectList/unfollowModal/label"
                  />
                )}
                title={(
                  <FormattedMessage
                    id="projectList/unfollowModal/title"
                  />
                )}
              />

              <ModalBody>
                <FormattedMessage
                  id="projectList/unfollowModal/body"
                  values={{ projectName: data.project.data.title }}
                />
              </ModalBody>

              <ModalFooter>
                <CustomButton
                  kind="secondary"
                  onClick={onClose}
                  autoFocus={true}
                  size="md"
                  disabled={data.status?.state === 'pending'}
                >
                  <FormattedMessage id="projectList/unfollowModal/cancel"/>
                </CustomButton>

                <CustomButton
                  disabled={data.status?.state === 'pending'}
                  kind="primary"
                  onClick={onConfirmUnfollow}
                  size="md"
                  loading={data.status?.state === 'pending'}
                >
                  <FormattedMessage id="projectList/unfollowModal/leave"/>
                </CustomButton>
              </ModalFooter>
            </>
          )
        }
      </PreserveContent>
    </CustomModal>
  );
};
