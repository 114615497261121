import './CustomMenuItem.scss';
import { OverflowMenuItem } from '@carbon/react';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocation } from 'src/modules/routing/selectors/getCurrentLocation';

type Props = {
  itemText: React.ReactNode;
  hasDivider: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: string;
  isDelete?: boolean;
  flipped?: boolean;
};

export const CustomMenuItem = memo((
  { onClick, disabled, itemText, hasDivider, href, isDelete, flipped, ...props }: Props,
): React.ReactElement => {
  const location = useSelector(getCurrentLocation);
  const currentRoute = location?.pathname === href;

  return (
    <OverflowMenuItem
      {...props}
      wrapperClassName="bp-custom-menu-item-wrapper"
      className={clsx(
        'bp-custom-menu-item',
        {
          'bp-custom-menu-item--flipped': flipped,
          'bp-custom-menu-item--disabled': disabled,
        },
      )}
      onClick={onClick}
      itemText={itemText}
      hasDivider={hasDivider}
      href={currentRoute ? undefined : href}
      isDelete={isDelete}
      disabled={disabled}
    />
  );
});
