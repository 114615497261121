import './ShareProjectRemoveModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { Project } from 'src/modules/common/types/Project';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';

type Props = {
  readonly project: Project;
  readonly user: SharedUser;
  readonly submitting: boolean;

  readonly onConfirm: () => void;
  readonly onCancel: () => void;
};

export const ShareProjectRemoveModal = ({
  project,
  user,
  submitting,
  onConfirm,
  onCancel,
}: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        title={<FormattedMessage id="projectList/removeModal/title"/>}
        buttonOnClick={onCancel}
      />

      <ModalBody>
        <div className="bp-share-project-remove-modal__body">
          <FormattedMessage
            id="shareProject/removeModal/body"
            values={{
              firstName: user.firstName,
              lastName: user.lastName,
              projectName: project.data.title,
            }}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          size="md"
          onClick={onCancel}
          autoFocus={true}
          disabled={submitting}
        >
          <FormattedMessage id="shareProject/removeModal/action/cancel"/>
        </CustomButton>

        <CustomButton
          disabled={submitting}
          kind="primary"
          size="md"
          onClick={onConfirm}
          loading={submitting}
        >
          <FormattedMessage id="shareProject/removeModal/action/remove"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};
