import './BottomNavigationHelper.scss';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';

type Props = {
  routeBackText?: string;
  routeBackPath?: string;
  routeForthText: string;
  routeForthPath: string;
};

export const BottomNavigationHelper = memo(({
  routeBackText,
  routeBackPath,
  routeForthText,
  routeForthPath,
}:Props) => (
  <div className="bp-bottom-navigation-helper">
    {(routeBackPath && routeBackText) && (
      <CustomButton
        kind="ghost"
        as={Link}
        to={routeBackPath}
        size="md"
      >
        <div className="bp-bottom-navigation-helper__button-icon">
          <ArrowLeftIcon/>
        </div>

        <FormattedMessage id="common/bottomNavigation/back" values={{ backText: routeBackText }}/>
      </CustomButton>
    )}

    <CustomButton
      className="bp-bottom-navigation-helper__forth-button"
      as={Link}
      to={routeForthPath}
      size="md"
    >
      <FormattedMessage id="common/bottomNavigation/forth" values={{ forthText: routeForthText }}/>

      <div className="bp-bottom-navigation-helper__button-icon">
        <ArrowRightIcon/>
      </div>
    </CustomButton>
  </div>
));
