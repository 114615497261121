import './ProjectListImportDataTable.scss';
import { Information } from '@carbon/icons-react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSelectRow,
  Tabs,
  Tag,
  Tooltip,
} from '@carbon/react';
import { fold, isLeft, isRight } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTab, CustomTabList } from 'src/modules/common/components/CustomTabList';
import { FormattedMathMessage } from 'src/modules/common/components/FormattedMathMessage';
import { ProjectListImportData, ProjectListImportEntry } from 'src/modules/project-list/types/ProjectListImport';

type Props = {
  readonly content: ProjectListImportData;
  readonly selected: ReadonlyArray<ProjectListImportEntry>;
  readonly onChange: (selected: ReadonlyArray<ProjectListImportEntry>) => void;
};

export const ProjectListImportDataTable = ({ content, selected, onChange }: Props): React.ReactElement => {
  const allTab = 0;

  const intl = useIntl();

  const [activeTab, setActiveTab] = useState(allTab);
  const handleTabChange = useCallback((state: {
    selectedIndex: number;
  }) => setActiveTab(state.selectedIndex), []);

  const errorCount = content.reduce((sum, it) => (isRight(it.importData) ? sum : sum + 1), 0);
  const visibleProjects = activeTab === allTab ? content : content.filter((it) => isLeft(it.importData));
  const composedContent = visibleProjects.map((row) => ({
    id: String(row.rowNumber),
    importData: row.importData,
  }));

  const handleToggle = useCallback((entry: ProjectListImportEntry) => {
    if (selected.includes(entry)) {
      onChange(selected.filter((it) => it !== entry));
    } else {
      onChange(selected.concat(entry));
    }
  }, [selected, onChange]);

  return (
    <Tabs onChange={handleTabChange} selectedIndex={activeTab}>
      <CustomTabList
        ariaLabel={intl.formatMessage({ id: 'projectList/importModal/selector/tabs' })}
        contained={false}
        isFullWidth={true}
      >
        <CustomTab>
          <div className="bp-project-list-import-data-table__tab">
            <FormattedMessage id="projectList/importModal/selector/tab/all"/>

            {content.length}
          </div>
        </CustomTab>

        <CustomTab
          disabled={errorCount === 0}
        >
          <div className="bp-project-list-import-data-table__tab">
            <FormattedMessage id="projectList/importModal/selector/tab/errors"/>

            {errorCount}
          </div>
        </CustomTab>
      </CustomTabList>

      <DataTable rows={composedContent} headers={[]} radio={true}>
        {({
          rows,
          getRowProps,
          getSelectionProps,
          getTableProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()} className="bp-project-list-import-data-table__table">
            <Table {...getTableProps()}>
              <TableBody>
                {composedContent.map((row, index) => (
                  <TableRow
                    {...getRowProps({
                      row: rows[index],
                    })}
                    key={row.id}
                  >
                    {pipe(row.importData, fold((importErrors) => (
                      <TableCell className="bp-project-list-import-data-table__cell">
                        <Tooltip
                          id={`import-error-details--${row.id}`}
                          align="right"
                          label={(
                            <ol>
                              {importErrors.map((importError, errorIndex) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={errorIndex}>
                                  <p className="bp-project-list-import-data-table__error-text">
                                    <FormattedMathMessage
                                      id="projectList/importModal/selector/item/invalidDetails"
                                      values={{ ...importError, code }}
                                    />
                                  </p>
                                </li>
                              ))}
                            </ol>
                          )}
                        >
                          <Information size={22}/>
                        </Tooltip>
                      </TableCell>
                    ), (importEntry) => (
                      <TableSelectRow
                        {...getSelectionProps({
                          row: rows[index],
                        })}
                        className="bp-project-list-import-data-table__cell
                        bp-project-list-import-data-table__cell--checkbox"
                        radio={false}
                        onSelect={() => handleToggle(importEntry)}
                        checked={selected.includes(importEntry)}
                      />
                    )))}

                    <TableCell
                      className="bp-project-list-import-data-table__cell
                      bp-project-list-import-data-table__cell--status"
                    >
                      {pipe(row.importData, fold(() => (
                        <Tag
                          type="red"
                        >
                          <FormattedMessage id="projectList/importModal/selector/item/statusFailure"/>
                        </Tag>
                      ), () => (
                        <Tag
                          type="green"
                        >
                          <FormattedMessage id="projectList/importModal/selector/item/statusSuccess"/>
                        </Tag>
                      )))}
                    </TableCell>

                    <TableCell>
                      {pipe(row.importData, fold(() => (
                        <p className="bp-project-list-import-data-table__invalid-text">
                          <FormattedMessage id="projectList/importModal/selector/item/invalidData"/>
                        </p>
                      ), (importEntry) => (
                        <p className="bp-project-list-import-data-table__valid-text">
                          {importEntry.project.title}
                        </p>
                      )))}
                    </TableCell>

                    <TableCell>
                      <p
                        className="bp-project-list-import-data-table__row-text
                        bp-project-list-import-data-table__row-text--number"
                      >
                        <FormattedMessage
                          id="projectList/importModal/selector/item/rowNumber"
                          values={{ number: row.id }}
                        />
                      </p>
                    </TableCell>
                  </TableRow>
                ))}

                {composedContent.length === 0 && (
                  <TableRow>
                    <TableCell>
                      <p className="bp-project-list-import-data-table__valid-text">
                        <FormattedMessage id="projectList/importModal/selector/empty"/>
                      </p>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </Tabs>
  );
};

const code = (children: React.ReactNode): React.ReactElement => (
  <b style={{ fontFamily: 'monospace' }}>{children}</b>
);
