import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getHvacResultsOverallRating } from 'src/modules/hvac/selectors/getHvacResultsOverallRating';
import { getProjectListItemTextBlocks } from 'src/modules/project-list/selectors/getProjectListItemTextBlocks';
import { ProjectListItem } from 'src/modules/project-list/types/ProjectListItem';

export const getProjectListItem = createSelector([
  getProjectLazy,
  getHvacResultsOverallRating,
  getProjectListItemTextBlocks,
], (project, rating, textBlocks): ProjectListItem => ({
  project,
  rating,
  textBlocks,
}));
