import { HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormVariantData } from 'src/modules/hvac/types/HvacFormData';

export function getDefaultFormVariant(config: HvacConfigSection): HvacFormVariantData {
  return {
    option: null,
    additionals: config.additionals.map((it) => ({
      config: it,
      value: null,
    })),
  };
}
