import { Ord } from 'fp-ts/Ord';
import { Ordering } from 'fp-ts/Ordering';

// NOTE: Option.getOrd is not OK because CMP(NONE, SOME) == -1
export const ordNullable: <T>(ord: Ord<T>) => Ord<T | null> = (ord) => ({
  equals: (a, b): boolean => {
    if (a === null) {
      return b === null;
    }
    if (b === null) {
      return false;
    }

    return ord.equals(a, b);
  },
  compare: (a, b): Ordering => {
    if (a === null) {
      return b === null ? 0 : 1;
    }
    if (b === null) {
      return -1;
    }

    return ord.compare(a, b);
  },
});

export const ordPrecedence: <T>(value: T) => ((ord: Ord<T>) => Ord<T>) = (value) => (ord) => ({
  equals: ord.equals,
  compare: (a, b): Ordering => {
    if (a === b) {
      return 0;
    }
    if (a === value) {
      return -1;
    }
    if (b === value) {
      return +1;
    }

    return ord.compare(a, b);
  },
});
