import { createSelector } from 'reselect';
import { getEvaluationFormData } from 'src/modules/evaluation/selectors/getEvaluationFormData';
import { getEvaluationResults } from 'src/modules/evaluation/selectors/getEvaluationResults';
import { getReportCurrency } from 'src/modules/report/selectors/getReportCurrency';
import { ReportGeneratedEvaluation } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedEvaluation = createSelector([
  getReportCurrency,
  getEvaluationFormData,
  getEvaluationResults,
], (currency, inputs, results): ReportGeneratedEvaluation => ({
  currency,
  inputs,
  results,
}));
