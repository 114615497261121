import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { array } from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { CONFIG_DEVICE } from 'src/modules/config/codecs/ConfigDevice';
import { ConfigDevice } from 'src/modules/config/types/ConfigDevice';
import { call } from 'typed-redux-saga';

export function* loadDevices(): SagaIterator<ConfigDevice[]> {
  try {
    const response = yield* call(fetch, '/configs/devices.json', {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      array(CONFIG_DEVICE),
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch schema devices', {}, NativeError.wrap(error));
  }
}
