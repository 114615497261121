import { Action } from 'redux-saga';
import { FUTURE } from 'src/modules/common/types/Future';
import { SHARE_PROJECT_CLOSE, SHARE_PROJECT_OPEN } from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { ShareProjectState } from 'src/modules/sharing-projects/types/ShareProjectState';

export function shareProjectDataReducer(state: ShareProjectState, action: Action): ShareProjectState {
  if (SHARE_PROJECT_CLOSE.is(action)) {
    return { ...state, data: null };
  }
  if (SHARE_PROJECT_OPEN.is(action)) {
    return {
      ...state,
      data: {
        project: action.data,
        users: FUTURE.pending(),
        removing: null,
      },
    };
  }

  return state;
}
