import { ProjectSchema, SchemaArea } from '@belimo-retrofit-portal/logic';
import { ThermalEnergy } from 'src/modules/common/types/ThermalEnergy';
import { MultipleSchemaPreselectedData, SchemaSelectedData } from 'src/modules/schema/types/SchemaSelectionState';

export const SCHEMA_CONTAINER_SIZE = {
  x: 100,
  y: 100,
};

export const DEFAULT_PROJECT_SCHEMA_DATA: ProjectSchema = {
  [SchemaArea.HEATING]: null,
  [SchemaArea.COOLING]: null,
  [SchemaArea.VENTILATION]: null,
};

export const SCHEMA_AREA_MAP: Record<ThermalEnergy, SchemaArea> = {
  [ThermalEnergy.HEATING]: SchemaArea.HEATING,
  [ThermalEnergy.COOLING]: SchemaArea.COOLING,
  [ThermalEnergy.MIXED]: SchemaArea.HEATING, // TODO: ?!
};

export const DEFAULT_MULTIPLE_PRESELECTED_DATA: Record<SchemaArea, SchemaSelectedData | null> = {
  [SchemaArea.HEATING]: null,
  [SchemaArea.COOLING]: null,
  [SchemaArea.VENTILATION]: null,
};

export const PRESELECTED_SCHEMA_DATA: MultipleSchemaPreselectedData = {
  [SchemaArea.HEATING]: null,
  [SchemaArea.COOLING]: null,
  [SchemaArea.VENTILATION]: null,
};
