import './CustomToggle.scss';
import { Toggle, ToggleProps } from '@carbon/react';
import clsx from 'clsx';
import React from 'react';

export const CustomToggle = ({ className, ...props }: ToggleProps): React.ReactElement => (
  <Toggle
    {...props}
    className={clsx('bp-custom-toggle', className)}
  />
);
