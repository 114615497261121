import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { getPreferredLocale } from 'src/modules/common/utils/locale';
import { CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { loadTranslations } from 'src/modules/config/sagas/utils/loadTranslations';
import { saveLanguage } from 'src/modules/config/sagas/utils/saveLanguage';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { hideLoadingIndicator, showLoadingIndicator } from 'src/modules/loader/sagas/utils/loadingIndicator';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* configLanguageSwitchSaga(
  action: GetRequestActionType<typeof CONFIG_LANGUAGE_SWITCH>,
): SagaIterator<void> {
  const language = action.data;
  const loaderKey = 'language-switch';
  try {
    yield* call(showLoadingIndicator, loaderKey);

    yield* call(logDebug, `Switching language to "${language}"...`);
    yield* put(CONFIG_LANGUAGE_SWITCH.pending());

    const locale = yield* call(getPreferredLocale);
    const translations = yield* call(loadTranslations, language);
    yield* call(saveLanguage, language);

    yield* call(logDebug, `Switching language to "${language}"... done`);
    yield* put(CONFIG_LANGUAGE_SWITCH.success({ locale, language, translations }));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, `Switching language to "${language}"... error`, { error });
    yield* put(CONFIG_LANGUAGE_SWITCH.failure(NativeError.wrap(error)));
  } finally {
    yield* call(hideLoadingIndicator, loaderKey);
  }
}
