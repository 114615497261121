import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const DateIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M15.067 18.933v-1.866h1.866v1.866h-1.866Zm-5.334 0v-1.866H11.6v1.866H9.733Zm10.667 0v-1.866h1.867v1.866H20.4ZM15.067 24v-1.867h1.866V24h-1.866Zm-5.334 0v-1.867H11.6V24H9.733ZM20.4 24v-1.867h1.867V24H20.4ZM5.733 27.6V7.067H10.4V4h1.067v3.067h9.2V4h.933v3.067h4.667V27.6H5.733Zm.934-.933h18.666V14.4H6.667v12.267Zm0-13.2h18.666V8H6.667v5.467Z"
      />
    </svg>
  </IconWrapper>
));
