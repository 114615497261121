import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { MODAL_PROJECT_ERROR_CLOSE, MODAL_PROJECT_ERROR_RETRY } from 'src/modules/modals/actions/ModalActions';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectForeground } from 'src/modules/project-sync/sagas/utils/syncProjectForeground';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* modalProjectErrorSaga(
  action: GetRequestActionType<typeof MODAL_PROJECT_ERROR_RETRY>,
): SagaIterator<void> {
  const project = action.data;

  try {
    yield* call(logDebug, 'Synchronizing project...', project);
    yield* put(MODAL_PROJECT_ERROR_RETRY.pending());

    const synchronized = yield* call(syncProjectForeground, project);
    yield* call(logDebug, 'Synchronizing project... done', project);

    yield* put(MODAL_PROJECT_ERROR_RETRY.success(synchronized));
    yield* put(MODAL_PROJECT_ERROR_CLOSE.trigger());
  } catch (error) {
    const wrapped = new RuntimeError('Could not save synchronize project', { project }, NativeError.wrap(error));
    yield* call(logError, 'Synchronizing project... error', error);
    yield* put(MODAL_PROJECT_ERROR_RETRY.failure(wrapped));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
