import { NativeError } from '@belimo-retrofit-portal/logic';
import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { USER_SAVE } from 'src/modules/users/actions/UserActions';
import { USER_FORM_SCHEMA } from 'src/modules/users/constants/schema';
import { createUser } from 'src/modules/users/sagas/utils/createUser';
import { updateUser } from 'src/modules/users/sagas/utils/updateUser';
import { User } from 'src/modules/users/types/User';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* userSaveSaga(action: GetRequestActionType<typeof USER_SAVE>): SagaIterator<void> {
  const userForm = action.data;
  const userValues = userForm.values.get(userForm.currentState);
  const userId = action.meta;

  const formValid = yield* call(formValidate, userForm, USER_FORM_SCHEMA);
  if (!formValid) {
    return;
  }

  try {
    yield* put(USER_SAVE.pending(action.meta));
    yield* call(logDebug, 'Saving user...');
    yield* call(formSubmitStart, userForm);

    let savedUser: User;
    const savedUserData = { ...userValues, email: userValues.email.trim() };
    if (!userId) {
      savedUser = yield* call(createUser, savedUserData);
    } else {
      savedUser = yield* call(updateUser, userId, savedUserData);
    }

    yield* put(replace('/admin/users'));

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'admin/user/notification/add/success',
      },
    );

    yield* call(logDebug, 'Saving user... done', savedUser);
    yield* put(USER_SAVE.success(savedUser, action.meta));
    yield* call(formSubmitSuccess, userForm);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, `Saving user "${action.meta}"... error`, error);
    yield* put(USER_SAVE.failure(NativeError.wrap(error), action.meta));

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(formSubmitFailure, userForm, {
      '': {
        path: '',
        code: 'unknown',
        value: userValues,

        message: String(error),
        context: {},
      },
    });
  }
}
