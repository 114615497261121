import './UserEditForm.scss';
import { TrashCan } from '@carbon/icons-react';
import { Stack } from '@carbon/react';
import { FormController } from '@form-ts/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { FormSection } from 'src/modules/common/components/FormSection';
import { UserForm } from 'src/modules/common/components/UserForm';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  editing: boolean;
  form: FormController<UserFormData, FormError>;
  isSelf: boolean;
  onDelete?: () => void;
};

export const UserEditForm = ({ editing, form, isSelf, onDelete }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <Stack as="main" className="bp-user-edit-form" gap="1rem" orientation="vertical">
      <FormSection title={intl.formatMessage({ id: 'admin/user/edit/section/mainSettings/title' })}>
        <UserForm
          className="bp-user-edit-form__main-settings"
          disableEmail={editing}
          disableRole={isSelf}
          form={form}
          isUserCreation={false}
        />
      </FormSection>

      <FormSection title={intl.formatMessage({ id: 'admin/user/edit/section/delete/title' })}>
        <CustomButton
          className="bp-user-edit-form__delete-user-button"
          kind="danger"
          disabled={isSelf}
          onClick={onDelete}
          size="md"
        >
          {intl.formatMessage({ id: 'admin/user/edit/deleteButton' })}

          <TrashCan className="bp-user-edit-form__delete-user-icon" size="1.5rem"/>
        </CustomButton>
      </FormSection>
    </Stack>
  );
};
