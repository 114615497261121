import React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { PreserveContent } from 'src/modules/common/components/PreserveContent';
import {
  SHARE_PROJECT_CLOSE,
  SHARE_PROJECT_SUBMIT,
  SHARE_PROJECT_REMOVE_CLOSE,
  SHARE_PROJECT_REMOVE_OPEN,
  SHARE_PROJECT_REMOVE_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { getShareProjectData } from 'src/modules/sharing-projects/selectors/getShareProjectData';
import { ShareProjectModal } from 'src/modules/sharing-projects/views/ShareProjectModal';
import { ShareProjectRemoveModal } from 'src/modules/sharing-projects/views/ShareProjectRemoveModal';

export const ShareProject = (): React.ReactElement => {
  const modalData = useSelector(getShareProjectData);

  const shareProjectClose = useBoundAction(SHARE_PROJECT_CLOSE.trigger, undefined);
  const shareProjectSubmit = useAction(SHARE_PROJECT_SUBMIT.request);

  const removeUserClose = useBoundAction(SHARE_PROJECT_REMOVE_CLOSE.trigger, undefined);
  const removeUserRequest = useAction(SHARE_PROJECT_REMOVE_OPEN.trigger);
  const removeUserConfirm = useAction(SHARE_PROJECT_REMOVE_SUBMIT.request);

  return (
    <>
      <CustomModal
        open={modalData !== null}
        onClose={shareProjectClose}
        size="lg"
        shouldUnmount={true}
        isShaded={true}
      >
        <PreserveContent>
          {modalData !== null && (
            <ShareProjectModal
              project={modalData.project}
              users={modalData.users}
              onSubmit={shareProjectSubmit}
              onCancel={shareProjectClose}
              onRemove={removeUserRequest}
            />
          )}
        </PreserveContent>
      </CustomModal>

      <CustomModal
        open={modalData !== null && modalData.removing !== null}
        onClose={removeUserClose}
        size="lg"
        shouldUnmount={true}
        isShaded={true}
      >
        <PreserveContent>
          {modalData !== null && modalData.removing !== null && (
            <ShareProjectRemoveModal
              project={modalData.project}
              user={modalData.removing.user}
              submitting={modalData.removing.status?.state === 'pending'}
              onConfirm={removeUserConfirm}
              onCancel={removeUserClose}
            />
          )}
        </PreserveContent>
      </CustomModal>
    </>
  );
};
