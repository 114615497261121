import { HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormSectionData } from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormAnswer } from 'src/modules/hvac/utils/getDefaultFormAnswer';

export function getDefaultFormSection(config: HvacConfigSection): HvacFormSectionData {
  return {
    title: '',
    enabled: false,
    config: config,
    copies: [],
    answer: getDefaultFormAnswer(config),
  };
}
