import { History } from 'history';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { SCROLL_RESTORE } from 'src/modules/scroll/actions/ScrollRestoration';
import { getScrollRestoration } from 'src/modules/scroll/selectors/getScrollRestoration';

type Props = {
  readonly history: History;
};

export const ScrollRestoration = ({ history }: Props): null => {
  const scrollPosition = useSelector(getScrollRestoration);
  const scrollRestore = useAction(SCROLL_RESTORE.trigger);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    const unlisten = history.listen((location, action) => {
      if (action === 'REPLACE') {
        scrollRestore({
          isRestoring: false,
          lastScrollPosition: window.scrollY,
        });
      }

      if (action === 'PUSH') {
        scrollRestore({
          isRestoring: false,
          lastScrollPosition: window.scrollY,
        });
      }

      if (action === 'POP') {
        scrollRestore({
          isRestoring: true,
          lastScrollPosition: scrollPosition.lastScrollPosition,
        });
      }
    });

    return () => {
      unlisten();
      window.history.scrollRestoration = 'auto';
    };
  }, [history, scrollRestore, scrollPosition.lastScrollPosition]);

  return null;
};
