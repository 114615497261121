import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { getPreferredLanguage } from 'src/modules/common/utils/language';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet } from 'src/sagas/utils/storage';
import { Language } from 'src/types/common/Language';
import { call } from 'typed-redux-saga';

export function* loadLanguage(environmentLanguages: ReadonlyArray<Language>): SagaIterator<Language> {
  const preferred = yield* call(getPreferredLanguage, environmentLanguages);

  try {
    yield* call(logDebug, 'Loading user language...');

    const stored = yield* call(storageGet, 'language');
    const language = environmentLanguages.find(
      (environmentLanguage) => environmentLanguage === stored,
    ) || environmentLanguages[0];

    yield* call(logDebug, 'Loading user language... done', language);
    return language;
  } catch (error) {
    yield* call(logError, 'Loading user language... error', error);
    yield* call(sentryCatch, new RuntimeError('Could not load user language', {}, NativeError.wrap(error)));

    return preferred;
  }
}
