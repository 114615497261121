import './ProjectListPager.scss';
import { INTEGER, Integer } from '@belimo-retrofit-portal/logic';
import { PaginationNav } from '@carbon/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { PROJECT_LIST_PAGE_SIZE } from 'src/modules/project-list/constants/pageSize';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';

type Props = {
  readonly total: Integer;
  readonly query: ProjectListQuery;
};

export const ProjectListPager = ({ query, total }: Props): React.ReactElement => {
  const history = useHistory();

  return (
    <PaginationNav
      className="bp-project-list-pager"
      page={query.page - 1}
      totalItems={Math.ceil(total / PROJECT_LIST_PAGE_SIZE[query.view])}
      itemsShown={2}
      onChange={(pageIndex) => history.push(`?${getQueryString(pageIndex + 1, query)}`)}
    />
  );
};

function getQueryString(page: number | null, query: ProjectListQuery): string {
  return buildProjectListQuery({
    ...query,
    page: decodeOrGet(INTEGER, page, INTEGER_ONE),
  }).toString();
}
