import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ChartUpIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      {/* eslint-disable-next-line */}
      <path fill="currentColor" d="M3.65 20.35v-1.075l.7-.7v1.775h-.7Zm4 0v-5.075l.7-.7v5.775h-.7Zm4 0v-5.775l.7.725v5.05h-.7Zm4 0V15.3l.7-.7v5.75h-.7Zm4 0v-9.075l.7-.7v9.775h-.7Zm-16-6.1v-.975L10 6.925l4 4 6.35-6.35v.975L14 11.9l-4-4-6.35 6.35Z"/>
    </svg>
  </IconWrapper>
));
