import { hideLoadingIndicator, showLoadingIndicator } from 'src/modules/loader/sagas/utils/loadingIndicator';
import { errorTransitionHandling } from 'src/modules/routing/hooks/errorTransitionHandling';
import { errorTransitionLogging } from 'src/modules/routing/hooks/errorTransitionLogging';
import { createGlobalTransitionLoggingSaga } from 'src/modules/routing/hooks/globalTransitionLogging';
import { ErrorHook } from 'src/modules/routing/types/ErrorHook';
import { TransitionHook } from 'src/modules/routing/types/TransitionHook';
import { scrollRestore } from 'src/modules/scroll/utils/scrollRestore';

export const ROUTING_PRE_HOOKS: ReadonlyArray<TransitionHook> = [
  createGlobalTransitionLoggingSaga('Starting transition'),
  showLoadingIndicator.bind(null, 'routing-transition'),
];

export const ROUTING_POST_HOOKS: ReadonlyArray<TransitionHook> = [
  createGlobalTransitionLoggingSaga('Finishing transition'),
  hideLoadingIndicator.bind(null, 'routing-transition'),
  scrollRestore,
];

export const ROUTING_ERROR_HOOKS: ReadonlyArray<ErrorHook> = [
  errorTransitionLogging,
  errorTransitionHandling,
  hideLoadingIndicator.bind(null, 'routing-transition'),
];
