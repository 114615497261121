import './EvaluationFormView.scss';
import { ProjectEvaluation, CurrencyCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CurrencySymbol } from 'src/modules/common/components/CurrencySymbol';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { TileCard } from 'src/modules/common/components/TileCard';
import { DECIMAL_PLACES, MAX_TRILLION } from 'src/modules/common/constants/decimal';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly form: FormController<ProjectEvaluation, FormError>;
  readonly currency: CurrencyCode;
};

export const EvaluationFormView = ({ form, currency }: Props): React.ReactElement => (
  <div className="bp-evaluation-form-view">
    <TileCard title={(
      <FormattedMessage
        id="evaluation/form/initial/title"
        values={{ sub, sup }}
      />
    )}
    >
      <div className="bp-evaluation-form-view__field">
        <DecimalField
          size="md"
          field={form.field.at('investmentAmount')}
          autoComplete="off"
          decimalScale={DECIMAL_PLACES}
          maxValue={MAX_TRILLION}
          labelText={<FormattedMessage id="evaluation/form/initial/investmentAmount/label"/>}
          endAdornment={<CurrencySymbol currency={currency}/>}
          helperText={(
            <FormattedMessage
              id="evaluation/form/initial/investmentAmount/note"
              values={{ sub, sup }}
            />
          )}
        />
      </div>

      <div className="bp-evaluation-form-view__field">
        <DecimalField
          size="md"
          field={form.field.at('carbonEmissionPrice')}
          autoComplete="off"
          decimalScale={DECIMAL_PLACES}
          maxValue={MAX_TRILLION}
          labelText={(
            <FormattedMessage
              id="evaluation/form/initial/carbonEmissionPrice/label"
              values={{ sub, sup }}
            />
          )}
          endAdornment={(
            <FormattedMessage
              id="evaluation/form/initial/carbonEmissionPrice/unit"
              values={{ sub: sub, sup: sup, currency: <CurrencySymbol currency={currency}/> }}
            />
          )}
        />
      </div>
    </TileCard>

    <TileCard title={<FormattedMessage id="evaluation/form/proposal/title"/>}>
      <div className="bp-evaluation-form-view__field">
        <TextAreaField
          field={form.field.at('proposalDescription')}
          labelText={<FormattedMessage id="evaluation/form/proposal/description/label"/>}
          rows={6}
          maxLength={280}
          showHint={true}
        />
      </div>
    </TileCard>
  </div>
);
