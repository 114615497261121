import { fromEvent, merge, Observable } from 'rxjs';
import { filter, map, mergeAll, pairwise, scan, skipWhile, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { SchemaEventCanvas } from 'src/modules/schema/types/SchemaEvent';

const menu$ = fromEvent<PointerEvent>(window, 'contextmenu', {
  once: false,
  capture: true,
  passive: true,
});

export function canvasPointer(
  element: Element,
): Observable<SchemaEventCanvas> {
  const down$ = fromEvent<PointerEvent>(element, 'pointerdown', {
    once: false,
    capture: false,
    passive: true,
  });
  const move$ = fromEvent<PointerEvent>(element, 'pointermove', {
    once: false,
    capture: false,
    passive: true,
  });
  const stop$ = merge(
    ...[
      'pointerup',
      'pointercancel',
      'pointerleave',
    ].map((eventName) => fromEvent<PointerEvent>(element, eventName, {
      once: false,
      capture: false,
      passive: true,
    })),
  );

  return down$.pipe(
    filter((event) => event.isPrimary),
    switchMap((start) => {
      const initial: ReadonlyMap<number, PointerEvent> = new Map([[start.pointerId, start]]);

      return merge(stop$, down$, move$).pipe(
        takeUntil(menu$),
        scan((store, event) => {
          const next = new Map(store);
          if (event.type === 'pointermove' || event.type === 'pointerdown') {
            next.set(event.pointerId, event);
          } else {
            next.delete(event.pointerId);
          }
          return next;
        }, initial),
        takeWhile((store) => store.size > 0),
        skipWhile((store) => ignoreMove(store, start)),
        pairwise(),
        map(([prev, curr]) => handleMove(prev, curr)),
        mergeAll(),
      );
    }),
  );
}

function ignoreMove(
  currStore: ReadonlyMap<number, PointerEvent>,
  initPoint: PointerEvent,
): boolean {
  if (currStore.size > 1) {
    return false;
  }

  const currPoint = currStore.get(initPoint.pointerId);
  if (!currPoint) {
    return false;
  }

  return (
    Math.abs(currPoint.pageX - initPoint.pageX) <= UNCERTAINTY_THRESHOLD &&
    Math.abs(currPoint.pageY - initPoint.pageY) <= UNCERTAINTY_THRESHOLD
  );
}

function handleMove(
  prevStore: ReadonlyMap<number, PointerEvent>,
  nextStore: ReadonlyMap<number, PointerEvent>,
): SchemaEventCanvas[] {
  if (nextStore.size < prevStore.size) {
    return [];
  }

  const [nextA, nextB = null] = nextStore.values();
  const prevA = prevStore.get(nextA.pointerId);
  if (!prevA) {
    return [];
  }

  if (nextB === null) {
    return [{
      type: 'move',
      delta: {
        x: nextA.pageX - prevA.pageX,
        y: nextA.pageY - prevA.pageY,
      },
    }];
  }

  const prevB = prevStore.get(nextB.pointerId);
  if (!prevB) {
    return [];
  }

  const prevCenter = {
    x: (prevA.pageX + prevB.pageX) / 2,
    y: (prevA.pageY + prevB.pageY) / 2,
  };
  const nextCenter = {
    x: (nextA.pageX + nextB.pageX) / 2,
    y: (nextA.pageY + nextB.pageY) / 2,
  };

  const prevDistance = Math.hypot(
    prevA.pageX - prevB.pageX,
    prevA.pageY - prevB.pageY,
  );
  const nextDistance = Math.hypot(
    nextA.pageX - nextB.pageX,
    nextA.pageY - nextB.pageY,
  );

  return [{
    type: 'move',
    delta: {
      x: nextCenter.x - prevCenter.x,
      y: nextCenter.y - prevCenter.y,
    },
  }, {
    type: 'zoom',
    mode: 'relative',
    delta: nextDistance / prevDistance,
    point: nextCenter,
  }];
}

const UNCERTAINTY_THRESHOLD = 3;
