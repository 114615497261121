import { SagaIterator } from 'redux-saga';
import { TransitionHook } from 'src/modules/routing/types/TransitionHook';
import { delay } from 'typed-redux-saga';

export function emulateTransitionDelay(firstDuration: number, enterDuration: number): TransitionHook {
  return function* saga(transition): SagaIterator<void> {
    const duration = transition.from === null
      ? firstDuration
      : enterDuration;
    yield* delay(duration);
  };
}
