import './ProjectListSortLink.scss';
import { CaretDown, CaretUp } from '@carbon/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { ProjectListSort } from 'src/modules/project-list/types/ProjectListSort';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';

type Props = {
  readonly query: ProjectListQuery;
  readonly field: ProjectListSort['field'];
  readonly children: React.ReactNode;
};

export const ProjectListSortLink = ({ query, field, children }: Props): React.ReactElement => (
  <Link
    to={`?${getSortSearch(query, field)}`}
    className="bp-project-list-sort-field"
  >
    <span className="bp-project-list-sort-field__title">
      {children}
    </span>

    {query.sort.field === field && (
      <span className="bp-project-list-sort-field__indicator">
        {query.sort.direction === 'asc'
          ? <CaretUp size={16} fontSize="inherit"/>
          : <CaretDown size={16} fontSize="inherit"/>}
      </span>
    )}
  </Link>
);

function getSortSearch(query: ProjectListQuery, field: ProjectListSort['field']): string {
  const direction = query.sort.field === field && query.sort.direction === 'asc' ? 'desc' : 'asc';

  return buildProjectListQuery({
    ...query,
    page: INTEGER_ONE,
    sort: { field, direction },
  }).toString();
}
