import './SchemaContainerSelect.scss';
import { Dropdown, OnChangeData } from '@carbon/react';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceType } from 'src/modules/common/types/DeviceType';
import { bind, memoize } from 'src/modules/common/utils/decorator';
import { DEVICE_OPTIONS } from 'src/modules/schema/constants/options';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { SchemaDeviceView } from 'src/modules/schema/views/canvas/SchemaDeviceView';

type OptionType = {
  readonly value: DeviceType | 'allDevices';
  readonly label: DeviceType | 'allDevices';
};

type Props = {
  readonly assignment: SchemaAssignment;
  readonly onSelectDevice: (assignment: SchemaAssignment) => void;
};
type State = {
  readonly group: OptionType | null;
};

export class SchemaContainerSelect extends React.Component<Props, State> {
  public readonly state: State = {
    group: null,
  };

  public render(): React.ReactElement {
    const { group } = this.state;
    const { assignment: { container, selection } } = this.props;

    return (
      <div className="bp-schema-container-select">
        <Dropdown
          id="schemaEdit/containerFill/category"
          label=""
          titleText={<FormattedMessage id="schemaEdit/containerFill/category"/>}
          onChange={this.handleGroupChange}
          items={this.filteredDeviceOptions}
          itemToElement={this.renderItem}
          className="bp-dropdown-field bp-dropdown-field--lg"
          selectedItem={group || this.filteredDeviceOptions[0]}
          renderSelectedItem={this.renderItem}
        />

        <div className="bp-schema-container-select__grid">
          {container.devices
            .filter((it) => group === null || it.device.type === group.value)
            .map((device) => (
              <button
                className={clsx(
                  'bp-schema-container-select__item',
                  device.device.id === selection?.device.id &&
                  device.valvePosition === selection?.valvePosition
                    ? 'bp-schema-container-select__item--selected'
                    : null,
                )}
                key={`${device.device.id}-${device.valvePosition}`}
                type="button"
                onClick={this.getDeviceSelectHandler(device)}
              >
                <div className="bp-schema-container-select__view">
                  <svg
                    width={PREVIEW_SIZE.x}
                    height={PREVIEW_SIZE.y}
                    viewBox={`0 0 ${PREVIEW_SIZE.x} ${PREVIEW_SIZE.y}`}
                    overflow="visible"
                  >
                    <SchemaDeviceView
                      size={PREVIEW_SIZE}
                      device={device}
                    />
                  </svg>
                </div>

                <h6 className="bp-schema-container-select__item-title">
                  <FormattedMessage id={device.device.name}/>
                </h6>

                {device.device.explanation && (
                  <p className="bp-schema-container-select__item-description">
                    <FormattedMessage id={device.device.explanation}/>
                  </p>
                )}
              </button>
            ))}
        </div>
      </div>
    );
  }

  private renderItem(deviceType: OptionType): React.ReactElement {
    if (deviceType.value === 'allDevices') {
      return <FormattedMessage id="schemaEdit/containerFill/allDevices"/>;
    }

    return (
      <FormattedMessage id={`schema/device/type/${deviceType?.value}`}/>
    );
  }

  private get filteredDeviceOptions(): OptionType[] {
    const { assignment: { container } } = this.props;

    const filteredOptions = DEVICE_OPTIONS.filter(
      (type) => container.devices.some((it) => it.device.type === type),
    );

    const composedOptions = filteredOptions.map((option) => ({
      value: option,
      label: option,
    }));

    if (filteredOptions.length > 1) {
      return [
        { value: 'allDevices', label: 'allDevices' },
        ...composedOptions,
      ];
    }

    return composedOptions;
  }

  @bind()
  private handleGroupChange(event: OnChangeData<OptionType>): void {
    this.setState({ group: event.selectedItem || { value: 'allDevices', label: 'allDevices' } });
  }

  @memoize((device) => device)
  private getDeviceSelectHandler(device: SchemaDevice): () => void {
    return () => {
      const { assignment, onSelectDevice } = this.props;
      onSelectDevice({ ...assignment, selection: device });
    };
  }
}

const PREVIEW_SIZE = {
  x: 150,
  y: 150,
};
