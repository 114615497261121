import { createSelector } from 'reselect';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { DEFAULT_MULTIPLE_SCHEMA_MATRIX } from 'src/modules/schema/constants/multipleSchemaMatrix';
import { getProjectSchema } from 'src/modules/schema/selectors/getProjectSchema';

export const getSchemaMatrix = createSelector([getProjectSchema], (schema) => Object.entries(schema).reduce(
  (result, [area, s]) => {
    if (s === null) {
      return {
        ...result,
        [area]: MATRIX_IDENTITY,
      };
    }

    return {
      ...result,
      [area]: s.matrix,
    };
  },
  DEFAULT_MULTIPLE_SCHEMA_MATRIX,
));
