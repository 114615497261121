import { Action } from 'redux';
import { REPORT_RESET } from 'src/modules/report/actions/ReportActions';
import { ReportState } from 'src/modules/report/types/ReportState';

export function reportResetReducer(state: ReportState, action: Action): ReportState {
  if (REPORT_RESET.is(action)) {
    return { status: null };
  }

  return state;
}
