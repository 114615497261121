import { ModalHeader } from '@carbon/react';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { PreserveContent } from 'src/modules/common/components/PreserveContent';
import { ProjectListImport, ProjectListImportEntry } from 'src/modules/project-list/types/ProjectListImport';
import { ProjectListImportSelector } from 'src/modules/project-list/views/ProjectListImportSelector';
import { ProjectListImportUpload } from 'src/modules/project-list/views/ProjectListImportUpload';

type Props = {
  readonly data: ProjectListImport | null;
  readonly onUpload: (file: File) => void;
  readonly onCancel: () => void;
  readonly onSubmit: (entries: ReadonlyArray<ProjectListImportEntry>) => void;
  readonly onComplete: () => void;
};

export const ProjectListImportModal = ({
  data,
  onUpload,
  onCancel,
  onSubmit,
  onComplete,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const title = useMemo(() => {
    if (data?.upload?.state === 'success') {
      return intl.formatMessage({ id: 'projectList/importModal/selector/title' });
    }

    if (data?.upload?.state !== 'failure') {
      return intl.formatMessage({ id: 'projectList/importModal/upload/title' });
    }

    return null;
  }, [data?.upload?.state, intl]);

  const handleClose = useCallback(() => {
    if (data?.submit?.state !== 'pending') {
      onCancel();
    }
  }, [data?.submit, onCancel]);

  return (
    <CustomModal
      open={data !== null}
      isShaded={true}
      size="md"
      onClose={handleClose}
      isFullScreen={true}
      shouldUnmount={true}
    >
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={intl.formatMessage({ id: 'projectList/importModal/title' })}
        title={title}
      />

      <PreserveContent>
        {data && (
          <>
            {data.upload?.state === 'success' && (
              <ProjectListImportSelector
                content={data.upload.value}
                status={data.submit}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onComplete={onComplete}
              />
            )}
            {data.upload?.state !== 'success' && (
              <ProjectListImportUpload
                status={data.upload}
                onUpload={onUpload}
                onCancel={onCancel}
              />
            )}
          </>
        )}
      </PreserveContent>
    </CustomModal>
  );
};
