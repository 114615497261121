import { NativeError, RuntimeError, SchemaArea } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { MULTIPLE_SCHEMA_PRESELECTED_CODEC } from 'src/modules/schema/codecs/MultipleSchemaForm';
import { SCHEMA_PRESELECTED } from 'src/modules/schema/constants/localStorageKeys';
import { getSchemaPreselected } from 'src/modules/schema/selectors/getSchemaSelectionState';
import { MultipleSchemaPreselectedData, SchemaSelectedData } from 'src/modules/schema/types/SchemaSelectionState';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageSet } from 'src/sagas/utils/storage';
import { call, select } from 'typed-redux-saga';

export function* savePreselectedSchema(
  data: SchemaSelectedData | null,
  area: SchemaArea,
): SagaIterator<MultipleSchemaPreselectedData> {
  const previous = yield* select(getSchemaPreselected);

  try {
    yield* call(logDebug, 'Saving preselected schema...', data);

    const modified: MultipleSchemaPreselectedData = {
      ...previous,
      [area]: data,
    };

    const stored = yield* call(MULTIPLE_SCHEMA_PRESELECTED_CODEC.encode, modified);
    yield* call(storageSet, SCHEMA_PRESELECTED, stored);

    yield* call(logDebug, 'Saving preselected schema... done', data);
    return modified;
  } catch (error) {
    const wrapped = new RuntimeError('Could not save preselected schema', { data }, NativeError.wrap(error));

    yield* call(logError, 'Saving preselected schema... error', error);
    yield* call(sentryCatch, wrapped);
    throw wrapped;
  }
}
