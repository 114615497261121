import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* shareProjectWith(
  project: Project,
  users: ReadonlyArray<string>,
): SagaIterator<void> {
  try {
    yield* call(makeRestRequest, 'POST', `/projects/${encodeURIComponent(project.id)}/users`, { users });
  } catch (error) {
    throw new RuntimeError('Could not post user to share project with', { users, project }, NativeError.wrap(error));
  }
}
