import './BodyLargeRegularText.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const BodyLargeRegularText = ({ className, children }: Props): React.ReactElement => (
  <p className={clsx('bp-body-large-regular-text', className)}>
    {children}
  </p>
);
