import './FeedbackSent.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const FeedbackSent = (): React.ReactElement => (
  <div className="bp-feedback-sent">
    <h6 className="bp-feedback-sent__title">
      <FormattedMessage id="feedback/modal/sent/title"/>
    </h6>
  </div>
);
