import './HvacFormSectionCopy.scss';
import { AccordionItem } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormChapterData, HvacFormData, HvacFormSectionCopyData } from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormAnswer } from 'src/modules/hvac/utils/getDefaultFormAnswer';
import { HvacFormSectionContent } from 'src/modules/hvac/views/HvacFormSectionContent';
import {
  HvacFormSectionCopyDeleteConfirmationModal,
} from 'src/modules/hvac/views/HvacFormSectionCopyDeleteConfirmationModal';
import { HvacFormSectionCopyTitleModal } from 'src/modules/hvac/views/HvacFormSectionCopyTitleModal';
import { HvacFormSectionHeader } from 'src/modules/hvac/views/HvacFormSectionHeader';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormChapterData>;
  readonly config: HvacConfigSection;
  readonly disabled: boolean;
  readonly readOnly: boolean;
  readonly sectionIndex: number;
  readonly copyIndex: number;
};

export const HvacFormSectionCopy = ({
  path, config, disabled, readOnly, sectionIndex, copyIndex,
}: Props): React.ReactElement => {
  const [isItemOpened, setItemOpened] = useState(false);
  const [isDeleteConfirmationModalOpened, setDeleteConfirmationModalOpened] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const field = path.at('sections').at(sectionIndex).at('copies').at(copyIndex);

  const { value: section } = useFormField(field);

  const handleClick = useCallback((
    {
      isOpen,
      event,
    }: {
      isOpen: boolean;
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    },
  ) => {
    setItemOpened(isOpen);

    if (isOpen) {
      event.currentTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleDeleteConfirmationModalOpen = useCallback(() => {
    setDeleteConfirmationModalOpened(true);
  }, []);

  const handleDeleteConfirmationModalCancel = useCallback(() => {
    setDeleteConfirmationModalOpened(false);
  }, []);

  const handleDeleteConfirmationModalSubmit = useCallback(() => {
    const copiesPath = path.at('sections').at(sectionIndex).at('copies');
    const copiesValues = copiesPath.value
      .get(path.form.currentState)
      .filter((_, index) => index !== copyIndex);

    path.form.change(pipe(
      path.form.currentState,
      copiesPath.value.set(copiesValues),
    ));

    setDeleteConfirmationModalOpened(false);
  }, [path, copyIndex, sectionIndex]);

  const handleClear = useCallback(() => {
    const answer = getDefaultFormAnswer(config);

    field.form.change(pipe(
      field.form.currentState,
      field.at('answer').value.set(answer),
    ));
  }, [field, config]);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);
  const handleModalCancel = useCallback(() => {
    setModalOpen(false);
  }, []);
  const handleModalSubmit = useCallback((title: string) => {
    setModalOpen(false);

    field.form.change(pipe(
      field.form.currentState,
      field.at('title').value.set(title),
    ));
  }, [field]);

  const renderToggle: ({ onClick }: {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }) => React.JSX.Element = useCallback(({ onClick }) => (
    <HvacFormSectionHeader
      actual={section.answer.actual.option?.class ?? null}
      future={section.answer.future.option?.class ?? null}
      isItemOpened={isItemOpened}
      disabled={disabled}
      readOnly={readOnly}
      isCopy={true}
      onClick={onClick}
      onRemove={handleDeleteConfirmationModalOpen}
      onRename={handleModalOpen}
    >
      <FormattedMessage id={config.name}/> &ndash; {section.title}
    </HvacFormSectionHeader>
  ), [
    isItemOpened,
    disabled,
    readOnly,
    handleDeleteConfirmationModalOpen,
    handleModalOpen,
    config.name,
    section,
  ]);

  return (
    <>
      <AccordionItem
        className="bp-hvac-form-section-copy"
        disabled={readOnly || disabled}
        onHeadingClick={handleClick}
        renderToggle={renderToggle}
      >
        <HvacFormSectionContent
          path={field.at('answer')}
          disabled={readOnly || disabled}
          config={config}
          onClear={handleClear}
        />
      </AccordionItem>

      <HvacFormSectionCopyTitleModal
        open={!readOnly && !disabled && modalOpen}
        title={section.title}
        onCancel={handleModalCancel}
        onSubmit={handleModalSubmit}
      />

      <HvacFormSectionCopyDeleteConfirmationModal
        sectionName={<><FormattedMessage id={config.name}/> &ndash; {section.title}</>}
        open={isDeleteConfirmationModalOpened}
        onClose={handleDeleteConfirmationModalCancel}
        onSubmit={handleDeleteConfirmationModalSubmit}
      />
    </>
  );
};
