import './UserActions.scss';
import { Stack } from '@carbon/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';

type Props = {
  className?: string;
  onSave?: () => void;
  submitting?: boolean;
};

export const UserActions = ({ className, onSave, submitting }: Props): React.ReactElement => {
  const intl = useIntl();
  const history = useHistory();

  const handleBack = (): void => history.goBack();

  return (
    <Stack className={className} orientation="horizontal">
      <CustomButton
        className="bp-user-actions__button"
        kind="secondary"
        onClick={handleBack}
        size="md"
      >
        <div className="bp-user-actions__button-content">
          <div className="bp-user-actions__button-icon">
            <ArrowLeftIcon/>
          </div>

          {intl.formatMessage({ id: 'admin/user/actions/cancelButton' })}
        </div>
      </CustomButton>

      <CustomButton
        className="bp-user-actions__button"
        disabled={submitting}
        loading={submitting}
        onClick={onSave}
        size="md"
      >
        {intl.formatMessage({ id: 'admin/user/actions/confirmButton' })}
      </CustomButton>
    </Stack>
  );
};
