import * as D from 'io-ts/Decoder';
import { LANGUAGE } from 'src/modules/common/codecs/Language';
import { Environment } from 'src/types/Environment';

export const ENVIRONMENT: D.Decoder<unknown, Environment> = D.struct({
  apiBaseUrl: D.string,
  sentryDsn: D.string,
  sentryEnv: D.string,
  languages: D.array(LANGUAGE),
});
