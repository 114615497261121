import { INTEGER, NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { PROJECT } from 'src/modules/common/codecs/Project';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* loadProjectListChunk(take: number, skip: number): SagaIterator<Project[]> {
  try {
    const query = new URLSearchParams({
      take: take.toString(),
      skip: skip.toString(),

      sort: 'title',
      order: 'asc',
    });

    const { content } = yield* call(makeRestRequest, 'GET', `/projects?${query.toString()}`);
    const { found } = yield* call(decodeOrThrow, RESPONSE_DATA, content);
    return found;
  } catch (error) {
    throw new RuntimeError('Could not load project list chunk', { take, skip }, NativeError.wrap(error));
  }
}

const RESPONSE_DATA = D.struct({
  total: INTEGER,
  found: D.array(PROJECT),
});
