import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

export function ReadOnlyArray<O, A>(item: C.Codec<unknown, O, A>): C.Codec<unknown, Array<O>, ReadonlyArray<A>> {
  return C.make<unknown, Array<O>, ReadonlyArray<A>>(
    D.array(item),
    { encode: (value) => E.array(item).encode(value.slice()) },
  );
}
