import { createSelector } from 'reselect';
import { getShareProjectData } from 'src/modules/sharing-projects/selectors/getShareProjectData';
import { assertNever } from 'src/utils/assert';

export const getShareProjectProject = createSelector([
  getShareProjectData,
], (data) => {
  if (data !== null) {
    return data.project;
  }

  return assertNever('Project to share is not initialed yet', { data });
});
