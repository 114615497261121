import { getEnergyEfficiencyLabel } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { EnergyEfficiencyLabel } from 'src/modules/common/components/EnergyEfficiencyLabel';
import { LABELS } from 'src/modules/common/constants/energyEfficiencyLabels';

type Props = {
  readonly value: Decimal | null;
  readonly sized: boolean;
  readonly disabled: boolean;
};

export const EnergyEfficiencyLabelWrapper = ({ value, sized, disabled }: Props): React.ReactElement => {
  if (value === null) {
    return (
      <EnergyEfficiencyLabel value={value} disabled={disabled} sized={sized}>
        &ndash;
      </EnergyEfficiencyLabel>
    );
  }

  const maxLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_UP));
  const minLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_DOWN));

  if (minLabel === maxLabel) {
    return (
      <EnergyEfficiencyLabel value={maxLabel} disabled={disabled} sized={sized}>
        {LABELS[maxLabel]}
      </EnergyEfficiencyLabel>
    );
  }

  return (
    <EnergyEfficiencyLabel value={maxLabel} disabled={disabled} sized={sized}>
      {LABELS[minLabel]}&ndash;{LABELS[maxLabel]}
    </EnergyEfficiencyLabel>
  );
};
