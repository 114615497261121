import './HvacResultEnergyElectricalView.scss';
import { EnergySource, getCarbonIntensityEditable, ProjectHvacConsumption } from '@belimo-retrofit-portal/logic';
import { FieldPath } from '@form-ts/core';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CarbonIntensityUnit } from 'src/modules/common/components/CarbonIntensityUnit';
import { EnergySourceUnit } from 'src/modules/common/components/EnergySourceUnit';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, ProjectHvacConsumption>;
  readonly disabled: boolean;
  readonly tooltip?: React.ReactNode;
};

export const HvacResultEnergyElectricalView = ({ path, disabled, tooltip }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <div className="bp-hvac-result-energy-electrical-view">
      <DecimalField
        size="md"
        labelText={(
          <div className="bp-hvac-result-energy-electrical-view__label">
            <FormattedMessage id="hvac/energy/annualEnergyUse"/>

            {tooltip}
          </div>
        )}
        field={path.at('annualEnergyUse')}
        disabled={disabled}
        autoComplete="off"
        endAdornment={<EnergySourceUnit source={EnergySource.ELECTRICITY}/>}
      />

      <DecimalField
        size="md"
        labelText={intl.formatMessage({ id: 'hvac/energy/carbonIntensity' })}
        field={path.at('carbonIntensity')}
        disabled={disabled || !getCarbonIntensityEditable(EnergySource.ELECTRICITY)}
        autoComplete="off"
        endAdornment={<CarbonIntensityUnit source={EnergySource.ELECTRICITY}/>}
      />
    </div>
  );
};
