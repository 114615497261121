import { FormController } from '@form-ts/core';
import { FormError } from 'src/modules/form/types/FormError';
import { BasicFormData } from 'src/modules/project-new/types/BasicFormData';
import { BuildingFormData } from 'src/modules/project-new/types/BuildingFormData';
import { createActions } from 'src/utils/createActions';

export const PROJECT_NEW_CREATE =
  createActions<CreateNewData, void>('PROJECT_NEW_CREATE');

type CreateNewData = {
  readonly stepOne: BasicFormData;
  readonly stepTwo: FormController<BuildingFormData, FormError>;
};
