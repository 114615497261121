import { AreaUnit, BuildingType, CurrencyCode, EnergySource } from '@belimo-retrofit-portal/logic';
import { ChangeOverLevel } from 'src/modules/common/types/ChangeOverLevel';
import { DifferentTemperatureLevel } from 'src/modules/common/types/DifferentTemperatureLevel';
import { ThermalEnergy } from 'src/modules/common/types/ThermalEnergy';
import { WaterToAir } from 'src/modules/common/types/WaterToAir';

export const ENERGY_SOURCE_HEATING_OPTIONS: ReadonlyArray<EnergySource> = [
  EnergySource.ELECTRICITY,
  EnergySource.HEATING_DISTRICT,
  EnergySource.HEATING_OIL,
  EnergySource.GAS,
  EnergySource.HEATING_OTHER,
];

export const ENERGY_SOURCE_COOLING_OPTIONS: ReadonlyArray<EnergySource> = [
  EnergySource.ELECTRICITY,
  EnergySource.COOLING_DISTRICT,
  EnergySource.GAS,
  EnergySource.COOLING_OTHER,
];

export const BUILDING_TYPE_OPTIONS: ReadonlyArray<BuildingType> = [
  BuildingType.OFFICE,
  BuildingType.DATA_CENTER,
  BuildingType.EDUCATION_ELEMENTARY,
  BuildingType.EDUCATION_HIGHER,
  BuildingType.EDUCATION_OTHER,
  BuildingType.ENTERTAINMENT_LEISURE,
  BuildingType.GOVERNMENT,
  BuildingType.HEALTH,
  BuildingType.HOSPITAL,
  BuildingType.HOTEL,
  BuildingType.LIFE_SCIENCE,
  BuildingType.RESTAURANT,
  BuildingType.RETAIL,
  BuildingType.WHOLESALE_RETAIL_TRADE,
];

export const CURRENCY_CODE_OPTIONS: ReadonlyArray<CurrencyCode> = [
  'EUR',
  'CHF',
  'USD',
  'GBP',
  'HKD',
];

export const UNIT_VERSION_OPTIONS: ReadonlyArray<AreaUnit> = [
  AreaUnit.SQM,
  AreaUnit.SQFT,
];

export const ALL_FILTER_FORM_OPTION = 'All';

export const THERMAL_ENERGY_OPTIONS: ReadonlyArray<ThermalEnergy | typeof ALL_FILTER_FORM_OPTION> = [
  ALL_FILTER_FORM_OPTION,
  ThermalEnergy.HEATING,
  ThermalEnergy.COOLING,
  ThermalEnergy.MIXED,
];

export const WATER_TO_AIR_OPTIONS: ReadonlyArray<WaterToAir | typeof ALL_FILTER_FORM_OPTION> = [
  ALL_FILTER_FORM_OPTION,
  WaterToAir.WATER,
  WaterToAir.WATER_TO_AIR,
];

export const CHANGE_OVER_LEVEL_OPTIONS: ReadonlyArray<ChangeOverLevel | typeof ALL_FILTER_FORM_OPTION> = [
  ALL_FILTER_FORM_OPTION,
  ChangeOverLevel.DISTRIBUTION,
  ChangeOverLevel.EMITTER,
  ChangeOverLevel.FLOOR,
  ChangeOverLevel.NO_CHANGE_OVER,
];

export const DIFFERENT_TEMPERATURE_LEVEL_OPTIONS: ReadonlyArray<
DifferentTemperatureLevel | typeof ALL_FILTER_FORM_OPTION
> = [
  ALL_FILTER_FORM_OPTION,
  DifferentTemperatureLevel.YES,
  DifferentTemperatureLevel.NO,
];
