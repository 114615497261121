import { AssertionError, DecodingError } from '@belimo-retrofit-portal/logic';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Decoder } from 'io-ts/Decoder';
import { isDefined, isNotEmpty, isNotNull } from 'src/utils/guard';

export function assertDefined<TValue>(
  value: TValue | undefined,
  message: string,
  context?: unknown,
): TValue {
  if (isDefined(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertNotNull<TValue>(
  value: TValue | null,
  message: string,
  context?: unknown,
): TValue {
  if (isNotNull(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertNotEmpty<TValue>(
  value: TValue | null | undefined,
  message: string,
  context?: unknown,
): TValue {
  if (isNotEmpty(value)) {
    return value;
  }

  throw new AssertionError(message, context);
}

export function assertDecode<TValue>(
  input: unknown,
  decoder: Decoder<unknown, TValue>,
  message: string,
  context?: unknown,
): TValue {
  return pipe(
    input,
    decoder.decode,
    fold(
      (error) => new AssertionError(message, context, new DecodingError(input, error)).throw(),
      (value) => value,
    ),
  );
}

export function assertNever(
  message: string,
  context?: unknown,
): never {
  throw new AssertionError(message, context);
}
