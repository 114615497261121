import { ProjectReport } from '@belimo-retrofit-portal/logic';
import { Project } from 'src/modules/common/types/Project';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

export const getReportHvacEnergy = (formData: HvacFormData, previous: Project): ProjectReport => {
  const currHvacAreas = formData.assessment;
  const prevHvacAreas = previous.data.hvac.areas;
  const prevReportHvac = previous.data.report.hvac;
  const prevReportEnergy = previous.data.report.energy;

  const isHeatingAreaChanged = prevHvacAreas.heating.enabled !== currHvacAreas.heating.enabled;
  const isCoolingAreaChanged = prevHvacAreas.cooling.enabled !== currHvacAreas.cooling.enabled;
  const isVentilationAreaChanged = prevHvacAreas.ventilation.enabled !== currHvacAreas.ventilation.enabled;

  const report: ProjectReport = {
    ...previous.data.report,
    hvac: {
      ...previous.data.report.hvac,
      showHeating: isHeatingAreaChanged ? currHvacAreas.heating.enabled : prevReportHvac.showHeating,
      showCooling: isCoolingAreaChanged ? currHvacAreas.cooling.enabled : prevReportHvac.showCooling,
      showVentilation: isVentilationAreaChanged ? currHvacAreas.ventilation.enabled : prevReportHvac.showVentilation,
    },
    energy: {
      ...previous.data.report.energy,
      showHeating: isHeatingAreaChanged ? currHvacAreas.heating.enabled : prevReportEnergy.showHeating,
      showCooling: isCoolingAreaChanged ? currHvacAreas.cooling.enabled : prevReportEnergy.showCooling,
      showVentilation: isVentilationAreaChanged ? currHvacAreas.ventilation.enabled : prevReportEnergy.showVentilation,
      showComments: Boolean(currHvacAreas.comment),
    },
  };

  return report;
};
