/* eslint-disable @typescript-eslint/dot-notation */
import { EnergySource, HvacArea } from '@belimo-retrofit-portal/logic';
import { DECIMAL_ZERO } from 'src/modules/common/constants/decimal';
import { ProjectListImportEntry, ProjectListImportRawLine } from 'src/modules/project-list/types/ProjectListImport';
import { PROJECT_VERSION } from 'src/modules/project-new/constants/projectData';

export function mapImportProjectData(line: ProjectListImportRawLine): ProjectListImportEntry {
  return {
    id: line['project id'],
    project: {
      version: PROJECT_VERSION,
      cover: null,
      title: line['project title'],
      goals: line['project goal'],
      currency: line['currency'],
      building: {
        type: line['building type'],
        size: line['building size'],
        unit: line['building size unit'],
        address: {
          country: line['building country'],
          city: line['building city'],
          street: line['building street address'],
          zip: line['building zip code'],
        },
      },
      participants: {
        author: line['participants author'],
        productManager: line['participants product engineer'],
        propertyManager: line['participants property manager'],
        consutlingEngineer: line['participants consulting engineer'],
      },
      hvac: {
        comment: line['project data and configuration'].hvac.comment,
        prices: {
          [EnergySource.ELECTRICITY]: line['electricity price'],
          [EnergySource.GAS]: line['gas price'],

          [EnergySource.HEATING_DISTRICT]: line['district heating price'],
          [EnergySource.HEATING_OIL]: line['heating oil price'],
          [EnergySource.HEATING_OTHER]: line['heating other price'],

          [EnergySource.COOLING_DISTRICT]: line['district cooling price'],
          [EnergySource.COOLING_OTHER]: line['cooling other price'],
        },
        areas: {
          [HvacArea.HEATING]: {
            enabled: line['project data and configuration'].hvac.areas[HvacArea.HEATING].enabled,
            sections: line['project data and configuration'].hvac.areas[HvacArea.HEATING].sections,
            thermal: {
              energySource: line['heating energy source'],
              carbonIntensity: line['heating energy carbon intensity'],
              annualEnergyUse: line['heating energy annual consumption'],
            },
            electrical: {
              energySource: EnergySource.ELECTRICITY,
              carbonIntensity: null,
              annualEnergyUse: line['heating electrical aux annual consumption'],
            },
          },
          [HvacArea.COOLING]: {
            enabled: line['project data and configuration'].hvac.areas[HvacArea.COOLING].enabled,
            sections: line['project data and configuration'].hvac.areas[HvacArea.COOLING].sections,
            thermal: {
              energySource: line['cooling energy source'],
              carbonIntensity: line['cooling energy carbon intensity'],
              annualEnergyUse: line['cooling energy annual consumption'],
            },
            electrical: {
              energySource: EnergySource.ELECTRICITY,
              carbonIntensity: null,
              annualEnergyUse: line['cooling electrical aux annual consumption'],
            },
          },
          [HvacArea.VENTILATION]: {
            enabled: line['project data and configuration'].hvac.areas[HvacArea.VENTILATION].enabled,
            sections: line['project data and configuration'].hvac.areas[HvacArea.VENTILATION].sections,
            thermal: {
              energySource: EnergySource.ELECTRICITY,
              carbonIntensity: null,
              annualEnergyUse: DECIMAL_ZERO,
            },
            electrical: {
              energySource: EnergySource.ELECTRICITY,
              carbonIntensity: null,
              annualEnergyUse: line['ventilation electrical aux annual consumption'],
            },
          },
        },
      },
      schema: line['project data and configuration'].schema,
      report: line['project data and configuration'].report,
      evaluation: line['project data and configuration'].evaluation,
    },
  };
}
