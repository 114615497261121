import './EvaluationScreen.scss';
import { CurrencyCode, EvaluationResults, ProjectEvaluation } from '@belimo-retrofit-portal/logic';
import { useForm } from '@form-ts/react';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { BottomNavigationHelper } from 'src/modules/common/components/BottomNavigationHelper';
import { EvaluationFormView } from 'src/modules/evaluation/views/EvaluationFormView';
import { EvaluationResultsCharts } from 'src/modules/evaluation/views/EvaluationResultsCharts';
import { EvaluationResultsSavings } from 'src/modules/evaluation/views/EvaluationResultsSavings';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly projectId: string;
  readonly formData: ProjectEvaluation;
  readonly onChange: (formData: ProjectEvaluation) => void;
  readonly results: EvaluationResults;
  readonly currency: CurrencyCode;
};

export const EvaluationScreen = ({ projectId, results, formData, onChange, currency }: Props): React.ReactElement => {
  const intl = useIntl();

  const projectSchemaUrl = `/project/${encodeURIComponent(projectId)}/schema`;
  const projectReportUrl = `/project/${encodeURIComponent(projectId)}/report`;

  const form = useForm<ProjectEvaluation, FormError>('project.evaluation', {
    reinitialize: false,
    initialValues: formData,
  });

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(nextState.values);
    }
  }), [form, onChange]);

  return (
    <div className="bp-evaluation-screen">
      <div className="bp-evaluation-screen__content">
        <EvaluationFormView
          form={form}
          currency={currency}
        />

        <EvaluationResultsCharts
          results={results}
          currency={currency}
        />

        <EvaluationResultsSavings
          results={results}
          currency={currency}
        />

        <BottomNavigationHelper
          routeBackText={intl.formatMessage({ id: 'common/bottomNavigation/schema' })}
          routeBackPath={projectSchemaUrl}
          routeForthText={intl.formatMessage({ id: 'common/bottomNavigation/report' })}
          routeForthPath={projectReportUrl}
        />
      </div>
    </div>
  );
};
