import './ReportFormSectionNoSchema.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { Project } from 'src/modules/common/types/Project';

type Props = {
  readonly area: SchemaArea;
  readonly project: Project;
};

export const ReportFormSectionNoSchema = ({ area, project }: Props): React.ReactElement => (
  <div className="bp-report-form-section-no-schema">
    <div className="bp-report-form-section-no-schema__title">
      <FormattedMessage
        id="report/form/noSchema/title"
        values={{ area: AREA_NAMES[area] }}
      />
    </div>

    <p className="bp-report-form-section-no-schema__description">
      <FormattedMessage
        id="report/form/noSchema/description"
        values={{ area: AREA_NAMES[area] }}
      />
    </p>

    <div className="bp-report-form-section-no-schema__action">
      <CustomButton
        as={Link}
        to={`/project/${encodeURIComponent(project.id)}/schema`}
        size="md"
        renderIcon={() => (
          <div className="bp-report-form-section-no-schema__action-icon">
            <ArrowRightIcon/>
          </div>
        )}
      >
        <FormattedMessage id="report/form/noSchema/editLink"/>
      </CustomButton>
    </div>
  </div>
);

const AREA_NAMES = {
  [SchemaArea.HEATING]: <FormattedMessage id="report/form/schema/area/heating"/>,
  [SchemaArea.COOLING]: <FormattedMessage id="report/form/schema/area/cooling"/>,
  [SchemaArea.VENTILATION]: <FormattedMessage id="report/form/schema/area/ventilation"/>,
};
