import { FormatIcu } from '@artshell/tolgee-format-icu-jsx-param';
import { LogicError } from '@belimo-retrofit-portal/logic';
import { Tolgee } from '@tolgee/react';
import { createSelector } from 'reselect';
import { getConfigData } from 'src/modules/config/selectors/getConfigData';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';

export const getTolgeeInstance = createSelector([
  getConfigData,
], ({ i18n }) => Tolgee()
  .use(FormatIcu())
  .init({
    language: i18n.language,
    staticData: {
      [i18n.language]: i18n.translations,
    },

    onTranslationMissing: (info) => {
      const error = new LogicError(`Missing translation "${i18n.language}:${info.key}"`);
      sentryCatch(error);

      return info.key;
    },
    onFormatError: (details, info) => {
      const error = new LogicError(`Invalid translation "${i18n.language}:${info.key}" (${details})`);
      sentryCatch(error);

      return info.key;
    },
  }));
