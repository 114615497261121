import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const PlusIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor" d="M11.632 12.368H6v-.736h5.632V6h.736v5.632H18v.736h-5.632V18h-.736v-5.632Z"/>
    </svg>
  </IconWrapper>
));
