import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const MailIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M4.8 18.7c-.433 0-.792-.14-1.075-.424-.283-.284-.425-.642-.425-1.075V6.8c0-.434.142-.792.425-1.075.283-.284.642-.425 1.075-.425h14.4c.433 0 .792.141 1.075.425.283.283.425.641.425 1.075v10.4c0 .433-.142.791-.425 1.075-.283.283-.642.425-1.075.425H4.8Zm7.2-6.85-8-5.3V17.2c0 .234.075.426.225.576.15.15.342.225.575.225h14.4a.779.779 0 0 0 .575-.225A.779.779 0 0 0 20 17.2V6.55l-8 5.3Zm0-.85 7.6-5H4.4l7.6 5ZM4 6.55V6v11.2c0 .234.075.426.225.576.15.15.342.225.575.225H4V6.55Z"
      />
    </svg>
  </IconWrapper>
));
