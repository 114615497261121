import { createSelector } from 'reselect';
import { getReportGeneratedCover } from 'src/modules/report/selectors/getReportGeneratedCover';
import { getReportGeneratedEnergy } from 'src/modules/report/selectors/getReportGeneratedEnergy';
import { getReportGeneratedEvaluation } from 'src/modules/report/selectors/getReportGeneratedEvaluation';
import { getReportGeneratedHvac } from 'src/modules/report/selectors/getReportGeneratedHvac';
import { getReportGeneratedSavings } from 'src/modules/report/selectors/getReportGeneratedSavings';
import { getReportGeneratedSchema } from 'src/modules/report/selectors/getReportGeneratedSchema';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';

export const getReportGenerated = createSelector([
  getReportGeneratedCover,
  getReportGeneratedEnergy,
  getReportGeneratedHvac,
  getReportGeneratedSavings,
  getReportGeneratedEvaluation,
  getReportGeneratedSchema,
], (cover, energy, hvac, savings, evaluation, schema): ReportGenerated => ({
  cover,
  energy,
  hvac,
  savings,
  evaluation,
  schema,
}));
