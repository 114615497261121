import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { LoginLayout } from 'src/modules/common/layouts/LoginLayout';
import { PASSWORD_RESET_SUBMIT } from 'src/modules/password-reset/actions/PasswordResetActions';
import { getPasswordResetValid } from 'src/modules/password-reset/selectors/getPasswordResetValid';
import { PasswordResetError } from 'src/modules/password-reset/views/PasswordResetError';
import { PasswordResetScreen } from 'src/modules/password-reset/views/PasswordResetScreen';

export const PasswordResetPage = (): React.ReactElement => {
  const intl = useIntl();
  const tokenValid = useSelector(getPasswordResetValid);
  const submitForm = useAction(PASSWORD_RESET_SUBMIT.request);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'passwordReset/pageTitle' })}</title>
      </Helmet>

      <LoginLayout>
        {tokenValid
          ? <PasswordResetScreen onSubmit={submitForm}/>
          : <PasswordResetError/>}
      </LoginLayout>
    </>
  );
};
