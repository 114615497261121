import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { HVAC_CHANGE } from 'src/modules/hvac/actions/HvacActions';
import { getReportHvacEnergy } from 'src/modules/hvac/utils/getReportHvacEnergy';
import { mapHvacFormToStore } from 'src/modules/hvac/utils/mapHvacFormToStore';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* hvacChangeSaga(
  action: GetTriggerActionType<typeof HVAC_CHANGE>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Saving HVAC data...', formData);

    const previous = yield* select(getProjectDetails);

    const updatedReport = yield* call(getReportHvacEnergy, formData, previous);

    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        currency: formData.currency,
        building: formData.building,
        hvac: yield* call(mapHvacFormToStore, formData.assessment),
        report: updatedReport,
      },
    };

    const reloaded = yield* call(syncProjectBackground, modified);
    yield* call(logDebug, 'Saving HVAC data... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save HVAC data', { formData }, NativeError.wrap(error));
    yield* call(logError, 'Saving HVAC data... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
