import { createSelector } from 'reselect';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { HvacFormCounter, HvacFormCounters } from 'src/modules/hvac/types/HvacFormCounter';
import { HvacFormChapterData, HvacFormSectionAnswerData } from 'src/modules/hvac/types/HvacFormData';

export const getHvacFormCounters = createSelector([
  getHvacAssesmentFormData,
], (formData): HvacFormCounters => ({
  heating: getCounter(formData.heating),
  cooling: getCounter(formData.cooling),
  ventilation: getCounter(formData.ventilation),
}));

function getCounter(formData: HvacFormChapterData): HvacFormCounter {
  return {
    answered: formData.sections.reduce((total, section) => {
      if (!section.enabled) {
        return total;
      }

      const answer = isAnswered(section.answer) ? 1 : 0;
      const copies = section.copies.reduce((sum, copy) => (isAnswered(copy.answer) ? sum + 1 : sum), 0);

      return total + answer + copies;
    }, 0),
    enabled: formData.sections.reduce((result, section) => {
      if (!section.enabled) {
        return result;
      }

      return result + 1 + section.copies.length;
    }, 0),
  };
}

function isAnswered(answer: HvacFormSectionAnswerData): boolean {
  return (
    answer.actual.option !== null &&
    answer.future.option !== null
  );
}
