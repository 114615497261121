import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ContainerType } from 'src/modules/common/types/ContainerType';

export const CONTAINER_TYPE: C.Codec<unknown, Json, ContainerType> = C.literal(
  'ETU',
  'EAC',
  'EAN',
  'EBY',
  'EHA',
  'DDI',
  'DDC',
  'DHW',
  'DWA',
  'DAP',
  'DBY',
  'DHE',
  'DPU',
  'PPU',
);
