import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { getConfigSchemas } from 'src/modules/config/selectors/getConfigSchemas';
import { SCHEMA_SELECTIONSTATE_FETCH } from 'src/modules/schema/actions/SchemaActions';
import {
  MULTIPLE_SCHEMA_FILTER_FORM,
  MULTIPLE_SCHEMA_PRESELECTED_CODEC,
} from 'src/modules/schema/codecs/MultipleSchemaForm';
import { DEFAULT_MULTIPLE_FILTER_FORM_DATA } from 'src/modules/schema/constants/filterFormData';
import { FILTER_FORM_SELECTED, SCHEMA_PRESELECTED } from 'src/modules/schema/constants/localStorageKeys';
import { PRESELECTED_SCHEMA_DATA } from 'src/modules/schema/constants/schema';
import {
  MultipleSchemaFilterForm,
  MultipleSchemaPreselectedData,
} from 'src/modules/schema/types/SchemaSelectionState';
import { filterSchemas } from 'src/modules/schema/utils/filter';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet } from 'src/sagas/utils/storage';
import { call, put, select } from 'typed-redux-saga';

export function* loadSchemaSelectionState(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Loading schema selection filter state...');

    const storedSchemaFilter = yield* call(storageGet, FILTER_FORM_SELECTED);

    const schemaSelection: MultipleSchemaFilterForm = yield* call(
      decodeOrGet,
      MULTIPLE_SCHEMA_FILTER_FORM,
      storedSchemaFilter,
      DEFAULT_MULTIPLE_FILTER_FORM_DATA,
    );

    const storedSchemaSelected = yield* call(storageGet, SCHEMA_PRESELECTED);

    const schemaSelected: MultipleSchemaPreselectedData = yield* call(
      decodeOrGet,
      MULTIPLE_SCHEMA_PRESELECTED_CODEC,
      storedSchemaSelected,
      PRESELECTED_SCHEMA_DATA,
    );

    const allCurrentSchemas = yield* select(getConfigSchemas);

    const checkedPreselectedSchema = Object.values(SchemaArea)
      .reduce((acc: MultipleSchemaPreselectedData, key: SchemaArea): MultipleSchemaPreselectedData => {
        const schemaSelectedArea = schemaSelected[key];
        const schemaSelectionArea = schemaSelection[key];

        if (!schemaSelectedArea || !schemaSelectionArea) {
          return {
            ...acc,
            [key]: null,
          };
        }
        const filteredSchemas = filterSchemas(allCurrentSchemas, schemaSelectionArea);

        const isExist = filteredSchemas.some((schema) => schema.id === schemaSelectedArea.id);

        return isExist ? {
          ...acc,
          [key]: schemaSelectedArea,
        } : {
          ...acc,
          [key]: null,
        };
      }, PRESELECTED_SCHEMA_DATA);

    yield* put(SCHEMA_SELECTIONSTATE_FETCH.trigger({
      filter: schemaSelection ?? DEFAULT_MULTIPLE_FILTER_FORM_DATA,
      schemaSelected: checkedPreselectedSchema,
    }));

    yield* call(logDebug, 'Loading schema selection filter state... done', schemaSelection);
  } catch (error) {
    yield* call(logError, 'Loading schema selection filter... error', error);
    throw error;
  }
}
