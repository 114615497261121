import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { FlowDirection } from 'src/modules/common/types/FlowDirection';

export const FLOW_DIRECTION: C.Codec<unknown, Json, FlowDirection> = C.literal(
  FlowDirection.NORTH,
  FlowDirection.SOUTH,
  FlowDirection.EAST,
  FlowDirection.WEST,
);
