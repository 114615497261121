import { emulateTransitionDelay } from 'src/modules/routing/hooks/emulateTransitionDelay';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_ORGANIZATION_STATES: ReadonlyArray<RouteState> = [
  {
    url: '/admin/organization',
    name: 'bp:admin:organization',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
