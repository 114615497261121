import { createSelector } from 'reselect';
import { getShareProjectData } from 'src/modules/sharing-projects/selectors/getShareProjectData';
import { assertNever } from 'src/utils/assert';

export const getShareProjectUsers = createSelector([
  getShareProjectData,
], (data) => {
  if (data?.users.state === 'success') {
    return data.users.value;
  }

  return assertNever('Project users is not initialed yet', { data });
});
