import React from 'react';
import { SvgFile } from 'src/modules/common/components/SvgFile';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';

type Props = {
  readonly schema: ConfigSchema;
};

export const SchemaBackground = React.memo(({ schema }: Props): React.ReactElement => (
  <g
    pointerEvents="auto"
    className="bp-schema-background"
  >
    <SvgFile
      urls={[`/images/schemas/${encodeURIComponent(schema.id)}.svg`]}
      width={schema.width}
      height={schema.height}
    />
  </g>
));
