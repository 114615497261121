import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';

export function getReportDocumentFilename(document: ReportGenerated): string {
  return [
    formatDate(document.cover.reportDate),
    document.cover.title,
  ].filter(Boolean).join(' ');
}

function formatDate(date: Date): string {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');

  return `${yyyy}${mm}${dd}`;
}
