import { Decimal, Numeric } from 'decimal.js-light';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLocale } from 'src/modules/config/selectors/getLocale';

type Props = Intl.NumberFormatOptions & {
  readonly value: Numeric;
};

export const FormattedNumber = ({ value, ...options }: Props): React.ReactElement => {
  const locale = useSelector(getLocale);

  // Roboto font doesn't contain NARROW NO-BREAK SPACE (NNBSP, U+202F)
  // so PDF builder maps the char to U+0000
  const number = new Decimal(value).toNumber();
  const formatted = new Intl.NumberFormat(locale, options)
    .format(number)
    .replace(/\u202f/g, '\u2009');

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
};
