import { HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import { HvacFormSectionAnswerData } from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormVariant } from 'src/modules/hvac/utils/getDefaultFormVariant';

export function getDefaultFormAnswer(config: HvacConfigSection): HvacFormSectionAnswerData {
  return {
    actual: getDefaultFormVariant(config),
    future: getDefaultFormVariant(config),
  };
}
