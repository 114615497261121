import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { useForm } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { TextAreaField } from 'src/modules/form/components/TextAreaField';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  readonly isOpen: boolean;
  readonly comment: string;
  readonly onSubmit: (comment: string) => void;
  readonly onClose: () => void;
};

export const CommentVersionModal = (
  { isOpen, comment, onSubmit, onClose }: Props,
): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<string, FormError>('schema.comment', {
    reinitialize: true,
    initialValues: comment || '',
  });

  const handleSubmit = useCallback(() => {
    onSubmit(form.currentState.values);
    onClose();
  }, [form, onSubmit, onClose]);

  return (
    <CustomModal open={isOpen} onClose={onClose} shouldUnmount={false} isShaded={false} size="md">
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        buttonOnClick={onClose}
        label={<FormattedMessage id="schemaEdit/commentDialog/title"/>}
        title={<FormattedMessage id="schemaEdit/commentDialog/subTitle"/>}
      />

      <ModalBody>
        <TextAreaField
          labelText={intl.formatMessage({ id: 'schemaEdit/commentFieldText' })}
          field={form.field}
          rows={3}
          maxLength={280}
          showHint={true}
        />
      </ModalBody>

      <ModalFooter>
        <CustomButton kind="secondary" onClick={onClose}>
          <FormattedMessage id="schemaEdit/commentField/cancel"/>
        </CustomButton>

        <CustomButton
          kind="primary"
          autoFocus={true}
          onClick={handleSubmit}
        >
          <FormattedMessage id="schemaEdit/commentFieldTextEdit"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
