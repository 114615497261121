import { UUID } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { Transition } from 'src/modules/routing/types/Transition';
import { USER_DETAILS_FETCH } from 'src/modules/users/actions/UserActions';
import { loadUserDetails } from 'src/modules/users/sagas/utils/loadUserDetails';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertDecode } from 'src/utils/assert';
import { call, put } from 'typed-redux-saga';

export function* fetchUserDetails(transition: Transition, match: RouteMatch): SagaIterator<void> {
  const id = assertDecode(
    match.params.userId,
    UUID,
    'Missing "userId" route parameter',
    { match, transition },
  );

  try {
    yield* call(logDebug, `Fetching user "${id}" details...`);

    const user = yield* call(loadUserDetails, id);

    yield* put(USER_DETAILS_FETCH.trigger(user, id));
    yield* call(logDebug, `Fetching user "${id}" details... done`, user);
  } catch (error) {
    yield* call(logError, `Fetch user "${id}" details... error`, error);
    throw error;
  }
}
