import { SagaIterator } from 'redux-saga';
import { PROJECT_SYNC_PERFORM, PROJECT_SYNC_RESET } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { projectSyncPerformSaga } from 'src/modules/project-sync/sagas/projectSyncPerformSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* projectSyncSaga(): SagaIterator<void> {
  while (true) {
    const task = yield* takeLatest(PROJECT_SYNC_PERFORM.isRequest, projectSyncPerformSaga);

    yield* take(PROJECT_SYNC_RESET.is);
    yield* cancel(task);
  }
}
