import './AddUserForm.scss';
import { Stack } from '@carbon/react';
import { FormController } from '@form-ts/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormSection } from 'src/modules/common/components/FormSection';
import { UserForm } from 'src/modules/common/components/UserForm';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormError } from 'src/modules/form/types/FormError';

type Props = {
  form: FormController<UserFormData, FormError>;
};

export const AddUserForm = ({ form }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <Stack as="main" className="bp-add-user-form" gap="1rem" orientation="vertical">
      <FormSection title={intl.formatMessage({ id: 'admin/user/add/form/section/mainSettings/title' })}>
        <UserForm className="bp-user-edit-form__main-settings" disableRole={false} form={form} isUserCreation={true}/>
      </FormSection>
    </Stack>
  );
};
