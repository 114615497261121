import './SupScript.scss';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const SupScript = ({ children }: Props): React.ReactElement => (
  <span className="bp-sup-script">{children}</span>
);
