import { SagaIterator } from 'redux-saga';
import { USERS_LIST_RESET } from 'src/modules/users/actions/UsersActions';
import { logDebug } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* resetUsersList(): SagaIterator<void> {
  yield* call(logDebug, 'Resetting users list...');
  yield* put(USERS_LIST_RESET.trigger());
  yield* call(logDebug, 'Resetting users list... done');
}
