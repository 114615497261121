import './ProjectListRating.scss';
import { HvacResultsRating } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { EnergyEfficiencyLabelWrapper } from 'src/modules/common/components/EnergyEfficiencyLabelWrapper';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';

type Props = {
  readonly rating: HvacResultsRating;
};

export const ProjectListRating = ({ rating }: Props): React.ReactElement => (
  <div className="bp-project-list-rating">
    <EnergyEfficiencyLabelWrapper value={rating.actual} disabled={false} sized={false}/>

    <div className="bp-project-list-rating__icon">
      <ArrowRightIcon/>
    </div>

    <EnergyEfficiencyLabelWrapper value={rating.future} disabled={false} sized={false}/>
  </div>
);
