import './ProfileHeader.scss';
import { Breadcrumb } from '@carbon/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { CustomButton } from 'src/modules/common/components/CustomButton';

type Props = {
  isSubmitting: boolean;
  title: string;
  onSave: () => void;
};

export const ProfileHeader = ({ isSubmitting, title, onSave }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <header className="bp-profile-header">
      <div className="bp-profile-header__content">
        <Breadcrumb className="bp-profile-header__breadcrumbs">
          <CustomBreadcrumbItem>
            <Link to="/projects">
              {intl.formatMessage({ id: 'profile/header/breadcrumbs/project' })}
            </Link>
          </CustomBreadcrumbItem>
        </Breadcrumb>

        <h1 className="bp-profile-header__title">{title}</h1>
      </div>

      <div className="bp-profile-header__extension">
        <CustomButton
          className="bp-profile-header__actions"
          kind="primary"
          onClick={onSave}
          loading={isSubmitting}
          size="md"
        >
          {intl.formatMessage({ id: 'profile/header/button/save' })}
        </CustomButton>
      </div>
    </header>
  );
};
