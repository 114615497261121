type OutputInnerHeight = number;
type OutputOuterHeight = number;

export type OutputBlock<TBlock, TElement> = {
  readonly widows: number;
  readonly orphans: number;

  readonly block: TBlock;
  readonly rows: ReadonlyArray<OutputBlockRow<TElement>>;
};
export type OutputBlockRow<TElement> = {
  readonly height: (before: ReadonlyArray<TElement>) => OutputOuterHeight;
  readonly element: TElement;
};

export function splitBlocksIntoPages<TBlock, TElement>(
  blocks: ReadonlyArray<OutputBlock<TBlock, TElement>>,
  pageHeight: (pageIndex: number) => OutputInnerHeight,
  onBlockBreak?: (block: TBlock, pageIndex: number) => OutputBlockRow<TElement> | null,
): TElement[][] {
  let currentPageIndex = 0;
  let currentPageItems: TElement[] = [];
  let currentPageHeight = 0;
  let maximumPageHeight = pageHeight(currentPageIndex);

  let currentBlockIndex = 0;
  let currentBlock = blocks[currentBlockIndex];
  let currentRows = currentBlock ? currentBlock.rows.slice() : [];
  let hasBlockRows = false;

  function breakBlock(insertWidowsHeader: boolean): void {
    const widowsHeader = insertWidowsHeader && onBlockBreak
      ? onBlockBreak(currentBlock.block, currentPageIndex)
      : null;

    currentPageIndex += 1;
    currentPageItems = widowsHeader ? [widowsHeader.element] : [];
    currentPageHeight = widowsHeader ? widowsHeader.height([]) : 0;
    maximumPageHeight = pageHeight(currentPageIndex);

    resultPages.push(currentPageItems);
  }
  function forwardBlock(): void {
    currentBlockIndex += 1;
    currentBlock = blocks[currentBlockIndex];
    currentRows = currentBlock ? currentBlock.rows.slice() : [];
    hasBlockRows = false;
  }

  function insertRow(row: OutputBlockRow<TElement>): void {
    const elementHeight = row.height(currentPageItems);

    currentPageItems.push(row.element);
    currentPageHeight += elementHeight;
    hasBlockRows = true;
  }

  const resultPages = [currentPageItems];
  while (currentBlockIndex < blocks.length) {
    if (currentRows.length === 0) {
      forwardBlock();
      continue;
    }

    const orphansCount = getOrphansCount(maximumPageHeight - currentPageHeight, currentRows, currentPageItems);
    const widowsCount = currentRows.length - orphansCount;
    if (widowsCount === 0) {
      currentRows.forEach(insertRow);

      forwardBlock();
    } else if (widowsCount < currentBlock.widows) {
      const orphansRows = currentRows.splice(0, currentRows.length - currentBlock.widows);
      orphansRows.forEach(insertRow);
      breakBlock(true);

      currentRows.forEach(insertRow);
      forwardBlock();
    } else if (orphansCount < currentBlock.orphans) {
      const orphansRows = currentRows.splice(0, orphansCount);
      breakBlock(hasBlockRows);
      orphansRows.forEach(insertRow);
    } else {
      const orphansRows = currentRows.splice(0, orphansCount);
      orphansRows.forEach(insertRow);
      breakBlock(true);
    }
  }

  return resultPages;
}

function getOrphansCount<TElement>(
  pageHeight: OutputInnerHeight,
  blockRows: ReadonlyArray<OutputBlockRow<TElement>>,
  pageItems: ReadonlyArray<TElement>,
): number {
  let requiredHeight = 0;
  for (let index = 0; index < blockRows.length; ++index) {
    requiredHeight += blockRows[index].height(pageItems);
    if (requiredHeight > pageHeight) {
      return index;
    }
  }

  return blockRows.length;
}
