import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CloudIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M8.667 24.933c-1.556 0-2.878-.544-3.967-1.633s-1.633-2.411-1.633-3.967c0-1.466.522-2.766 1.566-3.9 1.045-1.133 2.323-1.7 3.834-1.7.222-1.91 1.05-3.5 2.483-4.766 1.433-1.267 3.117-1.9 5.05-1.9 2.111 0 3.905.739 5.383 2.216 1.478 1.478 2.217 3.273 2.217 5.384V16.4h1.067c1.2 0 2.21.411 3.033 1.233.822.823 1.233 1.834 1.233 3.034 0 1.2-.41 2.21-1.233 3.033-.822.822-1.833 1.233-3.033 1.233h-16Zm0-.933h16c.933 0 1.722-.322 2.366-.967.645-.644.967-1.433.967-2.366 0-.934-.322-1.723-.967-2.367-.644-.645-1.433-.967-2.366-.967h-2v-2.666c0-1.845-.65-3.417-1.95-4.717C19.417 8.65 17.844 8 16 8c-1.845 0-3.417.65-4.717 1.95-1.3 1.3-1.95 2.872-1.95 4.717h-.666c-1.29 0-2.39.455-3.3 1.366-.911.911-1.367 2.011-1.367 3.3 0 1.29.456 2.39 1.367 3.3.91.911 2.01 1.367 3.3 1.367Z"
      />
    </svg>
  </IconWrapper>
));
