import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';

export function useAction<TArgs extends ReadonlyArray<unknown>>(
  creator: (...value: TArgs) => Action,
): (...value: TArgs) => void;

export function useAction(
  creator: () => Action,
): () => void;

export function useAction<TArgs extends ReadonlyArray<unknown>>(
  creator: (...value: TArgs) => Action,
): (...value: TArgs) => void {
  const dispatch = useDispatch();
  return useCallback((...value) => dispatch(creator(...value)), [dispatch, creator]);
}
