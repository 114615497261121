import './ProjectListView.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Project } from 'src/modules/common/types/Project';
import { PROJECT_LIST_PAGE_SIZE } from 'src/modules/project-list/constants/pageSize';
import { ProjectListDownloading } from 'src/modules/project-list/types/ProjectListDownloading';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { ProjectListSlice } from 'src/modules/project-list/types/ProjectListSlice';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';
import { ProjectListUnfollowing } from 'src/modules/project-list/types/ProjectUnfollowing';
import { ProjectListHeader } from 'src/modules/project-list/views/ProjectListHeader';
import { ProjectListItemGridView } from 'src/modules/project-list/views/ProjectListItemGridView';
import { ProjectListItemListView } from 'src/modules/project-list/views/ProjectListItemListView';
import { ProjectListPager } from 'src/modules/project-list/views/ProjectListPager';

type Props = {
  readonly query: ProjectListQuery;
  readonly projects: ProjectListSlice;
  readonly downloading: ProjectListDownloading | null;
  readonly unfollowing: ProjectListUnfollowing | null;
  readonly onRemove: (project: Project) => void;
  readonly onDownload: (project: Project) => void;
  readonly onUnfollow: (project: Project) => void;
  readonly onShare: (project: Project) => void;
};

export const ProjectListView = ({
  query,
  projects,
  downloading,
  unfollowing,
  onRemove,
  onDownload,
  onUnfollow,
  onShare,
}: Props): React.ReactElement => (
  <div className="bp-project-list-view">
    <div className="bp-project-list-view__content">
      {query.view === ProjectListViewMode.LIST && (
        <div className="bp-project-list-view__table">
          <ProjectListHeader query={query}/>

          {projects.found.map((listItem) => (
            <ProjectListItemListView
              key={listItem.project.id}
              item={listItem}
              downloading={(
                downloading?.project.id === listItem.project.id &&
                downloading.status.state === 'pending'
              )}
              unfollowing={(
                unfollowing?.project.id === listItem.project.id &&
                unfollowing?.status?.state === 'pending'
              )}
              onRemove={onRemove}
              onDownload={onDownload}
              onUnfollow={onUnfollow}
              onShare={onShare}
            />
          ))}
        </div>
      )}
      {query.view === ProjectListViewMode.GRID && (
        <div className="bp-project-list-view__grid">
          {projects.found.map((listItem) => (
            <ProjectListItemGridView
              key={listItem.project.id}
              item={listItem}
              downloading={(
                downloading?.project.id === listItem.project.id &&
                downloading.status.state === 'pending'
              )}
              unfollowing={(
                unfollowing?.project.id === listItem.project.id &&
                unfollowing?.status?.state === 'pending'
              )}
              onRemove={onRemove}
              onDownload={onDownload}
              onUnfollow={onUnfollow}
              onShare={onShare}
            />
          ))}
        </div>
      )}

      {projects.total === 0 && (
        <p className="bp-project-list-view__empty-text">
          <FormattedMessage id="projectList/noResults"/>
        </p>
      )}

      {projects.total > PROJECT_LIST_PAGE_SIZE[query.view] && (
        <div className="bp-project-list-view__pagination">
          <ProjectListPager
            total={projects.total}
            query={query}
          />
        </div>
      )}
    </div>
  </div>
);
