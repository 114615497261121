import './ErrorMessage.scss';
import { BaseError } from '@belimo-retrofit-portal/logic';
import React from 'react';

type Props = {
  readonly error: BaseError;
  readonly errorInfo: React.ErrorInfo | undefined;
};

export const ErrorMessage = ({ error, errorInfo }: Props): React.ReactElement => (
  <div className="bp-error-message">
    <ErrorBlock
      error={error}
      errorInfo={errorInfo}
    />
  </div>
);

const ErrorBlock = ({ error, errorInfo } : Props): React.ReactElement => (
  <div className="bp-error-message__block">
    <h6 className="bp-error-message__title">
      <b>{error.name}</b>. {error.message}
    </h6>

    <details open={false} className="bp-error-message__details">
      <summary>Error</summary>
      <pre className="bp-error-message__code">
        {JSON.stringify(error, null, 2)}
      </pre>
    </details>

    <details open={false} className="bp-error-message__details">
      <summary>Stack</summary>
      <pre className="bp-error-message__code">
        {error.stack}
      </pre>
    </details>

    {errorInfo && (
      <details open={false} className="bp-error-message__details">
        <summary>Components</summary>
        <pre className="bp-error-message__code">
          {errorInfo.componentStack}
        </pre>
      </details>
    )}

    {error.previous && (
      <div className="bp-error-message__previous">
        <ErrorBlock
          error={error.previous}
          errorInfo={undefined}
        />
      </div>
    )}
  </div>
);
