import './ReportFormSectionConfig.scss';
import { FieldPath } from '@form-ts/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { Project } from 'src/modules/common/types/Project';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { CheckboxField } from 'src/modules/form/components/CheckboxField';
import { FormError } from 'src/modules/form/types/FormError';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportFormFieldToggle } from 'src/modules/report/views/ReportFormFieldToggle';

type Props = {
  readonly field: FieldPath<ReportFormData, FormError, ReportFormData['energy']>;
  readonly project: Project;
};

export const ReportFormSectionConfigEnergy = ({ field, project }: Props): React.ReactElement => (
  <div className="bp-report-form-section-config">
    <div className="bp-report-form-section-config__header">
      <LeadText className="bp-report-form-section-config__header-title">
        <FormattedMessage id="report/form/energy/header"/>
      </LeadText>

      <CheckboxField field={field.at('enabled')} labelText="" hideLabel={true}/>
    </div>

    <HorizontalDivider/>

    <div className="bp-report-form-section-config__content">
      <ReportFormFieldToggle
        field={field.at('showHeating')}
        disabled={!project.data.hvac.areas.heating.enabled}
      >
        <FormattedMessage id="report/form/energy/showHeating"/>
      </ReportFormFieldToggle>

      <HorizontalDivider/>

      <ReportFormFieldToggle
        field={field.at('showCooling')}
        disabled={!project.data.hvac.areas.cooling.enabled}
      >
        <FormattedMessage id="report/form/energy/showCooling"/>
      </ReportFormFieldToggle>

      <HorizontalDivider/>

      <ReportFormFieldToggle
        field={field.at('showVentilation')}
        disabled={!project.data.hvac.areas.ventilation.enabled}
      >
        <FormattedMessage id="report/form/energy/showVentilation"/>
      </ReportFormFieldToggle>

      <HorizontalDivider/>

      <ReportFormFieldToggle
        field={field.at('showComments')}
        disabled={!project.data.hvac.comment.length}
      >
        <FormattedMessage id="report/form/energy/showComment"/>
      </ReportFormFieldToggle>

      <HorizontalDivider/>
    </div>

    <div className="bp-report-form-section-config__action">
      <CustomButton
        fullWidth={true}
        as={Link}
        to={`/project/${encodeURIComponent(project.id)}/hvac`}
        kind="tertiary"
        size="md"
        renderIcon={() => (
          <div className="bp-report-form-section-config__button-icon">
            <ArrowRightIcon/>
          </div>
        )}
      >
        <FormattedMessage id="report/form/energy/editLinkText"/>
      </CustomButton>
    </div>
  </div>
);
