import './HvacFormSectionHeader.scss';
import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import { ChevronDown, ChevronUp, OverflowMenuVertical } from '@carbon/icons-react';
import clsx from 'clsx';
import React, { MouseEventHandler, MutableRefObject, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { CustomToggle } from 'src/modules/common/components/CustomToggle';
import { EnergyEfficiencyTransition } from 'src/modules/common/components/EnergyEfficiencyTransition';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { CopyIcon } from 'src/modules/common/icons/CopyIcon';
import { DeleteIcon } from 'src/modules/common/icons/DeleteIcon';
import { EditIcon } from 'src/modules/common/icons/EditIcon';

type Props = {
  readonly actual: EnergyEfficiencyClass | null;
  readonly future: EnergyEfficiencyClass | null;
  readonly disabled: boolean;
  readonly readOnly: boolean;
  readonly children: React.ReactNode;
  readonly isItemOpened: boolean;
  readonly isCopy: boolean;
  readonly wrapperRef?: MutableRefObject<HTMLDivElement | null>;

  readonly onClick?: MouseEventHandler;
  readonly onToggle?: () => void;
  readonly onClone?: () => void;
  readonly onRemove?: () => void;
  readonly onRename?: () => void;
};

export const HvacFormSectionHeader = memo(({
  actual,
  future,
  disabled,
  readOnly,
  isItemOpened,
  isCopy,
  wrapperRef,
  onClick,
  onToggle,
  onClone,
  onRemove,
  onRename,
  children,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const isRenameExist = useMemo(() => onRename !== undefined, [onRename]);
  const isCloneExist = useMemo(() => onClone !== undefined, [onClone]);
  const isRemoveExist = useMemo(() => onRemove !== undefined, [onRemove]);

  const handleClone = useCallback((event: React.MouseEvent) => {
    stopPropagation(event);
    onClone?.();
  }, [onClone]);
  const handleRemove = useCallback((event: React.MouseEvent) => {
    stopPropagation(event);
    onRemove?.();
  }, [onRemove]);
  const handleRename = useCallback((event: React.MouseEvent) => {
    stopPropagation(event);
    onRename?.();
  }, [onRename]);
  const handleClick = useCallback((event: React.MouseEvent) => {
    stopPropagation(event);
  }, []);

  return (
    <div
      ref={wrapperRef}
      role="button"
      tabIndex={0}
      className={clsx('bp-hvac-form-section-header', disabled ? 'bp-hvac-form-section-header--disabled' : '')}
      onClick={disabled ? undefined : onClick}
      onKeyDown={() => null}
      style={{
        opacity: readOnly ? 0.5 : undefined,
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <CustomButton
        className="bp-hvac-form-section-header__button"
        label={
          isItemOpened
            ? intl.formatMessage({ id: 'hvac/form/section/close' })
            : intl.formatMessage({ id: 'hvac/form/section/open' })
        }
        kind="ghost"
        disabled={readOnly}
        style={{
          opacity: readOnly ? 0.5 : undefined,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        {isItemOpened ? (
          <ChevronUp size={24}/>
        ) : (
          <ChevronDown size={24}/>
        )}
      </CustomButton>

      <div className="bp-hvac-form-section-header__content">
        <h6
          className={clsx(
            'bp-hvac-form-section-header__title',
            {
              'bp-hvac-form-section-header__title--copy': isCopy,
            },
          )}
          style={{ opacity: disabled ? 0.5 : undefined }}
        >
          {children}
        </h6>

        <div
          className="bp-hvac-form-section-header__label"
          style={{
            opacity: disabled ? 0.25 : undefined,
            pointerEvents: readOnly || disabled ? 'none' : 'auto',
          }}
        >
          <EnergyEfficiencyTransition actual={actual} future={future}/>
        </div>
      </div>

      <div className="bp-hvac-form-section-header__controls">
        <div className="bp-hvac-form-section-header__control">
          {onToggle !== undefined && (
            <CustomToggle
              id="hvac/form/section/toggle"
              toggled={!disabled}
              disabled={readOnly}
              onClick={handleClick}
              onToggle={onToggle}
              hideLabel={true}
            />
          )}
        </div>

        {(isRenameExist || isCloneExist || isRemoveExist) && (
          <CustomMenu
            onClick={(e) => e?.preventDefault()}
            flipped={true}
            className="bp-hvac-form-section-header__menu"
            iconDescription={intl.formatMessage({ id: 'hvac/form/section/actions' })}
            tooltipPosition="bottom"
            disabled={readOnly || disabled}
            renderIcon={() => <OverflowMenuVertical size={32} color={CARBON_THEME.onSurfaceLightPrimary}/>}
          >
            {isRenameExist && (
              <CustomMenuItem
                onClick={handleRename}
                hasDivider={false}
                flipped={true}
                disabled={readOnly}
                itemText={(
                  <>
                    <div className="bp-hvac-form-section-header__menu-row-icon">
                      <EditIcon/>
                    </div>

                    <FormattedMessage id="hvac/form/section/rename"/>
                  </>
                )}
              />
            )}

            {isCloneExist && (
              <CustomMenuItem
                onClick={handleClone}
                hasDivider={false}
                flipped={true}
                disabled={readOnly}
                itemText={(
                  <>
                    <div className="bp-hvac-form-section-header__menu-row-icon">
                      <CopyIcon/>
                    </div>

                    <FormattedMessage id="hvac/form/section/copy"/>
                  </>
                )}
              />
            )}

            {isRemoveExist && (
              <CustomMenuItem
                isDelete={true}
                onClick={handleRemove}
                hasDivider={false}
                flipped={true}
                disabled={readOnly}
                itemText={(
                  <>
                    <div className="bp-hvac-form-section-header__menu-row-icon">
                      <DeleteIcon/>
                    </div>

                    <FormattedMessage id="hvac/form/section/remove"/>
                  </>
                )}
              />
            )}
          </CustomMenu>
        )}
      </div>
    </div>
  );
});

function stopPropagation(event: React.MouseEvent): void {
  event.stopPropagation();
}
