import { useCallback } from 'react';
import { Action } from 'redux';
import { useAction } from 'src/hooks/useAction';

export function useBoundAction<TData extends ReadonlyArray<unknown>>(
  creator: (...values: TData) => Action,
  ...values: TData
): () => void {
  const action = useAction(creator);
  return useCallback(() => action(...values), [action, values]);
}
