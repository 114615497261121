import { DecodingError } from '@belimo-retrofit-portal/logic';
import { getOrElseW } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Decoder } from 'io-ts/Decoder';

export function decodeOrThrow<I, A>(decoder: Decoder<I, A>, input: I): A {
  return pipe(
    decoder.decode(input),
    getOrElseW((error) => new DecodingError(input, error).throw()),
  );
}
