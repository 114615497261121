import { INTEGER, Integer } from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { NUMBER_FROM_STRING } from 'src/io/codecs/NumberFromString';

const decoder: D.Decoder<unknown, Integer> = pipe(
  NUMBER_FROM_STRING,
  D.parse(INTEGER.decode),
);

const encoder: E.Encoder<string, number> = {
  encode: String,
};

export const INTEGER_FROM_STRING = C.make(decoder, encoder);
