import './PasswordResetError.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowLeftIcon } from 'src/modules/common/icons/ArrowLeftIcon';

export const PasswordResetError = (): React.ReactElement => (
  <div className="bp-password-reset-error">
    <h4 className="bp-password-reset-error__title">
      <FormattedMessage id="passwordReset/error/title"/>
    </h4>

    <p className="bp-password-reset-error__subtitle">
      <FormattedMessage id="passwordReset/error/subtitle"/>
    </p>

    <div className="bp-password-reset-error__back">
      <CustomButton kind="ghost" as={Link} to="/login" size="md">
        <ArrowLeftIcon/>
        <FormattedMessage id="passwordReset/goBack"/>
      </CustomButton>
    </div>
  </div>
);
