import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { CpsCallback } from 'redux-saga/effects';
import { cps, SagaGenerator } from 'typed-redux-saga';

export function* doOnWorker(
  workerUrl: string,
  request: unknown,
): SagaIterator<unknown> {
  const worker = new Worker(workerUrl);
  try {
    return yield* doRequest(worker, request);
  } finally {
    worker.terminate();
  }
}

function* doRequest(
  worker: Worker,
  message: unknown,
): SagaGenerator<unknown> {
  const channel = new MessageChannel();

  try {
    return yield* cps(doWorkerRequestUsingMessagePort, worker, channel, message);
  } catch (error) {
    throw new RuntimeError('Error while sending Worker request', { message }, NativeError.wrap(error));
  } finally {
    channel.port1.close();
    channel.port1.onmessage = null;
    channel.port1.onmessageerror = null;

    channel.port2.close();
    channel.port2.onmessage = null;
    channel.port2.onmessageerror = null;
  }
}

function doWorkerRequestUsingMessagePort(
  worker: Worker,
  channel: MessageChannel,
  message: unknown,
  callback: CpsCallback<unknown>,
): void {
  worker.postMessage(message, [channel.port1]);

  // eslint-disable-next-line no-param-reassign
  channel.port2.onmessage = (event): void => callback(null, event.data);
  // eslint-disable-next-line no-param-reassign
  channel.port2.onmessageerror = (event): void => callback(event.data, undefined);
}
