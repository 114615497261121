import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';

const REPORT_FORM_SCHEMA_COVER: FormRule<ReportFormData['cover']> = R.struct({
  creationDate: R.nullable(R.date()),
  showBuildingInfo: R.pass(),
  showOrganizationLogo: R.pass(),
});

export const REPORT_FORM_SCHEMA: FormRule<ReportFormData> = R.struct({
  cover: REPORT_FORM_SCHEMA_COVER,
  energy: R.pass(),
  hvac: R.pass(),
  savings: R.pass(),
  schema: R.pass(),
  evaluation: R.pass(),
});
