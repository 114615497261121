import { fromEvent, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SchemaEventCanvas } from 'src/modules/schema/types/SchemaEvent';

export function canvasWheel(
  element: Element,
): Observable<SchemaEventCanvas> {
  const wheel$ = fromEvent<WheelEvent>(element, 'wheel', {
    once: false,
    capture: false,
    passive: false,
  });

  return wheel$.pipe(
    tap((event) => event.preventDefault()),
    map((event): SchemaEventCanvas => {
      if (!event.ctrlKey) {
        return {
          type: 'move',
          delta: {
            x: -event.deltaX,
            y: -event.deltaY,
          },
        };
      }

      const wheelDelta = Math.abs(getWheelDelta(event));
      const isMouseWheel = MOUSE_WHEEL_STEPS.has(wheelDelta) || MOUSE_WHEEL_STEPS.has(-wheelDelta);
      if (isMouseWheel) {
        return {
          type: 'zoom',
          mode: 'absolute',
          delta: -Math.sign(event.deltaY),
          point: {
            x: event.pageX,
            y: event.pageY,
          },
        };
      }

      return {
        type: 'zoom',
        mode: 'relative',
        delta: 1 - event.deltaY / 120,
        point: {
          x: event.pageX,
          y: event.pageY,
        },
      };
    }),
  );
}

function getWheelDelta(event: WheelEvent): number {
  if (event.deltaMode === WheelEvent.DOM_DELTA_PAGE) {
    return event.deltaY * 60;
  }
  if (event.deltaMode === WheelEvent.DOM_DELTA_LINE) {
    return event.deltaY * 20;
  }

  return event.deltaY;
}

const MOUSE_WHEEL_STEPS = new Set([
  200,
  160,
  120,
  100,
  80,
]);
