import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { REQUEST_ACCESS_SUBMIT } from 'src/modules/login/actions/RequestAccessActions';
import { REQUEST_ACCESS_FORM_SCHEMA } from 'src/modules/login/constants/schema';
import { submitRequestAccess } from 'src/modules/login/sagas/utils/submitRequestAccess';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, delay } from 'typed-redux-saga';

export function* requestAccessSubmitSaga(
  action: GetTriggerActionType<typeof REQUEST_ACCESS_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;
  const values = form.values.get(form.currentState);

  const valid = yield* call(formValidate, form, REQUEST_ACCESS_FORM_SCHEMA);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Sending request access...');
    yield* call(formSubmitStart, form);

    yield* delay(500);
    yield* call(submitRequestAccess, values);

    yield* call(logDebug, 'Sending request access... done');
    yield* call(formSubmitSuccess, form);
  } catch (error) {
    yield* call(logError, 'Sending request access... error', error);
    yield* call(formSubmitFailure, form, {});

    yield* call(sentryCatch, error);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
