import { EvaluationResultsChartsSeria } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { EvaluationResultsChartsGraphArea } from 'src/modules/evaluation/views/EvaluationResultsChartsGraphArea';

type Props = {
  readonly seria: EvaluationResultsChartsSeria | null;
  readonly interactive: boolean;
  readonly areCostSavingsExcludedNegative: boolean;
  readonly isInvestmentEmpty: boolean;
};

export const EvaluationResultsChartsGraphROI = (
  { seria, interactive, areCostSavingsExcludedNegative, isInvestmentEmpty }: Props,
): React.ReactElement => {
  const intl = useIntl();

  return (
    <EvaluationResultsChartsGraphArea
      data={areCostSavingsExcludedNegative ? [] : seria}
      labels={LABELS}
      xAxisLabel={intl.formatMessage({ id: 'unit/years' })}
      yAxisLabel={intl.formatMessage({ id: 'evaluation/charts/ROI/yAxisLabel' })}
      interactive={interactive}
      areCostSavingsExcludedNegative={areCostSavingsExcludedNegative}
      isInvestmentEmpty={isInvestmentEmpty}
    />
  );
};

const LABELS = {
  year: (
    <FormattedMessage
      id="evaluation/charts/ROI/values/year"
      values={{ sub, sup }}
    />
  ),
  exclCO2: (
    <FormattedMessage
      id="evaluation/charts/ROI/values/exclCO2"
      values={{ sub, sup }}
    />
  ),
  inclCO2: (
    <FormattedMessage
      id="evaluation/charts/ROI/values/inclCO2"
      values={{ sub, sup }}
    />
  ),
};
