/* eslint-disable quote-props,@typescript-eslint/dot-notation */
import { DECIMAL, PROJECT_DATA, ProjectData, UUID } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { altW, flatMap, map, mapLeft } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as J from 'fp-ts/Json';
import * as R from 'fp-ts/Reader';
import { map as mapRecord } from 'fp-ts/ReadonlyRecord';
import * as D from 'io-ts/Decoder';
import { XLSX_VALUE } from 'src/modules/common/codecs/XlsxValue';
import {
  IMPORT_AREA_UNIT_MAP,
  IMPORT_BUILDING_TYPE_MAP,
  IMPORT_COUNTRY_CODE_MAP,
  IMPORT_CURRENCY_CODE_MAP,
  IMPORT_ENERGY_SOURCE_COOLING,
  IMPORT_ENERGY_SOURCE_HEATING,
  IMPORT_HEADER_COLUMNS,
} from 'src/modules/project-list/constants/import';
import {
  ProjectListImportColumns,
  ProjectListImportLine,
  ProjectListImportLineMapper,
  ProjectListImportRawLine,
} from 'src/modules/project-list/types/ProjectListImport';
import { formatImportError } from 'src/modules/project-list/utils/formatImportError';

const CELL_BASE: D.Decoder<unknown, string> = pipe(
  XLSX_VALUE,
  D.parse((input) => D.success(String(input ?? ''))),
);
const CELL_EMPTY: D.Decoder<unknown, null> = {
  decode: (input) => (
    input == null || input === ''
      ? D.success(null)
      : D.failure(input, 'CellEmpty')
  ),
};
const CELL_DECIMAL_2: D.Decoder<unknown, Decimal> = pipe(
  DECIMAL,
  D.parse((input) => (
    input.isNegative()
      ? D.failure(input, 'CellDecimal')
      : D.success(input.toDecimalPlaces(2))
  )),
);
const CELL_DECIMAL_4: D.Decoder<unknown, Decimal> = pipe(
  DECIMAL,
  D.parse((input) => (
    input.isNegative()
      ? D.failure(input, 'CellDecimal')
      : D.success(input.toDecimalPlaces(4))
  )),
);
const CELL_STRING = (minLength: number, maxLength: number): D.Decoder<unknown, string> => pipe(
  CELL_BASE,
  D.parse((input) => (
    input.length < minLength
      ? D.failure(input, `CellString(${minLength},${maxLength})`)
      : D.success(input.substring(0, maxLength))
  )),
);
const CELL_ENUM: <T>(mapping: Readonly<Record<string, T>>) => D.Decoder<unknown, T> = (mapping) => pipe(
  CELL_BASE,
  D.parse((input) => (
    mapping[input] == null
      ? D.failure(input, `CellEnum(${Object.keys(mapping).join('|')})`)
      : D.success(mapping[input])
  )),
);
const CELL_PROJECT_DATA: D.Decoder<unknown, ProjectData> = pipe(
  CELL_BASE,
  D.parse((input) => pipe(
    J.parse(input),
    flatMap(PROJECT_DATA.decode),
    altW(() => D.failure(input, 'ProjectData')),
  )),
);

const CONTENT_DATA: D.Decoder<unknown, ProjectListImportRawLine> = D.struct({
  'project id': D.union(CELL_EMPTY, UUID),
  'project title': CELL_STRING(1, 40),
  'project goal': CELL_STRING(0, 280),

  'building type': CELL_ENUM(IMPORT_BUILDING_TYPE_MAP),
  'building size unit': CELL_ENUM(IMPORT_AREA_UNIT_MAP),
  'building size': D.union(CELL_EMPTY, CELL_DECIMAL_2),
  'building street address': CELL_STRING(0, 100),
  'building city': CELL_STRING(0, 40),
  'building country': CELL_ENUM(IMPORT_COUNTRY_CODE_MAP),
  'building zip code': CELL_STRING(0, 20),

  'participants author': CELL_STRING(0, 50),
  'participants property manager': CELL_STRING(0, 50),
  'participants consulting engineer': CELL_STRING(0, 50),
  'participants product engineer': CELL_STRING(0, 50),

  'currency': CELL_ENUM(IMPORT_CURRENCY_CODE_MAP),
  'electricity price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'gas price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'heating oil price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'district heating price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'district cooling price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'heating other price': D.union(CELL_EMPTY, CELL_DECIMAL_4),
  'cooling other price': D.union(CELL_EMPTY, CELL_DECIMAL_4),

  'heating energy source': CELL_ENUM(IMPORT_ENERGY_SOURCE_HEATING),
  'heating energy annual consumption': D.union(CELL_EMPTY, CELL_DECIMAL_2),
  'heating energy carbon intensity': D.union(CELL_EMPTY, CELL_DECIMAL_2),
  'heating electrical aux annual consumption': D.union(CELL_EMPTY, CELL_DECIMAL_2),

  'cooling energy source': CELL_ENUM(IMPORT_ENERGY_SOURCE_COOLING),
  'cooling energy annual consumption': D.union(CELL_EMPTY, CELL_DECIMAL_2),
  'cooling energy carbon intensity': D.union(CELL_EMPTY, CELL_DECIMAL_2),
  'cooling electrical aux annual consumption': D.union(CELL_EMPTY, CELL_DECIMAL_2),

  'ventilation electrical aux annual consumption': D.union(CELL_EMPTY, CELL_DECIMAL_2),

  'project data and configuration': CELL_PROJECT_DATA,
});

const COLUMN_ORDER: ProjectListImportColumns = {
  'project id': Number.NaN,
  'project title': Number.NaN,
  'project goal': Number.NaN,

  'building type': Number.NaN,
  'building size unit': Number.NaN,
  'building size': Number.NaN,
  'building street address': Number.NaN,
  'building city': Number.NaN,
  'building country': Number.NaN,
  'building zip code': Number.NaN,

  'participants author': Number.NaN,
  'participants property manager': Number.NaN,
  'participants consulting engineer': Number.NaN,
  'participants product engineer': Number.NaN,

  'currency': Number.NaN,
  'electricity price': Number.NaN,
  'gas price': Number.NaN,
  'heating oil price': Number.NaN,
  'district heating price': Number.NaN,
  'district cooling price': Number.NaN,
  'heating other price': Number.NaN,
  'cooling other price': Number.NaN,

  'heating energy source': Number.NaN,
  'heating energy annual consumption': Number.NaN,
  'heating energy carbon intensity': Number.NaN,
  'heating electrical aux annual consumption': Number.NaN,

  'cooling energy source': Number.NaN,
  'cooling energy annual consumption': Number.NaN,
  'cooling energy carbon intensity': Number.NaN,
  'cooling electrical aux annual consumption': Number.NaN,

  'ventilation electrical aux annual consumption': Number.NaN,

  'project data and configuration': Number.NaN,
};

const HEADER_LINE: D.Decoder<unknown, ProjectListImportColumns> = pipe(
  D.array(D.string),
  D.parse((columns) => {
    const result = { ...COLUMN_ORDER };

    for (const column of IMPORT_HEADER_COLUMNS) {
      const index = columns.indexOf(column);
      if (index < 0) {
        return D.failure(columns, 'HeaderLine');
      }

      result[column] = index;
    }

    return D.success(result);
  }),
);
const CONTENT_LINE = (columns: ProjectListImportColumns): D.Decoder<unknown, ProjectListImportRawLine> => pipe(
  D.array(XLSX_VALUE),
  D.parse((cells) => pipe(
    columns,
    mapRecord((index) => cells[index] ?? null),
    D.success,
  )),
  D.parse(CONTENT_DATA.decode),
);

export const IMPORT_CONTENT = pipe(
  R.ask<ProjectListImportLineMapper>(),
  R.map((mapper): D.Decoder<unknown, ProjectListImportLine[]> => pipe(
    D.array(D.array(XLSX_VALUE)),
    D.parse((rows) => pipe(
      HEADER_LINE.decode(rows[0]),
      map((columns) => rows.slice(1)
        .map(CONTENT_LINE(columns).decode)
        .map((line, index): ProjectListImportLine => ({
          rowNumber: index + 2,
          importData: pipe(line, map(mapper), mapLeft(formatImportError)),
        }))),
    )),
  )),
);
