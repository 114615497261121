import { Search, SearchProps } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
SearchProps,
'id' | 'value'
>;

type Props<TData> = BaseProps & {
  readonly size: 'sm' | 'md' | 'lg';
  readonly field: FieldPath<TData, FormError, string>;
};

export const SearchField: <TData>(props: Props<TData>) => React.ReactElement = ({ size, field, ...props }) => {
  const { error, meta, value } = useFormField(field);

  const { onChange, onBlur } = props;

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((event: { target: HTMLInputElement; type: 'change' }) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(event.target.value),
    ));
    onChange?.(event);
  }, [field, onChange]);

  const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
    onBlur?.(event);
  }, [field, onBlur]);

  return (
    <Search
      {...props}
      className={clsx(
        'bp-field',
        props.className,
        `bp-field--${size}`,
        { 'bp-field--invalid': invalid },
      )}
      id={`${field.form.name}.${field.path}`}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      size={size}
    />
  );
};
