import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, take, Pattern } from 'redux-saga/effects';
import { race } from 'typed-redux-saga';

type Saga<TResult, TArgs extends unknown[]> = (...args: TArgs) => SagaIterator<TResult>;

export function cancelSagaOn<TResult, TArgs extends unknown[]>(
  saga: Saga<TResult, TArgs>,
  cancelOn: Pattern<AnyAction>,
): Saga<TResult | undefined, TArgs> {
  return function* wrapped(...args: TArgs): SagaIterator<TResult | undefined> {
    const { cancel, result } = yield* race({
      result: call(saga, ...args),
      cancel: take(cancelOn),
    });
    if (cancel) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as TResult;
  };
}
