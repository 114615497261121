import { NativeError, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ORGANIZATION_USER } from 'src/modules/users/codecs/OrganizationUser';
import { User } from 'src/modules/users/types/User';
import { call, select } from 'typed-redux-saga';

export function* updateUser(userId: Uuid, userData: UserFormData): SagaIterator<User> {
  const authUser = yield* select(getLoggedInUser);

  try {
    const { content } = yield* call(
      makeRestRequest,
      'PUT',
      `/organizations/${encodeURIComponent(authUser.organization.id)}/users/${encodeURIComponent(userId)}`,
      userData,
    );
    return yield* call(decodeOrThrow, ORGANIZATION_USER, content);
  } catch (error) {
    throw new RuntimeError('Could not update user', { userData, userId }, NativeError.wrap(error));
  }
}
