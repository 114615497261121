import './HvacPage.scss';
import { useForm } from '@form-ts/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { useFormResolver } from 'src/modules/form/hooks/useFormResolver';
import { FormError } from 'src/modules/form/types/FormError';
import { HVAC_CHANGE } from 'src/modules/hvac/actions/HvacActions';
import { getHvacFormCounters } from 'src/modules/hvac/selectors/getHvacFormCounters';
import { getHvacFormData } from 'src/modules/hvac/selectors/getHvacFormData';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { getHvacResultsInaccurate } from 'src/modules/hvac/selectors/getHvacResultsInaccurate';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { resolveHvacFormData } from 'src/modules/hvac/utils/resolveHvacFormData';
import { HvacFormAssessmentView } from 'src/modules/hvac/views/HvacFormAssessmentView';
import { HvacResultEnergyView } from 'src/modules/hvac/views/HvacResultEnergyView';
import { HvacResultSavingsView } from 'src/modules/hvac/views/HvacResultSavingsView';
import { HvacResultSummaryView } from 'src/modules/hvac/views/HvacResultSummaryView';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const HvacPage = (): React.ReactElement => {
  const intl = useIntl();
  const project = useSelector(getProjectDetails);

  const results = useParametricSelector(getHvacResults, project);
  const counters = useParametricSelector(getHvacFormCounters, project);
  const inaccurate = useParametricSelector(getHvacResultsInaccurate, project);
  const formData = useParametricSelector(getHvacFormData, project);
  const saveFormData = useAction(HVAC_CHANGE.trigger);
  const shareProject = useBoundAction(PROJECT_VIEW_SHARE.trigger, project);

  const hvacForm = useForm<HvacFormData, FormError>('hvac', {
    reinitialize: false,
    initialValues: formData,
  });
  useFormResolver(hvacForm, resolveHvacFormData);

  useEffect(() => hvacForm.subscribe((prevState) => {
    const nextState = hvacForm.currentState;
    if (prevState.values !== nextState.values) {
      saveFormData(nextState.values);
    }
  }), [hvacForm, saveFormData]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'hvac/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />

        <div className="bp-hvac-page">
          <div className="bp-hvac-page__content">
            <HvacResultSummaryView
              form={hvacForm}
            />

            <HvacResultEnergyView
              form={hvacForm}
            />

            <HvacFormAssessmentView
              form={hvacForm}
              counters={counters}
            />

            <HvacResultSavingsView
              projectId={project.id}
              form={hvacForm}
              results={results}
              inaccurate={inaccurate}
            />
          </div>
        </div>
      </AppLayout>

      <ShareProject/>
    </>
  );
};
