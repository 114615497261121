import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { AUTH_USER } from 'src/modules/common/codecs/AuthUser';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { call } from 'typed-redux-saga';

export function* loadUser(): SagaIterator<AuthUser> {
  try {
    const { content } = yield* call(makeRestRequest, 'GET', '/account/profile');
    return yield* call(decodeOrThrow, AUTH_USER, content);
  } catch (error) {
    throw new RuntimeError('Could not load current user', {}, NativeError.wrap(error));
  }
}
