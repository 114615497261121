import { NativeError, RuntimeError, resolveCurrentProjectData } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { BASEDATA_CHANGE } from 'src/modules/basedata/actions/BasedataActions';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* basedataChangeSaga(
  action: GetTriggerActionType<typeof BASEDATA_CHANGE>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Saving project basedata...', formData);

    const previous = yield* select(getProjectDetails);
    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        cover: formData.cover,
        title: formData.title,
        goals: formData.projectGoals,
        building: formData.building,
        participants: formData.participants,
      },
    };

    const modifiedCarbonIntensity = {
      ...modified,
      data: resolveCurrentProjectData(modified.data),
    };

    const reloaded = yield* call(syncProjectBackground, modifiedCarbonIntensity);
    yield* call(logDebug, 'Saving project basedata... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save project basedata', { formData }, NativeError.wrap(error));
    yield* call(logError, 'Saving project basedata... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
