import './Field.scss';
import './TextField.scss';
import { TextInput } from '@carbon/react';
import { TextInputProps } from '@carbon/react/lib/components/TextInput/TextInput';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
TextInputProps,
'id' | 'invalid' | 'invalidText' | 'helperText' | 'name'
| 'value' | 'onChange' | 'onBlur' | 'maxCount' | 'maxLength'
>;

type Props<TData> = BaseProps & {
  readonly size: 'sm' | 'md' | 'lg';
  readonly theme?: 'gray' | 'white';
  readonly disabled?: boolean;
  readonly outlined?: boolean;
  readonly maxLength?: number;
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, string>;
  readonly onBlur?: () => void;
  readonly renderError?: (error: FormError) => React.ReactNode;
  readonly renderHint?: () => React.ReactNode;
};

export const TextField: <TData>(props: Props<TData>) => React.ReactElement = (
  {
    size,
    theme = 'gray',
    disabled,
    outlined = false,
    maxLength,
    field,
    wrapperClass,
    onBlur,
    renderError = renderErrorDefault,
    renderHint,
    ...props
  },
) => {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const hint = (renderHint || ((): React.ReactNode => renderHintDefault(value.length, maxLength)));

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(event.target.value),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));

    onBlur?.();
  }, [field, onBlur]);

  return (
    <div className={clsx(
      'bp-field',
      'bp-text-field',
      {
        'bp-text-field--invalid': invalid,
        'bp-text-field--outlined': outlined,
        'bp-text-field--disabled': disabled,
      },
      `bp-text-field--${size}`,
      `bp-text-field--${theme}`,
      wrapperClass,
    )}
    >
      <TextInput
        {...props}
        disabled={disabled}
        id={`${field.form.name}.${field.path}`}
        invalid={invalid}
        invalidText={error && invalid ? renderError(error) : null}
        helperText={hint()}
        name={field.path}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        maxCount={maxLength}
        maxLength={maxLength}
      />
    </div>
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);

const renderHintDefault = (length: number, maxLength?: number): React.ReactNode => (
  <FormattedMessage
    id="form/helper/maxLength"
    values={{ actualLength: length, maxLength: maxLength }}
  />
);
