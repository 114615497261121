import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { ProjectToolbar } from 'src/modules/common/components/ProjectToolbar';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { getConfigSchemas } from 'src/modules/config/selectors/getConfigSchemas';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import {
  PRESELECTED_SCHEMA_CHANGE,
  SCHEMA_COMMENT_CHANGE,
  SCHEMA_MATRIX_CHANGE,
  SCHEMA_SELECTION_FILTER_CHANGE,
  SCHEMA_TEMPLATE_SELECT,
  SCHEMA_VERSION_CHANGE,
  SCHEMA_VERSION_CREATE,
  SCHEMA_VERSION_REMOVE,
} from 'src/modules/schema/actions/SchemaActions';
import { getSchemaFormData } from 'src/modules/schema/selectors/getSchemaFormData';
import { getSchemaMatrix } from 'src/modules/schema/selectors/getSchemaMatrix';
import { getSchemaPreselected, getSchemaSelectionFilter } from 'src/modules/schema/selectors/getSchemaSelectionState';
import { MultipleSchemasScreen } from 'src/modules/schema/views/MultipleSchemasScreen';
import { ShareProject } from 'src/modules/sharing-projects/containers/ShareProject';

export const SchemaPage = (): React.ReactElement => {
  const intl = useIntl();

  const project = useSelector(getProjectDetails);
  const hvacAssessmentFormData = useParametricSelector(getHvacAssesmentFormData, project);
  const schemas = useParametricSelector(getSchemaFormData, project);
  const multipleSchemasMatrix = useParametricSelector(getSchemaMatrix, project);
  const allConfigschemas = useSelector(getConfigSchemas);

  const storedFilterFormValue = useSelector(getSchemaSelectionFilter);
  const storedPreselectedSchema = useSelector(getSchemaPreselected);

  const selectTemplate = useAction(SCHEMA_TEMPLATE_SELECT.trigger);

  const createVersion = useAction(SCHEMA_VERSION_CREATE.trigger);
  const changeVersion = useAction(SCHEMA_VERSION_CHANGE.trigger);
  const removeVersion = useAction(SCHEMA_VERSION_REMOVE.trigger);

  const syncSchemaMatrix = useAction(SCHEMA_MATRIX_CHANGE.trigger);
  const savePreselectedSchema = useAction(PRESELECTED_SCHEMA_CHANGE.request);
  const saveSchemaFilter = useAction(SCHEMA_SELECTION_FILTER_CHANGE.request);

  const changeSchemaAreaComment = useAction(SCHEMA_COMMENT_CHANGE.trigger);

  const shareProject = useBoundAction(PROJECT_VIEW_SHARE.trigger, project);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'schema/pageTitle' })}
        </title>
      </Helmet>

      <AppLayout>
        <ProjectToolbar
          project={project}
          onShare={shareProject}
        />

        <MultipleSchemasScreen
          projectId={project.id}
          schemas={schemas}
          hvacAssessmentFormData={hvacAssessmentFormData}
          storedFilterFormValue={storedFilterFormValue}
          storedPreselectedSchema={storedPreselectedSchema}
          multipleSchemasMatrix={multipleSchemasMatrix}
          allConfigschemas={allConfigschemas}
          selectTemplate={selectTemplate}
          createVersion={createVersion}
          changeVersion={changeVersion}
          removeVersion={removeVersion}
          changeComment={changeSchemaAreaComment}
          syncSchemaMatrix={syncSchemaMatrix}
          savePreselectedSchema={savePreselectedSchema}
          saveSchemaFilter={saveSchemaFilter}
        />
      </AppLayout>

      <ShareProject/>
    </>
  );
};
