import './ProfilePage.scss';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { AppLayout } from 'src/modules/common/layouts/AppLayout';
import { ProfileContent } from 'src/modules/profile/views/ProfileContent';

export const ProfilePage = (): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'profile/pageTitle' })}</title>
      </Helmet>

      <AppLayout>
        <ProfileContent/>
      </AppLayout>
    </>
  );
};
