import { T, useTranslate } from '@tolgee/react';
import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';

export type PrimitiveType =
  | string
  | number
  | boolean
  | null
  | undefined
  | Date;

export type MessageDescriptor = {
  readonly id: string;
};

export type FormatIcuElement = {
  (node: React.ReactNode): React.ReactNode;
};

export type StringMessageValues = Readonly<Record<string, PrimitiveType>>;

export type ElementMessageValues = Readonly<Record<string, PrimitiveType | FormatIcuElement | React.ReactNode>>;

export type FormattedMessageProps = MessageDescriptor & {
  readonly values?: ElementMessageValues;
};

export type FormattedDateProps = {
  readonly value: Date;
};

export type IntlInstance = {
  readonly formatMessage: (descriptor: MessageDescriptor, values?: StringMessageValues) => string;
};

export type WrappedComponentProps<TProps> = TProps & {
  readonly intl: IntlInstance;
};

export const FormattedMessage = ({ id, values }: FormattedMessageProps): React.ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <T keyName={id} params={values}/>
);

export const FormattedDate = ({ value }: FormattedDateProps): React.ReactElement => {
  const language = useSelector(getLanguage);

  const formatted = new Intl.DateTimeFormat(language, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(value);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
};

export function useIntl(): IntlInstance {
  const { t } = useTranslate();
  return useMemo((): IntlInstance => ({
    formatMessage: (descriptor, values) => t(descriptor.id, values),
  }), [t]);
}

export function injectIntl<TProps>(
  Component: React.ComponentType<WrappedComponentProps<TProps>>,
): React.FunctionComponent<TProps> {
  return (props: TProps): React.ReactElement => {
    const intl = useIntl();
    return <Component intl={intl} {...props}/>;
  };
}
