import './EvaluationResultsSavings.scss';
import { CurrencyCode, EvaluationResults, HvacArea } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CarbonSavingsUnit } from 'src/modules/common/components/CarbonSavingsUnit';
import { CurrencySymbol } from 'src/modules/common/components/CurrencySymbol';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';
import { SavingsAmount } from 'src/modules/common/components/SavingsAmount';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { SavingTileCard } from 'src/modules/common/components/SavingTileCard';
import { TileCard } from 'src/modules/common/components/TileCard';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { ChartUpIcon } from 'src/modules/common/icons/ChartUpIcon';
import { CloudIcon } from 'src/modules/common/icons/CloudIcon';
import { CurrencyExchangeIcon } from 'src/modules/common/icons/CurrencyExchangeIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';

type Props = {
  readonly results: EvaluationResults;
  readonly currency: CurrencyCode;
};

export const EvaluationResultsSavings = ({ results, currency }: Props): React.ReactElement => (
  <TileCard title={(
    <FormattedMessage
      id="evaluation/savings/title"
      values={{ sub, sup }}
    />
  )}
  >
    <div className="bp-evaluation-results-savings__grid">
      <SavingTileCard
        savingTitle={(
          <FormattedMessage
            id="evaluation/savings/energy/title"
            values={{ sub, sup }}
          />
        )}
        icon={<HeatingIcon/>}
        amountTitle={(
          <FormattedMessage
            id="evaluation/savings/energy/subtitle"
            values={{ sub, sup }}
          />
        )}
        amount={(
          <SavingsValue
            value={results.savings.energy.overall?.mul(100) ?? null}
            precision={0}
          />
        )}
        unit={<FormattedMessage id="unit/percent"/>}
        small={false}
      >
        {HVAC_AREAS.map((area) => (
          <div key={area} className="bp-evaluation-results-savings__details">
            <div className="bp-evaluation-results-savings__details-title">
              {HVAC_NAMES[area]}
            </div>
            <div className="bp-evaluation-results-savings__details-value">
              <span>
                <SavingsValue
                  value={results.savings.energy.perArea[area]?.mul(100) ?? null}
                  precision={0}
                />
              </span>
              <span className="bp-evaluation-results-savings__details-unit">
                <FormattedMessage id="unit/percent"/>
              </span>
            </div>
          </div>
        ))}
      </SavingTileCard>

      <SavingTileCard
        savingTitle={(
          <FormattedMessage
            id="evaluation/savings/costExclCO2/title"
            values={{ sub, sup }}
          />
        )}
        icon={<CurrencyExchangeIcon/>}
        amountTitle={(
          <FormattedMessage
            id="evaluation/savings/costExclCO2/subtitle"
            values={{ sub: sub, sup: sup, currency: <CurrencySymbol currency={currency}/> }}
          />
        )}
        amount={(
          <SavingsAmount
            value={results.savings.costExclCO2.overall}
            currency={currency}
          />
        )}
        unit={null}
        small={false}
      >
        {HVAC_AREAS.map((area) => (
          <div key={area} className="bp-evaluation-results-savings__details">
            <div className="bp-evaluation-results-savings__details-title">
              {HVAC_NAMES[area]}
            </div>
            <div className="bp-evaluation-results-savings__details-value">
              <SavingsAmount
                value={results.savings.costExclCO2.perArea[area]}
                currency={currency}
              />
            </div>
          </div>
        ))}
      </SavingTileCard>

      <SavingTileCard
        savingTitle={(
          <FormattedMessage
            id="evaluation/savings/costInclCO2/title"
            values={{ sub, sup }}
          />
        )}
        icon={<CurrencyExchangeIcon/>}
        amountTitle={(
          <FormattedMessage
            id="evaluation/savings/costInclCO2/subtitle"
            values={{ sub: sub, sup: sup, currency: <CurrencySymbol currency={currency}/> }}
          />
        )}
        amount={(
          <SavingsAmount
            value={results.savings.costInclCO2.overall}
            currency={currency}
          />
        )}
        unit={null}
        small={false}
      >
        {HVAC_AREAS.map((area) => (
          <div key={area} className="bp-evaluation-results-savings__details">
            <div className="bp-evaluation-results-savings__details-title">
              {HVAC_NAMES[area]}
            </div>
            <div className="bp-evaluation-results-savings__details-value">
              <SavingsAmount
                value={results.savings.costInclCO2.perArea[area]}
                currency={currency}
              />
            </div>
          </div>
        ))}
      </SavingTileCard>
    </div>

    <div className="bp-evaluation-results-savings__grid">
      <SavingTileCard
        savingTitle={(
          <FormattedMessage
            id="evaluation/investment/title"
            values={{ sub, sup }}
          />
        )}
        icon={<ChartUpIcon/>}
        amountTitle={(
          <FormattedMessage
            id="evaluation/investment/subtitle"
            values={{ sub: sub, sup: sup, currency: <CurrencySymbol currency={currency}/> }}
          />
        )}
        amount={(
          <FormattedValue
            value={results.investment.carbonEmission}
            precision={0}
          />
        )}
        unit={(
          <FormattedMessage
            id="evaluation/investment/unit"
            values={{ sub: sub, sup: sup, currency: <CurrencySymbol currency={currency}/> }}
          />
        )}
        small={false}
      >
        <h5>
          <FormattedMessage
            id="evaluation/investment/description/title"
            values={{ sub, sup }}
          />
        </h5>
        <p>
          <FormattedMessage
            id="evaluation/investment/description/text"
            values={{ sub, sup }}
          />
        </p>
      </SavingTileCard>

      <SavingTileCard
        savingTitle={(
          <FormattedMessage
            id="evaluation/savings/carbon/title"
            values={{ sub, sup }}
          />
        )}
        icon={<CloudIcon/>}
        amountTitle={(
          <FormattedMessage
            id="evaluation/savings/carbon/subtitle"
            values={{ sub, sup }}
          />
        )}
        amount={(
          <SavingsValue
            value={results.savings.carbon.overall?.div(1_000_000) ?? null}
            precision={0}
          />
        )}
        unit={<CarbonSavingsUnit/>}
        small={false}
      >
        {HVAC_AREAS.map((area) => (
          <div key={area} className="bp-evaluation-results-savings__details">
            <div className="bp-evaluation-results-savings__details-title">
              {HVAC_NAMES[area]}
            </div>
            <div className="bp-evaluation-results-savings__details-value">
              <SavingsValue
                value={results.savings.carbon.perArea[area]?.div(1_000_000) ?? null}
                precision={0}
              />
            </div>
            <div className="bp-evaluation-results-savings__details-unit">
              <CarbonSavingsUnit/>
            </div>
          </div>
        ))}
      </SavingTileCard>
    </div>
  </TileCard>
);

const HVAC_AREAS = [
  HvacArea.HEATING,
  HvacArea.COOLING,
  HvacArea.VENTILATION,
];

const HVAC_NAMES = {
  heating: <FormattedMessage id="evaluation/hvacArea/heating"/>,
  cooling: <FormattedMessage id="evaluation/hvacArea/cooling"/>,
  ventilation: <FormattedMessage id="evaluation/hvacArea/ventilation"/>,
};
