import './EvaluationResultsChartsGraph.scss';
import { EvaluationResultsChartsBEP } from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import { FormattedNumber } from 'src/modules/common/components/FormattedNumber';
import { sub, sup } from 'src/modules/common/constants/formattingHelpers';
import { EvaluationResultsChartsXTick } from 'src/modules/evaluation/views/EvaluationResultsChartsXTick';

type Props = {
  readonly data: EvaluationResultsChartsBEP | null;
  readonly xAxisLabel?: string;
  readonly yAxisLabel?: string;
  readonly interactive: boolean;
  readonly areCostSavingsExcludedNegative: boolean;
  readonly isInvestmentEmpty: boolean;
};

export const EvaluationResultsChartsGraphBEP = memo(({
  data,
  xAxisLabel,
  yAxisLabel,
  interactive,
  areCostSavingsExcludedNegative,
  isInvestmentEmpty,
}: Props) => {
  const chartData = useMemo(() => {
    if (data === null) {
      return [];
    }

    return data.values.map((point) => ({
      year: point.year.toNumber(),
      exclCO2: point.value.exclCO2.div(DIVIDER).toNumber(),
      inclCO2: point.value.inclCO2.div(DIVIDER).toNumber(),
      investment: data.investment.div(DIVIDER).toNumber(),
    }));
  }, [data]);

  const [isChartReady, setChartReady] = useState(chartData.length === 0);

  const xDomain = useMemo(() => {
    if (data === null) {
      return [0, 7];
    }

    return [
      0,
      data.values[data.values.length - 1].year
        .toDecimalPlaces(0, Decimal.ROUND_UP).toNumber(),
    ];
  }, [data]);

  const xTicks = useMemo(() => {
    const [min, max] = xDomain;
    return Array.from({ length: (max - min) + 1 }, (_, i) => min + i);
  }, [xDomain]);

  const yStep = useMemo(() => (data ? getYStep(data) : 50), [data]);

  const yDomain = useMemo(() => {
    if (data === null) {
      return [0, 350];
    }

    const roundMax = (v: Decimal): Decimal => v.div(yStep).add(0.25).toDecimalPlaces(0, Decimal.ROUND_CEIL).mul(yStep);

    const domainMax = Math.max(
      roundMax(data.investment.div(DIVIDER)).toNumber(),
      ...data.values.map((it) => roundMax(it.value.inclCO2.div(DIVIDER)).toNumber()),
      ...data.values.map((it) => roundMax(it.value.exclCO2.div(DIVIDER)).toNumber()),
    );

    return [
      0,
      Math.max(yStep, domainMax),
    ];
  }, [data, yStep]);

  const yTicks = useMemo(() => {
    const [min, max] = yDomain;
    return Array.from({ length: (max - min) / yStep + 1 }, (_, i) => min + i * yStep);
  }, [yStep, yDomain]);

  const renderTooltip = useCallback((props: TooltipProps<ValueType, NameType>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label } = props;

    const value = chartData.find((it) => it.year === label);
    if (value === undefined) {
      return null;
    }

    return (
      <div className="bp-evaluation-results-charts-graph__tooltip">
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: 'none' }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/years"
              values={{ sub, sup }}
            />
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.year}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.exclCO2 }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/exclCO2"
              values={{ sub, sup }}
            />
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.exclCO2}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__tooltip-row">
          <div
            className="bp-evaluation-results-charts-graph__tooltip-color"
            style={{ backgroundColor: COLORS.inclCO2 }}
          />
          <div className="bp-evaluation-results-charts-graph__tooltip-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/inclCO2"
              values={{ sub, sup }}
            />
          </div>
          <div className="bp-evaluation-results-charts-graph__tooltip-value">
            <FormattedNumber
              value={value.inclCO2}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </div>
        </div>
      </div>
    );
  }, [chartData]);

  const renderLegend = useMemo(() => (
    <div className="bp-evaluation-results-charts-graph__legend-wrapper">
      <div className="bp-evaluation-results-charts-graph__legend">
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.investment }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/investment"
              values={{ sub, sup }}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.exclCO2 }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/exclCO2"
              values={{ sub, sup }}
            />
          </div>
        </div>
        <div className="bp-evaluation-results-charts-graph__legend-row">
          <div
            className="bp-evaluation-results-charts-graph__legend-icon"
            style={{ backgroundColor: COLORS.inclCO2 }}
          />

          <div className="bp-evaluation-results-charts-graph__legend-name">
            <FormattedMessage
              id="evaluation/charts/BEP/values/inclCO2"
              values={{ sub, sup }}
            />
          </div>
        </div>
      </div>
    </div>
  ), []);

  const renderNotification = useMemo(() => {
    if (areCostSavingsExcludedNegative) {
      return (
        <div className="bp-evaluation-results-charts-graph__empty">
          <FormattedMessage id="evaluation/charts/savings/negative"/>
        </div>
      );
    }

    if (isInvestmentEmpty) {
      return (
        <div className="bp-evaluation-results-charts-graph__empty">
          <FormattedMessage id="evaluation/charts/savings/empty"/>
        </div>
      );
    }

    return null;
  }, [areCostSavingsExcludedNegative, isInvestmentEmpty]);

  return (
    <div className="bp-evaluation-results-charts-graph__graph-wrapper" data-ready={isChartReady} data-type="BEP">
      <div className="bp-evaluation-results-charts-graph__graph">
        {renderNotification}

        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <CartesianGrid
              stroke="#C3C3C3"
              strokeWidth={1}
              strokeDasharray="3 3"
            />

            <XAxis
              dataKey="year"
              domain={xDomain}
              ticks={xTicks}
              tick={EvaluationResultsChartsXTick}
              fontSize={12}
            />

            <YAxis
              domain={yDomain}
              ticks={yTicks}
              fontSize={12}
              tickFormatter={formatYTick}
            />

            {interactive && (
              <Tooltip
                isAnimationActive={false}
                content={renderTooltip}
                cursor={{
                  stroke: '#2C2B2B',
                  strokeWidth: 1,
                  strokeDasharray: '3 3',
                }}
              />
            )}

            <Line
              onAnimationEnd={() => setChartReady(true)}
              dataKey="investment"
              stroke={COLORS.investment}
              strokeWidth={3}
              strokeOpacity={1}
              dot={false}
              activeDot={false}
            />

            <Line
              onAnimationEnd={() => setChartReady(true)}
              dataKey="exclCO2"
              stroke={COLORS.exclCO2}
              strokeWidth={3}
              strokeOpacity={1}
              dot={false}
              activeDot={{
                fill: COLORS.exclCO2,
                fillOpacity: 1,
                stroke: COLORS.exclCO2,
                strokeWidth: 5,
              }}
            />

            <Line
              onAnimationEnd={() => setChartReady(true)}
              dataKey="inclCO2"
              stroke={COLORS.inclCO2}
              strokeWidth={3}
              strokeOpacity={1}
              dot={false}
              activeDot={{
                fill: COLORS.inclCO2,
                fillOpacity: 1,
                stroke: COLORS.inclCO2,
                strokeWidth: 5,
              }}
            />
          </LineChart>
        </ResponsiveContainer>

        {renderLegend}

        <div className="bp-evaluation-results-charts-graph__y-label">
          <p className="bp-evaluation-results-charts-graph__y-label-text">
            {yAxisLabel}
          </p>
        </div>

        <div className="bp-evaluation-results-charts-graph__x-label">
          <p className="bp-evaluation-results-charts-graph__x-label-text">
            {xAxisLabel}
          </p>
        </div>
      </div>
    </div>
  );
});

const COLORS = {
  investment: '#437FA3',
  exclCO2: '#BC7DB3',
  inclCO2: '#BDD2DF',
};

function getYStep(data: EvaluationResultsChartsBEP): number {
  const min = Math.min(
    data.investment.div(DIVIDER).toDecimalPlaces(0).toNumber(),
    ...data.values.map((it) => it.value.inclCO2.div(DIVIDER).toDecimalPlaces(0).toNumber()),
    ...data.values.map((it) => it.value.exclCO2.div(DIVIDER).toDecimalPlaces(0).toNumber()),
  );
  const max = Math.max(
    data.investment.div(DIVIDER).toDecimalPlaces(0).toNumber(),
    ...data.values.map((it) => it.value.inclCO2.div(DIVIDER).toDecimalPlaces(0).toNumber()),
    ...data.values.map((it) => it.value.exclCO2.div(DIVIDER).toDecimalPlaces(0).toNumber()),
  );

  const diff = max - min;
  const pow = Math.max(2, 10 ** (Math.ceil(Math.log10(diff)) - 1));

  for (const x of [1, 2, 5]) {
    if (diff <= x * pow) {
      return x * pow * 0.1;
    }
  }

  return pow;
}

const DIVIDER = 1_000;

function formatYTick(value: unknown): string {
  if (typeof value !== 'number') {
    return '';
  }

  return `${value.toFixed(0)}`;
}
