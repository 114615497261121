import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { SCHEMA_VERSION_CREATE } from 'src/modules/schema/actions/SchemaActions';
import { getSchemaFormData } from 'src/modules/schema/selectors/getSchemaFormData';
import { SchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import { getReportPreselectedFutureVersion } from 'src/modules/schema/utils/getReportPreselectedFutureVersion';
import { mapSchemaFormDataToProject } from 'src/modules/schema/utils/mapSchema';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { assertNotNull } from 'src/utils/assert';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* schemaVersionRemoveSaga(
  action: GetTriggerActionType<typeof SCHEMA_VERSION_CREATE>,
): SagaIterator<void> {
  const version = action.data;
  const area = action.meta;

  try {
    yield* call(logDebug, 'Removing schema version...', version);

    const previous = yield* select(getProjectDetails);

    const previousProjectSchemaArea = assertNotNull(
      previous.data.schema[area],
      'Could not find schema to change',
    );

    const previousSchema = yield* select(getSchemaFormData, previous);

    const previousSchemaArea = assertNotNull(
      previousSchema[area],
      'Could not find schema to delete',
    );

    const modifiedSchema: SchemaFormData = {
      ...previousSchemaArea,
      future: version.type === 'future'
        ? previousSchemaArea.future.filter((it) => it.number !== version.number)
        : previousSchemaArea.future,
    };

    const schemaSelectionResult = yield* call(mapSchemaFormDataToProject, modifiedSchema);

    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        schema: {
          ...previous.data.schema,
          [area]: version.type === 'actual'
            ? null
            : {
              ...schemaSelectionResult,
              matrix: previousProjectSchemaArea.matrix,
            },
        },
      },
    };

    const updatedReport = yield* call(
      getReportPreselectedFutureVersion,
      previous.data,
      modified.data,
      area,
    );

    const modifiedWithReport: Project = {
      ...modified,
      data: {
        ...modified.data,
        report: updatedReport,
      },
    };

    const reloaded = yield* call(syncProjectBackground, modifiedWithReport);
    yield* call(logDebug, 'Removing schema version... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not remove schema version', { version }, NativeError.wrap(error));
    yield* call(logError, 'Removing schema version... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
