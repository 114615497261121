import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import * as C from 'io-ts/Codec';

export const ENERGY_EFFICIENCY_CLASS: C.Codec<unknown, EnergyEfficiencyClass, EnergyEfficiencyClass> = C.literal(
  'A+',
  'A',
  'B',
  'C',
  'D',
);
