import { HvacArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ReportGeneratedSavingsAnnualValues } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsTotalCost = createSelector([
  getHvacResults,
], (results): ReportGeneratedSavingsAnnualValues => ({
  heating: results.perArea[HvacArea.HEATING].savings.thermal.annual,
  cooling: results.perArea[HvacArea.COOLING].savings.thermal.annual,

  total: results.overall.savings.overall.annual,
  electricity: results.overall.savings.electrical.annual,
}));
