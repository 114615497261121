import { constVoid } from 'fp-ts/function';
import { createContext } from 'react';
import { OutputDocumentPageInfo } from 'src/modules/output/types/OutputDocumentPageInfo';

export type OutputDocumentContextData = {
  readonly registerPage: (page: OutputDocumentPageInfo) => void;
  readonly unregisterPage: (id: string) => void;
};

export const OutputDocumentContext = createContext<OutputDocumentContextData>({
  registerPage: constVoid,
  unregisterPage: constVoid,
});
