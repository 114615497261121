/* eslint-disable import/no-import-module-exports */
import { routerMiddleware } from 'connected-react-router';
import { constVoid } from 'fp-ts/function';
import { History, createBrowserHistory } from 'history';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'src/reducers/rootReducer';
import { Root } from 'src/root';
import { rootSaga } from 'src/sagas/rootSaga';
import { RootState } from 'src/types/RootState';

export function init(
  rootElement: HTMLElement,
): void {
  nProgress.configure({ showSpinner: false });

  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware({
    onError(error: Error, { sagaStack }) {
      /* eslint-disable no-console */
      console.error(error);
      console.error(sagaStack);

      // TODO: sentry logging
    },
  });

  const store = rootStore(history, [
    sagaMiddleware,
    routerMiddleware(history),
  ]);

  const reactRoot = createRoot(rootElement);

  let sagaTask = sagaMiddleware.run(rootSaga);
  const renderApp = (RootElement: typeof Root): void => reactRoot.render((
    <AppContainer>
      <RootElement store={store} history={history}/>
    </AppContainer>
  ));

  renderApp(Root);

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./root', () => {
      // eslint-disable-next-line
      const nextRoot: typeof Root = require('./root').Root;
      renderApp(nextRoot);
    });
    module.hot.accept('./sagas/rootSaga', () => {
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        // eslint-disable-next-line
        const nextSaga: typeof rootSaga = require('./sagas/rootSaga').rootSaga;
        sagaTask = sagaMiddleware.run(nextSaga);
      }, constVoid);
    });
    module.hot.accept('./reducers/rootReducer', () => {
      // eslint-disable-next-line
      const nextReducer: typeof rootReducer = require('./reducers/rootReducer').rootReducer;
      store.replaceReducer(nextReducer(history));
    });
  }
}

function rootStore(
  history: History,
  middlewares: ReadonlyArray<Middleware>,
): Store<RootState> {
  return createStore(
    rootReducer(history),
    composeWithDevTools({ name: 'Belimo Portal', trace: true })(applyMiddleware(...middlewares)),
  );
}
