import { SagaIterator } from 'redux-saga';
import { CONFIG_FETCH, CONFIG_LANGUAGE_SWITCH } from 'src/modules/config/actions/ConfigActions';
import { configFetchSaga } from 'src/modules/config/sagas/configFetchSaga';
import { configLanguageSwitchSaga } from 'src/modules/config/sagas/configLanguageSwitchSaga';
import { takeLatest } from 'typed-redux-saga';

export function* configSaga(): SagaIterator<void> {
  yield* takeLatest(CONFIG_FETCH.isRequest, configFetchSaga);
  yield* takeLatest(CONFIG_LANGUAGE_SWITCH.isRequest, configLanguageSwitchSaga);
}
