import { FUTURE } from 'src/modules/common/types/Future';
import { configFetchReducer } from 'src/modules/config/reducers/configFetchReducer';
import { configLanguageSwitchReducer } from 'src/modules/config/reducers/configLanguageSwitchReducer';
import { ConfigState } from 'src/modules/config/types/ConfigState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ConfigState = {
  data: FUTURE.pending(),
};

export const configReducer = chainReducers(defaultState, [
  configFetchReducer,
  configLanguageSwitchReducer,
]);
