import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { LANGUAGE } from 'src/modules/common/codecs/Language';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageSet } from 'src/sagas/utils/storage';
import { Language } from 'src/types/common/Language';
import { call } from 'typed-redux-saga';

export function* saveLanguage(language: Language): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving user language...', language);

    const stored = yield* call(LANGUAGE.encode, language);
    yield* call(storageSet, 'language', stored);

    yield* call(logDebug, 'Saving user language... done', language);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save user language', { language }, NativeError.wrap(error));

    yield* call(logError, 'Saving user language... error', error);
    yield* call(sentryCatch, wrapped);
    throw wrapped;
  }
}
