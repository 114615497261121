import { reportGenerateReducer } from 'src/modules/report/reducers/reportGenerateReducer';
import { reportResetReducer } from 'src/modules/report/reducers/reportResetReducer';
import { ReportState } from 'src/modules/report/types/ReportState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ReportState = {
  status: null,
};

export const reportReducer = chainReducers(defaultState, [
  reportResetReducer,
  reportGenerateReducer,
]);
