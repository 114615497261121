import { routeChangeReducer } from 'src/modules/routing/reducers/routeChangeReducer';
import { RoutingState } from 'src/modules/routing/types/RoutingState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: RoutingState = {
  isTransitioning: false,
  currentState: null,
  transitionError: null,
};

export const routingReducer = chainReducers(defaultState, [
  routeChangeReducer,
]);
