import { FormController } from '@form-ts/core';
import { Project } from 'src/modules/common/types/Project';
import { FormError } from 'src/modules/form/types/FormError';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { ShareProjectForm } from 'src/modules/sharing-projects/types/ShareProjectForm';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const SHARE_PROJECT_OPEN = createTrigger<Project>('SHARE_PROJECT_OPEN');
export const SHARE_PROJECT_CLOSE = createTrigger<void>('SHARE_PROJECT_CLOSE');

export const SHARE_PROJECT_FETCH = createActions<void, ReadonlyArray<SharedUser>, Project>('SHARE_PROJECT_FETCH');
export const SHARE_PROJECT_SUBMIT = createActions<
FormController<ShareProjectForm, FormError>,
ReadonlyArray<SharedUser>
>('SHARE_PROJECT_SUBMIT');

export const SHARE_PROJECT_REMOVE_OPEN = createTrigger<SharedUser>('SHARE_PROJECT_REMOVE_OPEN');
export const SHARE_PROJECT_REMOVE_CLOSE = createTrigger<void>('SHARE_PROJECT_REMOVE_CLOSE');
export const SHARE_PROJECT_REMOVE_SUBMIT = createActions<
void,
ReadonlyArray<SharedUser>
>('SHARE_PROJECT_REMOVE_SUBMIT');
