import { HvacArea } from '@belimo-retrofit-portal/logic';
import { createSelector } from 'reselect';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { getReportCurrency } from 'src/modules/report/selectors/getReportCurrency';
import { ReportGeneratedEnergy, ReportGeneratedEnergyAreaDetails } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedEnergy = createSelector([
  getReportCurrency,
  getHvacAssesmentFormData,
  getHvacResults,
], (currency, hvacForm, results): ReportGeneratedEnergy => {
  return {
    comment: hvacForm.comment,
    currency: currency,

    areas: {
      [HvacArea.HEATING]: {
        thermal: mapThermalArea(HvacArea.HEATING),
        electrical: mapElectricalArea(HvacArea.HEATING),
      },
      [HvacArea.COOLING]: {
        thermal: mapThermalArea(HvacArea.COOLING),
        electrical: mapElectricalArea(HvacArea.COOLING),
      },
      [HvacArea.VENTILATION]: {
        thermal: mapThermalArea(HvacArea.VENTILATION),
        electrical: mapElectricalArea(HvacArea.VENTILATION),
      },
    },
  };

  function mapThermalArea(area: HvacArea): ReportGeneratedEnergyAreaDetails {
    return {
      source: hvacForm[area].thermal.energySource,
      annualConsumption: hvacForm[area].thermal.annualEnergyUse,

      carbonEmission: results.perArea[area].carbonEmission.thermal,
      totalCosts: results.perArea[area].energyCosts.thermal,
    };
  }

  function mapElectricalArea(area: HvacArea): ReportGeneratedEnergyAreaDetails {
    return {
      source: hvacForm[area].electrical.energySource,
      annualConsumption: hvacForm[area].electrical.annualEnergyUse,

      carbonEmission: results.perArea[area].carbonEmission.electrical,
      totalCosts: results.perArea[area].energyCosts.electrical,
    };
  }
});
