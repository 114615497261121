import './HvacResultSummaryView.scss';
import { HvacArea } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import { modify } from 'monocle-ts/Lens';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { CircleCheckFilledIcon } from 'src/modules/common/icons/CircleCheckFilledIcon';
import { CommentIcon } from 'src/modules/common/icons/CommentIcon';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { FanIcon } from 'src/modules/common/icons/FanIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { ResetIcon } from 'src/modules/common/icons/ResetIcon';
import { BodyLargeLightText } from 'src/modules/common/typography/BodyLargeLightText';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormAssessmentData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormSection } from 'src/modules/hvac/utils/getDefaultFormSection';
import { HvacFormClearConfirmationModal } from 'src/modules/hvac/views/HvacFormClearConfirmationModal';
import { HvacFormCommentModal } from 'src/modules/hvac/views/HvacFormCommentModal';
import { HvacResultSummaryChapter } from 'src/modules/hvac/views/HvacResultSummaryChapter';
import { PROJECT_HVAC_DEFAULT_DATA } from 'src/modules/project-new/constants/projectData';

type Props = {
  readonly form: FormController<HvacFormData, FormError>;
};

export const HvacResultSummaryView = ({ form }: Props): React.ReactElement => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const handleCommentOpen = useCallback(() => {
    setIsCommentModalOpen(true);
  }, []);
  const handleCommentCancel = useCallback(() => {
    setIsCommentModalOpen(false);
  }, []);
  const handleCommentSubmit = useCallback((comment: string) => {
    setIsCommentModalOpen(false);

    form.change(pipe(
      form.currentState,
      form.field.at('assessment').at('comment').value.set(comment),
    ));
  }, [form]);

  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const handleClearOpen = useCallback(() => {
    setIsClearModalOpen(true);
  }, []);
  const handleClearCancel = useCallback(() => {
    setIsClearModalOpen(false);
  }, []);
  const handleClearSubmit = useCallback(() => {
    setIsClearModalOpen(false);

    form.change(pipe(
      form.currentState,
      pipe(
        form.field.at('assessment').value,
        modify((previous): HvacFormAssessmentData => ({
          comment: '',
          prices: PROJECT_HVAC_DEFAULT_DATA.prices,
          heating: {
            ...PROJECT_HVAC_DEFAULT_DATA.areas[HvacArea.HEATING],
            enabled: previous.heating.enabled,
            sections: previous.heating.sections.map((section) => getDefaultFormSection(section.config)),
          },
          cooling: {
            ...PROJECT_HVAC_DEFAULT_DATA.areas[HvacArea.COOLING],
            enabled: previous.cooling.enabled,
            sections: previous.cooling.sections.map((section) => getDefaultFormSection(section.config)),
          },
          ventilation: {
            ...PROJECT_HVAC_DEFAULT_DATA.areas[HvacArea.VENTILATION],
            enabled: previous.ventilation.enabled,
            sections: previous.ventilation.sections.map((section) => getDefaultFormSection(section.config)),
          },
        })),
      ),
    ));
  }, [form]);

  const comment = useFormWatch(form, form.field.at('assessment').at('comment').value.get);

  return (
    <>
      <div className="bp-hvac-result-summary-view">
        <div className="bp-hvac-result-summary-view__row">
          <div className="bp-hvac-result-summary-view__title">
            <LeadText>
              <FormattedMessage id="hvac/result/areas/title"/>
            </LeadText>
            <BodyLargeLightText>
              <FormattedMessage id="hvac/result/areas/subtitle"/>
            </BodyLargeLightText>
          </div>

          <div className="bp-hvac-result-summary-view__buttons">
            <CustomButton kind="tertiary" onClick={handleClearOpen} size="md">
              <FormattedMessage id="hvac/form/areas/clear"/>

              <div className="bp-hvac-result-summary-view__button-icon">
                <ResetIcon/>
              </div>
            </CustomButton>

            <CustomButton
              kind="tertiary"
              onClick={handleCommentOpen}
              size="md"
            >
              <FormattedMessage id="hvac/form/areas/addComment"/>

              <div className="bp-hvac-result-summary-view__button-icon">
                <CommentIcon/>
              </div>

              <div className="bp-hvac-result-summary-view__comment-badge">
                {comment.length > 0 && <CircleCheckFilledIcon/>}
              </div>
            </CustomButton>
          </div>
        </div>

        <HorizontalDivider/>

        <div className="bp-hvac-result-summary-view__row">
          <HvacResultSummaryChapter
            path={form.field.at('assessment').at('heating')}
            title={<FormattedMessage id="hvac/tab/heating"/>}
            icon={<HeatingIcon/>}
          />

          <HvacResultSummaryChapter
            path={form.field.at('assessment').at('cooling')}
            title={<FormattedMessage id="hvac/tab/cooling"/>}
            icon={<CoolingIcon/>}
          />

          <HvacResultSummaryChapter
            path={form.field.at('assessment').at('ventilation')}
            title={<FormattedMessage id="hvac/tab/ventilation"/>}
            icon={<FanIcon/>}
          />
        </div>
      </div>

      <HvacFormCommentModal
        open={isCommentModalOpen}
        comment={comment}
        onCancel={handleCommentCancel}
        onSubmit={handleCommentSubmit}
      />

      <HvacFormClearConfirmationModal
        open={isClearModalOpen}
        onCancel={handleClearCancel}
        onSubmit={handleClearSubmit}
      />
    </>
  );
};
