import { NativeError } from '@belimo-retrofit-portal/logic';
import { saveAs } from 'file-saver';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { PROJECT_LIST_DOWNLOAD } from 'src/modules/project-list/actions/ProjectListActions';
import { buildReportDocument } from 'src/modules/report/sagas/utils/buildReportDocument';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { getReportGenerated } from 'src/modules/report/selectors/getReportGenerated';
import { getReportDocumentFilename } from 'src/modules/report/utils/getReportDocumentFilename';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* projectListDownloadSaga(
  action: GetRequestActionType<typeof PROJECT_LIST_DOWNLOAD>,
): SagaIterator<void> {
  const project = action.meta;

  try {
    yield* put(PROJECT_LIST_DOWNLOAD.pending(project));
    yield* call(logDebug, 'Downloading project report...');

    const document = yield* select(getReportGenerated, project);
    const formData = yield* select(getReportFormData, project);
    const fileData = yield* call(buildReportDocument, document, formData, project);
    const fileName = yield* call(getReportDocumentFilename, document);

    yield* call(
      saveAs,
      fileData,
      `${fileName}.pdf`,
    );

    yield* call(logDebug, 'Downloading project report... done', project);
    yield* put(PROJECT_LIST_DOWNLOAD.success(undefined, project));
  } catch (error) {
    yield* call(sentryCatch, error);
    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(logError, 'Downloading project report... error', error);
    yield* put(PROJECT_LIST_DOWNLOAD.failure(NativeError.wrap(error), project));
  }
}
