import './index.scss';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/fr-ch';
import { init } from 'src/init';
import { assertNotNull } from 'src/utils/assert';

const ROOT_ELEMENT = assertNotNull(
  document.getElementById('root'),
  'Could not find DOM root',
);
init(ROOT_ELEMENT);
