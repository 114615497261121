import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { ROUTE_CHANGE } from 'src/modules/routing/actions/RouteChange';
import { ROUTING_STATES } from 'src/modules/routing/constants/RoutingStates';
import { RouteNotFoundError } from 'src/modules/routing/errors/RouteNotFoundError';
import { handleTransitionError } from 'src/modules/routing/sagas/handleTransitionError';
import { performPostTransition, performPreTransition } from 'src/modules/routing/sagas/performTransition';
import { getCurrentState } from 'src/modules/routing/selectors/getCurrentState';
import { TransitionTarget } from 'src/modules/routing/types/TransitionTarget';
import { createTransitionUid } from 'src/modules/routing/utils/createTransitionUid';
import { matchState } from 'src/modules/routing/utils/matchState';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* routeChangeSaga(
  { data: { location } }: GetRequestActionType<typeof ROUTE_CHANGE>,
): SagaIterator<void> {
  const match = yield* call(matchState, ROUTING_STATES, location.pathname);
  if (!match) {
    yield* put(ROUTE_CHANGE.failure(new RouteNotFoundError(location)));
    return;
  }

  const from = yield* select(getCurrentState);
  const to: TransitionTarget = {
    uid: yield* call(createTransitionUid),
    match: match,
    location: location,
  };

  try {
    yield* put(ROUTE_CHANGE.pending());
    yield* call(performPreTransition, { to, from });
    yield* put(ROUTE_CHANGE.success(to));
    yield* call(performPostTransition, { to, from });
  } catch (error) {
    const wrapped = NativeError.wrap(error);

    yield* call(handleTransitionError, { to, from }, wrapped);
    yield* put(ROUTE_CHANGE.failure(wrapped));
  }
}
