import './PasswordForgotForm.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { TextField } from 'src/modules/form/components/TextField';
import { FormError } from 'src/modules/form/types/FormError';
import { PasswordForgotFormData } from 'src/modules/password-forgot/types/PasswordForgotFormData';

type Props = {
  readonly form: FormController<PasswordForgotFormData, FormError>;
  readonly onSubmit: () => void;
};

export const PasswordForgotForm = ({ form, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const isSubmitting = useFormWatch(form, form.submitting.get);

  return (
    <form
      className="bp-password-forgot-form"
      noValidate={true}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        wrapperClass="bp-password-forgot-form__field"
        field={form.field.at('username')}
        labelText={intl.formatMessage({ id: 'passwordForgot/username/label' })}
        type="email"
        size="md"
        disabled={isSubmitting}
        autoFocus={true}
        autoComplete="username"
        placeholder={intl.formatMessage({ id: 'passwordForgot/username/placeholder' })}
        spellCheck={false}
        autoCorrect="off"
        autoCapitalize="off"
        renderHint={constNull}
      />

      <CustomButton
        disabled={isSubmitting}
        kind="primary"
        type="submit"
        className="bp-password-forgot-form__submit"
        loading={isSubmitting}
        fullWidth={true}
        renderIcon={() => (
          <div className="bp-password-forgot-form__submit-icon">
            <ArrowRightIcon/>
          </div>
        )}
        size="md"
      >
        <FormattedMessage id="passwordForgot/submit"/>
      </CustomButton>
    </form>
  );
};
