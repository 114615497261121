import {
  EnergySource,
  getCarbonIntensityEditable,
  getCarbonIntensityValue,
  HvacArea,
} from '@belimo-retrofit-portal/logic';
import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import * as L from 'monocle-ts/Lens';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

const toFixedDecimal = (decimalValue: Decimal | null, decimalPlaces: number): Decimal | null => (
  decimalValue ? new Decimal(decimalValue.toDecimalPlaces(decimalPlaces)) : null
);

export function resolveHvacFormData(
  prevValues: HvacFormData,
  nextValues: HvacFormData,
): HvacFormData {
  return pipe(
    nextValues,
    resolveThermalCarbonIntensity.bind(null, HvacArea.HEATING, prevValues),
    resolveThermalCarbonIntensity.bind(null, HvacArea.COOLING, prevValues),
    resolveThermalCarbonIntensity.bind(null, HvacArea.VENTILATION, prevValues),
    resolveElectricityCarbonIntensity.bind(null, HvacArea.HEATING, prevValues),
    resolveElectricityCarbonIntensity.bind(null, HvacArea.COOLING, prevValues),
    resolveElectricityCarbonIntensity.bind(null, HvacArea.VENTILATION, prevValues),
  );
}

function resolveThermalCarbonIntensity(
  system: HvacArea,
  prevValues: HvacFormData,
  nextValues: HvacFormData,
): HvacFormData {
  const prevValue = toFixedDecimal(getCarbonIntensityValue(
    prevValues.assessment[system].thermal.energySource,
    prevValues.building.address.country,
  ), 2);
  const nextValue = toFixedDecimal(getCarbonIntensityValue(
    nextValues.assessment[system].thermal.energySource,
    nextValues.building.address.country,
  ), 2);

  const prevEditable = getCarbonIntensityEditable(prevValues.assessment[system].thermal.energySource);
  const nextEditable = getCarbonIntensityEditable(nextValues.assessment[system].thermal.energySource);

  if (
    nextEditable &&
    prevEditable &&
    decimalEquals(prevValue, nextValue)
  ) {
    return nextValues;
  }

  return pipe(
    L.id<HvacFormData>(),
    L.prop('assessment'),
    L.prop(system),
    L.prop('thermal'),
    L.prop('carbonIntensity'),
    L.modify((actual) => (
      decimalEquals(actual, nextValue)
        ? actual
        : nextValue
    )),
  )(nextValues);
}

function resolveElectricityCarbonIntensity(
  system: HvacArea,
  prevValues: HvacFormData,
  nextValues: HvacFormData,
): HvacFormData {
  const nextValue = toFixedDecimal(getCarbonIntensityValue(
    EnergySource.ELECTRICITY,
    nextValues.building.address.country,
  ), 2);

  // electricity input is always disabled
  return pipe(
    L.id<HvacFormData>(),
    L.prop('assessment'),
    L.prop(system),
    L.prop('electrical'),
    L.prop('carbonIntensity'),
    L.modify((actual) => (
      decimalEquals(actual, nextValue)
        ? actual
        : nextValue
    )),
  )(nextValues);
}

function decimalEquals(a: Decimal | null, b: Decimal | null): boolean {
  if (a === null && b === null) {
    return true;
  }
  if (a !== null && b !== null) {
    return a.equals(b);
  }

  return false;
}
