import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { CONFIG_HVAC } from 'src/modules/config/codecs/ConfigHvac';
import { ConfigHvac } from 'src/modules/config/types/ConfigHvac';
import { call } from 'typed-redux-saga';

export function* loadHvacConfig(): SagaIterator<ConfigHvac> {
  try {
    const response = yield* call(fetch, '/configs/hvac.json', {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      CONFIG_HVAC,
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch HVAC configuration file', {}, NativeError.wrap(error));
  }
}
