import { BUILDING_TYPE, COUNTRY_CODE, Integer } from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';
import { INTEGER_FROM_STRING } from 'src/io/codecs/IntegerFromString';
import { decodeOrGet } from 'src/io/utils/decodeOrGet';
import { ENERGY_EFFICIENCY_CLASS } from 'src/modules/common/codecs/EnergyEfficiencyClass';
import { NON_EMPTY_STRING } from 'src/modules/common/codecs/NonEmptyString';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { PROJECT_LIST_VIEW_MODE } from 'src/modules/project-list/codecs/ProjectListViewMode';
import { PROJECT_LIST_DEFAULT_SORT } from 'src/modules/project-list/constants/sort';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { ProjectListSort } from 'src/modules/project-list/types/ProjectListSort';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';
import { isNotNull } from 'src/utils/guard';

export function parseProjectListQuery(query: URLSearchParams, defaultViewMode: ProjectListViewMode): ProjectListQuery {
  const view = decodeOrGet(PROJECT_LIST_VIEW_MODE, query.get('view'), defaultViewMode);
  const page = decodeOrGet(PAGE_NUMBER, query.get('page'), INTEGER_ONE);
  const sort = decodeOrGet(SORT_ORDER, query.get('sort'), PROJECT_LIST_DEFAULT_SORT[view]);
  const filter = parseProjectListFilter(query);

  return { view, page, sort, filter };
}

export function buildProjectListQuery(query: ProjectListQuery): URLSearchParams {
  const params = buildProjectListFilter(query.filter);
  if (query.page > 1) {
    params.set('page', query.page.toString());
  }

  const sort = (query.sort.direction === 'asc' ? '' : '-') + query.sort.field;
  params.set('sort', sort);

  params.set('view', query.view);

  return params;
}

function parseProjectListFilter(query: URLSearchParams): ProjectListFilter {
  const buildingType = query.getAll('buildingType')
    .map((it) => decodeOrGet(BUILDING_TYPE, it, null))
    .filter(isNotNull);

  const country = query.getAll('country')
    .map((it) => decodeOrGet(COUNTRY_CODE, it, null))
    .filter(isNotNull);
  const city = query.getAll('city')
    .map((it) => decodeOrGet(NON_EMPTY_STRING, it, null))
    .filter(isNotNull);

  const ratingActual = query.getAll('ratingActual')
    .map((it) => decodeOrGet(ENERGY_EFFICIENCY_CLASS, it, null))
    .filter(isNotNull);
  const ratingFuture = query.getAll('ratingFuture')
    .map((it) => decodeOrGet(ENERGY_EFFICIENCY_CLASS, it, null))
    .filter(isNotNull);

  const ownership = query.getAll('ownership')
    .map((it) => decodeOrGet(NON_EMPTY_STRING, it, null))
    .filter(isNotNull);

  return {
    term: decodeOrGet(NON_EMPTY_STRING, query.get('term'), ''),

    buildingType: new Set(buildingType),

    country: new Set(country),
    city: new Set(city),

    ratingActual: new Set(ratingActual),
    ratingFuture: new Set(ratingFuture),

    ownership: new Set(ownership),
  };
}

function buildProjectListFilter(filter: ProjectListFilter): URLSearchParams {
  const params = new URLSearchParams();

  if (filter.term.length > 0) {
    params.set('term', filter.term);
  }
  if (filter.buildingType.size > 0) {
    filter.buildingType.forEach((it) => params.append('buildingType', it));
  }
  if (filter.country.size > 0) {
    filter.country.forEach((it) => params.append('country', it));
  }
  if (filter.city.size > 0) {
    filter.city.forEach((it) => params.append('city', it));
  }
  if (filter.ratingActual.size > 0) {
    filter.ratingActual.forEach((it) => params.append('ratingActual', it));
  }
  if (filter.ratingFuture.size > 0) {
    filter.ratingFuture.forEach((it) => params.append('ratingFuture', it));
  }
  if (filter.ownership.size > 0) {
    filter.ownership.forEach((it) => params.append('ownership', it));
  }

  return params;
}

const SORT_MAP: ReadonlyMap<string, ProjectListSort> = new Map([
  ['title', { field: 'title', direction: 'asc' }],
  ['-title', { field: 'title', direction: 'desc' }],

  ['rating', { field: 'rating', direction: 'asc' }],
  ['-rating', { field: 'rating', direction: 'desc' }],

  ['updated', { field: 'updated', direction: 'asc' }],
  ['-updated', { field: 'updated', direction: 'desc' }],

  ['ownership', { field: 'ownership', direction: 'asc' }],
  ['-ownership', { field: 'ownership', direction: 'desc' }],
]);

const PAGE_NUMBER: D.Decoder<unknown, Integer> = pipe(
  INTEGER_FROM_STRING,
  D.parse((input) => (input >= 1 ? D.success(input) : D.failure(input, 'PageNumber'))),
);

const SORT_ORDER: D.Decoder<unknown, ProjectListSort> = pipe(
  D.string,
  D.parse((input) => {
    const sort = SORT_MAP.get(input);
    return sort ? D.success(sort) : D.failure(input, 'SortOrder');
  }),
);
