import './ProjectListItemGridView.scss';
import { ProjectAddress } from '@belimo-retrofit-portal/logic';
import { DocumentPdf, Folder, OverflowMenuVertical, TrashCan } from '@carbon/icons-react';
import { Tag, Tooltip } from '@carbon/react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AssetImage } from 'src/modules/common/components/AssetImage';
import { CustomLoading } from 'src/modules/common/components/CustomLoading';
import { CustomMenu } from 'src/modules/common/components/CustomMenu';
import { CustomMenuItem } from 'src/modules/common/components/CustomMenuItem';
import { ProjectTitle } from 'src/modules/common/components/ProjectTitle';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { ShareOff } from 'src/modules/common/icons/ShareOff';
import { Project } from 'src/modules/common/types/Project';
import { BodyDesktopLargeRegularText } from 'src/modules/common/typography/BodyDesktopLargeRegularText';
import { OverlineDesktopSmallText } from 'src/modules/common/typography/OverlineDesktopSmallText';
import { ProjectListItem } from 'src/modules/project-list/types/ProjectListItem';
import { ProjectListRating } from 'src/modules/project-list/views/ProjectListRating';

type Props = {
  readonly item: ProjectListItem;
  readonly downloading: boolean;
  readonly unfollowing: boolean;
  readonly onRemove: (project: Project) => void;
  readonly onDownload: (project: Project) => void;
  readonly onUnfollow: (project: Project) => void;
  readonly onShare: (project: Project) => void;
};

export const ProjectListItemGridView = ({
  item,
  downloading,
  unfollowing,
  onRemove,
  onDownload,
  onUnfollow,
  onShare,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const { project, rating } = item;
  const shared = project.isShared;

  const handleRemove = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onRemove(project);
  }, [project, onRemove]);

  const handleDownload = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onDownload(project);
  }, [project, onDownload]);

  const handleUnfollow = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onUnfollow(project);
  }, [project, onUnfollow]);

  const handleOwnershipClick = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onShare(project);
  }, [project, onShare]);

  const buildingType = intl.formatMessage({ id: `common/buildingType/${project.data.building.type}` });
  const ownershipContent = shared
    ? project.owner?.email ?? '—'
    : intl.formatMessage({ id: 'projectList/item/owned' });

  return (
    <Link to={`/project/${encodeURIComponent(project.id)}`} className="bp-project-list-item-grid-view">
      <div className="bp-project-list-item-grid-view__header">
        <div className="bp-project-list-item-grid-view__header-title">
          <BodyDesktopLargeRegularText className="bp-project-list-item-grid-view__text-limited">
            <ProjectTitle title={project.data.title}/>
          </BodyDesktopLargeRegularText>
        </div>
        <div className="bp-project-list-item-grid-view__header-address">
          <OverlineDesktopSmallText className="bp-project-list-item-grid-view__text-limited">
            {formatProjectDetailsSubTitle(buildingType, project.data.building.address)}
          </OverlineDesktopSmallText>
        </div>
      </div>

      <div className="bp-project-list-item-grid-view__cover">
        {project.data.cover && (
          <AssetImage
            alt={project.data.title}
            asset={project.data.cover}
            size="medium"
            className="bp-project-list-item-grid-view__cover-image"
          />
        )}
        {!project.data.cover && (
          <div className="bp-project-list-item-grid-view__cover-empty">
            <Folder size={32}/>
          </div>
        )}
      </div>

      <div className="bp-project-list-item-grid-view__info">
        <div className="bp-project-list-item-grid-view__info-updated">
          <FormattedMessage
            id="projectList/item/lastUpdate"
            values={{ date: <FormattedDate value={project.updatedAt}/> }}
          />
        </div>
        <div className="bp-project-list-item-grid-view__info-rating">
          <ProjectListRating rating={rating}/>
        </div>
      </div>

      <div className="bp-project-list-item-grid-view__actions">
        <div className="bp-project-list-item-grid-view__actions-owner">
          <Tooltip className="bp-project-list-item-grid-view__ownership-tag-tooltip" description={ownershipContent}>
            <Tag
              className={clsx('bp-project-list-item-grid-view__ownership-tag', {
                'bp-project-list-item-grid-view__ownership-tag--shared': shared,
                'bp-project-list-item-grid-view__ownership-tag--owned': !shared,
              })}
              onClick={handleOwnershipClick}
            >
              {ownershipContent}
            </Tag>
          </Tooltip>
        </div>
        <div className="bp-project-list-item-grid-view__actions-menu">
          <CustomMenu
            onClick={(e) => e?.preventDefault()}
            flipped={true}
            iconDescription={intl.formatMessage({ id: 'projectList/items' })}
            tooltipPosition="bottom"
            renderIcon={() => <OverflowMenuVertical size={32} color={CARBON_THEME.onSurfaceLightPrimary}/>}
          >
            <CustomMenuItem
              onClick={handleDownload}
              hasDivider={false}
              flipped={true}
              itemText={downloading ? (
                <div className="bp-project-list-item-grid-view__loading">
                  <CustomLoading
                    withOverlay={false}
                    small={true}
                  />
                </div>
              ) : (
                <>
                  <DocumentPdf size={22}/>

                  <FormattedMessage id="projectList/item/button/download"/>
                </>
              )}
            />

            {!shared ? (
              <CustomMenuItem
                onClick={handleRemove}
                isDelete={true}
                hasDivider={false}
                flipped={true}
                itemText={(
                  <>
                    <TrashCan size={22}/>

                    <FormattedMessage id="projectList/item/button/delete"/>
                  </>
                )}
              />
            ) : (
              <CustomMenuItem
                hasDivider={false}
                flipped={true}
                onClick={handleUnfollow}
                itemText={unfollowing ? (
                  <div className="bp-project-list-item-grid-view__loading">
                    <CustomLoading
                      withOverlay={false}
                      small={true}
                    />
                  </div>
                ) : (
                  <>
                    <ShareOff/>

                    <FormattedMessage id="projectList/item/button/unfollow"/>
                  </>
                )}
              />
            )}
          </CustomMenu>
        </div>
      </div>
    </Link>
  );
};

function formatProjectDetailsSubTitle(buildingType: string, address: ProjectAddress): string {
  return [buildingType, address.street.trim(), `${address.city} ${address.zip}`.trim()].filter(Boolean).join(', ');
}
