import './DeleteUserModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';

type Props = {
  fullName: string;
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
  removing: boolean;
};

export const DeleteUserModal = ({ fullName, onClose, onDelete, open, removing }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <CustomModal
      isShaded={false}
      danger={true}
      className="bp-delete-user-modal"
      onClose={onClose}
      open={open}
      shouldUnmount={true}
      size="sm"
    >
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        className="bp-delete-user-modal__header"
        closeClassName="bp-delete-user-modal__close-button"
        closeIconClassName="bp-delete-user-modal__close-icon"
        closeModal={onClose}
        label={intl.formatMessage({ id: 'admin/user/deleteModal/header/label' })}
        labelClassName="bp-delete-user-modal__header-label"
        title={intl.formatMessage({ id: 'admin/user/deleteModal/header/title' })}
        titleClassName="bp-delete-user-modal__header-title"
      />

      <ModalBody className="bp-delete-user-modal__body">
        {intl.formatMessage({ id: 'admin/user/deleteModal/body/content' }, { fullName })}
      </ModalBody>

      <ModalFooter>
        <CustomButton
          className="bp-delete-user-modal__footer-button bp-delete-user-modal__footer-button--secondary"
          kind="secondary"
          autoFocus={true}
          onClick={onClose}
        >
          {intl.formatMessage({ id: 'admin/user/deleteModal/footer/cancel' })}
        </CustomButton>

        <CustomButton
          className={clsx('bp-delete-user-modal__footer-button', 'bp-delete-user-modal__footer-button--primary', {
            'bp-delete-user-modal__footer-button--disabled': removing,
          })}
          disabled={removing}
          kind="primary"
          onClick={onDelete}
        >
          {intl.formatMessage({ id: 'admin/user/deleteModal/footer/confirm' })}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
