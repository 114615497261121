import './HvacResultEnergyPricesView.scss';
import { CurrencyCode, EnergySource, HvacArea } from '@belimo-retrofit-portal/logic';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { EnergyPriceUnit } from 'src/modules/common/components/EnergyPriceUnit';
import { DECIMAL_PLACES, MAX_TRILLION } from 'src/modules/common/constants/decimal';
import { DecimalField } from 'src/modules/form/components/DecimalField';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormAssessmentData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, HvacFormAssessmentData>;
  readonly currency: CurrencyCode;
  readonly heatingEnabled: boolean;
  readonly coolingEnabled: boolean;
  readonly horizontal?: boolean;
};

export const HvacResultEnergyPricesView = ({
  path,
  currency,
  heatingEnabled,
  coolingEnabled,
  horizontal,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const energyInfo = useFormWatch(path.form, path.value.get);
  const visibleSources = getVisibleEnergySources(energyInfo, heatingEnabled, coolingEnabled);

  return (
    <div className={clsx(
      'bp-hvac-result-energy-prices-view',
      {
        'bp-hvac-result-energy-prices-view--horizontal': horizontal,
      },
    )}
    >
      {visibleSources.map((source) => (
        <DecimalField
          wrapperClass="bp-hvac-result-energy-prices-view__input"
          size="md"
          key={source}
          decimalScale={DECIMAL_PLACES}
          field={path.at('prices').at(source)}
          labelText={intl.formatMessage({ id: `hvac/energy/price/${source}` })}
          autoComplete="off"
          decimalPlaces={4}
          maxValue={MAX_TRILLION}
          endAdornment={(
            <EnergyPriceUnit
              source={source}
              currency={currency}
            />
          )}
        />
      ))}
    </div>
  );
};

function getVisibleEnergySources(
  formData: HvacFormAssessmentData,
  heatingEnabled: boolean,
  coolingEnabled: boolean,
): EnergySource[] {
  return Array.from(new Set([
    ...getSystemEnergySources(formData, HvacArea.HEATING, heatingEnabled),
    ...getSystemEnergySources(formData, HvacArea.COOLING, coolingEnabled),
  ]));
}

function getSystemEnergySources(
  formData: HvacFormAssessmentData,
  systemType: HvacArea,
  systemEnabled: boolean,
): EnergySource[] {
  return systemEnabled
    ? [EnergySource.ELECTRICITY, formData[systemType].thermal.energySource]
    : [EnergySource.ELECTRICITY];
}
