import React from 'react';
import { SCHEMA_CONTAINER_SIZE } from 'src/modules/schema/constants/schema';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaContainer } from 'src/modules/schema/types/SchemaContainer';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { SchemaDeviceView } from 'src/modules/schema/views/canvas/SchemaDeviceView';

type Props = {
  readonly assignments: ReadonlyArray<SchemaAssignment>;
};

export const SchemaAssignments = React.memo(({ assignments }: Props): React.ReactElement => (
  <g
    pointerEvents="auto"
    className="bp-schema-assignments"
  >
    {assignments.map((assignment) => (
      assignment.selection
        ? (
          <SchemaDeviceShape
            key={assignment.container.id}
            container={assignment.container}
            selection={assignment.selection}
          />
        )
        : (
          <SchemaContainerShape
            key={assignment.container.id}
            container={assignment.container}
          />
        )
    ))}
  </g>
));

type ContainerProps = {
  readonly container: SchemaContainer;
};

const SchemaContainerShape = ({ container }: ContainerProps): React.ReactElement => {
  const position = {
    x: container.position.x,
    y: container.position.y,
  };

  return (
    <g
      transform={`translate(${position.x} ${position.y})`}
      className="bp-schema-device-container"
      data-container-type={container.type}
    >
      <rect
        x={0}
        y={0}
        width={SCHEMA_CONTAINER_SIZE.x}
        height={SCHEMA_CONTAINER_SIZE.y}
        rx={4}
        ry={4}
        fill="white"
        stroke="blue"
        strokeWidth={2}
        className="bp-schema-device-container__outline"
      />
    </g>
  );
};

type DeviceProps = {
  readonly container: SchemaContainer;
  readonly selection: SchemaDevice;
};

const SchemaDeviceShape = ({ container, selection }: DeviceProps): React.ReactElement => {
  const position = {
    x: container.position.x,
    y: container.position.y,
  };

  return (
    <g
      transform={`translate(${position.x} ${position.y})`}
      className="bp-schema-device-shape"
      data-container-type={container.type}
    >
      <rect
        x={0}
        y={0}
        width={SCHEMA_CONTAINER_SIZE.x}
        height={SCHEMA_CONTAINER_SIZE.y}
        rx={4}
        ry={4}
        fill="white"
        stroke="none"
        className="bp-schema-device-shape__fill"
      />

      <g className="bp-schema-device-shape__view">
        <SchemaDeviceView
          size={SCHEMA_CONTAINER_SIZE}
          device={selection}
        />
      </g>

      <rect
        x={0}
        y={0}
        width={SCHEMA_CONTAINER_SIZE.x}
        height={SCHEMA_CONTAINER_SIZE.y}
        rx={4}
        ry={4}
        fill="none"
        stroke="blue"
        strokeWidth={2}
        className="bp-schema-device-shape__outline"
      />
    </g>
  );
};
