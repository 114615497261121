import { FormController } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { FormError } from 'src/modules/form/types/FormError';
import { FormRule } from 'src/modules/form/types/FormRule';
import { validate } from 'src/modules/form/utils/validate';

export function useFormValidator<TData>(
  form: FormController<TData, FormError>,
  rule: FormRule<TData>,
): void {
  useEffect(() => form.middleware((nextState) => {
    const nextValues = nextState.values;
    const prevValues = form.currentState.values;

    if (nextValues === prevValues) {
      return nextState;
    }

    const errors = validate(nextValues, rule);
    return pipe(
      nextState,
      form.errors.set(errors),
    );
  }), [form, rule]);
}
