type Method<A extends unknown[], R> = (...args: A) => R;

type Decorator<A extends unknown[], R> = (
  target: unknown,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<Method<A, R>>,
) => TypedPropertyDescriptor<Method<A, R>>;

export function bind<A extends unknown[], R>(): Decorator<A, R> {
  return (
    _: unknown,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<Method<A, R>>,
  ) => ({
    configurable: true,
    get: function getter(this: object): Method<A, R> {
      const bound = descriptor.value!.bind(this);

      Object.defineProperty(this, propertyKey, {
        value: bound,
        configurable: true,
        writable: true,
      });

      return bound;
    },
  });
}

export function memoize<A extends unknown[], R>(hashFunc: Method<A, unknown>): Decorator<A, R> {
  return (
    _: unknown,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<Method<A, R>>,
  ) => ({
    configurable: true,
    get: function getter(this: object): Method<A, R> {
      const cacheMap = new Map<unknown, R>();
      const memoized = getNewFunction(hashFunc, cacheMap, descriptor.value!);

      Object.defineProperty(this, propertyKey, {
        value: memoized,
        configurable: true,
        writable: true,
      });

      return memoized;
    },
  });
}

function getNewFunction<A extends [], R>(
  hashFunc: Method<A, unknown>,
  cacheMap: Map<unknown, R>,
  originalMethod: Method<A, R>,
): Method<A, R> {
  return function method(this: unknown, ...args: A): R {
    const cacheKey = hashFunc(...args);
    if (cacheMap.has(cacheKey)) {
      return cacheMap.get(cacheKey)!;
    }

    const result = originalMethod.apply(this, args);
    cacheMap.set(cacheKey, result);
    return result;
  };
}
