import { SagaIterator } from 'redux-saga';
import {
  SHARE_PROJECT_CLOSE,
  SHARE_PROJECT_FETCH,
  SHARE_PROJECT_OPEN,
  SHARE_PROJECT_REMOVE_SUBMIT,
  SHARE_PROJECT_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { shareProjectFetchSaga } from 'src/modules/sharing-projects/sagas/shareProjectFetchSaga';
import { shareProjectRemoveSaga } from 'src/modules/sharing-projects/sagas/shareProjectRemoveSaga';
import { shareProjectSubmitSaga } from 'src/modules/sharing-projects/sagas/shareProjectSubmitSaga';
import { cancel, fork, put, take, takeLatest } from 'typed-redux-saga';

export function* shareProjectSaga(): SagaIterator<void> {
  do {
    const { data: project } = yield* take(SHARE_PROJECT_OPEN.is);

    const task = yield* fork(shareProjectModalSaga);
    yield* put(SHARE_PROJECT_FETCH.request(undefined, project));

    yield* take(SHARE_PROJECT_CLOSE.is);
    yield* cancel(task);
  } while (true);
}

function* shareProjectModalSaga(): SagaIterator<void> {
  yield* takeLatest(SHARE_PROJECT_FETCH.isRequest, shareProjectFetchSaga);
  yield* takeLatest(SHARE_PROJECT_SUBMIT.isRequest, shareProjectSubmitSaga);
  yield* takeLatest(SHARE_PROJECT_REMOVE_SUBMIT.isRequest, shareProjectRemoveSaga);
}
