import { SagaIterator } from 'redux-saga';
import { LOADER_HIDE, LOADER_SHOW } from 'src/modules/loader/actions/LoaderActions';
import { put } from 'typed-redux-saga';

export function* showLoadingIndicator(key: string): SagaIterator<void> {
  yield* put(LOADER_SHOW.trigger(undefined, key));
}

export function* hideLoadingIndicator(key: string): SagaIterator<void> {
  yield* put(LOADER_HIDE.trigger(undefined, key));
}
