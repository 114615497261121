import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { PROJECT_LIST_IMPORT_UPLOAD } from 'src/modules/project-list/actions/ProjectListActions';
import { IMPORT_CONTENT } from 'src/modules/project-list/codecs/import';
import { mapImportProjectData } from 'src/modules/project-list/utils/mapImportLine';
import { doOnImportWorker } from 'src/sagas/utils/doOnImportWorker';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, delay, put } from 'typed-redux-saga';

export function* projectListImportUploadSaga(
  action: GetRequestActionType<typeof PROJECT_LIST_IMPORT_UPLOAD>,
): SagaIterator<void> {
  try {
    yield* put(PROJECT_LIST_IMPORT_UPLOAD.pending());
    yield* call(logDebug, 'Reading import file...');

    yield* delay(1_000);
    const lines = yield* call(doOnImportWorker, action.data);
    const decoder = yield* call(IMPORT_CONTENT, mapImportProjectData);
    const content = yield* call(decodeOrThrow, decoder, lines);

    yield* call(logDebug, 'Reading import file... done', content);
    yield* put(PROJECT_LIST_IMPORT_UPLOAD.success(content));
  } catch (error) {
    yield* call(logError, 'Reading import file... error', error);
    yield* put(PROJECT_LIST_IMPORT_UPLOAD.failure(NativeError.wrap(error)));
  }
}
