import { SagaIterator } from 'redux-saga';
import { Project } from 'src/modules/common/types/Project';
import { loadProjectListChunk } from 'src/modules/project-list/sagas/utils/loadProjectListChunk';
import { call } from 'typed-redux-saga';

export function* loadProjectListFull(): SagaIterator<Project[]> {
  let projects: Project[] = [];

  while (true) {
    // NOTE: 100 is max `take` on the backend
    const limit = 100;
    const chunk = yield* call(loadProjectListChunk, limit, projects.length);
    projects = projects.concat(chunk);

    if (chunk.length < limit) {
      return projects;
    }
  }
}
