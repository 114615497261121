import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useAction } from 'src/hooks/useAction';
import { LoginLayout } from 'src/modules/common/layouts/LoginLayout';
import { PASSWORD_FORGOT_SUBMIT } from 'src/modules/password-forgot/actions/PasswordForgotActions';
import { PasswordForgotScreen } from 'src/modules/password-forgot/views/PasswordForgotScreen';

export const PasswordForgotPage = (): React.ReactElement => {
  const intl = useIntl();
  const submitForm = useAction(PASSWORD_FORGOT_SUBMIT.request);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'passwordForgot/pageTitle' })}</title>
      </Helmet>

      <LoginLayout>
        <PasswordForgotScreen onSubmit={submitForm}/>
      </LoginLayout>
    </>
  );
};
