import './HorizontalDivider.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  readonly className?: string;
};

export const HorizontalDivider = ({ className }: Props):React.ReactElement => (
  <hr className={clsx('bp-horizontal-divider', className)}/>
);
