import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { CONFIG_FETCH } from 'src/modules/config/actions/ConfigActions';
import { loadConfig } from 'src/modules/config/sagas/utils/loadConfig';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* configFetchSaga(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching configuration...');
    yield* put(CONFIG_FETCH.pending());

    const config = yield* call(loadConfig);

    yield* call(logDebug, 'Fetching configuration... done', config);
    yield* put(CONFIG_FETCH.success(config));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Fetching configuration... error', { error });
    yield* put(CONFIG_FETCH.failure(NativeError.wrap(error)));
  }
}
