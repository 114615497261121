import * as D from 'io-ts/Decoder';
import { EMPTY } from 'src/modules/common/codecs/Empty';
import {
  ConfigHvac,
  ConfigHvacAddition,
  ConfigHvacOption,
  ConfigHvacSection,
} from 'src/modules/config/types/ConfigHvac';

const CONFIG_HVAC_ADDITION: D.Decoder<unknown, ConfigHvacAddition> = D.struct({
  id: D.string,
  title: D.string,
  placeholder: D.union(EMPTY, D.string),
});

const CONFIG_HVAC_OPTION: D.Decoder<unknown, ConfigHvacOption> = D.struct({
  id: D.string,
  title: D.string,
  explanation: D.union(EMPTY, D.string),
});

const CONFIG_HVAC_SECTION: D.Decoder<unknown, ConfigHvacSection> = D.struct({
  id: D.string,
  name: D.string,
  explanation: D.union(EMPTY, D.string),
  option: D.array(CONFIG_HVAC_OPTION),
  additionals: D.array(CONFIG_HVAC_ADDITION),
});

export const CONFIG_HVAC: D.Decoder<unknown, ConfigHvac> = D.struct({
  heating: D.array(CONFIG_HVAC_SECTION),
  cooling: D.array(CONFIG_HVAC_SECTION),
  ventilation: D.array(CONFIG_HVAC_SECTION),
});
