import { Lens } from 'monocle-ts/Lens';

const NON_NULL_LENS = {
  get: <T>(value: T): T => value,
  set: <T>(value: T | null) => (state: T): T => value ?? state,
};

export function ensureNonNull<T>(): Lens<T, T | null> {
  return NON_NULL_LENS;
}

const NULL_TO_ALL = {
  get: <T>(value: T | null): T | 'All' => value ?? 'All',
  set: <T>(value: T | 'All') => (): T | null => (value === 'All' ? null : value),
};

export function nullToAll<T>(): Lens<T | null, T | 'All'> {
  return NULL_TO_ALL;
}
