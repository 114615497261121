import './EnergyEfficiencyStack.scss';
import { getEnergyEfficiencyLabel } from '@belimo-retrofit-portal/logic';
import { CaretRight } from '@carbon/icons-react';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { EnergyEfficiencyLabelWrapper } from 'src/modules/common/components/EnergyEfficiencyLabelWrapper';
import { FACTOR_WEIGHTS_LABELS } from 'src/modules/common/constants/factorWeightedLabel';

type Props = {
  readonly line: boolean;
  readonly value: Decimal | null;
};

export const EnergyEfficiencyStack = ({ line, value }: Props): React.ReactElement => {
  if (value === null) {
    return (
      <div className="bp-energy-efficiency-stack">
        <div className="bp-energy-efficiency-stack__stack">
          {CLASS_LIST.map((label) => (
            <div key={label} className="bp-energy-efficiency-stack__stack-class">
              <EnergyEfficiencyLabelWrapper
                value={FACTOR_WEIGHTS_LABELS[label]}
                sized={true}
                disabled={true}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const position = getPosition(value);
  const maxLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_UP));
  const minLabel = getEnergyEfficiencyLabel(value.toDecimalPlaces(0, Decimal.ROUND_DOWN));

  return (
    <div className="bp-energy-efficiency-stack">
      <div className="bp-energy-efficiency-stack__addition" data-hvac-factor={value?.toFixed(3)}>
        <div className="bp-energy-efficiency-stack__addition-value" style={{ marginBottom: position }}>
          <div className="bp-energy-efficiency-stack__addition-value-label">
            {minLabel === maxLabel
              ? minLabel
              : <>{minLabel}&ndash;{maxLabel}</>}
          </div>

          <div className="bp-energy-efficiency-stack__addition-value-arrow">
            <CaretRight size={22}/>
          </div>
        </div>

        {line && (
          <div className="bp-energy-efficiency-stack__addition-line"/>
        )}
      </div>

      <div className="bp-energy-efficiency-stack__stack">
        {CLASS_LIST.map((label) => (
          <div key={label} className="bp-energy-efficiency-stack__stack-class">
            <EnergyEfficiencyLabelWrapper
              value={FACTOR_WEIGHTS_LABELS[label]}
              sized={true}
              disabled={label !== maxLabel && label !== minLabel}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CLASS_LIST = ['A+', 'A', 'B', 'C', 'D'] as const;

const STACK_ITEM_HEIGHT = 1.333;
const STACK_ITEM_MARGIN = 0.25;

function getPosition(value: Decimal): React.CSSProperties['marginBottom'] {
  return `${value.mul(STACK_ITEM_HEIGHT + STACK_ITEM_MARGIN).add(STACK_ITEM_HEIGHT / 2).toFixed(3)}em`;
}
