import './HvacFormSectionAdditionals.scss';
import { FieldPath } from '@form-ts/core';
import { useFormField } from '@form-ts/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';
import { HvacFormAdditionalData, HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacFormSectionAdditionalInput } from 'src/modules/hvac/views/HvacFormSectionAdditionalInput';

type Props = {
  readonly path: FieldPath<HvacFormData, FormError, ReadonlyArray<HvacFormAdditionalData>>;
  readonly disabled: boolean;
};

export const HvacFormSectionAdditionals = ({ path, disabled }: Props): React.ReactElement => {
  const intl = useIntl();

  const { value } = useFormField(path);

  return (
    <div className="bp-hvac-form-section-additionals">
      {value.map((field, index) => (
        <HvacFormSectionAdditionalInput
          key={field.config.id}
          labelText={intl.formatMessage({ id: field.config.title })}
          field={path.at(index)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
