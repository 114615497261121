import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const FileIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M8.65 17.35h6.7v-.7h-6.7v.7Zm0-4h6.7v-.7h-6.7v.7ZM5.3 20.7V3.3h9.35l4.05 4.05V20.7H5.3Zm9-13V4H6v16h12V7.7h-3.7Z"
      />
    </svg>
  </IconWrapper>
));
