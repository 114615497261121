import { createSelector } from 'reselect';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { HvacFormSectionData, HvacFormVariantData } from 'src/modules/hvac/types/HvacFormData';
import { ReportGeneratedHvac, ReportGeneratedHvacSection } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedHvac = createSelector([
  getHvacAssesmentFormData,
], (hvacFormData): ReportGeneratedHvac => ({
  heating: hvacFormData.heating.sections
    .filter((section) => section.enabled)
    .map(mapSection),
  cooling: hvacFormData.cooling.sections
    .filter((section) => section.enabled)
    .map(mapSection),
  ventilation: hvacFormData.ventilation.sections
    .filter((section) => section.enabled)
    .map(mapSection),
}));

function mapSection(section: HvacFormSectionData): ReportGeneratedHvacSection {
  return {
    title: section.title,
    config: section.config,
    actual: {
      rating: section.answer.actual.option?.class ?? null,
      title: section.answer.actual.option?.title ?? null,
      explanation: section.answer.actual.option?.explanation ?? null,
      comment: getComment(section.answer.actual),
    },
    future: {
      rating: section.answer.future.option?.class ?? null,
      title: section.answer.future.option?.title ?? null,
      explanation: section.answer.future.option?.explanation ?? null,
      comment: getComment(section.answer.future),
    },
    copies: section.copies.map((copy, copyIndex) => ({
      id: `${section.config.id}-${copyIndex}`,
      name: copy.title,
      actual: {
        rating: copy.answer.actual.option?.class ?? null,
        title: copy.answer.actual.option?.title ?? null,
        explanation: copy.answer.actual.option?.explanation ?? null,
        comment: getComment(copy.answer.actual),
      },
      future: {
        rating: copy.answer.future.option?.class ?? null,
        title: copy.answer.future.option?.title ?? null,
        explanation: copy.answer.future.option?.explanation ?? null,
        comment: getComment(copy.answer.future),
      },
    })),
  };
}

function getComment(answer: HvacFormVariantData): string {
  const value = answer.additionals.find((it) => it.config.id === 'comment')?.value;
  return typeof value === 'string' ? value : '';
}
