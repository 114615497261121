import { AnyAction } from 'redux';

type ActionHelper<TData, TMeta = void> = {
  trigger(data: TData, meta: TMeta): ActionTrigger<TData, TMeta>;
  is(action: AnyAction): action is ActionTrigger<TData, TMeta>;
};

type ActionTrigger<TData, TMeta = void> = {
  readonly type: string;
  readonly data: TData;
  readonly meta: TMeta;
};

export type GetTriggerActionType<TActions extends ActionHelper<unknown, unknown>>
    = TActions extends ActionHelper<infer D, infer M>
      ? ActionTrigger<D, M>
      : never;

export function createTrigger<TData, TMeta = void>(type: string): ActionHelper<TData, TMeta> {
  const TRIGGER = `${type}_TRIGGER`;

  return {
    trigger: (data, meta) => ({ type: TRIGGER, data: data, meta: meta }),
    is: (action): action is ActionTrigger<TData, TMeta> => action.type === TRIGGER,
  };
}
