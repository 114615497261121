import { NativeError } from '@belimo-retrofit-portal/logic';
import { captureException, withScope } from '@sentry/browser';

export function sentryCatch(error: unknown): void {
  withScope((scope) => {
    // TODO: don't we need a user info here?
    const context = NativeError.wrap(error).toJSON();

    scope.setExtra('error_context', context);
    captureException(error);
  });
}
