import { SagaIterator } from 'redux-saga';
import { PROJECT_LIST_ABORT } from 'src/modules/project-list/actions/ProjectListActions';
import { logDebug } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* abortProjectList(): SagaIterator<void> {
  yield* call(logDebug, 'Aborting project list operations...');
  yield* put(PROJECT_LIST_ABORT.trigger());
  yield* call(logDebug, 'Aborting project list operations... done');
}
