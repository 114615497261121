import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { Project } from 'src/modules/common/types/Project';
import { call } from 'typed-redux-saga';

export function* removeProject(project: Project): SagaIterator<void> {
  try {
    yield* call(makeRestRequest, 'DELETE', `/projects/${encodeURIComponent(project.id)}`);
  } catch (error) {
    throw new RuntimeError('Could not remove project', { project }, NativeError.wrap(error));
  }
}
