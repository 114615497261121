import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { ORGANIZATION } from 'src/modules/common/codecs/Organization';
import { ORGANIZATION_CHANGE } from 'src/modules/organization/actions/OrganizationActions';
import { updateUserSaga } from 'src/modules/organization/sagas/updateUserSaga';
import { getOrganization } from 'src/modules/organization/selectors/getOrganization';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* updateLogoSaga(action: GetTriggerActionType<typeof ORGANIZATION_CHANGE>): SagaIterator<void> {
  try {
    const organization = yield* select(getOrganization);

    const { content } = yield* call(
      makeRestRequest,
      'PUT',
      `/organizations/${encodeURIComponent(organization?.id)}`,
      {
        logoId: action.data.logo?.id || null,
      },
    );

    const decodedOrganization = yield* call(decodeOrThrow, ORGANIZATION, content);

    yield* call(updateUserSaga, decodedOrganization);
  } catch (error) {
    throw new RuntimeError('Could not update organization', action.data, NativeError.wrap(error));
  }
}
