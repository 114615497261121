import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { PROJECT_LIST_VIEW_MODE } from 'src/modules/project-list/codecs/ProjectListViewMode';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';
import { storageSet } from 'src/sagas/utils/storage';
import { call } from 'typed-redux-saga';

export function* saveProjectListViewMode(viewMode: ProjectListViewMode): SagaIterator<void> {
  try {
    const raw = yield* call(PROJECT_LIST_VIEW_MODE.encode, viewMode);
    yield* call(storageSet, 'project-list-view-mode', raw);
  } catch (error) {
    throw new RuntimeError('Could not save project list view mode', {}, NativeError.wrap(error));
  }
}
