import { trim } from 'fp-ts/string';
import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { LoginFormData } from 'src/modules/login/types/LoginFormData';
import { RequestAccessFormData } from 'src/modules/login/types/RequestAccessFormData';

export const LOGIN_FORM_SCHEMA: FormRule<LoginFormData> = R.struct({
  username: R.allOf([R.nonEmpty(), R.email()]),
  password: R.nonEmpty(),
});

export const REQUEST_ACCESS_FORM_SCHEMA: FormRule<RequestAccessFormData> = R.struct({
  email: R.allOf([
    R.map(trim, R.nonEmpty()),
    R.maxLength(40),
    R.email(),
  ]),
  fullName: R.allOf([
    R.map(trim, R.nonEmpty()),
    R.maxLength(40),
  ]),
  companyName: R.allOf([
    R.map(trim, R.nonEmpty()),
    R.maxLength(40),
  ]),
});
