import { createSelector } from 'reselect';
import { getHvacResultsOverallRating } from 'src/modules/hvac/selectors/getHvacResultsOverallRating';
import {
  getReportGeneratedSavingsAnnualSavings,
} from 'src/modules/report/selectors/getReportGeneratedSavingsAnnualSavings';
import {
  getReportGeneratedSavingsRatingPerArea,
} from 'src/modules/report/selectors/getReportGeneratedSavingsRatingPerArea';
import { ReportGeneratedSavings } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavings = createSelector([
  getHvacResultsOverallRating,
  getReportGeneratedSavingsRatingPerArea,
  getReportGeneratedSavingsAnnualSavings,
], (ratingOverall, ratingPerArea, annualSavings): ReportGeneratedSavings => ({
  ratingOverall,
  ratingPerArea,
  annualSavings,
}));
