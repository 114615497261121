import './FileField.scss';
import { Close } from '@carbon/icons-react';
import { IconButton } from '@carbon/react';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileUpload } from 'src/modules/asset/views/FileUpload';
import { BlobImage } from 'src/modules/common/components/BlobImage';
import { FILE_MAX_SIZE_FILED_MB } from 'src/modules/common/constants/fileField';
import { FormError } from 'src/modules/form/types/FormError';

type Props<TData> = {
  readonly theme?: 'gray' | 'white';
  readonly className?: string;
  readonly field: FieldPath<TData, FormError, File | null>;
};

export const FileField: <TData>(props: Props<TData>) => React.ReactElement = ({ theme = 'gray', className, field }) => {
  const intl = useIntl();

  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((file: File) => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
      field.value.set(file),
    ));
  }, [field]);

  const handleReset = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
      field.value.set(null),
    ));
  }, [field]);

  const handleTouch = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  if (value !== null) {
    return (
      <div className={clsx(
        'bp-file-field',
        'bp-file-field--filled',
        `bp-file-field--${theme}`,
        className,
      )}
      >
        <BlobImage
          image={value}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />

        <div className="bp-file-field__icon-wrapper">
          <IconButton
            kind="secondary"
            className="bp-file-field__cancel-button"
            label={intl.formatMessage({ id: 'fileUpload/reset' })}
            onClick={handleReset}
          >
            <Close size={24}/>
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={clsx(
        'bp-file-field',
        `bp-file-field--${theme}`,
        {
          'bp-file-field--invalid': invalid,
        },
      )}
      >
        <FileUpload
          id={`${field.form.name}.${field.path}`}
          name={field.path}
          disabled={false}
          onTouch={handleTouch}
          onChange={handleChange}
        >
          <p className="bp-file-field__placeholder">
            <FormattedMessage id="fileUpload/placeholder"/>
          </p>
        </FileUpload>
      </div>

      <p className="bp-file-field__hint">
        <FormattedMessage
          id="form/field/file/maxSize"
          values={{ maxSize: FILE_MAX_SIZE_FILED_MB }}
        />
      </p>
    </>
  );
};
