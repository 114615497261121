import './ProjectListImportSelector.scss';
import { CheckmarkFilled } from '@carbon/icons-react';
import { ModalBody, ModalFooter } from '@carbon/react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomLoading } from 'src/modules/common/components/CustomLoading';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { Future } from 'src/modules/common/types/Future';
import { toNullable } from 'src/modules/common/utils/either';
import { ProjectListImportData, ProjectListImportEntry } from 'src/modules/project-list/types/ProjectListImport';
import { ProjectListImportDataTable } from 'src/modules/project-list/views/ProjectListImportDataTable';
import { isNotNull } from 'src/utils/guard';

type Props = {
  readonly content: ProjectListImportData;
  readonly status: Future<void> | null;
  readonly onSubmit: (entries: ReadonlyArray<ProjectListImportEntry>) => void;
  readonly onCancel: () => void;
  readonly onComplete: () => void;
};

export const ProjectListImportSelector = ({
  content,
  status,
  onSubmit,
  onCancel,
  onComplete,
}: Props): React.ReactElement => {
  const [selected, setSelected] = useState<ReadonlyArray<ProjectListImportEntry>>(() => (
    content
      .map((it) => it.importData)
      .map(toNullable)
      .filter(isNotNull)
  ));
  const handleSubmit = useCallback(() => onSubmit(selected), [selected, onSubmit]);

  return (
    <>
      <ModalBody className="bp-project-list-import-selector">
        {status?.state === 'pending' && (
          <div className="bp-project-list-import-selector__result-wrapper">
            <CustomLoading withOverlay={false}/>

            <p className="bp-project-list-import-selector__result-text">
              <FormattedMessage id="projectList/importModal/selector/loading"/>
            </p>
          </div>
        )}

        {status?.state === 'success' && (
          <div className="bp-project-list-import-selector__result-wrapper">
            <CheckmarkFilled size={64} color={CARBON_THEME.additionalSuccess100}/>

            <p className="bp-project-list-import-selector__result-text">
              <FormattedMessage id="projectList/importModal/selector/success"/>
            </p>
          </div>
        )}

        {(status === null || status.state === 'failure') && (
          <div className="bp-project-list-import-selector__result-wrapper">
            <ProjectListImportDataTable
              content={content}
              selected={selected}
              onChange={setSelected}
            />
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        {status?.state !== 'success' && (
          <>
            <CustomButton
              kind="secondary"
              autoFocus={true}
              onClick={onCancel}
              disabled={status?.state === 'pending'}
            >
              <FormattedMessage id="projectList/importModal/selector/cancel"/>
            </CustomButton>

            <CustomButton
              kind="primary"
              onClick={handleSubmit}
              disabled={selected.length === 0 || status?.state === 'pending'}
              loading={status?.state === 'pending'}
            >
              <FormattedMessage id="projectList/importModal/selector/submit"/>
            </CustomButton>
          </>
        )}

        {status?.state === 'success' && (
          <CustomButton
            className="bp-project-list-import-modal__button"
            onClick={onComplete}
          >
            <FormattedMessage id="projectList/importModal/selector/close"/>
          </CustomButton>
        )}
      </ModalFooter>
    </>
  );
};
