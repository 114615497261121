import { Stack } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { constNull } from 'fp-ts/function';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { UserRole } from 'src/modules/common/types/UserRole';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { TextField } from 'src/modules/form/components/TextField';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';

type Props = {
  className?: string;
  disableEmail?: boolean;
  disableRole: boolean;
  form: FormController<UserFormData, FormError>;
  isUserCreation: boolean;
};

export const UserForm = memo(({
  className, disableEmail, disableRole, form, isUserCreation,
}: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <Stack className={className} gap="1rem" orientation="vertical">
      <TextField
        disabled={disableEmail}
        field={form.field.at('email')}
        labelText={isUserCreation
          ? intl.formatMessage({ id: 'admin/user/form/field/email' })
          : intl.formatMessage({ id: 'admin/user/form/field/username' })}
        required={true}
        size="md"
        maxLength={50}
      />

      <TextField
        field={form.field.at('firstName')}
        labelText={intl.formatMessage({ id: 'admin/user/form/field/firstName' })}
        maxLength={50}
        renderHint={constNull}
        required={true}
        size="md"
      />

      <TextField
        field={form.field.at('lastName')}
        labelText={intl.formatMessage({ id: 'admin/user/form/field/lastName' })}
        maxLength={50}
        renderHint={constNull}
        required={true}
        size="md"
      />

      <DropdownField
        disabled={disableRole}
        field={form.field.at('role').transform(ensureNonNull())}
        itemToElement={RenderItem}
        options={ROLE_LIST}
        renderSelectedItem={renderSelectedItem}
        size="md"
        titleText={intl.formatMessage({ id: 'admin/user/form/field/role' })}
      />
    </Stack>
  );
});

const renderSelectedItem = (role: UserRole): React.ReactElement => (
  <span className="bp-user-form__form-dropdown-selected-item">{ROLE_NAMES[role]}</span>
);
const RenderItem = (role: UserRole): React.ReactElement => (
  <span className="bp-user-form__form-dropdown-item">{ROLE_NAMES[role]}</span>
);

const ROLE_LIST = [
  UserRole.USER,
  UserRole.ADMIN,
];
const ROLE_NAMES = {
  [UserRole.USER]: <FormattedMessage id="common/role/user"/>,
  [UserRole.ADMIN]: <FormattedMessage id="common/role/admin"/>,
};
