import { BaseError } from '@belimo-retrofit-portal/logic';
import { HttpError } from 'src/errors/HttpError';

export function isHttpError(error: BaseError, status: number): boolean {
  if (error instanceof HttpError) {
    return error.status === status;
  }

  return error.previous
    ? isHttpError(error.previous, status)
    : false;
}
