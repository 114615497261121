import './ProjectListImportUpload.scss';
import { Upload } from '@carbon/icons-react';
import { InlineNotification, ModalBody, ModalFooter } from '@carbon/react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomLoading } from 'src/modules/common/components/CustomLoading';
import { Future } from 'src/modules/common/types/Future';
import { ProjectListImportData } from 'src/modules/project-list/types/ProjectListImport';

type Props = {
  readonly status: Future<ProjectListImportData> | null;
  readonly onUpload: (file: File) => void;
  readonly onCancel: () => void;
};

export const ProjectListImportUpload = ({ status, onUpload, onCancel }: Props): React.ReactElement => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0] ?? new File([], 'invalid.xlsx');
    onUpload(file);
  }, [onUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    disabled: status?.state === 'pending',
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  return (
    <>
      <ModalBody className="bp-project-list-import-upload bp-project-list-import-modal__body">
        <p className="bp-project-list-import-upload__description">
          <FormattedMessage id="projectList/importModal/upload/description" values={{ link: formatLink }}/>
        </p>

        {status?.state !== 'pending' && (
          <div
            {...getRootProps()}
            className="bp-project-list-import-upload__import-wrapper"
          >
            <input {...getInputProps()}/>

            <Upload size={64}/>

            <p className="bp-project-list-import-upload__text">
              <FormattedMessage id="projectList/importModal/upload/dropZone"/>
            </p>
          </div>
        )}
        {status?.state === 'pending' && (
          <div className="bp-project-list-import-upload__pending-wrapper">
            <CustomLoading withOverlay={false}/>

            <p className="bp-project-list-import-upload__text">
              <FormattedMessage id="projectList/importModal/upload/loading"/>
            </p>
          </div>
        )}
        {status?.state === 'failure' && (
          <InlineNotification kind="error" hideCloseButton={true}>
            <FormattedMessage id="projectList/importModal/upload/incorrectData"/>
          </InlineNotification>
        )}
      </ModalBody>

      <ModalFooter className="bp-project-list-import-upload__footer">
        <CustomButton
          kind="secondary"
          autoFocus={true}
          onClick={onCancel}
        >
          <FormattedMessage id="projectList/importModal/upload/cancel"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};

function formatLink(children: React.ReactNode): React.ReactElement {
  return (
    <a
      href="/documents/import-instructions.xlsx"
      rel="noopener noreferrer"
      target="_blank"
      style={{ textDecoration: 'none', color: '#f60' }}
    >
      {children}
    </a>
  );
}
