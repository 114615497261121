import { useForm, useFormWatch } from '@form-ts/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { AdminLayout } from 'src/modules/common/layouts/AdminLayout';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import {
  USER_REMOVE_CANCEL,
  USER_REMOVE_CONFIRM,
  USER_REMOVE_PROCESS,
  USER_SAVE,
} from 'src/modules/users/actions/UserActions';
import { UserActions } from 'src/modules/users/components/UserActions';
import { USER_FORM_SCHEMA } from 'src/modules/users/constants/schema';
import { getUserDetailsData } from 'src/modules/users/selectors/getUserDetailsData';
import { getUserRemoving } from 'src/modules/users/selectors/getUserRemoving';
import { DeleteUserModal } from 'src/modules/users/views/DeleteUserModal';
import { UserEditForm } from 'src/modules/users/views/UserEditForm';

export const EditUserPage = (): React.ReactElement => {
  const intl = useIntl();

  const loggedInUser = useSelector(getLoggedInUser);
  const user = useSelector(getUserDetailsData);
  const saveUser = useAction(USER_SAVE.request);

  const removeUserState = useSelector(getUserRemoving);
  const startUserRemovingProcess = useBoundAction(USER_REMOVE_PROCESS.trigger, user.id);
  const confirmUserRemovingProcess = useAction(USER_REMOVE_CONFIRM.request);
  const cancelUserRemovingProcess = useAction(USER_REMOVE_CANCEL.trigger);

  const deleteUserModalOpen = removeUserState !== null;
  const userRemoving = removeUserState?.status?.state === 'pending';

  const form = useForm<UserFormData, FormError>('admin.users.edit', {
    initialValues: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    },
    reinitialize: false,
  });
  useFormValidator(form, USER_FORM_SCHEMA);

  const submitting = useFormWatch(form, form.submitting.get);

  const handleSave = (): void => saveUser(form, user.id);
  const handleDelete = (): void => confirmUserRemovingProcess(undefined, user.id);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'admin/user/edit/title' })}</title>
      </Helmet>

      <AdminLayout
        breadcrumbs={(
          <>
            <CustomBreadcrumbItem>
              <Link to="/projects">
                {intl.formatMessage({ id: 'admin/users/header/breadcrumbs/project' })}
              </Link>
            </CustomBreadcrumbItem>

            <CustomBreadcrumbItem>
              <Link to="/admin/users">
                {intl.formatMessage({ id: 'admin/user/header/breadcrumbs/users' })}
              </Link>
            </CustomBreadcrumbItem>
          </>
        )}
        title={`${user.firstName} ${user.lastName}`}
        extension={<UserActions onSave={handleSave} submitting={submitting}/>}
      >
        <UserEditForm
          editing={true}
          isSelf={loggedInUser.id === user.id}
          form={form}
          onDelete={startUserRemovingProcess}
        />
      </AdminLayout>

      <DeleteUserModal
        fullName={`${user.firstName} ${user.lastName}`}
        onClose={cancelUserRemovingProcess}
        onDelete={handleDelete}
        open={deleteUserModalOpen}
        removing={userRemoving}
      />
    </>
  );
};
