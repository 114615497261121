import { LogicError } from '@belimo-retrofit-portal/logic';
import { createElement, ReactElement, ReactNode } from 'react';
import { isNotNull } from 'src/utils/guard';

export function parseSvgFile(content: string): ReactElement {
  const document = new DOMParser().parseFromString(content, 'image/svg+xml');
  if (document.documentElement instanceof SVGSVGElement) {
    return parseElement(document.documentElement);
  }

  throw new LogicError('Invalid SVG content', { content });
}

function parseNode(node: Node): ReactNode {
  if (node instanceof Text) {
    return node.textContent?.trim() || null;
  }
  if (node instanceof SVGElement) {
    return parseElement(node);
  }

  return null;
}

function parseElement(node: SVGElement): ReactElement {
  const validAttributes = ALLOWED[node.nodeName];
  if (!validAttributes) {
    return createElement('g', { 'data-invalid-node': node.nodeName });
  }

  const props: Record<string, string> = { key: Math.random().toFixed(6) };
  for (const svgAttribute of validAttributes) {
    const value = node.getAttribute(svgAttribute);
    if (value !== null) {
      const reactAttribute = svgAttribute.replace(/-([a-z])/g, (dashed) => dashed.substring(1).toUpperCase());
      props[reactAttribute] = value;
    }
  }

  const children = Array.from(node.childNodes).map(parseNode).filter(isNotNull);
  return createElement(node.nodeName.toLowerCase(), props, children);
}

const COMMON_ATTRIBUTES = [
  'display',
  'visibility',

  'color',
  'opacity',
  'overflow',
  'transform',

  'clip-path',
  'clip-rule',

  'fill',
  'fill-opacity',
  'fill-rule',

  'stroke',
  'stroke-width',
  'stroke-opacity',
  'stroke-dasharray',
  'stroke-dashoffset',
  'stroke-linecap',
  'stroke-linejoin',
  'stroke-miterlimit',
];
const TEXT_ATTRIBUTES = [
  'x',
  'y',

  'dx',
  'dy',

  'font-family',
  'font-size',
  'font-stretch',
  'font-style',
  'font-variant',
  'font-weight',

  'text-anchor',
  'text-decoration',
  'text-rendering',

  'dominant-baseline',
  'baseline-shift',
  'alignment-baseline',
];

const ALLOWED: Record<string, string[] | undefined> = {
  svg: [
    'width',
    'height',

    'viewBox',
    'preserveAspectRatio',
  ],
  g: [
    ...COMMON_ATTRIBUTES,
  ],
  clipPath: [
    ...COMMON_ATTRIBUTES,
    'clipPathUnits',
  ],
  line: [
    ...COMMON_ATTRIBUTES,
    'x1',
    'y1',
    'x2',
    'y2',
  ],
  rect: [
    ...COMMON_ATTRIBUTES,
    'x',
    'y',
    'rx',
    'ry',
    'width',
    'height',
  ],
  circle: [
    ...COMMON_ATTRIBUTES,
    'cx',
    'cy',
    'r',
  ],
  ellipse: [
    ...COMMON_ATTRIBUTES,
    'cx',
    'cy',
    'rx',
    'ry',
  ],
  path: [
    ...COMMON_ATTRIBUTES,
    'd',
  ],
  polyline: [
    ...COMMON_ATTRIBUTES,
    'points',
  ],
  polygon: [
    ...COMMON_ATTRIBUTES,
    'points',
  ],
  text: [
    ...COMMON_ATTRIBUTES,
    ...TEXT_ATTRIBUTES,
  ],
  tspan: [
    ...COMMON_ATTRIBUTES,
    ...TEXT_ATTRIBUTES,
  ],
};
