import { Integer, NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ORGANIZATION_USER } from 'src/modules/users/codecs/OrganizationUser';
import { User } from 'src/modules/users/types/User';
import { UsersListData } from 'src/modules/users/types/UsersListData';
import { UsersListQuery } from 'src/modules/users/types/UsersListQuery';
import { applyUsersListQuery } from 'src/modules/users/utils/slice';
import { call, select } from 'typed-redux-saga';

export function* loadUsersList(query: UsersListQuery): SagaIterator<UsersListData> {
  const authUser = yield* select(getLoggedInUser);

  try {
    const { content } = yield* call(
      makeRestRequest,
      'GET',
      `/organizations/${encodeURIComponent(authUser.organization.id)}/users`,
    );

    const users = yield* call(decodeOrThrow, USERS, content);
    const filteredUsers = applyUsersListQuery(users, query);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return { found: filteredUsers, total: users.length as Integer };
  } catch (error) {
    throw new RuntimeError('Could not load organization users', { query }, NativeError.wrap(error));
  }
}

const USERS: D.Decoder<unknown, Array<User>> = D.array(ORGANIZATION_USER);
