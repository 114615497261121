import { createSelector } from 'reselect';
import { getPasswordResetState } from 'src/modules/password-reset/selectors/getPasswordResetState';
import { assertNotNull } from 'src/utils/assert';

export const getPasswordResetToken = createSelector([
  getPasswordResetState,
], (state) => assertNotNull(
  state.token,
  'Password recovery token is not valid',
));
