import {
  HVAC_CONFIG,
  HvacArea,
  HvacConfigAddition,
  HvacConfigOption,
  HvacConfigSection,
} from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import { mapWithIndex } from 'fp-ts/ReadonlyRecord';
import { createSelector } from 'reselect';
import { getConfigHvac } from 'src/modules/config/selectors/getHvacConfig';
import { ConfigHvacSection } from 'src/modules/config/types/ConfigHvac';
import * as App from 'src/modules/hvac/types/HvacConfig';
import { assertDefined } from 'src/utils/assert';

export const getHvacConfig = createSelector([
  getConfigHvac,
], (config): App.HvacConfig => {
  return pipe(
    HVAC_CONFIG,
    mapWithIndex(mapArea),
  );

  function mapArea(
    area: HvacArea,
    sections: ReadonlyArray<HvacConfigSection>,
  ): App.HvacConfigSection[] {
    return sections.map((section) => mapSection(area, section));
  }

  function mapSection(
    area: HvacArea,
    section: HvacConfigSection,
  ): App.HvacConfigSection {
    const extra = assertDefined(
      config[area].find((it) => it.id === section.id),
      'Could not find HVAC section translations',
      { area, section, config },
    );

    return {
      ...section,
      name: extra.name,
      explanation: extra.explanation,
      option: section.option.map((option) => mapOption(area, extra, option)),
      additionals: section.additionals.map((addition) => mapAddition(area, extra, addition)),
    };
  }

  function mapOption(
    area: HvacArea,
    section: ConfigHvacSection,
    option: HvacConfigOption,
  ): App.HvacConfigOption {
    const extra = assertDefined(
      section.option.find((it) => it.id === option.id),
      'Could not find HVAC option translations',
      { area, section, option, config },
    );

    return {
      ...option,
      title: extra.title,
      explanation: extra.explanation,
    };
  }

  function mapAddition(
    area: HvacArea,
    section: ConfigHvacSection,
    addition: HvacConfigAddition,
  ): App.HvacConfigAddition {
    const extra = assertDefined(
      section.additionals.find((it) => it.id === addition.id),
      'Could not find HVAC addition translations',
      { area, section, addition, config },
    );

    return {
      ...addition,
      title: extra.title,
      placeholder: extra.placeholder,
    };
  }
});
