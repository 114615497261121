import { createSelector } from 'reselect';
import { getLoginState } from 'src/modules/login/selectors/getLoginState';
import { assertNotNull } from 'src/utils/assert';

export const getLoggedInUser = createSelector([
  getLoginState,
], (state) => assertNotNull(
  state.user,
  'User is not authenticated',
  { state },
));
