import { Matrix } from '@belimo-retrofit-portal/logic';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { Point } from 'src/modules/common/types/Point';

export function matrixMultiply(
  matrix: Matrix,
  ...more: ReadonlyArray<Matrix>
): Matrix {
  return more.reduce((m1, m2) => ({
    a: m2.a * m1.a + m2.b * m1.c,
    b: m2.a * m1.b + m2.b * m1.d,
    c: m2.c * m1.a + m2.d * m1.c,
    d: m2.c * m1.b + m2.d * m1.d,
    e: m2.e * m1.a + m2.f * m1.c + m1.e,
    f: m2.e * m1.b + m2.f * m1.d + m1.f,
  }), matrix);
}

export function matrixScale(scale: number, origin: Point): Matrix {
  const scaleMatrix = { a: scale, b: 0, c: 0, d: scale, e: 0, f: 0 };
  if (origin.x === 0 && origin.y === 0) {
    return scaleMatrix;
  }

  return matrixMultiply(
    matrixTranslate(origin),
    scaleMatrix,
    matrixTranslate({ x: -origin.x, y: -origin.y }),
  );
}

export function matrixTranslate(point: Point): Matrix {
  return { a: 1, b: 0, c: 0, d: 1, e: point.x, f: point.y };
}

export function matrixInverse(matrix: Matrix): Matrix {
  const det = matrix.a * matrix.d - matrix.b * matrix.c;
  if (det === 0) {
    return MATRIX_IDENTITY;
  }

  return {
    a: +matrix.d / det,
    b: -matrix.b / det,
    c: -matrix.c / det,
    d: +matrix.a / det,
    e: +(matrix.c * matrix.f - matrix.d * matrix.e) / det,
    f: -(matrix.a * matrix.f - matrix.b * matrix.e) / det,
  };
}
