import './SchemaContainerPreview.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BodySmallText } from 'src/modules/common/typography/BodySmallText';
import { LeadText } from 'src/modules/common/typography/LeadText';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';
import { SchemaDeviceView } from 'src/modules/schema/views/canvas/SchemaDeviceView';

type Props = {
  readonly selection: SchemaDevice;
};

export const SchemaContainerPreview = ({ selection }: Props): React.ReactElement => (
  <div className="bp-schema-container-preview">
    <div className="bp-schema-container-preview__view">
      <svg
        width={PREVIEW_SIZE.x}
        height={PREVIEW_SIZE.y}
        viewBox={`0 0 ${PREVIEW_SIZE.x} ${PREVIEW_SIZE.y}`}
        overflow="visible"
      >
        <SchemaDeviceView
          size={PREVIEW_SIZE}
          device={selection}
        />
      </svg>
    </div>

    <LeadText>
      <FormattedMessage id={selection.device.name}/>
    </LeadText>

    {selection.device.explanation && (
      <BodySmallText>
        <FormattedMessage id={selection.device.explanation}/>
      </BodySmallText>
    )}
  </div>
);

const PREVIEW_SIZE = {
  x: 250,
  y: 250,
};
