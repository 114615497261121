import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const WarningIcon = memo(() => (
  <IconWrapper>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        // eslint-disable-next-line
        d="M9.00078 13.3C9.14245 13.3 9.2612 13.2521 9.35703 13.1563C9.45287 13.0605 9.50078 12.9417 9.50078 12.8C9.50078 12.6584 9.45287 12.5396 9.35703 12.4438C9.2612 12.348 9.14245 12.3 9.00078 12.3C8.85911 12.3 8.74037 12.348 8.64453 12.4438C8.5487 12.5396 8.50078 12.6584 8.50078 12.8C8.50078 12.9417 8.5487 13.0605 8.64453 13.1563C8.74037 13.2521 8.85911 13.3 9.00078 13.3ZM8.65078 10.2H9.35078V4.20005H8.65078V10.2ZM9.00513 17.7C7.80223 17.7 6.67113 17.4718 5.61183 17.0153C4.55251 16.5587 3.63106 15.9392 2.84746 15.1566C2.06387 14.374 1.44353 13.4537 0.986431 12.3957C0.529331 11.3377 0.300781 10.2073 0.300781 9.0044C0.300781 7.8015 0.52904 6.6704 0.985556 5.6111C1.44209 4.55178 2.06166 3.63033 2.84426 2.84675C3.62687 2.06315 4.54716 1.4428 5.60513 0.985699C6.6631 0.528599 7.79353 0.300049 8.99643 0.300049C10.1993 0.300049 11.3304 0.528307 12.3897 0.984824C13.449 1.44136 14.3705 2.06092 15.1541 2.84352C15.9377 3.62614 16.558 4.54643 17.0151 5.6044C17.4722 6.66237 17.7008 7.7928 17.7008 8.9957C17.7008 10.1986 17.4725 11.3297 17.016 12.389C16.5595 13.4483 15.9399 14.3698 15.1573 15.1534C14.3747 15.937 13.4544 16.5573 12.3964 17.0144C11.3385 17.4715 10.208 17.7 9.00513 17.7ZM9.00078 17C11.2341 17 13.1258 16.225 14.6758 14.675C16.2258 13.125 17.0008 11.2334 17.0008 9.00005C17.0008 6.76672 16.2258 4.87505 14.6758 3.32505C13.1258 1.77505 11.2341 1.00005 9.00078 1.00005C6.76745 1.00005 4.87578 1.77505 3.32578 3.32505C1.77578 4.87505 1.00078 6.76672 1.00078 9.00005C1.00078 11.2334 1.77578 13.125 3.32578 14.675C4.87578 16.225 6.76745 17 9.00078 17Z"
      />
    </svg>
  </IconWrapper>
));
