import { SagaIterator } from 'redux-saga';
import { PROJECT_LIST_RESET } from 'src/modules/project-list/actions/ProjectListActions';
import { logDebug } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* resetProjectList(): SagaIterator<void> {
  yield* call(logDebug, 'Resetting project list...');
  yield* put(PROJECT_LIST_RESET.trigger());
  yield* call(logDebug, 'Resetting project list... done');
}
