import { emulateTransitionDelay } from 'src/modules/routing/hooks/emulateTransitionDelay';
import { abortUsersList } from 'src/modules/routing/hooks/resolvers/abortUsersList';
import { fetchUserDetails } from 'src/modules/routing/hooks/resolvers/fetchUserDetails';
import { fetchUsersList } from 'src/modules/routing/hooks/resolvers/fetchUsersList';
import { resetUserDetails } from 'src/modules/routing/hooks/resolvers/resetUserDetails';
import { resetUsersList } from 'src/modules/routing/hooks/resolvers/resetUsersList';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_ADMIN_STATES: ReadonlyArray<RouteState> = [
  {
    url: '/admin/users',
    name: 'bp:admin:users',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchUsersList,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
      abortUsersList,
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
      abortUsersList,
      fetchUsersList,
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
      resetUsersList,
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
      abortUsersList,
    ),
  },
  {
    url: '/admin/users/:userId([-a-f0-9]+)/edit',
    name: 'bp:admin:users:edit',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
      fetchUserDetails,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
      resetUserDetails,
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
  {
    url: '/admin/users/add',
    name: 'bp:admin:users:add',
    parent: 'bp:authorized',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 300),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
