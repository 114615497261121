import { createSelector } from 'reselect';
import { getProjectListState } from 'src/modules/project-list/selectors/getProjectListState';
import { assertNotNull } from 'src/utils/assert';

export const getProjectListData = createSelector([
  getProjectListState,
], (state) => assertNotNull(
  state.data,
  'Project list should be fetched first',
  { state },
));
