import './ReportDocumentPageEnergyValue.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedValue } from 'src/modules/common/components/FormattedValue';

type Props = {
  readonly title: React.ReactNode;
  readonly value: Decimal | null;
  readonly unit: React.ReactNode;
};

export const ReportDocumentPageEnergyValue = ({ title, value, unit }: Props): React.ReactElement => (
  <div className="bp-report-document-page-energy-value">
    <div className="bp-report-document-page-energy-value__title">
      {title}
    </div>

    <div className="bp-report-document-page-energy-value__value">
      <div className="bp-report-document-page-energy-value__amount">
        <FormattedValue
          value={value}
          precision={0}
        />
      </div>

      <div className="bp-report-document-page-energy-value__unit">
        {unit}
      </div>
    </div>
  </div>
);
