import { projectViewDataReducer } from 'src/modules/project-view/reducers/projectViewDataReducer';
import { ProjectViewState } from 'src/modules/project-view/types/ProjectViewState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ProjectViewState = {
  data: null,
};

export const projectViewReducer = chainReducers(defaultState, [
  projectViewDataReducer,
]);
