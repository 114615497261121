import './Field.scss';
// @ts-expect-error It works fine, but in the 1.51.1 version TypeScript prints
// an error Module '"@carbon/react"' has no exported member 'PasswordInput'
// should be resolved soon
import { PasswordInput } from '@carbon/react';
import { TextInputProps } from '@carbon/react/lib/components/TextInput/TextInput';
import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormError } from 'src/modules/form/types/FormError';

type BaseProps = Omit<
TextInputProps,
'id' | 'invalid' | 'invalidText' | 'name' | 'value' | 'onChange' | 'onBlur'
>;

type Props<TData> = BaseProps & {
  readonly size: 'sm' | 'lg' | 'md';
  readonly wrapperClass?: string;
  readonly field: FieldPath<TData, FormError, string>;
  readonly renderError?: (error: FormError) => React.ReactNode;
};

export const PasswordField: <TData>(props: Props<TData>) => React.ReactElement = (
  { size, field, wrapperClass, renderError = renderErrorDefault, ...props },
) => {
  const intl = useIntl();
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(event.target.value),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <div className={clsx(
      'bp-field',
      'bp-text-field',
      { 'bp-text-field--invalid': invalid },
      wrapperClass,
      `bp-field--${size}`,
    )}
    >
      <PasswordInput
        {...props}
        id={`${field.form.name}.${field.path}`}
        invalid={invalid}
        invalidText={error && invalid ? renderError(error) : null}
        name={field.path}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showPasswordLabel={intl.formatMessage({ id: 'form/field/password/show' })}
        hidePasswordLabel={intl.formatMessage({ id: 'form/field/password/hide' })}
      />
    </div>
  );
};

const renderErrorDefault = (error: FormError): React.ReactNode => (
  <FormattedMessage id={`form/error/${error.code}`} values={error.context}/>
);
