import { Action } from 'redux';
import { ASSET_UPLOAD } from 'src/modules/asset/actions/AssetActions';
import { AssetState } from 'src/modules/asset/types/AssetState';
import { FUTURE } from 'src/modules/common/types/Future';

export function assetUploadReducer(state: AssetState, action: Action): AssetState {
  if (ASSET_UPLOAD.isRequest(action)) {
    const copy = new Map(state);
    copy.set(action.meta, FUTURE.pending());
    return copy;
  }
  if (ASSET_UPLOAD.isSuccess(action)) {
    const copy = new Map(state);
    copy.set(action.meta, FUTURE.success(action.data));
    return copy;
  }
  if (ASSET_UPLOAD.isFailure(action)) {
    const copy = new Map(state);
    copy.set(action.meta, FUTURE.failure(action.data));
    return copy;
  }

  return state;
}
