import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { array } from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { CONFIG_CONTAINER } from 'src/modules/config/codecs/ConfigContainer';
import { ConfigContainer } from 'src/modules/config/types/ConfigContainer';
import { call } from 'typed-redux-saga';

export function* loadContainers(): SagaIterator<ConfigContainer[]> {
  try {
    const response = yield* call(fetch, '/configs/containers.json', {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    return yield* call(
      decodeOrThrow,
      array(CONFIG_CONTAINER),
      content,
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch schema containers', {}, NativeError.wrap(error));
  }
}
