import './ReportFormFieldToggle.scss';
import { FieldPath } from '@form-ts/core';
import React from 'react';
import { ToggleField } from 'src/modules/form/components/ToggleField';
import { FormError } from 'src/modules/form/types/FormError';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';

type Props = {
  readonly field: FieldPath<ReportFormData, FormError, boolean>;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
};

export const ReportFormFieldToggle = ({ field, disabled, children }: Props): React.ReactElement => (
  <div className="bp-report-form-field-switch">
    <ToggleField
      disabled={disabled}
      field={field}
      label={children}
    />
  </div>
);
