import {
  HvacArea,
  ProjectHvac,
  ProjectHvacAnswer,
  ProjectHvacArea,
  ProjectHvacSection,
  ProjectHvacSectionCopy,
  ProjectHvacVariant,
} from '@belimo-retrofit-portal/logic';
import { HvacConfig, HvacConfigSection } from 'src/modules/hvac/types/HvacConfig';
import {
  HvacFormAssessmentData,
  HvacFormChapterData,
  HvacFormSectionAnswerData,
  HvacFormSectionCopyData,
  HvacFormSectionData,
  HvacFormVariantData,
} from 'src/modules/hvac/types/HvacFormData';
import { getDefaultFormSection } from 'src/modules/hvac/utils/getDefaultFormSection';

export function mapHvacStoreToForm(
  store: ProjectHvac,
  config: HvacConfig,
): HvacFormAssessmentData {
  return {
    comment: store.comment,
    prices: store.prices,

    heating: mapHvacFormChapter(
      store.areas[HvacArea.HEATING],
      config.heating,
    ),
    cooling: mapHvacFormChapter(
      store.areas[HvacArea.COOLING],
      config.cooling,
    ),
    ventilation: mapHvacFormChapter(
      store.areas[HvacArea.VENTILATION],
      config.ventilation,
    ),
  };
}

function mapHvacFormChapter(
  store: ProjectHvacArea,
  config: ReadonlyArray<HvacConfigSection>,
): HvacFormChapterData {
  return {
    enabled: store.enabled,
    thermal: store.thermal,
    electrical: store.electrical,
    sections: config.map((section) => {
      const data = store.sections.find((it) => it.id === section.id);
      return data
        ? mapHvacFormSection(data, section)
        : getDefaultFormSection(section);
    }),
  };
}

function mapHvacFormSection(
  store: ProjectHvacSection,
  config: HvacConfigSection,
): HvacFormSectionData {
  return {
    title: store.title,
    enabled: config.deactivation ? store.enabled : true,
    config: config,
    answer: mapHvacFormSectionAnswer(store.answer, config),
    copies: config.multiplication
      ? store.copies.map((copy) => mapHvacFormSectionCopy(copy, config))
      : [],
  };
}

function mapHvacFormSectionCopy(
  store: ProjectHvacSectionCopy,
  config: HvacConfigSection,
): HvacFormSectionCopyData {
  return {
    title: store.title,
    answer: mapHvacFormSectionAnswer(store.answer, config),
  };
}

function mapHvacFormSectionAnswer(
  store: ProjectHvacAnswer,
  config: HvacConfigSection,
): HvacFormSectionAnswerData {
  return {
    actual: mapHvacFormVariant(store.actual, config),
    future: mapHvacFormVariant(store.future, config),
  };
}

function mapHvacFormVariant(
  store: ProjectHvacVariant,
  config: HvacConfigSection,
): HvacFormVariantData {
  return {
    option: config.option.find((it) => it.id === store.option) ?? null,
    additionals: config.additionals.map((it) => ({
      config: it,
      value: store.additionals[it.id] ?? null,
    })),
  };
}
