import { createSelector } from 'reselect';
import { getUsersState } from 'src/modules/users/selectors/getUsersState';
import { assertNotNull } from 'src/utils/assert';

export const getUsersListData = createSelector([
  getUsersState,
], (state) => assertNotNull(
  state.users,
  'Users list should be fetched first',
  { state },
));
