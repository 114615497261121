import { SagaIterator } from 'redux-saga';
import { call as callEffect, take as takeEffect } from 'redux-saga/effects';
import { CancelledError } from 'src/errors/CancelledError';
import { Project } from 'src/modules/common/types/Project';
import { PROJECT_SYNC_PERFORM } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { waitProjectSynchronization } from 'src/modules/project-sync/sagas/utils/waitProjectSynchronization';
import { cancel, put, race } from 'typed-redux-saga';

export function* syncProjectBackground(project: Project): SagaIterator<Project> {
  yield* put(PROJECT_SYNC_PERFORM.request('background', project));

  const { synchronized } = yield* race({
    synchronized: callEffect(waitProjectSynchronization, project),
    cancelled: takeEffect(PROJECT_SYNC_PERFORM.isRequest),
  });
  if (synchronized) {
    return synchronized;
  }

  yield* cancel();
  throw new CancelledError('Explicit abort of control flow');
}
