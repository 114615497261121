import './EnergyEfficiencyTransition.scss';
import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { EnergyEfficiencyLabel } from 'src/modules/common/components/EnergyEfficiencyLabel';
import { LABELS } from 'src/modules/common/constants/energyEfficiencyLabels';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';

type Props = {
  readonly actual: EnergyEfficiencyClass | null;
  readonly future: EnergyEfficiencyClass | null;
};

export const EnergyEfficiencyTransition = ({ actual, future }: Props): React.ReactElement | null => (
  <div className="bp-energy-efficiency-transition">
    <div className="bp-energy-efficiency-transition__actual">
      <EnergyEfficiencyLabel value={actual} sized={false}>
        {actual === null ? '\u2013' : LABELS[actual]}
      </EnergyEfficiencyLabel>
    </div>

    <div className="bp-energy-efficiency-transition__arrow">
      <ArrowRightIcon/>
    </div>

    <div className="bp-energy-efficiency-transition__future">
      <EnergyEfficiencyLabel value={future} sized={false}>
        {future === null ? '\u2013' : LABELS[future]}
      </EnergyEfficiencyLabel>
    </div>
  </div>
);
