import './ReportDocumentPageAmount.scss';
import React, { memo } from 'react';

type Props = {
  title: React.ReactNode;
  amount: React.ReactNode;
  unit: React.ReactNode;
};

export const ReportDocumentPageAmount = memo(({ title, amount, unit }: Props) => (
  <div className="bp-report-document-page-amount">
    <div className="bp-report-document-page-amount__title">
      {title}
    </div>

    <div className="bp-report-document-page-amount__amount">
      {amount || '\u2014'}

      {unit}
    </div>
  </div>
));
