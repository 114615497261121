import { SagaIterator } from 'redux-saga';
import { PROJECT_VIEW_RESET, PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { projectViewShareSaga } from 'src/modules/project-view/sagas/projectViewShareSaga';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { takeLatest } from 'typed-redux-saga';

export function* projectViewSaga(): SagaIterator<void> {
  yield* takeLatest(PROJECT_VIEW_SHARE.is, cancelSagaOn(projectViewShareSaga, [
    PROJECT_VIEW_RESET.is,
  ]));
}
