import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { RequestAccessFormData } from 'src/modules/login/types/RequestAccessFormData';
import { call } from 'typed-redux-saga';

export function* submitRequestAccess(form: RequestAccessFormData): SagaIterator<void> {
  try {
    yield* call(makeRestRequest, 'POST', '/auth/request', form);
  } catch (error) {
    throw new RuntimeError('Could not submit request access', { form }, NativeError.wrap(error));
  }
}
