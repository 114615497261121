import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { saveAs } from 'file-saver';
import { SagaIterator } from 'redux-saga';
import { XlsxValue } from 'src/modules/common/types/XlsxValue';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { PROJECT_LIST_EXPORT } from 'src/modules/project-list/actions/ProjectListActions';
import { EXPORT_CONTENT_LINE } from 'src/modules/project-list/codecs/export';
import { EXPORT_HEADER_COLUMNS } from 'src/modules/project-list/constants/export';
import { loadProjectListFull } from 'src/modules/project-list/sagas/utils/loadProjectListFull';
import { getProjectListExportEntry } from 'src/modules/project-list/selectors/getProjectListExportEntry';
import { getExportDocumentFilename } from 'src/modules/project-list/utils/getExportDocumentFilename';
import { doOnExportWorker } from 'src/sagas/utils/doOnExportWorker';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put, select } from 'typed-redux-saga';

export function* projectListExportSaga(): SagaIterator<void> {
  try {
    yield* put(PROJECT_LIST_EXPORT.pending());
    yield* call(logDebug, 'Exporting projects...');

    const projects = yield* call(loadProjectListFull);
    const exportData: XlsxValue[][] = [EXPORT_HEADER_COLUMNS.slice()];

    for (const project of projects) {
      const exportItem = yield* select(getProjectListExportEntry, project);
      const exportLine = yield* call(EXPORT_CONTENT_LINE.encode, exportItem);
      exportData.push(exportLine);
    }

    const fileData = yield* call(doOnExportWorker, exportData);
    const fileName = yield* call(getExportDocumentFilename, new Date());
    yield* call(saveAs, fileData, `${fileName}.xlsx`);

    yield* call(logDebug, 'Exporting projects... done', exportData);
    yield* put(PROJECT_LIST_EXPORT.success());
  } catch (error) {
    const wrapped = new RuntimeError('Could not export projects', {}, NativeError.wrap(error));
    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });

    yield* call(logError, 'Exporting projects... error', error);
    yield* put(PROJECT_LIST_EXPORT.failure(NativeError.wrap(error)));
  }
}
