import 'katex/dist/katex.min.css';
import React from 'react';
import { FormattedMessage, FormattedMessageProps } from 'react-intl';
import { BlockMath, InlineMath } from 'react-katex';

export const FormattedMathMessage = ({ id, values }: FormattedMessageProps): React.ReactElement => (
  <FormattedMessage
    id={id}
    values={{
      ...values,
      // eslint-disable-next-line react/no-unstable-nested-components
      'tex-inline': (content) => <InlineMath math={String(content)}/>,
      // eslint-disable-next-line react/no-unstable-nested-components
      'tex-block': (content) => <BlockMath math={String(content)}/>,
    }}
  />
);
