import { trim } from 'fp-ts/string';
import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { PasswordForgotFormData } from 'src/modules/password-forgot/types/PasswordForgotFormData';

export const PASSWORD_FORGOT_FORM_SCHEMA: FormRule<PasswordForgotFormData> = R.struct({
  username: R.allOf([
    R.map(trim, R.nonEmpty()),
    R.email(),
  ]),
});
