import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ShareOff = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fill="currentColor"
        d="M24.0002 8.86667C24.6002 8.86667 25.1169 8.65001 25.5502 8.21667C25.9836 7.78334 26.2002 7.26667
        26.2002 6.66667C26.2002 6.06667 25.9836 5.55001 25.5502 5.11667C25.1169 4.68334 24.6002 4.46667 24.0002
        4.46667C23.4002 4.46667 22.8836 4.68334 22.4502 5.11667C22.0169 5.55001 21.8002 6.06667 21.8002
        6.66667C21.8002 7.26667 22.0169 7.78334 22.4502 8.21667C22.8836 8.65001 23.4002 8.86667 24.0002
        8.86667ZM24.0002 27.5333C24.6002 27.5333 25.1169 27.3167 25.5502 26.8833C25.9836 26.45 26.2002 25.9333
        26.2002 25.3333C26.2002 24.7333 25.9836 24.2167 25.5502 23.7833C25.1169 23.35 24.6002 23.1333 24.0002
        23.1333C23.4002 23.1333 22.8836 23.35 22.4502 23.7833C22.0169 24.2167 21.8002 24.7333 21.8002
        25.3333C21.8002 25.9333 22.0169 26.45 22.4502 26.8833C22.8836 27.3167 23.4002 27.5333 24.0002
        27.5333ZM8.00023 28.2667C6.62245 28.2667 5.45578 27.7889 4.50023 26.8333C3.54467 25.8778 3.06689 24.7111
        3.06689 23.3333C3.06689 21.9556 3.54467 20.7889 4.50023 19.8333C5.45578 18.8778 6.62245 18.4 8.00023
        18.4C9.37801 18.4 10.5447 18.8778 11.5002 19.8333C12.4558 20.7889 12.9336 21.9556 12.9336 23.3333C12.9336
        24.7111 12.4558 25.8778 11.5002 26.8333C10.5447 27.7889 9.37801 28.2667 8.00023 28.2667ZM4.90023
        15.6667C4.98912 14.8889 5.32245 14.2278 5.90023 13.6833C6.47801 13.1389 7.17801 12.8667 8.00023
        12.8667C8.48912 12.8667 8.94467 12.9722 9.3669 13.1833C9.78912 13.3945 10.1558 13.6889 10.4669
        14.0667L21.0669 7.80001C21.0002 7.62223 20.9502 7.43334 20.9169 7.23334C20.8836 7.03334 20.8669
        6.84445 20.8669 6.66667C20.8669 5.80001 21.1725 5.06112 21.7836 4.45001C22.3947 3.8389 23.1336 3.53334
        24.0002 3.53334C24.8669 3.53334 25.6058 3.8389 26.2169 4.45001C26.828 5.06112 27.1336 5.80001 27.1336
        6.66667C27.1336 7.53334 26.828 8.27223 26.2169 8.88334C25.6058 9.49445 24.8669 9.80001 24.0002
        9.80001C23.5113 9.80001 23.0558 9.69445 22.6336 9.48334C22.2113 9.27223 21.8447 8.97778 21.5336
        8.60001L10.9336 14.8667C10.978 14.9778 11.0113 15.1 11.0336 15.2333C11.0558 15.3667 11.078 15.5111 11.1002
        15.6667C10.6113 15.4667 10.1113 15.3167 9.60023 15.2167C9.08912 15.1167 8.55578 15.0667 8.00023
        15.0667C7.44467 15.0667 6.91134 15.1167 6.40023 15.2167C5.88912 15.3167 5.38912 15.4667 4.90023
        15.6667ZM24.0002 28.4667C23.1336 28.4667 22.3947 28.1611 21.7836 27.55C21.1725 26.9389 20.8669
        26.2 20.8669 25.3333C20.8669 25.1556 20.8836 24.9667 20.9169 24.7667C20.9502 24.5667 21.0002 24.3778
        21.0669 24.2L16.0002 21.2C15.9336 20.9556 15.8613 20.7222 15.7836 20.5C15.7058 20.2778 15.6113 20.0556
        15.5002 19.8333L21.5336 23.4C21.8447 23.0222 22.2113 22.7278 22.6336 22.5167C23.0558 22.3056 23.5113
        22.2 24.0002 22.2C24.8669 22.2 25.6058 22.5056 26.2169 23.1167C26.828 23.7278 27.1336 24.4667 27.1336
        25.3333C27.1336 26.2 26.828 26.9389 26.2169 27.55C25.6058 28.1611 24.8669 28.4667 24.0002 28.4667ZM8.00023
        24L10.3336 26.3333L11.0002 25.6667L8.6669 23.3333L11.0002 21L10.3336 20.3333L8.00023 22.6667L5.66689
        20.3333L5.00023 21L7.33356 23.3333L5.00023 25.6667L5.66689 26.3333L8.00023 24Z"
      />
    </svg>
  </IconWrapper>
));
