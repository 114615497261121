import { SagaIterator } from 'redux-saga';
import { MODAL_PROJECT_ERROR_CLOSE, MODAL_PROJECT_ERROR_OPEN } from 'src/modules/modals/actions/ModalActions';
import { PROJECT_SYNC_RESET } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { forceProjectSynchronization } from 'src/modules/project-sync/sagas/utils/forceProjectSynchronization';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { call, put, select, take } from 'typed-redux-saga';

export function* syncProjectDetails(): SagaIterator<void> {
  const project = yield* select(getProjectDetails);

  try {
    yield* call(forceProjectSynchronization, project);
  } catch (error) {
    yield* put(MODAL_PROJECT_ERROR_OPEN.trigger(project));
    yield* take(MODAL_PROJECT_ERROR_CLOSE.is);

    // reset current synchronization status
    yield* put(PROJECT_SYNC_RESET.trigger());
  }
}
