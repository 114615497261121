import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { Project } from 'src/modules/common/types/Project';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncProjectBackground } from 'src/modules/project-sync/sagas/utils/syncProjectBackground';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { SCHEMA_TEMPLATE_SELECT } from 'src/modules/schema/actions/SchemaActions';
import { SCHEMA_AREA_MAP } from 'src/modules/schema/constants/schema';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* schemaTemplateSelectSaga(
  action: GetTriggerActionType<typeof SCHEMA_TEMPLATE_SELECT>,
): SagaIterator<void> {
  const schema = action.data;

  try {
    yield* call(logDebug, 'Saving schema template...', schema);
    const { id, thermal_energy: thermalEnergy } = schema;
    const area = SCHEMA_AREA_MAP[thermalEnergy];

    const previous = yield* select(getProjectDetails);
    const modified: Project = {
      ...previous,
      data: {
        ...previous.data,
        schema: {
          ...previous.data.schema,
          [area]: {
            schema: id,
            comment: '',
            actual: {
              assignments: {},
            },
            future: [],
            matrix: MATRIX_IDENTITY,
          },
        },
      },
    };

    const reloaded = yield* call(syncProjectBackground, modified);
    yield* call(logDebug, 'Saving schema template... done', reloaded);
  } catch (error) {
    const wrapped = new RuntimeError('Could not save schema template', { schema }, NativeError.wrap(error));
    yield* call(logError, 'Saving schema template... error', error);

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
