import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ProjectListExportEntry } from 'src/modules/project-list/types/ProjectListExport';

export const getProjectListExportEntry = createSelector([
  getProjectLazy,
  getHvacResults,
], (project, results): ProjectListExportEntry => ({
  project,
  results,
}));
