import './OutputPagination.scss';
import React from 'react';
import { OutputPageContext } from 'src/modules/output/context/OutputPageContext';

export const OutputPagination = (): React.ReactElement => {
  const { pageNumber, totalPages } = React.useContext(OutputPageContext);

  return (
    <div className="bp-output-pagination">
      {pageNumber > 0
        ? `${pageNumber} / ${totalPages}`
        : 'x / x'}
    </div>
  );
};
