import { createSelector } from 'reselect';
import { getConfigState } from 'src/modules/config/selectors/getConfigState';
import { assertNever } from 'src/utils/assert';

export const getConfigData = createSelector([
  getConfigState,
], (state) => {
  if (state.data.state === 'success') {
    return state.data.value;
  }

  return assertNever('Config data is not initialed yet', { state });
});
