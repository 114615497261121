import { SagaIterator } from 'redux-saga';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { performLogout } from 'src/modules/login/sagas/utils/performLogout';
import { logDebug } from 'src/sagas/utils/logging';
import { call, delay, put } from 'typed-redux-saga';

export function* destroySession(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Logging out...');

    yield* delay(100);
    yield* call(performLogout);

    yield* call(logDebug, 'Logging out... done');
    yield* put(WHOAMI_ACTION.trigger(null));
  } catch (error) {
    yield* call(logDebug, 'Logging out... error', error);
    yield* put(WHOAMI_ACTION.trigger(null));
  }
}
