import { SagaIterator } from 'redux-saga';
import { PROJECT_LIST_FETCH } from 'src/modules/project-list/actions/ProjectListActions';
import { loadProjectList } from 'src/modules/project-list/sagas/utils/loadProjectList';
import { loadProjectListViewMode } from 'src/modules/project-list/sagas/utils/loadProjectListViewMode';
import { saveProjectListViewMode } from 'src/modules/project-list/sagas/utils/saveProjectListViewMode';
import { ProjectListData } from 'src/modules/project-list/types/ProjectListData';
import { parseProjectListQuery } from 'src/modules/project-list/utils/query';
import { Transition } from 'src/modules/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchProjectList(transition: Transition): SagaIterator<void> {
  const viewMode = yield* call(loadProjectListViewMode);
  const params = new URLSearchParams(transition.to.location.search);
  const query = yield* call(parseProjectListQuery, params, viewMode);

  try {
    yield* call(logDebug, 'Fetching project list...', query);

    const projects = yield* call(loadProjectList, query);
    const data: ProjectListData = { projects, query };

    // store selected view mode if overriden by URL
    yield* call(saveProjectListViewMode, query.view);

    yield* put(PROJECT_LIST_FETCH.trigger(data));
    yield* call(logDebug, 'Fetching project list... done', data);
  } catch (error) {
    yield* call(logError, 'Fetching project list... error', error);
    throw error;
  }
}
