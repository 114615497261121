import { HvacArea } from '@belimo-retrofit-portal/logic';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { createSelector } from 'reselect';
import { getHvacResults } from 'src/modules/hvac/selectors/getHvacResults';
import { ReportGeneratedSavingsRating } from 'src/modules/report/types/ReportGenerated';

export const getReportGeneratedSavingsRatingPerArea = createSelector([
  getHvacResults,
], (results): ReadonlyRecord<HvacArea, ReportGeneratedSavingsRating> => ({
  [HvacArea.HEATING]: results.perArea[HvacArea.HEATING].rating,
  [HvacArea.COOLING]: results.perArea[HvacArea.COOLING].rating,
  [HvacArea.VENTILATION]: results.perArea[HvacArea.VENTILATION].rating,
}));
