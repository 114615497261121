import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import {
  PROJECT_VIEW_FETCH,
  PROJECT_VIEW_RESET,
  PROJECT_VIEW_REPLACE,
} from 'src/modules/project-view/actions/ProjectViewActions';
import { ProjectViewState } from 'src/modules/project-view/types/ProjectViewState';

export function projectViewDataReducer(state: ProjectViewState, action: Action): ProjectViewState {
  if (PROJECT_VIEW_FETCH.is(action)) {
    return {
      ...state,
      data: action.data,
    };
  }
  if (PROJECT_VIEW_RESET.is(action)) {
    return pipe(
      O.id<ProjectViewState>(),
      O.prop('data'),
      O.filter((current) => current?.project.id === action.meta),
      O.modify(() => null),
    )(state);
  }
  if (PROJECT_VIEW_REPLACE.is(action)) {
    return pipe(
      O.id<ProjectViewState>(),
      O.prop('data'),
      O.fromNullable,
      O.prop('project'),
      O.filter((current) => current.id === action.data.id),
      O.modify(() => action.data),
    )(state);
  }

  return state;
}
