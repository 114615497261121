import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { PasswordForgotFormData } from 'src/modules/password-forgot/types/PasswordForgotFormData';
import { call } from 'typed-redux-saga';

export function* submitPasswordForgot(form: PasswordForgotFormData): SagaIterator<void> {
  try {
    const body = {
      email: form.username,
    };

    yield* call(makeRestRequest, 'POST', '/auth/reset-password', body);
  } catch (error) {
    throw new RuntimeError('Could not request password recovery', { form }, NativeError.wrap(error));
  }
}
