import { BaseError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { cancelledErrorHandler } from 'src/modules/routing/hooks/errorHandlers/cancelledErrorHandler';
import { unauthorizedErrorHandler } from 'src/modules/routing/hooks/errorHandlers/unauthorizedErrorHandler';
import { unknownErrorHandler } from 'src/modules/routing/hooks/errorHandlers/unknownErrorHandler';
import { Transition } from 'src/modules/routing/types/Transition';
import { call } from 'typed-redux-saga';

export function* errorTransitionHandling(transition: Transition, error: BaseError): SagaIterator<void> {
  yield* call(unauthorizedErrorHandler, error);
  yield* call(cancelledErrorHandler, error);
  yield* call(unknownErrorHandler, error);
}
