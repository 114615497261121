import { CurrencyCode } from '@belimo-retrofit-portal/logic';
import { Numeric } from 'decimal.js-light';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedNumber } from 'src/modules/common/components/FormattedNumber';

type Props = {
  readonly value: Numeric | null;
  readonly currency: CurrencyCode;
};

export const FormattedAmount = ({ value, currency }: Props): React.ReactElement => (
  <>
    <FormattedMessage
      id={`common/currency/${currency}`}
    />
    &nbsp;
    {value === null ? '—' : (
      <FormattedNumber
        value={value}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    )}
  </>
);
