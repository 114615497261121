import { FieldMap, FormController } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import { SagaIterator } from 'redux-saga';
import { FormError } from 'src/modules/form/types/FormError';
import { call } from 'typed-redux-saga';

export function* formSubmitFailure<T>(
  form: FormController<T, FormError>,
  errors: FieldMap<FormError>,
): SagaIterator<void> {
  yield* call({
    fn: form.change,
    context: form,
  }, pipe(
    form.currentState,
    form.errors.set(errors),
    form.submitted.set(true),
    form.submitting.set(false),
    form.submitSucceded.set(false),
  ));
}
