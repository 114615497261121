import React from 'react';
import { SvgFile } from 'src/modules/common/components/SvgFile';
import { Point } from 'src/modules/common/types/Point';
import { SchemaDevice } from 'src/modules/schema/types/SchemaDevice';

type Props = {
  readonly size: Point;
  readonly device: SchemaDevice;
};

export const SchemaDeviceView = ({ size, device }: Props): React.ReactElement => (
  <g
    pointerEvents="auto"
    className="bp-schema-device-view"
    data-device-id={device.device.id}
  >
    <SvgFile
      urls={['X', 'N', 'S', 'E', 'W'].map((flowDirection) => `/images/devices/${encodeURIComponent(
        [
          'C',
          device.device.id,
          device.valvePosition,
          device.orientation,
          flowDirection,
          device.thermalEnergy,
        ].join(''),
      )}.svg`)}
      width={size.x}
      height={size.y}
    />
  </g>
);
