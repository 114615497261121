import { FormRule } from 'src/modules/form/types/FormRule';
import * as R from 'src/modules/form/utils/rules';
import { PasswordResetFormData } from 'src/modules/password-reset/types/PasswordResetFormData';

// NOTE: the translations are OK
export const PASSWORD_RESET_FORM_SCHEMA: FormRule<PasswordResetFormData> = R.allOf([
  R.struct({
    confirmation: R.nonEmpty('profile/form/section/changePassword/error/empty'),
    password: R.allOf([
      R.nonEmpty('profile/form/section/changePassword/error/empty'),
      R.minLength(8, 'profile/form/section/changePassword/error/short'),
      R.maxLength(30, 'profile/form/section/changePassword/error/long'),
      R.match(/\d/, 'profile/form/section/changePassword/error/noDigits'),
      R.match(/[a-z]/i, 'profile/form/section/changePassword/error/noLetters'),
      R.match(/^\S+$/, 'profile/form/section/changePassword/error/spaces'),
    ]),
  }),
  R.matchField(
    'confirmation',
    'password',
    'profile/form/section/changePassword/error/mismatch',
  ),
]);
