import { DEFAULT_MULTIPLE_FILTER_FORM_DATA } from 'src/modules/schema/constants/filterFormData';
import { DEFAULT_MULTIPLE_PRESELECTED_DATA } from 'src/modules/schema/constants/schema';
import { schemaSelectionReducer } from 'src/modules/schema/reducers/schemaSelectionReducer';
import { SchemaSelectionState } from 'src/modules/schema/types/SchemaSelectionState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: SchemaSelectionState = {
  data: {
    filter: DEFAULT_MULTIPLE_FILTER_FORM_DATA,
    schemaSelected: DEFAULT_MULTIPLE_PRESELECTED_DATA,
  },
};

export const schemaReducer = chainReducers(defaultState, [
  schemaSelectionReducer,
]);
