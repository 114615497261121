import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import { CONTAINER_TYPE } from 'src/modules/common/codecs/ContainerType';
import { EMPTY } from 'src/modules/common/codecs/Empty';
import { FLOW_DIRECTION } from 'src/modules/common/codecs/FlowDirection';
import { ORIENTATION } from 'src/modules/common/codecs/Orientation';
import { THERMAL_ENERGY } from 'src/modules/common/codecs/ThermalEnergy';
import { ChangeOverLevel } from 'src/modules/common/types/ChangeOverLevel';
import { DifferentTemperatureLevel } from 'src/modules/common/types/DifferentTemperatureLevel';
import { WaterToAir } from 'src/modules/common/types/WaterToAir';
import { ConfigSchema, ConfigSchemaContainer } from 'src/modules/config/types/ConfigSchema';

const CONFIG_SCHEMA_CONTAINER: D.Decoder<unknown, ConfigSchemaContainer> = D.struct({
  type: CONTAINER_TYPE,

  left: D.number,
  top: D.number,

  orientation: ORIENTATION,
  flow_direction: FLOW_DIRECTION,
  termal_energy: THERMAL_ENERGY,
});

export const CHANGE_OVER_LEVEL: C.Codec<unknown, Json, ChangeOverLevel> = C.literal(
  ChangeOverLevel.NO_CHANGE_OVER,
  ChangeOverLevel.EMITTER,
  ChangeOverLevel.DISTRIBUTION,
  ChangeOverLevel.FLOOR,
);

export const WATER_TO_AIR: C.Codec<unknown, Json, WaterToAir> = C.literal(
  WaterToAir.WATER,
  WaterToAir.WATER_TO_AIR,
);

export const DIFFERENT_TEMPERATURE_LEVEL: C.Codec<unknown, Json, DifferentTemperatureLevel> = C.literal(
  DifferentTemperatureLevel.YES,
  DifferentTemperatureLevel.NO,
);

export const CONFIG_SCHEMA: D.Decoder<unknown, ConfigSchema> = D.struct({
  id: D.string,
  thermal_energy: THERMAL_ENERGY,
  water_to_air: WATER_TO_AIR,
  change_over_level: CHANGE_OVER_LEVEL,
  different_temperature_level: DIFFERENT_TEMPERATURE_LEVEL,
  children: D.record(CONFIG_SCHEMA_CONTAINER),

  name: D.string,
  explanation: D.union(EMPTY, D.string),
  width: D.number,
  height: D.number,
});
