import './HeadlineSmallText.scss';
import clsx from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const HeadlineSmallText = ({ className, children }: Props): React.ReactElement => (
  <h2 className={clsx('bp-headline-small-text', className)}>
    {children}
  </h2>
);
