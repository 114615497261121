import { Project } from 'src/modules/common/types/Project';
import { User } from 'src/modules/common/types/User';
import { ProjectListData } from 'src/modules/project-list/types/ProjectListData';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { ProjectListImportData, ProjectListImportEntry } from 'src/modules/project-list/types/ProjectListImport';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const PROJECT_LIST_FETCH = createTrigger<ProjectListData>('PROJECT_LIST_FETCH');
export const PROJECT_LIST_RESET = createTrigger<void>('PROJECT_LIST_RESET');
export const PROJECT_LIST_ABORT = createTrigger<void>('PROJECT_LIST_ABORT');
export const PROJECT_LIST_FILTER = createTrigger<ProjectListFilter>('PROJECT_LIST_FILTER');
export const PROJECT_LIST_SHARE = createTrigger<Project>('PROJECT_LIST_SHARE');

export const PROJECT_LIST_EXPORT = createActions<void, void>('PROJECT_LIST_EXPORT');
export const PROJECT_LIST_DOWNLOAD = createActions<void, void, Project>('PROJECT_LIST_DOWNLOAD');

export const PROJECT_LIST_REMOVE_CONFIRM = createTrigger<Project>('PROJECT_LIST_REMOVE_CONFIRM');
export const PROJECT_LIST_REMOVE_CANCEL = createTrigger<void>('PROJECT_LIST_REMOVE_CANCEL');
export const PROJECT_LIST_REMOVE_SUBMIT = createActions<void, void>('PROJECT_LIST_REMOVE_SUBMIT');

export const PROJECT_LIST_UNFOLLOW_CONFIRM = createTrigger<Project, User>('PROJECT_LIST_UNFOLLOW_CONFIRM');
export const PROJECT_LIST_UNFOLLOW_CANCEL = createTrigger<void>('PROJECT_LIST_UNFOLLOW_CANCEL');
export const PROJECT_LIST_UNFOLLOW_SUBMIT = createActions<void, void>('PROJECT_LIST_UNFOLLOW_SUBMIT');

export const PROJECT_LIST_IMPORT_OPEN =
  createTrigger<void>('PROJECT_LIST_IMPORT_OPEN');
export const PROJECT_LIST_IMPORT_CANCEL =
  createTrigger<void>('PROJECT_LIST_IMPORT_CANCEL');
export const PROJECT_LIST_IMPORT_UPLOAD =
  createActions<File, ProjectListImportData>('PROJECT_LIST_IMPORT_UPLOAD');
export const PROJECT_LIST_IMPORT_SUBMIT =
  createActions<ReadonlyArray<ProjectListImportEntry>, void>('PROJECT_LIST_IMPORT_SUBMIT');
export const PROJECT_LIST_IMPORT_COMPLETE =
  createTrigger<void>('PROJECT_LIST_IMPORT_COMPLETE');
