import { EnergyEfficiencyClass } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { SupScript } from 'src/modules/common/components/SupScript';

export const LABELS: Record<EnergyEfficiencyClass, React.ReactNode> = {
  'A+': <>A <SupScript>+</SupScript></>,
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};
