import { FormController } from '@form-ts/core';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { FormError } from 'src/modules/form/types/FormError';
import { ChangePasswordData } from 'src/modules/profile/types/ChangePasswordData';
import { createActions } from 'src/utils/createActions';

export const PROFILE_SAVE = createActions<FormController<UserFormData, FormError>, void>('PROFILE_SAVE');
export const PROFILE_CHANGE_PASSWORD = createActions<FormController<ChangePasswordData, FormError>, void>(
  'PROFILE_CHANGE_PASSWORD',
);
