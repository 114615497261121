import './ReportFormSectionPreviewPage.scss';
import React from 'react';
import { bind } from 'src/modules/common/utils/decorator';

type Props = {
  readonly children: React.ReactNode;
};
type State = {
  readonly scale: number;
  readonly ratio: number;
};

export class ReportFormSectionPreviewPage extends React.Component<Props, State> {
  public readonly state: State = {
    scale: 1,
    ratio: 0,
  };

  private wrapper: React.RefObject<HTMLDivElement> = React.createRef();

  private content: React.RefObject<HTMLDivElement> = React.createRef();

  public componentDidMount(): void {
    this.updateContent();

    window.addEventListener('resize', this.updateContent, { passive: true });
    window.addEventListener('orientationchange', this.updateContent, { passive: true });
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateContent);
    window.removeEventListener('orientationchange', this.updateContent);
  }

  public componentDidUpdate(): void {
    this.updateContent();
  }

  public render(): React.ReactElement {
    const { children } = this.props;

    return (
      <div
        ref={this.wrapper}
        style={this.getWrapperStyle()}
        className="bp-report-form-preview-page"
      >
        <div
          ref={this.content}
          style={this.getContentStyle()}
          className="bp-report-form-preview-page__content"
        >
          {children}
        </div>
      </div>
    );
  }

  private getWrapperStyle(): React.CSSProperties {
    const { ratio } = this.state;
    return { paddingBottom: `${(ratio * 100).toFixed(5)}%` };
  }

  private getContentStyle(): React.CSSProperties {
    const { scale } = this.state;
    return { transform: `scale(${scale.toFixed(5)})` };
  }

  @bind()
  private updateContent(): void {
    if (!this.content.current || !this.wrapper.current) {
      return;
    }

    const wrapperW = this.wrapper.current.clientWidth;
    const contentW = this.content.current.clientWidth;
    const contentH = this.content.current.clientHeight;

    const scale = wrapperW / contentW;
    const ratio = contentH / contentW;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.scale !== scale || this.state.ratio !== ratio) {
      this.setState({ scale, ratio });
    }
  }
}
