import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedNumber } from 'src/modules/common/components/FormattedNumber';

type Props = {
  readonly value: Decimal | null;
  readonly precision: number;
};

export const SavingsValue = ({ value, precision }: Props): React.ReactElement => {
  if (value === null) {
    return <>&mdash;</>;
  }

  if (value.isNegative()) {
    return (
      <>
        &lt;
        <FormattedNumber
          value={0}
          minimumFractionDigits={precision}
          maximumFractionDigits={precision}
        />
      </>
    );
  }
  if (value.isPositive() && value.lessThan(1)) {
    return (
      <>
        &lt;
        <FormattedNumber
          value={1}
          minimumFractionDigits={precision}
          maximumFractionDigits={precision}
        />
      </>
    );
  }

  return (
    <FormattedNumber
      value={value.toDecimalPlaces(precision)}
      minimumFractionDigits={precision}
      maximumFractionDigits={precision}
    />
  );
};
