import './HvacFormClearConfirmationModal.scss';
import { WarningFilled } from '@carbon/icons-react';
import { Button, ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { BodyText } from 'src/modules/common/typography/BodyText';
import { LeadText } from 'src/modules/common/typography/LeadText';

type Props = {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
};

export const HvacFormClearConfirmationModal = ({
  open,
  onCancel,
  onSubmit,
}: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <CustomModal
      className="bp-hvac-form-clear-confirmation-modal"
      open={open}
      isShaded={false}
      onClose={onCancel}
      danger={true}
      size="sm"
      shouldUnmount={true}
    >
      <ModalHeader
        labelClassName="bp-hvac-form-clear-confirmation-modal__label"
        label={intl.formatMessage({ id: 'hvac/form/clearConfirmation/modal/title' })}
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
      />

      <ModalBody>
        <div className="bp-hvac-form-clear-confirmation-modal__body">
          <LeadText>
            <FormattedMessage
              id="hvac/form/clearConfirmation/modal/subtitle"
            />
          </LeadText>

          <BodyText>
            <FormattedMessage
              id="hvac/form/clearConfirmation/modal/description"
            />
          </BodyText>
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton kind="secondary" autoFocus={true} onClick={onCancel}>
          <FormattedMessage id="hvac/form/clearConfirmation/modal/cancel"/>
        </CustomButton>

        <CustomButton kind="primary" onClick={onSubmit}>
          <FormattedMessage id="hvac/form/clearConfirmation/modal/confirm"/>
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};
