import './ReportFormSectionPreview.scss';
import clsx from 'clsx';
import React from 'react';
import { ReportContentPage } from 'src/modules/report/types/ReportContent';
import { ReportFormSectionPreviewPage } from 'src/modules/report/views/ReportFormSectionPreviewPage';

type Props = {
  readonly pages: ReadonlyArray<ReportContentPage>;
  readonly enabled: boolean;
};

export const ReportFormSectionPreview = ({ pages, enabled }: Props): React.ReactElement => (
  <div className="bp-report-form-section-preview">
    {pages.map((page) => (
      <div key={page.id} className="bp-report-form-section-preview__page">
        <div className={clsx(
          'bp-report-form-section-preview__content',
          enabled ? null : 'bp-report-form-section-preview__content--disabled',
        )}
        >
          <ReportFormSectionPreviewPage>
            {page.content}
          </ReportFormSectionPreviewPage>
        </div>
      </div>
    ))}
  </div>
);
