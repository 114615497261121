import { RouteMatch } from 'src/modules/routing/types/RouteMatch';
import { isMatchContain } from 'src/modules/routing/utils/matchUtils';

export function getStatesToLeave(to: RouteMatch, from: RouteMatch | null): RouteMatch[] {
  if (!from) {
    return [];
  }

  if (isMatchContain(to, from)) {
    return [];
  }

  return [from, ...getStatesToLeave(to, from.parent)];
}

export function getStatesToEnter(to: RouteMatch, from: RouteMatch | null): RouteMatch[] {
  if (!from) {
    return getEnterPath(to);
  }

  if (isMatchContain(from, to)) {
    return [];
  }

  return to.parent
    ? [...getStatesToEnter(to.parent, from), to]
    : [to];
}

export function getStatesToRetain(to: RouteMatch, from: RouteMatch | null): RouteMatch[] {
  if (!from) {
    return [];
  }

  if (isMatchContain(from, to)) {
    return getEnterPath(to);
  }

  return to.parent
    ? getStatesToRetain(to.parent, from)
    : [];
}

function getEnterPath(match: RouteMatch): RouteMatch[] {
  return match.parent
    ? [...getEnterPath(match.parent), match]
    : [match];
}
