import './ReportDocumentPageEnergyVentilation.scss';
import { CurrencyCode } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CarbonSavingsUnit } from 'src/modules/common/components/CarbonSavingsUnit';
import { CurrencySymbol } from 'src/modules/common/components/CurrencySymbol';
import { EnergySavingsUnit } from 'src/modules/common/components/EnergySavingsUnit';
import { sub } from 'src/modules/common/constants/formattingHelpers';
import { ReportGeneratedEnergyArea } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageCO2Value } from 'src/modules/report/views/document/ReportDocumentPageCO2Value';
import { ReportDocumentPageEnergyValue } from 'src/modules/report/views/document/ReportDocumentPageEnergyValue';

type Props = {
  readonly icon: React.ReactElement;
  readonly name: React.ReactNode;
  readonly values: ReportGeneratedEnergyArea;
  readonly currency: CurrencyCode;
};

export const ReportDocumentPageEnergyVentilation = ({ icon, name, values, currency }: Props): React.ReactElement => (
  <div className="bp-report-document-page-energy-ventilation">
    <div className="bp-report-document-page-energy-ventilation__header">
      <div className="bp-report-document-page-energy-ventilation__header-icon">
        {icon}
      </div>

      <div className="bp-report-document-page-energy-ventilation__header-name">
        {name}
      </div>
    </div>

    <div className="bp-report-document-page-energy-ventilation__content">
      <div className="bp-report-document-page-energy-ventilation__content-line">
        <ReportDocumentPageEnergyValue
          title={<FormattedMessage id="report/document/energy/table/energyConsumption"/>}
          value={values.electrical.annualConsumption?.div(1_000) ?? null}
          unit={(
            <FormattedMessage
              id="report/document/energy/table/unit"
              values={{ unit: <EnergySavingsUnit source={values.electrical.source}/> }}
            />
          )}
        />
      </div>

      <div className="bp-report-document-page-energy-ventilation__content-line">
        <ReportDocumentPageCO2Value
          title={<FormattedMessage id="report/document/energy/table/carbonEmission" values={{ sub }}/>}
          value={values.electrical.carbonEmission?.div(1_000_000) ?? null}
          unit={(
            <FormattedMessage
              id="report/document/energy/table/unit"
              values={{ unit: <CarbonSavingsUnit/> }}
            />
          )}
        />
      </div>

      <div className="bp-report-document-page-energy-ventilation__content-line">
        <ReportDocumentPageEnergyValue
          title={<FormattedMessage id="report/document/energy/table/totalCost"/>}
          value={values.electrical.totalCosts}
          unit={(
            <FormattedMessage
              id="report/document/energy/table/unit"
              values={{ unit: <CurrencySymbol currency={currency}/> }}
            />
          )}
        />
      </div>
    </div>
  </div>
);
