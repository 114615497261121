import './ProjectListToolbar.scss';
import { Popover, PopoverContent } from '@carbon/react';
import { useForm, useFormWatch } from '@form-ts/react';
import { flow } from 'fp-ts/function';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { DownloadIcon } from 'src/modules/common/icons/DownloadIcon';
import { FilterIcon } from 'src/modules/common/icons/FilterIcon';
import { GridViewIcon } from 'src/modules/common/icons/GridViewIcon';
import { ListViewIcon } from 'src/modules/common/icons/ListViewIcon';
import { PlusIcon } from 'src/modules/common/icons/PlusIcon';
import { UploadIcon } from 'src/modules/common/icons/UploadIcon';
import { Future } from 'src/modules/common/types/Future';
import { SearchField } from 'src/modules/form/components/SearchField';
import { FormError } from 'src/modules/form/types/FormError';
import { PROJECT_LIST_DEFAULT_SORT } from 'src/modules/project-list/constants/sort';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { ProjectListQuery } from 'src/modules/project-list/types/ProjectListQuery';
import { ProjectListViewMode } from 'src/modules/project-list/types/ProjectListViewMode';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';
import { ProjectListFilterDropdown } from 'src/modules/project-list/views/ProjectListFilterDropdown';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  readonly query: ProjectListQuery;
  readonly exporting: Future<void> | null;
  readonly onCreate: () => void;
  readonly onImport: () => void;
  readonly onExport: () => void;
  readonly onFilter: (filter: ProjectListFilter) => void;
};

export const ProjectListToolbar = memo(({
  query,
  exporting,
  onCreate,
  onImport,
  onExport,
  onFilter,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const [isFiltersDropdownOpened, setFiltersDropdownOpened] = useState(false);

  const form = useForm<ProjectListFilter, FormError>('project.filter', {
    reinitialize: true,
    initialValues: query.filter,
  });

  const count = useFormWatch(form, flow(form.values.get, getAppliedFilterCount));

  const handleFilterDebounced = useDebouncedCallback(onFilter, 300);

  const handleDropdownOpen = useCallback(() => {
    setFiltersDropdownOpened(true);
  }, []);

  const handleDropdownClose = useCallback(() => {
    setFiltersDropdownOpened(false);
    onFilter(form.currentState.values);
  }, [form, onFilter]);

  const handleDropdownApply = useCallback(() => {
    onFilter(form.currentState.values);
  }, [form, onFilter]);

  const handleChange = useCallback(() => {
    handleFilterDebounced(form.currentState.values);
  }, [form.currentState.values, handleFilterDebounced]);

  useEffect(() => () => {
    handleFilterDebounced.cancel();
  }, [handleFilterDebounced]);

  return (
    <div className="bp-project-list-toolbar">
      <div className="bp-project-list-toolbar__content">
        <div className="bp-project-list-toolbar__title-wrapper">
          <h2 className="bp-project-list-toolbar__title">
            <FormattedMessage id="projectList/pageHeader"/>
          </h2>
        </div>

        <div className="bp-project-list-toolbar__controls">
          <div className="bp-project-list-toolbar__controls-actions">
            <CustomButton
              as={Link}
              to={{
                search: buildProjectListQuery({
                  ...query,
                  view: ProjectListViewMode.LIST,
                  sort: PROJECT_LIST_DEFAULT_SORT[ProjectListViewMode.LIST],
                  page: INTEGER_ONE,
                }).toString(),
              }}
              className="bp-project-list-toolbar__controls-button"
              hasIconOnly={true}
              iconDescription={intl.formatMessage({ id: 'projectList/toolbar/viewMode/list' })}
              kind="ghost"
              size="md"
              onClick={onImport}
            >
              <ListViewIcon/>
            </CustomButton>

            <CustomButton
              as={Link}
              to={{
                search: buildProjectListQuery({
                  ...query,
                  view: ProjectListViewMode.GRID,
                  sort: PROJECT_LIST_DEFAULT_SORT[ProjectListViewMode.GRID],
                  page: INTEGER_ONE,
                }).toString(),
              }}
              className="bp-project-list-toolbar__controls-button"
              hasIconOnly={true}
              iconDescription={intl.formatMessage({ id: 'projectList/toolbar/viewMode/grid' })}
              kind="ghost"
              size="md"
            >
              <GridViewIcon/>
            </CustomButton>
          </div>

          <div className="bp-project-list-toolbar__controls-actions">
            <CustomButton
              className="bp-project-list-toolbar__controls-button"
              hasIconOnly={true}
              iconDescription={intl.formatMessage({ id: 'projectList/toolbar/upload' })}
              kind="ghost"
              size="md"
              onClick={onImport}
            >
              <UploadIcon/>
            </CustomButton>

            <CustomButton
              className="bp-project-list-toolbar__controls-button"
              hasIconOnly={true}
              size="md"
              iconDescription={intl.formatMessage({ id: 'projectList/toolbar/download' })}
              kind="ghost"
              onClick={onExport}
              loading={exporting?.state === 'pending'}
            >
              <DownloadIcon/>
            </CustomButton>

            <Popover
              open={isFiltersDropdownOpened}
              onRequestClose={handleDropdownClose}
              align="bottom"
              className="bp-project-list-toolbar__popover"
            >
              <CustomButton
                className="bp-project-list-toolbar__controls-button"
                hasIconOnly={true}
                size="md"
                iconDescription={intl.formatMessage({ id: 'projectList/toolbar/filter' }, { count })}
                kind="ghost"
                onClick={handleDropdownOpen}
              >
                <FilterIcon/>
              </CustomButton>

              <PopoverContent className="bp-project-list-toolbar__popover-content">
                <ProjectListFilterDropdown
                  form={form}
                  onApply={handleDropdownApply}
                  onClose={handleDropdownClose}
                />
              </PopoverContent>
            </Popover>

            <SearchField
              size="md"
              className="bp-project-list-toolbar__controls-search"
              labelText={intl.formatMessage({ id: 'projectList/toolbar/search' })}
              placeholder={intl.formatMessage({ id: 'projectList/searchPlaceholder' })}
              field={form.field.at('term')}
              onChange={handleChange}
              autoComplete="off"
              autoCapitalize="off"
              spellCheck={false}
            />

            <CustomButton
              kind="primary"
              size="md"
              onClick={onCreate}
            >
              <FormattedMessage id="projectList/newProject"/>

              <div className="bp-project-list-toolbar__controls-icon">
                <PlusIcon/>
              </div>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
});

function getAppliedFilterCount(filter: ProjectListFilter): number {
  return (
    (filter.buildingType.size > 0 ? 1 : 0) +
    (filter.country.size > 0 ? 1 : 0) +
    (filter.city.size > 0 ? 1 : 0) +
    (filter.ratingActual.size > 0 ? 1 : 0) +
    (filter.ratingFuture.size > 0 ? 1 : 0)
  );
}
