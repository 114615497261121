import { NativeError, RuntimeError, SchemaArea } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { MULTIPLE_SCHEMA_FILTER_FORM } from 'src/modules/schema/codecs/MultipleSchemaForm';
import { FILTER_FORM_SELECTED } from 'src/modules/schema/constants/localStorageKeys';
import { getSchemaSelectionFilter } from 'src/modules/schema/selectors/getSchemaSelectionState';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import { MultipleSchemaFilterForm } from 'src/modules/schema/types/SchemaSelectionState';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageSet } from 'src/sagas/utils/storage';
import { call, select } from 'typed-redux-saga';

export function* saveSchemaSelection(
  data: SchemaFilterForm,
  area: SchemaArea,
): SagaIterator<MultipleSchemaFilterForm> {
  const previous = yield* select(getSchemaSelectionFilter);

  try {
    yield* call(logDebug, 'Saving schema selection...', data);

    const modified: MultipleSchemaFilterForm = {
      ...previous,
      [area]: data,
    };

    const stored = yield* call(MULTIPLE_SCHEMA_FILTER_FORM.encode, modified);
    yield* call(storageSet, FILTER_FORM_SELECTED, stored);

    yield* call(logDebug, 'Saving schema selection... done', data);

    return modified;
  } catch (error) {
    const wrapped = new RuntimeError('Could not save schema selection', { data }, NativeError.wrap(error));

    yield* call(logError, 'Saving schema selection... error', error);
    yield* call(sentryCatch, wrapped);
    throw wrapped;
  }
}
