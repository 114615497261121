import { createSelector } from 'reselect';
import { getHvacAssesmentFormData } from 'src/modules/hvac/selectors/getHvacAssesmentFormData';
import { getHvacBuildingFormData } from 'src/modules/hvac/selectors/getHvacBuildingFormData';
import { getHvacCurrencyCode } from 'src/modules/hvac/selectors/getHvacCurrencyCode';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';

export const getHvacFormData = createSelector([
  getHvacCurrencyCode,
  getHvacBuildingFormData,
  getHvacAssesmentFormData,
], (currency, building, assessment): HvacFormData => ({
  currency,
  building,
  assessment,
}));
