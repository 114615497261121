import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';

const decoder: D.Decoder<unknown, Blob> = {
  decode: (input) => (input instanceof Blob
    ? D.success(input)
    : D.failure(input, 'NativeBlob')),
};

export const NATIVE_BLOB: C.Codec<unknown, Blob, Blob> = C.make(decoder, E.id());
