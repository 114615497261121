import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import { SagaIterator } from 'redux-saga';
import { call } from 'typed-redux-saga';

export function* storageGet(key: string): SagaIterator<Json | undefined> {
  try {
    const stored = yield* call({
      context: window.localStorage,
      fn: window.localStorage.getItem,
    }, key);
    if (stored === null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(stored);
  } catch (error) {
    throw new RuntimeError(`Could not get "${key}" storage value`, { key }, NativeError.wrap(error));
  }
}

export function* storageSet(key: string, val: Json): SagaIterator<void> {
  try {
    const stored = JSON.stringify(val);
    yield* call({
      context: window.localStorage,
      fn: window.localStorage.setItem,
    }, key, stored);
  } catch (error) {
    throw new RuntimeError(`Could not set "${key}" storage value`, { key, val }, NativeError.wrap(error));
  }
}
