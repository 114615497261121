import './SchemaSelectScreen.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { InlineNotification } from '@carbon/react';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { ChangeOverLevel } from 'src/modules/common/types/ChangeOverLevel';
import { DifferentTemperatureLevel } from 'src/modules/common/types/DifferentTemperatureLevel';
import { WaterToAir } from 'src/modules/common/types/WaterToAir';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { FormError } from 'src/modules/form/types/FormError';
import { nullToAll } from 'src/modules/form/utils/transform';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import { SchemaSelectedData } from 'src/modules/schema/types/SchemaSelectionState';
import { SchemaCard } from 'src/modules/schema/views/SchemaCard';

type Props = {
  readonly area: SchemaArea;
  readonly schemas: ReadonlyArray<ConfigSchema>;
  readonly storedFilterFormValue: SchemaFilterForm;
  readonly filteredSchemas: ReadonlyArray<ConfigSchema>;
  readonly currentSelectedSchema: ConfigSchema | null;
  readonly onSavePreselectedSchema: (data: SchemaSelectedData | null) => void;
  readonly onSaveSchemaFilter: (data: SchemaFilterForm) => void;
  readonly handleSchemaCreate: () => void;
};

export const SchemaSelectScreen = ({
  area,
  schemas,
  storedFilterFormValue,
  filteredSchemas,
  currentSelectedSchema,
  onSavePreselectedSchema,
  onSaveSchemaFilter,
  handleSchemaCreate,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const cardRefs = useRef<{ [key: ConfigSchema['id']]: HTMLButtonElement | null }>({});

  const form = useForm<SchemaFilterForm, FormError>('schema.filter', {
    reinitialize: false,
    initialValues: storedFilterFormValue,
  });

  const values = useFormWatch(form, form.values.get);

  const handleSelectSchema = (schema: ConfigSchema): void => {
    onSavePreselectedSchema({ id: schema.id });
  };

  useEffect(() => {
    if (values) {
      onSaveSchemaFilter(values);
    }
  }, [onSaveSchemaFilter, values]);

  useEffect(() => {
    if (currentSelectedSchema && cardRefs.current[currentSelectedSchema.id]) {
      cardRefs.current[currentSelectedSchema.id]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [currentSelectedSchema]);

  return schemas.length !== 0 ? (
    <div className="bp-schema-select-screen">
      <h2 className="bp-schema-select-screen__title">
        <FormattedMessage
          id="schemaSelection/pageTitle"
          values={{ area: intl.formatMessage({ id: `schemaSelection/area/${area}` }) }}
        />
      </h2>

      <HorizontalDivider className="bp-schema-select-screen__divider"/>

      <div className="bp-schema-select-screen__actions">
        <CustomButton
          className="bp-multiple-schemas-screen__button"
          disabled={!currentSelectedSchema}
          onClick={handleSchemaCreate}
          size="md"
        >
          <FormattedMessage id="schemaSelection/action/create"/>
        </CustomButton>
      </div>

      <div className="bp-schema-select-screen__content">
        {/* RPA-248 — Schema drawing — hide the filters but keep them in the codebase */}
        {/* <div className="bp-schema-select-screen__fields">
          <DropdownField
            size="lg"
            field={form.field.at('waterToAir').transform(nullToAll())}
            titleText={intl.formatMessage({ id: 'schemaSelection/filter/water-to-air' })}
            options={WATER_TO_AIR_OPTIONS}
            itemToElement={renderWaterToAirItem}
            renderSelectedItem={renderWaterToAirItem}
            outlined={true}
          />

          <DropdownField
            size="lg"
            field={form.field.at('changeOverLevel').transform(nullToAll())}
            titleText={intl.formatMessage({ id: 'schemaSelection/filter/change-over-level' })}
            options={CHANGE_OVER_LEVEL_OPTIONS}
            itemToElement={renderChangeOverLevelItem}
            renderSelectedItem={renderChangeOverLevelItem}
            outlined={true}
          />

          <DropdownField
            size="lg"
            field={form.field.at('differentTemperatureLevel').transform(nullToAll())}
            titleText={intl.formatMessage({ id: 'schemaSelection/filter/different-temperature' })}
            options={DIFFERENT_TEMPERATURE_LEVEL_OPTIONS}
            itemToElement={renderDifferentTemperatureLevelItem}
            renderSelectedItem={renderDifferentTemperatureLevelItem}
            outlined={true}
          />
        </div> */}

        <div className="bp-schema-select-screen__schema">
          <div className="bp-schema-select-screen__schema-options">
            {filteredSchemas.map((schema) => (
              <SchemaCard
                ref={(el) => {
                  cardRefs.current[schema.id] = el;
                }}
                key={schema.id}
                schema={schema}
                selected={schema === currentSelectedSchema}
                onSelect={handleSelectSchema}
              />
            ))}
          </div>

          <div className="bp-schema-select-screen__schema-container">
            {currentSelectedSchema && (
              <div className="bp-schema-select-screen__schema-preview">
                <div className="bp-schema-select-screen__schema-info">
                  <h2 className="bp-schema-select-screen__schema-title">
                    {currentSelectedSchema.id}
                  </h2>

                  {currentSelectedSchema.explanation && (
                    <p className="bp-schema-select-screen__schema-description">
                      <FormattedMessage id={currentSelectedSchema.explanation}/>
                    </p>
                  )}
                </div>

                <div className="bp-schema-select-screen__schema-image">
                  <img
                    src={`/images/schemas/${encodeURIComponent(currentSelectedSchema.id)}.svg`}
                    alt="schema"
                    style={{
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <InlineNotification
      className="bp-schema-select-screen__notification"
      kind="warning"
      title={intl.formatMessage({ id: 'schemaSelection/filter/noSchemasMessage' })}
      hideCloseButton={true}
    />
  );
};

const renderWaterToAirItem = (option: WaterToAir | 'All'): React.ReactElement => (
  <FormattedMessage id={`schemaSelection/option/waterToAir/${option}`}/>
);

const renderChangeOverLevelItem = (option: ChangeOverLevel | 'All'): React.ReactElement => (
  <FormattedMessage id={`schemaSelection/option/changeOverLevel/${option}`}/>
);

const renderDifferentTemperatureLevelItem = (option: DifferentTemperatureLevel | 'All'): React.ReactElement => (
  <FormattedMessage id={
    `schemaSelection/option/differentTemperatureLevel/${option}`
  }
  />
);
