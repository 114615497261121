import './HvacResultEnergyView.scss';
import { CurrencyCode } from '@belimo-retrofit-portal/logic';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TileCard } from 'src/modules/common/components/TileCard';
import {
  CURRENCY_CODE_OPTIONS,
  ENERGY_SOURCE_COOLING_OPTIONS,
  ENERGY_SOURCE_HEATING_OPTIONS,
} from 'src/modules/common/constants/options';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { BodyLargeRegularText } from 'src/modules/common/typography/BodyLargeRegularText';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { HvacFormData } from 'src/modules/hvac/types/HvacFormData';
import { HvacResultEnergyPricesView } from 'src/modules/hvac/views/HvacResultEnergyPricesView';
import { HvacResultEnergySystemVentilationView } from 'src/modules/hvac/views/HvacResultEnergySystemVentilationView';
import { HvacResultEnergySystemView } from 'src/modules/hvac/views/HvacResultEnergySystemView';

type Props = {
  readonly form: FormController<HvacFormData, FormError>;
};

export const HvacResultEnergyView = ({ form }: Props): React.ReactElement => {
  const intl = useIntl();

  const currency = useFormWatch(form, form.field.at('currency').value.get);
  const heatingEnabled = useFormWatch(form, form.field.at('assessment').at('heating').at('enabled').value.get);
  const coolingEnabled = useFormWatch(form, form.field.at('assessment').at('cooling').at('enabled').value.get);
  const ventilationEnabled = useFormWatch(form, form.field.at('assessment').at('ventilation').at('enabled').value.get);

  return (
    <>
      <TileCard title={<FormattedMessage id="hvac/energy/title"/>}>
        <div className="bp-hvac-result-energy-view__row">
          <div className="bp-hvac-result-energy-view__items">
            <div className={clsx(
              'bp-hvac-result-energy-view__item',
              { 'bp-hvac-result-energy-view__item--disabled': !heatingEnabled },
            )}
            >
              <div className={clsx('bp-hvac-result-energy-view__header', {
                'bp-hvac-result-energy-view__header--disabled': !heatingEnabled,
              })}
              >
                <div className="bp-hvac-result-energy-view__title-wrapper">
                  <div className="bp-hvac-result-energy-view__title-icon">
                    <HeatingIcon/>
                  </div>

                  <BodyLargeRegularText>
                    <FormattedMessage id="hvac/energy/heating"/>
                  </BodyLargeRegularText>
                </div>
              </div>

              <HvacResultEnergySystemView
                title={<FormattedMessage id="hvac/energy/electricalEnergy/heating"/>}
                path={form.field.at('assessment').at('heating')}
                disabled={!heatingEnabled}
                energySources={ENERGY_SOURCE_HEATING_OPTIONS}
                tooltipTextId="hvac/energy/electricalEnergy/heating/tooltip"
              />
            </div>

            <div className={clsx(
              'bp-hvac-result-energy-view__item',
              { 'bp-hvac-result-energy-view__item--disabled': !coolingEnabled },
            )}
            >
              <div className={clsx('bp-hvac-result-energy-view__header', {
                'bp-hvac-result-energy-view__header--disabled': !coolingEnabled,
              })}
              >
                <div className="bp-hvac-result-energy-view__title-wrapper">
                  <div className="bp-hvac-result-energy-view__title-icon">
                    <CoolingIcon/>
                  </div>

                  <BodyLargeRegularText>
                    <FormattedMessage id="hvac/energy/cooling"/>
                  </BodyLargeRegularText>
                </div>
              </div>

              <HvacResultEnergySystemView
                title={<FormattedMessage id="hvac/energy/electricalEnergy/cooling"/>}
                path={form.field.at('assessment').at('cooling')}
                disabled={!coolingEnabled}
                energySources={ENERGY_SOURCE_COOLING_OPTIONS}
                tooltipTextId="hvac/energy/electricalEnergy/cooling/tooltip"
              />
            </div>

            <div className={clsx(
              'bp-hvac-result-energy-view__item',
              { 'bp-hvac-result-energy-view__item--disabled': !ventilationEnabled },
            )}
            >
              <div className="bp-hvac-result-energy-view__header"/>

              <HvacResultEnergySystemVentilationView
                path={form.field.at('assessment').at('ventilation')}
                disabled={!ventilationEnabled}
              />
            </div>
          </div>
        </div>
      </TileCard>

      <TileCard title={<FormattedMessage id="hvac/energyPrices/title"/>}>
        <div className="bp-hvac-result-energy-view__row">
          <div className="bp-hvac-result-energy-view__items">
            <div className="bp-hvac-result-energy-view__item">
              <DropdownField
                size="md"
                titleText={intl.formatMessage({ id: 'hvac/energy/currency' })}
                field={form.field.at('currency').transform(ensureNonNull())}
                options={CURRENCY_CODE_OPTIONS}
                renderSelectedItem={renderCurrencyCodeOption}
                itemToElement={renderCurrencyCodeOption}
              />
            </div>
          </div>
        </div>

        <div className="bp-hvac-result-energy-view__row">
          <HvacResultEnergyPricesView
            path={form.field.at('assessment')}
            currency={currency}
            coolingEnabled={coolingEnabled}
            heatingEnabled={heatingEnabled}
            horizontal={true}
          />
        </div>
      </TileCard>
    </>
  );
};

function renderCurrencyCodeOption(option: CurrencyCode): React.ReactElement {
  return <FormattedMessage id={`common/currency/${option}`}/>;
}
