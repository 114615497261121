import { SagaIterator } from 'redux-saga';
import { PROFILE_CHANGE_PASSWORD, PROFILE_SAVE } from 'src/modules/profile/actions/ProfileActions';
import { profileChangePasswordSaga } from 'src/modules/profile/sagas/profileChangePasswordSaga';
import { profileSaveSaga } from 'src/modules/profile/sagas/profileSaveSaga';
import { takeLatest } from 'typed-redux-saga';

export function* profileSaga(): SagaIterator<void> {
  yield* takeLatest(PROFILE_SAVE.isRequest, profileSaveSaga);
  yield* takeLatest(PROFILE_CHANGE_PASSWORD.isRequest, profileChangePasswordSaga);
}
