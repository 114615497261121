import { Integer } from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import { AuthUser } from 'src/modules/common/types/AuthUser';
import { PROJECT_LIST_PAGE_SIZE } from 'src/modules/project-list/constants/pageSize';
import { ProjectListData } from 'src/modules/project-list/types/ProjectListData';
import { ProjectListSlice } from 'src/modules/project-list/types/ProjectListSlice';
import { getProjectFilter } from 'src/modules/project-list/utils/filter';
import { getProjectOrd } from 'src/modules/project-list/utils/sort';

export function applyProjectListQuery(
  { projects, query }: ProjectListData,
  authUser: AuthUser,
): ProjectListSlice {
  const filtered = projects.filter(getProjectFilter(query.filter));
  const pageSize = PROJECT_LIST_PAGE_SIZE[query.view];

  return {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    total: filtered.length as Integer,
    found: pipe(
      filtered,
      (data) => data.sort(getProjectOrd(query.sort, authUser).compare),
      (data) => data.slice((query.page - 1) * pageSize, query.page * pageSize),
    ),
  };
}
