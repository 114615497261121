/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-constructed-context-values */
import './OutputDocument.scss';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { OutputDocumentContext, OutputDocumentContextData } from 'src/modules/output/context/OutputDocumentContext';
import { OutputPageContext } from 'src/modules/output/context/OutputPageContext';
import { OutputDocumentPageInfo } from 'src/modules/output/types/OutputDocumentPageInfo';

type Props = {
  readonly children: React.ReactNode;
};

export const OutputDocument = ({ children }: Props): React.ReactElement => {
  const pageContents = useRef(new Map<string, OutputDocumentPageInfo>());
  const documentContext = useMemo((): OutputDocumentContextData => ({
    registerPage: (page: OutputDocumentPageInfo): void => {
      pageContents.current = pageContents.current.set(page.id, page);
    },
    unregisterPage: (id: string): void => {
      pageContents.current.delete(id);
    },
  }), []);

  const [collectedPages, setCollectedPages] = useState<React.ReactElement[] | null>(null);
  useLayoutEffect(() => {
    const timerId = window.setTimeout(() => {
      setCollectedPages(
        [...pageContents.current.values()]
          .filter((it) => it.visible)
          .sort((a, b) => a.order - b.order)
          .map((it) => it.content),
      );
    }, 100);
    return () => window.clearTimeout(timerId);
  }, []);

  return (
    <div className="bp-output-document" data-ready={collectedPages !== null}>
      {collectedPages !== null && collectedPages.map((page, index) => (
        <div key={index} className="bp-output-document__page">
          <OutputPageContext.Provider value={{ pageNumber: index + 1, totalPages: collectedPages.length }}>
            {page}
          </OutputPageContext.Provider>
        </div>
      ))}
      {collectedPages === null && (
        <OutputDocumentContext.Provider value={documentContext}>
          <div className="bp-output-document__temp">
            {children}
          </div>
        </OutputDocumentContext.Provider>
      )}
    </div>
  );
};
