import { SchemaArea } from '@belimo-retrofit-portal/logic';
import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { CHANGE_OVER_LEVEL, DIFFERENT_TEMPERATURE_LEVEL, WATER_TO_AIR } from 'src/modules/config/codecs/ConfigSchema';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import {
  MultipleSchemaFilterForm,
  MultipleSchemaPreselectedData,
  SchemaSelectedData,
} from 'src/modules/schema/types/SchemaSelectionState';

export const SCHEMA_FILTER_FORM: C.Codec<unknown, Json, SchemaFilterForm> = C.struct({
  waterToAir: C.nullable(WATER_TO_AIR),
  changeOverLevel: C.nullable(CHANGE_OVER_LEVEL),
  differentTemperatureLevel: C.nullable(DIFFERENT_TEMPERATURE_LEVEL),
});

export const SCHEMA_PRESELECTED_CODEC: C.Codec<unknown, Json, SchemaSelectedData> = C.struct({
  id: C.string,
});

export const MULTIPLE_SCHEMA_FILTER_FORM: C.Codec<unknown, Json, MultipleSchemaFilterForm> = C.struct({
  [SchemaArea.HEATING]: SCHEMA_FILTER_FORM,
  [SchemaArea.COOLING]: SCHEMA_FILTER_FORM,
  [SchemaArea.VENTILATION]: SCHEMA_FILTER_FORM,
});

export const MULTIPLE_SCHEMA_PRESELECTED_CODEC: C.Codec<unknown, Json, MultipleSchemaPreselectedData> = C.struct({
  [SchemaArea.HEATING]: C.nullable(SCHEMA_PRESELECTED_CODEC),
  [SchemaArea.COOLING]: C.nullable(SCHEMA_PRESELECTED_CODEC),
  [SchemaArea.VENTILATION]: C.nullable(SCHEMA_PRESELECTED_CODEC),
});
