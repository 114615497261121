import './SchemaEditScreen.scss';
import { Matrix } from '@belimo-retrofit-portal/logic';
import { Add } from '@carbon/icons-react';
import { Tabs, Tooltip } from '@carbon/react';
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CustomTab, CustomTabList } from 'src/modules/common/components/CustomTabList';
import { HorizontalDivider } from 'src/modules/common/components/HorizontalDivider';
import { DeleteIcon } from 'src/modules/common/icons/DeleteIcon';
import { ResetIcon } from 'src/modules/common/icons/ResetIcon';
import { bind } from 'src/modules/common/utils/decorator';
import { SchemaAssignment } from 'src/modules/schema/types/SchemaAssignment';
import { SchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { CommentVersionModal } from 'src/modules/schema/views/CommentVersionModal';
import { DeleteVersionDialog } from 'src/modules/schema/views/DeleteVersionDialog';
import { SchemaCanvas } from 'src/modules/schema/views/SchemaCanvas';
import { SchemaClearContainersConfirmation } from 'src/modules/schema/views/SchemaClearContainersConfirmation';

type Props = {
  readonly schema: SchemaFormData;
  readonly selectedVersionIndex: number;
  readonly isCommentModalOpen: boolean;

  readonly handleCommentModalClose: () => void;
  readonly onSelectVersionIndex: React.Dispatch<React.SetStateAction<number>>;
  readonly onCreateVersion: (version: SchemaVersion) => void;
  readonly onRemoveVersion: (version: SchemaVersion) => void;
  readonly onChangeVersion: (version: SchemaVersion) => void;
  readonly onChangeSchemaMatrix: (matrix: Matrix) => void;
  readonly changeComment: (comment: string) => void;
  readonly schemaMatrix: Matrix;
};
type State = {
  readonly schema: SchemaFormData;

  readonly isClearConfirmationOpen: boolean;
  readonly isDeleteConfirmationOpen: boolean;
};

class SchemaEditScreenWithoutContext extends React.Component<WrappedComponentProps<Props>, State> {
  public readonly state: State = {
    // eslint-disable-next-line react/destructuring-assignment
    schema: this.props.schema,

    isClearConfirmationOpen: false,
    isDeleteConfirmationOpen: false,
  };

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    const { onSelectVersionIndex } = nextProps;

    if (nextProps.schema.future.length !== prevState.schema.future.length) {
      onSelectVersionIndex(nextProps.schema.future.length);
    }

    return nextProps;
  }

  public render(): React.ReactElement {
    const {
      schema,
      intl,
      isCommentModalOpen,
      selectedVersionIndex,
      schemaMatrix,
      onSelectVersionIndex,
      handleCommentModalClose,
      onChangeSchemaMatrix,
      changeComment,
    } = this.props;
    const { isClearConfirmationOpen, isDeleteConfirmationOpen } = this.state;

    const isMaximumVersionAmountExceeded = schema.future.length >= 5;

    return (
      <div className="bp-schema-edit-screen">
        <div className="bp-schema-edit-screen__header">
          <p className="bp-schema-edit-screen__description">
            <FormattedMessage
              id="schemaEdit/subTitle/details"
              values={{
                id: schema.schema.id,
                details: <FormattedMessage id={schema.schema.name}/>,
              }}
            />
          </p>
        </div>

        <HorizontalDivider/>

        <div className="bp-schema-edit-screen__content">
          <div className="bp-schema-edit-screen__content-tabs">
            <Tabs selectedIndex={selectedVersionIndex}>
              <CustomTabList
                ariaLabel={intl.formatMessage({ id: 'schemaEdit/versionTabs' })}
                contained={true}
                isFullWidth={false}
                theme="white"
              >
                <CustomTab
                  onClick={(): void => onSelectVersionIndex(0)}
                >
                  <FormattedMessage id="schemaEdit/version/actual"/>
                </CustomTab>

                {schema.future.map((future, index) => (
                  <CustomTab
                    key={future.number}
                    // the first version offset
                    onClick={(): void => onSelectVersionIndex(index + 1)}
                  >
                    <FormattedMessage
                      id="schemaEdit/version/future"
                      values={{ number: future.number }}
                    />
                  </CustomTab>
                ))}
              </CustomTabList>
            </Tabs>

            <div className="bp-schema-edit-screen__content-actions">
              <CustomButton
                size="lg"
                className="bp-schema-edit-screen__content-button"
                iconDescription={intl.formatMessage({ id: 'schemaEdit/clear' })}
                hasIconOnly={true}
                kind="ghost"
                onClick={this.handleClearOpen}
              >
                <ResetIcon/>
              </CustomButton>

              <CustomButton
                size="lg"
                className="bp-schema-edit-screen__content-button"
                iconDescription={intl.formatMessage({ id: 'schemaEdit/delete' })}
                hasIconOnly={true}
                kind="ghost"
                onClick={this.handleDeleleOpen}
              >
                <DeleteIcon/>
              </CustomButton>

              {isMaximumVersionAmountExceeded ? (
                <Tooltip align="left" description={intl.formatMessage({ id: 'schemaEdit/version/maximumExceeded' })}>
                  <CustomButton
                    as="div"
                    onClick={this.handleVersionCreate}
                    disabled={true}
                    size="lg"
                  >
                    <FormattedMessage id="schemaEdit/version/add"/>

                    <Add size={22}/>
                  </CustomButton>
                </Tooltip>
              ) : (
                <CustomButton
                  onClick={this.handleVersionCreate}
                  size="lg"
                >
                  <FormattedMessage id="schemaEdit/version/add"/>

                  <Add size={22}/>
                </CustomButton>
              )}

            </div>
          </div>

          <SchemaCanvas
            schema={schema.schema}
            schemaMatrix={schemaMatrix}
            assignments={this.currentVersion.assignments}
            onChange={this.handleAssignmentsChange}
            onChangeSchemaMatrix={onChangeSchemaMatrix}
          />
        </div>

        <CommentVersionModal
          onClose={handleCommentModalClose}
          isOpen={isCommentModalOpen}
          comment={schema.comment}
          onSubmit={changeComment}
        />

        <DeleteVersionDialog
          isOpen={isDeleteConfirmationOpen}
          onClose={this.handleDeleleCancel}
          onConfirm={this.handleDeleleSubmit}
          version={this.currentVersion}
        />

        <SchemaClearContainersConfirmation
          isOpen={isClearConfirmationOpen}
          onCancel={this.handleClearCancel}
          onSubmit={this.handleClearSubmit}
        />
      </div>
    );
  }

  private get currentVersion(): SchemaVersion {
    const { selectedVersionIndex } = this.props;
    const { schema } = this.state;

    const versions = [schema.actual, ...schema.future];

    const currentVersion = selectedVersionIndex
      ? versions[selectedVersionIndex]
      : schema.actual;

    return currentVersion;
  }

  @bind()
  private handleDeleleOpen(): void {
    this.setState({ isDeleteConfirmationOpen: true });
  }

  @bind()
  private handleDeleleCancel(): void {
    this.setState({ isDeleteConfirmationOpen: false });
  }

  @bind()
  private handleDeleleSubmit(): void {
    this.setState({ isDeleteConfirmationOpen: false });

    const { onRemoveVersion, onSelectVersionIndex } = this.props;

    onRemoveVersion(this.currentVersion);
    // in case of deleting the actual version
    onSelectVersionIndex((prev) => Math.max(prev - 1, 0));
  }

  @bind()
  private handleClearOpen(): void {
    this.setState({ isClearConfirmationOpen: true });
  }

  @bind()
  private handleClearCancel(): void {
    this.setState({ isClearConfirmationOpen: false });
  }

  @bind()
  private handleClearSubmit(): void {
    this.setState({ isClearConfirmationOpen: false });

    const { onChangeVersion } = this.props;

    onChangeVersion({ ...this.currentVersion, assignments: [] });
  }

  @bind()
  private handleAssignmentsChange(assignments: ReadonlyArray<SchemaAssignment>): void {
    const { onChangeVersion } = this.props;

    onChangeVersion({ ...this.currentVersion, assignments });
  }

  @bind()
  private handleVersionCreate(): void {
    const { schema, onCreateVersion, onChangeVersion } = this.props;

    // only 5 future versions are allowed
    if (schema.future.length >= 5) {
      return;
    }

    onCreateVersion(this.currentVersion);
    onChangeVersion(this.currentVersion);
  }
}

export const SchemaEditScreen = injectIntl(SchemaEditScreenWithoutContext);
