import { fromEvent, NEVER, Observable } from 'rxjs';
import { filter, map, pairwise, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { SchemaEventCanvas } from 'src/modules/schema/types/SchemaEvent';

export function canvasGesture(
  element: Element,
): Observable<SchemaEventCanvas> {
  const useGesture = (
    window.GestureEvent !== undefined &&
    window.TouchEvent === undefined
  );
  if (!useGesture) {
    // we are not using Safari on macOS
    return NEVER;
  }

  const start$ = fromEvent<GestureEvent>(element, 'gesturestart', {
    once: false,
    capture: false,
    passive: false,
  });
  const change$ = fromEvent<GestureEvent>(element, 'gesturechange', {
    once: false,
    capture: false,
    passive: false,
  });
  const stop$ = fromEvent<GestureEvent>(element, 'gestureend', {
    once: false,
    capture: false,
    passive: true,
  });

  return start$.pipe(
    filter((event) => event.cancelable),
    tap((event) => event.preventDefault()),
    switchMap(() => change$.pipe(
      takeUntil(stop$),
      takeWhile((event) => event.cancelable),
      tap((event) => event.preventDefault()),
      pairwise(),
      map(([prev, curr]): SchemaEventCanvas => ({
        type: 'zoom',
        mode: 'relative',
        delta: curr.scale / prev.scale,
        point: {
          x: curr.pageX,
          y: curr.pageY,
        },
      })),
    )),
  );
}
