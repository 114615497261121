import { HvacAssessmentCategories } from 'src/modules/hvac/types/HvacAssessmentCategories';

export const ISO_CATEGORIES: Record<string, HvacAssessmentCategories> = {
  1.1: HvacAssessmentCategories.EMISSION,
  '1.1a': HvacAssessmentCategories.EMISSION,
  1.2: HvacAssessmentCategories.EMISSION,
  1.3: HvacAssessmentCategories.DISTRIBUTION,
  1.4: HvacAssessmentCategories.DISTRIBUTION,
  '1.4a': HvacAssessmentCategories.DISTRIBUTION,
  1.5: HvacAssessmentCategories.DISTRIBUTION,
  1.6: HvacAssessmentCategories.GENERATION,
  1.7: HvacAssessmentCategories.GENERATION,
  1.8: HvacAssessmentCategories.GENERATION,
  1.9: HvacAssessmentCategories.GENERATION,
  '1.10': HvacAssessmentCategories.GENERATION,

  3.1: HvacAssessmentCategories.EMISSION,
  '3.1a': HvacAssessmentCategories.EMISSION,
  3.2: HvacAssessmentCategories.EMISSION,
  3.3: HvacAssessmentCategories.DISTRIBUTION,
  3.4: HvacAssessmentCategories.DISTRIBUTION,
  '3.4a': HvacAssessmentCategories.DISTRIBUTION,
  3.5: HvacAssessmentCategories.DISTRIBUTION,
  3.6: HvacAssessmentCategories.GENERATION,
  3.7: HvacAssessmentCategories.GENERATION,
  3.8: HvacAssessmentCategories.GENERATION,
  3.9: HvacAssessmentCategories.GENERATION,

  4.1: HvacAssessmentCategories.EMISSION,
  4.2: HvacAssessmentCategories.EMISSION,
  4.3: HvacAssessmentCategories.EMISSION,
  4.4: HvacAssessmentCategories.DISTRIBUTION,
  4.5: HvacAssessmentCategories.DISTRIBUTION,
  4.6: HvacAssessmentCategories.DISTRIBUTION,
  4.7: HvacAssessmentCategories.DISTRIBUTION,
  4.8: HvacAssessmentCategories.DISTRIBUTION,
  4.9: HvacAssessmentCategories.DISTRIBUTION,
  '4.10': HvacAssessmentCategories.DISTRIBUTION,
};
