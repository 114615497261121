import { ISO_CATEGORIES } from 'src/modules/hvac/constants/ISOCategories';
import { HvacAssessmentCategories } from 'src/modules/hvac/types/HvacAssessmentCategories';
import { HvacFormSectionData } from 'src/modules/hvac/types/HvacFormData';

export function filterHvacSectionByCategory(
  sections: readonly HvacFormSectionData[],
  category: HvacAssessmentCategories,
): { section: HvacFormSectionData; index: number }[] {
  const EMPTY_SECTIONS: { section: HvacFormSectionData; index: number }[] = [];
  return sections.reduce((prev, curr, index) => {
    if (ISO_CATEGORIES[curr.config.number] === category) {
      return [
        ...prev,
        {
          section: curr,
          index: index,
        }];
    }

    return prev;
  }, EMPTY_SECTIONS);
}
