import { ProjectData, ProjectReport, SchemaArea } from '@belimo-retrofit-portal/logic';

export function getReportPreselectedFutureVersion(
  prevProjectData: ProjectData,
  nextProjectData: ProjectData,
  area: SchemaArea,
): ProjectReport {
  const futureSchemaFirstId = nextProjectData.schema[area]?.future[0]?.id ?? null;

  const previousReport = prevProjectData.report;
  const previousFutureVersionSelected = previousReport.schema[area].futureVersion;
  const previousFutureVersionFound = nextProjectData.schema[area]?.future
    .find((version) => version.id === previousFutureVersionSelected);

  const report: ProjectReport = {
    ...previousReport,
    schema: {
      ...previousReport.schema,
      [area]: {
        ...previousReport.schema[area],
        futureVersion: previousFutureVersionFound
          ? previousFutureVersionFound.id
          : futureSchemaFirstId,
      },
    },
  };

  return report;
}
