import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly title: string;
};

export const ProjectTitle = ({ title }: Props): React.ReactElement => (
  title.trim().length === 0
    ? <FormattedMessage id="common/noProjectTitle"/>
    : <>{title.trim()}</>
);
