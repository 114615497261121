import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CurrencyExchangeIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      {/* eslint-disable-next-line */}
      <path fill="currentColor" d="M12 21.7c-2.084 0-3.938-.575-5.563-1.725-1.625-1.15-2.812-2.7-3.562-4.65V18.8h-.7v-4.7h4.7v.7H3.45c.6 1.867 1.691 3.367 3.275 4.5C8.308 20.433 10.066 21 12 21a8.902 8.902 0 0 0 6.1-2.363 9.067 9.067 0 0 0 1.962-2.65 9.15 9.15 0 0 0 .913-3.337h.7a9.341 9.341 0 0 1-.938 3.55 10.071 10.071 0 0 1-2.1 2.875 9.543 9.543 0 0 1-3 1.925 9.52 9.52 0 0 1-3.637.7Zm-.4-3.2v-1.2c-.65-.15-1.213-.4-1.688-.75-.475-.35-.862-.85-1.162-1.5l.65-.3c.3.6.687 1.067 1.162 1.4a2.7 2.7 0 0 0 1.588.5c.6 0 1.175-.162 1.725-.487.55-.325.825-.863.825-1.613 0-.583-.18-1.038-.538-1.363-.358-.324-1.079-.687-2.162-1.087-1.1-.4-1.875-.808-2.325-1.225C9.225 10.458 9 9.9 9 9.2c0-.583.225-1.125.675-1.625.45-.5 1.108-.808 1.975-.925V5.5h.75v1.15c.5.033.97.196 1.412.488.442.291.788.662 1.038 1.112l-.65.25a2.976 2.976 0 0 0-.875-.862c-.35-.225-.775-.338-1.275-.338-.684 0-1.238.183-1.663.55-.425.367-.637.817-.637 1.35 0 .533.141.942.425 1.225.283.283.991.625 2.125 1.025 1.2.433 2.016.883 2.45 1.35.433.467.65 1.05.65 1.75 0 .467-.096.875-.288 1.225-.191.35-.437.638-.737.862-.3.226-.63.396-.988.513a3.991 3.991 0 0 1-1.037.2v1.15h-.75Zm-9.275-7.15c.116-1.35.454-2.58 1.012-3.687A9.826 9.826 0 0 1 5.487 4.8a9.459 9.459 0 0 1 2.988-1.85A9.658 9.658 0 0 1 12 2.3c2.033 0 3.887.58 5.562 1.738 1.675 1.158 2.863 2.737 3.563 4.737V5.2h.7v4.7h-4.7v-.7h3.425c-.55-1.8-1.621-3.283-3.213-4.45C15.746 3.583 13.967 3 12 3c-1.117 0-2.192.2-3.225.6A9.083 9.083 0 0 0 6 5.288a9.064 9.064 0 0 0-2.025 2.625c-.534 1.025-.85 2.17-.95 3.437h-.7Z"/>
    </svg>
  </IconWrapper>
));
