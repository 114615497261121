import './MultipleSchemasScreen.scss';
import { Matrix, SchemaArea } from '@belimo-retrofit-portal/logic';
import { Checkmark } from '@carbon/icons-react';
import { RadioButton, RadioButtonGroup } from '@carbon/react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BottomNavigationHelper } from 'src/modules/common/components/BottomNavigationHelper';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { CircleCheckFilledIcon } from 'src/modules/common/icons/CircleCheckFilledIcon';
import { CommentIcon } from 'src/modules/common/icons/CommentIcon';
import { ConfigSchema } from 'src/modules/config/types/ConfigSchema';
import { HvacFormAssessmentData } from 'src/modules/hvac/types/HvacFormData';
import { SCHEMA_AREA_MAP } from 'src/modules/schema/constants/schema';
import { MultipleSchemaMatrix } from 'src/modules/schema/types/MultipleSchemaMatrix';
import { SchemaFilterForm } from 'src/modules/schema/types/SchemaFilterForm';
import { MultipleSchemaFormData } from 'src/modules/schema/types/SchemaFormData';
import {
  MultipleSchemaFilterForm,
  MultipleSchemaPreselectedData,
  SchemaSelectedData,
} from 'src/modules/schema/types/SchemaSelectionState';
import { SchemaVersion } from 'src/modules/schema/types/SchemaVersion';
import { filterSchemas } from 'src/modules/schema/utils/filter';
import { SchemaEditScreen } from 'src/modules/schema/views/SchemaEditScreen';
import { SchemaSelectScreen } from 'src/modules/schema/views/SchemaSelectScreen';

type Props = {
  readonly projectId: string;
  readonly schemas: MultipleSchemaFormData;
  readonly hvacAssessmentFormData: HvacFormAssessmentData;
  readonly storedFilterFormValue: MultipleSchemaFilterForm;
  readonly storedPreselectedSchema: MultipleSchemaPreselectedData;
  readonly multipleSchemasMatrix: MultipleSchemaMatrix;
  readonly allConfigschemas: ReadonlyArray<ConfigSchema>;
  readonly selectTemplate: (data: ConfigSchema) => void;
  readonly createVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly changeVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly removeVersion: (data: SchemaVersion, meta: SchemaArea) => void;
  readonly changeComment: (data: string, meta: SchemaArea) => void;
  readonly syncSchemaMatrix: (data: Matrix, meta: SchemaArea) => void;
  readonly savePreselectedSchema: (data: SchemaSelectedData | null, meta: SchemaArea) => void;
  readonly saveSchemaFilter: (data: SchemaFilterForm, meta: SchemaArea) => void;
};

export const MultipleSchemasScreen = memo(({
  projectId,
  storedFilterFormValue,
  schemas,
  hvacAssessmentFormData,
  storedPreselectedSchema,
  multipleSchemasMatrix,
  allConfigschemas,
  selectTemplate,
  createVersion,
  changeVersion,
  removeVersion,
  changeComment,
  syncSchemaMatrix,
  savePreselectedSchema,
  saveSchemaFilter,
}: Props): React.ReactElement => {
  const intl = useIntl();

  const projectHvacUrl = `/project/${encodeURIComponent(projectId)}/hvac`;
  const projectEvaluationUrl = `/project/${encodeURIComponent(projectId)}/evaluation`;

  const [activeTab, setActiveTab] = useState<SchemaArea>(SchemaArea.HEATING);
  const [selectedVersionIndex, setSelectedVersionIndex] = useState(0);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);

  const currentSchema = useMemo(() => schemas[activeTab] || null, [schemas, activeTab]);

  const currentMatrix = multipleSchemasMatrix[activeTab];

  const activeSchemas = useMemo(
    () => allConfigschemas.filter((s) => SCHEMA_AREA_MAP[s.thermal_energy] === activeTab),
    [activeTab, allConfigschemas],
  );

  const filteredSchemas = useMemo(
    () => filterSchemas(activeSchemas, storedFilterFormValue[activeTab]),
    [
      activeSchemas,
      storedFilterFormValue,
      activeTab,
    ],
  );

  const currentSelectedSchema = useMemo(() => {
    if (!storedPreselectedSchema[activeTab]) {
      return null;
    }

    return filteredSchemas.find((item) => item.id === storedPreselectedSchema[activeTab]?.id) || null;
  }, [activeTab, filteredSchemas, storedPreselectedSchema]);

  const handleTabChange = useCallback((selection: unknown) => {
    setSelectedVersionIndex(0);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    setActiveTab(selection as SchemaArea);
  }, []);

  const handleSavePreselectedSchema = useCallback((data: SchemaSelectedData | null) => {
    savePreselectedSchema(data, activeTab);
  }, [activeTab, savePreselectedSchema]);

  const handleSaveSchemaFilter = useCallback((data: SchemaFilterForm) => {
    saveSchemaFilter(data, activeTab);
  }, [activeTab, saveSchemaFilter]);

  const handleSchemaCreate = useCallback(() => {
    if (currentSelectedSchema) {
      selectTemplate(currentSelectedSchema);
    }
  }, [currentSelectedSchema, selectTemplate]);

  useEffect(() => {
    if (!currentSelectedSchema && activeSchemas.length > 0 && filteredSchemas.length > 0) {
      handleSavePreselectedSchema(filteredSchemas[0] ? filteredSchemas[0] : null);
    }
  }, [currentSelectedSchema, filteredSchemas, handleSavePreselectedSchema, activeSchemas.length]);

  return (
    <div className="bp-multiple-schemas-screen">
      <div className="bp-multiple-schemas-screen__content">
        <div className="bp-multiple-schemas-screen__controls">
          <RadioButtonGroup
            className="bp-multiple-schemas-screen__radio-group"
            name={intl.formatMessage({ id: 'schemaPage/tab/group' })}
            valueSelected={activeTab}
            onChange={handleTabChange}
          >
            {SCHEMA_AREAS.map((area) => (
              <RadioButton
                key={area}
                value={area}
                id={area}
                labelText={(
                  <div className="bp-multiple-schemas-screen__tab-content">
                    <FormattedMessage id={`schemaPage/tab/${area}`}/>

                    {schemas[area]
                      ? (
                        <Checkmark color={CARBON_THEME.onSurfaceLightSecondary}/>
                      )
                      : null}
                  </div>
                )}
              />
            ))}
          </RadioButtonGroup>

          {currentSchema && (
            <CustomButton
              className="bp-multiple-schemas-screen__button"
              kind="tertiary"
              size="md"
              onClick={() => setCommentModalOpen(true)}
            >
              <FormattedMessage id="schemaEdit/addComment"/>

              <div className="bp-multiple-schemas-screen__button-icon">
                <CommentIcon/>
              </div>

              <div className="bp-multiple-schemas-screen__comment-badge">
                {(currentSchema?.comment?.length || 0) > 0 && <CircleCheckFilledIcon/>}
              </div>
            </CustomButton>
          )}
        </div>

        {currentSchema ? (
          <SchemaEditScreen
            handleCommentModalClose={() => setCommentModalOpen(false)}
            isCommentModalOpen={isCommentModalOpen}
            key={activeTab}
            schema={currentSchema}
            schemaMatrix={currentMatrix}
            selectedVersionIndex={selectedVersionIndex}
            onSelectVersionIndex={setSelectedVersionIndex}
            onChangeSchemaMatrix={(matrix) => syncSchemaMatrix(matrix, activeTab)}
            onCreateVersion={(version) => createVersion(version, activeTab)}
            onRemoveVersion={(version) => removeVersion(version, activeTab)}
            onChangeVersion={(version) => changeVersion(version, activeTab)}
            changeComment={(comment) => changeComment(comment, activeTab)}
          />
        ) : (
          <SchemaSelectScreen
            key={activeTab}
            area={activeTab}
            schemas={activeSchemas}
            filteredSchemas={filteredSchemas}
            storedFilterFormValue={storedFilterFormValue[activeTab]}
            currentSelectedSchema={currentSelectedSchema}
            onSavePreselectedSchema={handleSavePreselectedSchema}
            onSaveSchemaFilter={handleSaveSchemaFilter}
            handleSchemaCreate={handleSchemaCreate}
          />
        )}

        <BottomNavigationHelper
          routeBackText={intl.formatMessage({ id: 'common/bottomNavigation/hvac' })}
          routeBackPath={projectHvacUrl}
          routeForthText={intl.formatMessage({ id: 'common/bottomNavigation/evaluation' })}
          routeForthPath={projectEvaluationUrl}
        />
      </div>
    </div>
  );
});

const SCHEMA_AREAS = [
  SchemaArea.HEATING,
  SchemaArea.COOLING,
];
