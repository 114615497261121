import { none, some } from 'fp-ts/Option';
import * as O from 'monocle-ts/Optional';
import { Future, FUTURE } from 'src/modules/common/types/Future';

export function optionalFuture<T>(): O.Optional<Future<T>, T> {
  return O.optional(
    (state) => (state.state === 'success'
      ? some(state.value)
      : none),
    (data) => (state) => (state.state === 'success'
      ? FUTURE.success(data)
      : state),
  );
}
