import { replace } from 'connected-react-router';
import { Eq, eqStrict, struct } from 'fp-ts/Eq';
import { SagaIterator } from 'redux-saga';
import { INTEGER_ONE } from 'src/modules/common/constants/integer';
import { PROJECT_LIST_FILTER } from 'src/modules/project-list/actions/ProjectListActions';
import { getProjectListQuery } from 'src/modules/project-list/selectors/getProjectListQuery';
import { ProjectListFilter } from 'src/modules/project-list/types/ProjectListFilter';
import { buildProjectListQuery } from 'src/modules/project-list/utils/query';
import { navigate } from 'src/modules/routing/utils/navigate';
import { logDebug } from 'src/sagas/utils/logging';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, select } from 'typed-redux-saga';

export function* projectListFilterSaga(
  action: GetTriggerActionType<typeof PROJECT_LIST_FILTER>,
): SagaIterator<void> {
  const filter = action.data;
  const query = yield* select(getProjectListQuery);
  if (EQ_FILTER.equals(query.filter, filter)) {
    return;
  }

  yield* call(logDebug, 'Applying project filter...', { filter });
  const search = yield* call(buildProjectListQuery, { ...query, filter: filter, page: INTEGER_ONE });
  yield* call(navigate, replace({ pathname: '/projects', search: search.toString() }));
}

const EQ_SET: Eq<ReadonlySet<unknown>> = {
  equals: (a, b) => (
    a.size === b.size &&
    new Set([...a, ...b]).size === a.size
  ),
};
const EQ_FILTER: Eq<ProjectListFilter> = struct({
  term: eqStrict,
  buildingType: EQ_SET,

  country: EQ_SET,
  city: EQ_SET,

  ratingActual: EQ_SET,
  ratingFuture: EQ_SET,

  ownership: EQ_SET,
});
