import { replace } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { PROJECT_SYNC_RESET } from 'src/modules/project-sync/actions/ProjectSyncActions';
import { PROJECT_VIEW_SHARE } from 'src/modules/project-view/actions/ProjectViewActions';
import { navigate } from 'src/modules/routing/utils/navigate';
import {
  SHARE_PROJECT_CLOSE,
  SHARE_PROJECT_OPEN,
  SHARE_PROJECT_REMOVE_SUBMIT,
} from 'src/modules/sharing-projects/actions/ShareProjectActions';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { call, put, select, take } from 'typed-redux-saga';

export function* projectViewShareSaga(
  action: GetTriggerActionType<typeof PROJECT_VIEW_SHARE>,
): SagaIterator<void> {
  const project = action.data;
  const loggedInUser = yield* select(getLoggedInUser);

  yield* put(SHARE_PROJECT_OPEN.trigger(project));
  const trigger = yield* take([SHARE_PROJECT_REMOVE_SUBMIT.isSuccess, SHARE_PROJECT_CLOSE.is]);
  if (SHARE_PROJECT_CLOSE.is(trigger)) {
    // the Share Project modal was closed
    // there is no need to do anything
    return;
  }

  const hasAccess = (
    project.owner?.id === loggedInUser.id ||
    trigger.data.some((it) => it.id === loggedInUser.id)
  );
  if (hasAccess) {
    // we still have access to the project
    // there is no need to do anything
    return;
  }

  // the project is not accessible for us anymore
  // we need to close the modal immediately
  yield* put(SHARE_PROJECT_CLOSE.trigger());

  // abort project synchronization
  yield* put(PROJECT_SYNC_RESET.trigger());

  // and redirect to the project list
  yield* call(navigate, replace({ pathname: '/projects' }));
}
