import { ProjectData } from '@belimo-retrofit-portal/logic';
import { Project } from 'src/modules/common/types/Project';
import { PROJECT_VERSION } from 'src/modules/project-new/constants/projectData';

export function mergeImportData(
  project: Project,
  importData: ProjectData,
): ProjectData {
  return {
    version: PROJECT_VERSION,

    title: importData.title,
    goals: importData.goals,
    building: importData.building,
    currency: importData.currency,
    participants: importData.participants,
    hvac: importData.hvac,
    report: importData.report,
    schema: importData.schema,
    evaluation: importData.evaluation,

    // these data should stay as it is
    cover: project.data.cover,
  };
}
