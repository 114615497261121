import { createSelector } from 'reselect';
import { BasedataFormData } from 'src/modules/basedata/types/BasedataFormData';
import { Project } from 'src/modules/common/types/Project';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';

const mapProjectDetails = (project: Project): BasedataFormData => ({
  cover: project.data.cover,
  title: project.data.title,
  building: project.data.building,
  participants: project.data.participants,
  projectGoals: project.data.goals,
});

export const getBasedataFormData = createSelector(
  [getProjectDetails],
  mapProjectDetails,
);
