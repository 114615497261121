import './ShareProjectShareForm.scss';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { Project } from 'src/modules/common/types/Project';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { SHARE_PROJECT_FORM_SCHEMA } from 'src/modules/sharing-projects/constants/schema';
import { SharedUser } from 'src/modules/sharing-projects/types/SharedUser';
import { ShareProjectForm } from 'src/modules/sharing-projects/types/ShareProjectForm';

type Props = {
  readonly project: Project;
  readonly users: ReadonlyArray<SharedUser>;
  readonly onSubmit: (form: FormController<ShareProjectForm, FormError>) => void;
};

export const ShareProjectShareForm = ({ project, users, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();

  const form = useForm<ShareProjectForm, FormError>('shareProject.form', {
    reinitialize: false,
    initialValues: {
      users: '',
    },
  });

  const validationSchema = useMemo(() => SHARE_PROJECT_FORM_SCHEMA(
    users
      .map((item) => item.email)
      .concat(project.owner ? [project.owner.email] : []),
  ), [users, project]);
  useFormValidator(form, validationSchema);

  const submitting = useFormWatch(form, form.submitting.get);
  const submitSucceeded = useFormWatch(form, form.submitSucceded.get);

  const handleBlur = useCallback(() => {
    form.change(pipe(
      form.currentState,
      form.submitSucceded.set(false),
    ));
  }, [form]);
  const handleSubmit = useCallback((event: React.FormEvent): void => {
    event.preventDefault();
    onSubmit(form);
  }, [form, onSubmit]);

  return (
    <form
      className="bp-share-project-share-form"
      onSubmit={handleSubmit}
    >
      <TextField
        size="lg"
        labelText=""
        theme="white"
        field={form.field.at('users')}
        autoComplete="off"
        maxLength={500}
        placeholder={intl.formatMessage({ id: 'shareProject/modal/shareField/placeholder' })}
        onBlur={handleBlur}
        disabled={submitting}
        renderHint={() => (submitSucceeded ? (
          <div className="bp-share-project-share-form__input-hint">
            <FormattedMessage id="shareProject/modal/shareField/successHint"/>
          </div>
        ) : '')}
      />

      <CustomButton
        className="bp-share-project-share-form__button"
        type="submit"
        size="lg"
        disabled={submitting}
        loading={submitting}
        onClick={handleSubmit}
      >
        <FormattedMessage id="shareProject/modal/button/share"/>
      </CustomButton>
    </form>
  );
};
