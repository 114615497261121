import { Json } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { DeviceType } from 'src/modules/common/types/DeviceType';

export const DEVICE_TYPE: C.Codec<unknown, Json, DeviceType> = C.literal(
  DeviceType.THROTTLING_CIRCUIT,
  DeviceType.DIVERTING_CIRCUIT,
  DeviceType.INJECTION_CIRCUIT,
  DeviceType.MIXING_CIRCUIT,
  DeviceType.BYPASS,
  DeviceType.PUMP,
  DeviceType.ACTIVE_OR_PASSIVE_CIRCUIT,
  DeviceType.SPECIFIC_CIRCUIT,
  DeviceType.CHANGEOVER,
);
